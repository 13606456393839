import { memo, useEffect, useState } from 'react';
import { format } from 'date-fns';
import { Box, Button, alpha, Divider, TextField } from '@mui/material';
import {
  GridValidRowModel,
  GridRowsProp,
  GridRowModesModel,
  GridRowModes,
  DataGrid,
  GridColDef,
  GridToolbarContainer,
  GridActionsCellItem,
  GridEventListener,
  GridRowId,
  GridRowModel,
  GridRowEditStopReasons,
  GridSlotProps,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
} from '@mui/x-data-grid';
import useSaveRequest from '../../hooks/useSaveRequest';
import useDeleteRequest from '../../hooks/useDeleteRequest';
import { createMapByKey } from '../../utils/createMaps';
import { formatTime } from '../../utils/dateFormatters';
import { ExpenseCategory, IncomeCategory } from '../../types';
import { extensionIconMap } from './Icons';
import Icon from '../ui/Icon';
import { theme } from '../../theme';

declare module '@mui/x-data-grid' {
  interface ToolbarPropsOverrides {
    setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
    setRowModesModel: (newModel: (oldModel: GridRowModesModel) => GridRowModesModel) => void;
  }
}

const CustomToolbar = (
  props: GridSlotProps['toolbar'] & {
    rows: readonly GridValidRowModel[];
    table: string;
    currentMonth?: Date;
  }
) => {
  const { rows, setRows, setRowModesModel, table, currentMonth } = props;

  const getNewRow = () => {
    if (table === 'money') {
      return {
        date: currentMonth,
        type: '支出',
        category: '',
        amount: null,
        content: '',
      };
    }
    if (['income', 'expense', 'healthCategory'].includes(table)) {
      return {
        name: '',
        icon: '',
      };
    }
    if (table === 'foodDB') {
      return {
        name: '',
        perItem: false,
        energy: null,
        protein: null,
        carb: null,
        fat: null,
        salt: null,
      };
    }
    // デフォルトの newRow
    return {
      content: '',
    };
  };

  const getNextId = (data: readonly GridValidRowModel[]) => {
    // 配列が空の場合、初期値として1を返す
    if (data.length === 0) return 1;
    // 最大のidを取得
    const maxId = data.reduce((max, item) => Math.max(max, item.id as number), 0);
    return maxId + 1;
  };

  const addNewRow = () => {
    const id = getNextId(rows);
    const newRow = getNewRow();
    setRows(oldRows => [...oldRows, { id, ...newRow, isNew: true }]);
    setRowModesModel(oldModel => ({
      ...oldModel,
      [id]: {
        mode: GridRowModes.Edit,
      },
    }));
  };

  return (
    <GridToolbarContainer>
      {table !== 'file' && table !== 'nurt' && (
        <>
          <Button
            startIcon={
              <Icon
                icon="add-circle-line"
                size="1rem"
                color={alpha(theme.palette.secondary.light, 0.8)}
              />
            }
            onClick={addNewRow}
            sx={{
              color: alpha(theme.palette.secondary.light, 0.8),
              '@media (hover: hover)': {
                '&:hover': {
                  bgcolor: 'rgba(255, 255, 255, 0.12)',
                },
              },
            }}
          >
            データの追加
          </Button>
          <Divider
            orientation="vertical"
            variant="middle"
            flexItem
            sx={{
              borderColor: alpha(theme.palette.secondary.light, 0.6),
            }}
          />
        </>
      )}
      <GridToolbarColumnsButton
        slotProps={{
          button: {
            startIcon: (
              <Icon
                icon="kanban-view-2"
                size="1rem"
                color={alpha(theme.palette.secondary.light, 0.8)}
              />
            ),
            sx: {
              color: alpha(theme.palette.secondary.light, 0.8),
              '@media (hover: hover)': {
                '&:hover': {
                  bgcolor: 'rgba(255, 255, 255, 0.12)',
                },
              },
            },
          },
        }}
      />
      <Divider
        orientation="vertical"
        variant="middle"
        flexItem
        sx={{
          borderColor: alpha(theme.palette.secondary.light, 0.6),
        }}
      />
      <GridToolbarFilterButton
        slotProps={{
          button: {
            startIcon: (
              <Icon
                icon="filter-2-line"
                size="1rem"
                color={alpha(theme.palette.secondary.light, 0.8)}
              />
            ),
            sx: {
              color: alpha(theme.palette.secondary.light, 0.8),
              '@media (hover: hover)': {
                '&:hover': {
                  bgcolor: 'rgba(255, 255, 255, 0.12)',
                },
              },
            },
          },
        }}
      />
      <Box sx={{ flexGrow: 1 }} />
      <GridToolbarQuickFilter
        autoComplete="off"
        spellCheck={false}
        sx={{
          paddingBottom: 0,
          caretColor: theme.palette.secondary.light,
          '& .MuiInputBase-root': {
            color: theme.palette.secondary.light,
          },
          '.MuiSvgIcon-root': {
            color: alpha(theme.palette.secondary.light, 0.8),
          },
          '.MuiInput-underline:before': {
            borderBottom: `solid 1px transparent`,
          },
          '.MuiInputBase-root.MuiInput-root:hover:not(.Mui-disabled, .Mui-error):before': {
            borderBottom: `solid 1px ${theme.palette.secondary.dark}`,
          },
          '.MuiInputBase-root.MuiInput-root:after': {
            borderBottom: `1px solid ${theme.palette.secondary.light}`,
          },
        }}
      />
    </GridToolbarContainer>
  );
};

interface HasId {
  id: number;
}

// 型ガード関数
const hasId = <T,>(data: T): data is T & HasId => (data as T & HasId).id !== undefined;

interface DataTableProps<T> {
  table: string;
  apiUrl: string;
  gridRows: GridRowsProp | null;
  isDataFetch?: boolean;
  setData?: React.Dispatch<React.SetStateAction<T[] | null>>;
  currentMonth?: Date;
  incomeCategory?: IncomeCategory[];
  expenseCategory?: ExpenseCategory[];
}

const DataTable = <T,>({
  table,
  apiUrl,
  gridRows,
  isDataFetch,
  setData,
  currentMonth,
  incomeCategory,
  expenseCategory,
}: DataTableProps<T>) => {
  useEffect(() => {
    console.log('DataTable.tsx：初回レンダリング');
  }, []);
  console.log('DataTable.tsx：レンダリング');

  const [rows, setRows] = useState<GridRowsProp>(gridRows || []);
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
  const saveRequest = useSaveRequest();
  const deleteRequest = useDeleteRequest();

  // isDataFetchがある場合、データを更新
  useEffect(() => {
    if (isDataFetch) setRows(gridRows || []);
  }, [isDataFetch]);

  // Moneyページの場合、収入カテゴリーと支出カテゴリーのアイコンを表示するためのマップを作成
  const incomeCategoryValueOptions = incomeCategory
    ? incomeCategory.map(category => category.name)
    : [];
  const expenseCategoryValueOptions = expenseCategory
    ? expenseCategory.map(category => category.name)
    : [];

  const incomeCategoryMap: Map<string, IncomeCategory> = incomeCategory
    ? createMapByKey(incomeCategory, 'name')
    : new Map<string, IncomeCategory>();
  const expenseCategoryMap: Map<string, ExpenseCategory> = expenseCategory
    ? createMapByKey(expenseCategory, 'name')
    : new Map<string, ExpenseCategory>();

  // 編集中の行からフォームが外れたとき
  const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      // eslint-disable-next-line no-param-reassign
      event.defaultMuiPrevented = true;
    }
  };

  // 行の編集
  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  // 行の保存
  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  // 行の内容のコピー
  const handleCopyClick = (id: GridRowId) => () => {
    const rowData = rows.find(row => row.id === id) as GridRowModel;
    if (table === 'file') {
      const extension = rowData.extension !== '' ? `.${rowData.extension}` : '';
      const name = rowData.name as string;
      const url = `${rowData.url}${name}${extension}`;
      // テキストをクリップボードにコピー
      navigator.clipboard.writeText(url).catch(error => {
        // エラー処理
        alert('コピーに失敗しました');
        console.error('コピーに失敗しました: ', error);
      });
    }
  };

  // 行の削除
  const handleDeleteClick = (id: GridRowId) => () => {
    const text = table === 'file' ? 'ファイル' : 'データ';
    // eslint-disable-next-line no-restricted-globals, no-alert
    const result = confirm(`${text}を削除しますか？`);
    if (result) {
      if (table === 'file') {
        deleteRequest({
          apiUrl,
          data: {
            name: rows.find(row => row.id === id)?.name as string,
            extension: rows.find(row => row.id === id)?.extension as string,
          },
        })
          .then(() => {
            setRows(rows.filter(row => row.id !== id));
          })
          .catch(error => {
            console.error(`ファイルの削除に失敗しました:`, error);
          });
      } else {
        deleteRequest({
          apiUrl,
          id: String(id),
          target: table,
        })
          .then(response => {
            if (response.message?.indexOf('削除できませんでした') === -1) {
              setRows(rows.filter(row => row.id !== id));
              if (!setData) return;
              setData(prev => {
                if (!prev) return [];
                const newData = prev.filter(data => {
                  if (hasId(data)) {
                    // 型ガードを使って、idがある場合にのみ処理
                    return data.id !== id;
                  }
                  return false;
                });
                return newData;
              });
            } else {
              // 削除できなかった場合に通知
              alert(response.message);
            }
          })
          .catch(error => {
            console.error(`${table}のテーブルの削除に失敗しました:`, error);
          });
      }
    }
  };

  // 編集のキャンセル
  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });
    const editedRow = rows.find(row => row.id === id);
    if (editedRow!.isNew) {
      // 新規追加行の場合
      setRows(rows.filter(row => row.id !== id));
    }
  };

  // 行の更新
  const processRowUpdate = async (newRow: GridValidRowModel): Promise<GridValidRowModel> => {
    let isValidationError = false;
    const errorMessages: string[] = [];

    // 正の整数値かどうか
    const integerCheck = (property: keyof GridRowModel, key: string) => {
      if (!Number.isInteger(newRow[property])) {
        isValidationError = true;
        errorMessages.push(`【${key}】整数値である必要があります。`);
      }
      if (newRow[property] <= 0) {
        isValidationError = true;
        errorMessages.push(`【${key}】0以上である必要があります。`);
      }
    };

    // 日付が設定されているかどうか
    const dateCheck = (property: keyof GridRowModel, key: string) => {
      if (!newRow[property] || Number.isNaN(Date.parse(newRow[property] as string))) {
        isValidationError = true;
        errorMessages.push(`【${key}】日付が正しく設定されていません。`);
      }
    };

    // 空文字または空白のみかどうか
    const blankCheck = (property: keyof GridRowModel, key: string): boolean => {
      if (!newRow[property] || /^\s*$/.test(newRow[property] as string)) {
        isValidationError = true;
        errorMessages.push(`【${key}】内容を入力してください。`);
        return true; // エラーが発生した場合は true を返す
      }
      return false;
    };

    // nullかどうか
    const nullCheck = (property: keyof GridRowModel, key: string) => {
      if (newRow[property] == null) {
        isValidationError = true;
        errorMessages.push(`【${key}】値が正しく設定されていません。`);
      }
    };

    // 文字数制限
    const maxLengthCheck = (property: keyof GridRowModel, key: string, maxLength: number) => {
      if ((newRow[property] as string).length > maxLength) {
        isValidationError = true;
        errorMessages.push(`【${key}】${maxLength}文字以内で入力してください。`);
      }
    };

    // 重複チェック
    const uniqueCheck = (property: keyof GridRowModel, key: string) => {
      const isDuplicate = rows.some(row => row[property] === newRow[property]);
      if (isDuplicate) {
        isValidationError = true;
        errorMessages.push(`【${key}】既存のデータと重複しています`);
      }
    };

    // ファイル名のチェック
    const fileNameCheck = (property: keyof GridRowModel, key: string) => {
      // 使用できない文字の正規表現（\, *, ?, ", <, >, |）
      const invalidChars = /[\\:*?"<>|]/;
      // 使用できない文字が含まれているかチェック
      if (invalidChars.test(newRow[property] as string)) {
        isValidationError = true;
        errorMessages.push(`【${key}】ファイル名に使用できない文字が含まれています。`);
      }
    };

    if (table === 'money') {
      integerCheck('id', 'ID');
      dateCheck('date', '日付');
      integerCheck('amount', '金額');
      blankCheck('content', '内容');
      maxLengthCheck('content', '内容', 100);
      // typeとcategoryのチェック
      if (!['収入', '支出'].includes(newRow.type as string)) {
        isValidationError = true;
        errorMessages.push('【タイプ】「収入」か「支出」を選択してください。');
      } else {
        const categoryOptions =
          newRow.type === '収入'
            ? incomeCategoryValueOptions
            : newRow.type === '支出'
              ? expenseCategoryValueOptions
              : null;
        if (categoryOptions && !categoryOptions.includes(newRow.category as string)) {
          isValidationError = true;
          errorMessages.push('【カテゴリー】有効なカテゴリーを選択してください。');
        }
      }
    }
    if (['income', 'expense', 'healthCategory'].includes(table)) {
      integerCheck('id', 'ID');
      blankCheck('icon', 'アイコン');
      maxLengthCheck('icon', 'アイコン', 30);
      if (!blankCheck('name', 'カテゴリー名')) {
        uniqueCheck('name', 'カテゴリー名');
        maxLengthCheck('name', 'カテゴリー名', 30);
      }
    }
    if (table === 'file') {
      integerCheck('id', 'ID');
      if (!blankCheck('name', 'ファイル名')) {
        fileNameCheck('name', 'ファイル名');
      }
    }
    if (table === 'foodDB') {
      integerCheck('id', 'ID');
      blankCheck('name', '名称');
      maxLengthCheck('name', '名称', 100);
      nullCheck('energy', '熱量');
    }
    if (table === 'fitness') {
      integerCheck('id', 'ID');
      dateCheck('date', '日付');
      blankCheck('content', '内容');
      maxLengthCheck('content', '内容', 100);
    }
    if (table === 'nurt') {
      integerCheck('id', 'ID');
      nullCheck('energy', '熱量');
      nullCheck('protein', 'たんぱく質');
      nullCheck('fat', '脂質');
      nullCheck('carb', '炭水化物');
      nullCheck('salt', '食塩相当量');
    }

    if (isValidationError) {
      // eslint-disable-next-line no-restricted-globals, no-alert
      alert(`以下の内容に不備があります:\n${errorMessages.join('\n')}`);
      throw new Error('入力内容にエラーがあります');
    }

    // エラーがない場合データベースに保存
    let newId = newRow.id as number; // idの初期値を設定
    const oldRow = rows.find(row => row.id === newRow.id) as GridRowModel;
    let updatedRow = {
      ...oldRow,
      id: newId,
      isNew: false,
      ...{ ...(table === 'file' ? { name: oldRow.name as string } : {}) },
    }; // 初期値として更新前の行をセット
    if (table === 'file') {
      try {
        const response = await saveRequest({
          apiUrl,
          data: {
            name: oldRow.name as string,
            extension: oldRow.extension as string,
            newName: newRow.name as string,
          },
        });
        const newName = response.name;
        updatedRow = { ...newRow, name: newName, id: newId, isNew: false }; // 更新後の行をセット
        setRows(rows.map(row => (row.id === newRow.id ? updatedRow : row)));
      } catch (error) {
        console.error(`ファイル名の更新に失敗しました:`, error);
      }
    } else {
      try {
        const response = await saveRequest({
          apiUrl,
          id: String(newRow.id),
          target: table,
          data: {
            ...newRow,
            ...{
              ...(newRow.date ? { date: format(newRow.date as Date, 'yyyy-MM-dd') } : {}),
            },
            ...{
              ...(table === 'money'
                ? {
                    category:
                      newRow.type === '収入'
                        ? incomeCategoryMap.get(newRow.category as string)?.id
                        : expenseCategoryMap.get(newRow.category as string)?.id,
                  }
                : {}),
            },
          },
        });
        newId = Number(response.id); // idを更新
        updatedRow = { ...newRow, id: newId, isNew: false }; // 更新後の行をセット
        setRows(rows.map(row => (row.id === newRow.id ? updatedRow : row)));
        // setDataがある場合、データを更新
        if (setData) {
          const { isNew, ...rest } = newRow; // isNew を取り出し、それ以外を rest に格納
          const updatedData = {
            ...rest,
            id: newId,
            ...{
              ...(newRow.date ? { date: format(newRow.date as Date, 'yyyy-MM-dd') } : {}),
            },
            ...{
              ...(table === 'money'
                ? {
                    category:
                      rest.type === '収入'
                        ? incomeCategoryMap.get(rest.category as string)?.id
                        : expenseCategoryMap.get(rest.category as string)?.id,
                  }
                : {}),
            },
          };
          if (newRow.isNew) {
            // 新規追加
            setData(prev => {
              if (!prev) return [];
              return [...prev, updatedData as T];
            });
          } else {
            // 既存データの更新
            setData(prev => {
              if (!prev) return [];
              const newData = prev.map(data => {
                if (hasId(data) && data.id === newRow.id) {
                  return updatedData as T; // idが一致していれば、新しいデータを返す
                }
                return data; // idが一致しなければ、元のデータを返す
              });
              return newData;
            });
          }
        }
      } catch (error) {
        console.error(`${table}のテーブルの更新に失敗しました:`, error);
      }
    }
    // 更新された行を返す
    return updatedRow;
  };

  // 編集→表示に切り替え
  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  // ページによってカラムを変更
  const columns = (): GridColDef[] => {
    if (table === 'money') {
      return [
        {
          field: 'date',
          headerName: '日付',
          type: 'date',
          width: 120,
          editable: true,
        },
        {
          field: 'type',
          headerName: 'タイプ',
          width: 90,
          editable: true,
          align: 'center',
          headerAlign: 'center',
          type: 'singleSelect',
          valueOptions: ['収入', '支出'],
          renderCell: params => {
            const row = params.row as GridRowModel;
            const selectedType = row.type as string;
            return (
              <>
                {selectedType === '収入' ? (
                  <span
                    style={{
                      backgroundColor: alpha(theme.palette.incomeColor.dark, 0.15),
                      padding: '2px 10px 3px',
                      borderRadius: '999px',
                      color: theme.palette.incomeColor.dark,
                      fontSize: '0.825rem',
                      border: `1px solid ${alpha(theme.palette.incomeColor.dark, 0.5)}`,
                    }}
                  >
                    {params.value}
                  </span>
                ) : (
                  <span
                    style={{
                      backgroundColor: alpha(theme.palette.expenseColor.dark, 0.15),
                      padding: '2px 10px 3px',
                      borderRadius: '999px',
                      color: theme.palette.expenseColor.dark,
                      fontSize: '0.825rem',
                      border: `1px solid ${alpha(theme.palette.expenseColor.dark, 0.5)}`,
                    }}
                  >
                    {params.value}
                  </span>
                )}
              </>
            );
          },
        },
        {
          field: 'category',
          headerName: 'カテゴリー',
          width: 150,
          editable: true,
          type: 'singleSelect',
          valueOptions: params => {
            const row = params.row as GridRowModel;
            const selectedType = (row?.type as string | undefined) || '';
            switch (selectedType) {
              case '収入':
                return incomeCategoryValueOptions;
              case '支出':
                return expenseCategoryValueOptions;
              default:
                return Array.from(
                  new Set([...incomeCategoryValueOptions, ...expenseCategoryValueOptions])
                );
            }
          },
          renderCell: params => {
            const row = params.row as GridRowModel;
            const selectedType = row.type as string;
            return (
              <Box
                display="flex"
                alignItems="center"
                flexWrap={'nowrap'}
                justifyContent={'flex-start'}
                sx={{
                  flexWrap: 'nowrap',
                }}
              >
                {selectedType === '収入' ? (
                  <Icon
                    icon={
                      `${incomeCategoryMap.get(params.value as string)?.icon}` || 'question-mark'
                    }
                    style={{ marginRight: 8, flexShrink: 0 }}
                  />
                ) : (
                  <Icon
                    icon={
                      `${expenseCategoryMap.get(params.value as string)?.icon}` || 'question-mark'
                    }
                    style={{ marginRight: 8, flexShrink: 0 }}
                  />
                )}
                <span>{params.value}</span>
              </Box>
            );
          },
        },
        {
          field: 'amount',
          headerName: '金額',
          type: 'number',
          width: 120,
          align: 'left',
          headerAlign: 'left',
          editable: true,
          renderCell: params => <>¥ {Intl.NumberFormat('ja-JP').format(params.value as number)}</>,
        },
        { field: 'content', headerName: '内容', width: 200, editable: true },
        {
          field: 'actions',
          type: 'actions',
          headerName: '編集',
          width: 100,
          cellClassName: 'actions',
          getActions: ({ id }) => {
            const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
            if (isInEditMode) {
              return [
                <GridActionsCellItem
                  key={`${id}-save`}
                  icon={<Icon icon="save-3-fill" size="1.15rem" />}
                  label="保存"
                  onClick={handleSaveClick(id)}
                />,
                <GridActionsCellItem
                  key={`${id}-cancel`}
                  icon={<Icon icon="close-large-fill" size="1rem" />}
                  label="キャンセル"
                  onClick={handleCancelClick(id)}
                />,
              ];
            }
            return [
              <GridActionsCellItem
                key={`${id}-edit`}
                icon={<Icon icon="pencil-fill" size="1rem" />}
                label="編集"
                onClick={handleEditClick(id)}
              />,
              <GridActionsCellItem
                key={`${id}-delete`}
                icon={<Icon icon="delete-bin-7-fill" size="1rem" />}
                label="削除"
                onClick={handleDeleteClick(id)}
              />,
            ];
          },
        },
      ];
    }
    if (['income', 'expense', 'healthCategory'].includes(table)) {
      return [
        {
          field: 'icon',
          headerName: 'アイコン',
          width: 120,
          editable: true,
          align: 'center',
          headerAlign: 'center',
          renderCell: params => (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              sx={{
                height: '100%',
              }}
            >
              <Icon icon={`${params.value}`} />
            </Box>
          ),
        },
        { field: 'name', headerName: 'カテゴリー名', width: 200, editable: true },
        {
          field: 'actions',
          type: 'actions',
          headerName: '編集',
          width: 100,
          cellClassName: 'actions',
          getActions: ({ id }) => {
            const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
            if (isInEditMode) {
              return [
                <GridActionsCellItem
                  key={`${id}-save`}
                  icon={<Icon icon="save-3-fill" size="1.15rem" />}
                  label="保存"
                  onClick={handleSaveClick(id)}
                />,
                <GridActionsCellItem
                  key={`${id}-cancel`}
                  icon={<Icon icon="close-large-fill" size="1rem" />}
                  label="キャンセル"
                  onClick={handleCancelClick(id)}
                />,
              ];
            }
            return [
              <GridActionsCellItem
                key={`${id}-edit`}
                icon={<Icon icon="pencil-fill" size="1rem" />}
                label="編集"
                onClick={handleEditClick(id)}
              />,
              <GridActionsCellItem
                key={`${id}-delete`}
                icon={<Icon icon="delete-bin-7-fill" size="1rem" />}
                label="削除"
                onClick={handleDeleteClick(id)}
              />,
            ];
          },
        },
      ];
    }
    if (table === 'file') {
      return [
        {
          field: 'extension',
          headerName: '拡張子',
          width: 140,
          type: 'singleSelect',
          headerAlign: 'center',
          valueOptions: () => {
            const uniqueExtensions = Array.from(
              new Set(rows.map(file => file.extension as string))
            );
            return uniqueExtensions;
          },
          renderCell: params => {
            const row = params.row as GridRowModel;
            const extension = params.value !== '' ? `.${params.value}` : '';
            const isImage = [
              'jpg',
              'JPG',
              'jpeg',
              'JPEG',
              'png',
              'PNG',
              'gif',
              'GIF',
              'bmp',
              'svg',
              'ico',
              'webp',
              'tiff',
            ].includes(params.value as string);
            if (isImage) {
              return (
                <Box
                  display={'flex'}
                  justifyContent={'center'}
                  alignItems={'center'}
                  sx={{
                    minHeight: '52px',
                    height: '100%',
                  }}
                >
                  <img
                    src={`${row.url as string}${row.name as string}${extension}`}
                    alt={row.name as string}
                    style={{ width: 100, height: 'auto', objectFit: 'contain' }}
                  />
                </Box>
              );
            }
            return (
              <Box
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
                sx={{
                  minHeight: '52px',
                  height: '100%',
                }}
              >
                {extensionIconMap.get(params.value as string) ? (
                  <Icon icon={`${extensionIconMap.get(params.value as string)}`} />
                ) : (
                  <span>{params.value}</span>
                )}
              </Box>
            );
          },
        },
        {
          field: 'name',
          headerName: 'ファイル名',
          width: 200,
          editable: true,
          renderCell: params => {
            const row = params.row as GridRowModel;
            const extension = row.extension !== '' ? `.${row.extension}` : '';
            return (
              <Box
                display={'flex'}
                alignItems={'center'}
                sx={{
                  minHeight: '52px',
                  height: '100%',
                  padding: '8px 0',
                }}
              >
                <a
                  href={`${row.url as string}${params.value}${extension}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {params.value}
                  {extension}
                </a>
              </Box>
            );
          },
        },
        {
          field: 'date',
          headerName: '最終更新日',
          type: 'dateTime',
          width: 150,
          align: 'center',
          headerAlign: 'center',
          renderCell: params => (
            <Box
              display={'flex'}
              justifyContent={'center'}
              alignItems={'center'}
              sx={{
                minHeight: '52px',
                height: '100%',
                padding: '8px 0',
              }}
            >
              <span>{format(new Date(params.value as Date), 'yyyy/MM/dd HH:mm')}</span>
            </Box>
          ),
        },
        {
          field: 'size',
          headerName: 'サイズ',
          type: 'number',
          width: 120,
          headerAlign: 'center',
          renderCell: params => (
            <Box
              display={'flex'}
              justifyContent={'flex-end'}
              alignItems={'center'}
              sx={{
                minHeight: '52px',
                height: '100%',
                padding: '8px 0',
              }}
            >
              <span>{(params.value / 1024).toFixed(2)} KB</span>
            </Box>
          ),
        },
        {
          field: 'actions',
          type: 'actions',
          headerName: '編集',
          width: 130,
          cellClassName: 'actions',
          getActions: ({ id }) => {
            const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
            if (isInEditMode) {
              return [
                <GridActionsCellItem
                  key={`${id}-save`}
                  icon={<Icon icon="save-3-fill" size="1.15rem" />}
                  label="保存"
                  onClick={handleSaveClick(id)}
                />,
                <GridActionsCellItem
                  key={`${id}-cancel`}
                  icon={<Icon icon="close-large-fill" size="1rem" />}
                  label="キャンセル"
                  onClick={handleCancelClick(id)}
                />,
              ];
            }
            return [
              <GridActionsCellItem
                key={`${id}-copy`}
                icon={<Icon icon="file-copy-fill" size="1rem" />}
                label="コピー"
                onClick={handleCopyClick(id)}
              />,
              <GridActionsCellItem
                key={`${id}-edit`}
                icon={<Icon icon="pencil-fill" size="1rem" />}
                label="編集"
                onClick={handleEditClick(id)}
              />,
              <GridActionsCellItem
                key={`${id}-delete`}
                icon={<Icon icon="delete-bin-7-fill" size="1rem" />}
                label="削除"
                onClick={handleDeleteClick(id)}
              />,
            ];
          },
        },
      ];
    }
    if (table === 'foodDB') {
      return [
        {
          field: 'name',
          headerName: '名称',
          width: 150,
          editable: true,
        },
        {
          field: 'perItem',
          headerName: '1個あたり',
          width: 100,
          editable: true,
          type: 'boolean',
          headerAlign: 'center',
          renderCell: params => <>{params.value && <Icon icon="check-fill" />}</>,
        },
        {
          field: 'energy',
          headerName: '熱量(kcal)',
          type: 'number',
          width: 100,
          editable: true,
          headerAlign: 'center',
        },
        {
          field: 'protein',
          headerName: 'たんぱく質(g)',
          type: 'number',
          width: 100,
          editable: true,
          headerAlign: 'center',
          renderCell: params => <>{params.value !== null && params.value}</>,
        },
        {
          field: 'fat',
          headerName: '脂質(g)',
          type: 'number',
          width: 100,
          editable: true,
          headerAlign: 'center',
          renderCell: params => <>{params.value !== null && params.value}</>,
        },
        {
          field: 'carb',
          headerName: '炭水化物(g)',
          type: 'number',
          width: 100,
          editable: true,
          headerAlign: 'center',
          renderCell: params => <>{params.value !== null && params.value}</>,
        },
        {
          field: 'salt',
          headerName: '食塩相当量(g)',
          type: 'number',
          width: 100,
          editable: true,
          headerAlign: 'center',
          renderCell: params => <>{params.value !== null && params.value}</>,
        },
        {
          field: 'actions',
          type: 'actions',
          headerName: '編集',
          width: 100,
          cellClassName: 'actions',
          getActions: ({ id }) => {
            const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
            if (isInEditMode) {
              return [
                <GridActionsCellItem
                  key={`${id}-save`}
                  icon={<Icon icon="save-3-fill" size="1.15rem" />}
                  label="保存"
                  onClick={handleSaveClick(id)}
                />,
                <GridActionsCellItem
                  key={`${id}-cancel`}
                  icon={<Icon icon="close-large-fill" size="1rem" />}
                  label="キャンセル"
                  onClick={handleCancelClick(id)}
                />,
              ];
            }
            return [
              <GridActionsCellItem
                key={`${id}-edit`}
                icon={<Icon icon="pencil-fill" size="1rem" />}
                label="編集"
                onClick={handleEditClick(id)}
              />,
              <GridActionsCellItem
                key={`${id}-delete`}
                icon={<Icon icon="delete-bin-7-fill" size="1rem" />}
                label="削除"
                onClick={handleDeleteClick(id)}
              />,
            ];
          },
        },
      ];
    }
    if (table === 'fitness') {
      return [
        {
          field: 'date',
          headerName: '日付',
          type: 'date',
          width: 120,
          editable: true,
        },
        { field: 'content', headerName: '内容', width: 200, editable: true },
        {
          field: 'time',
          headerName: '運動時間',
          width: 120,
          editable: true,
          headerAlign: 'center',
          align: 'center',
          renderEditCell: params => (
            <TextField
              fullWidth
              type="time"
              size="small"
              value={(params.value as string) || ''}
              onChange={e => {
                // eslint-disable-next-line no-void
                void params.api.setEditCellValue(
                  {
                    id: params.id,
                    field: params.field,
                    value: e.target.value,
                  },
                  e
                );
              }}
              sx={{
                '& input': {
                  fontSize: '0.875rem',
                  padding: '11px 8px',
                },
                '& input.MuiInputBase-input:focus': {
                  outline: 'none',
                },
              }}
            />
          ),
          renderCell: params => <>{formatTime(params.value as string) || ''}</>,
        },
        {
          field: 'calorie',
          headerName: '消費カロリー',
          type: 'number',
          width: 120,
          headerAlign: 'center',
          editable: true,
          renderCell: params => <>{params.value ? `${params.value} kcal` : ''}</>,
        },
        {
          field: 'actions',
          type: 'actions',
          headerName: '編集',
          width: 100,
          cellClassName: 'actions',
          getActions: ({ id }) => {
            const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
            if (isInEditMode) {
              return [
                <GridActionsCellItem
                  key={`${id}-save`}
                  icon={<Icon icon="save-3-fill" size="1.15rem" />}
                  label="保存"
                  onClick={handleSaveClick(id)}
                />,
                <GridActionsCellItem
                  key={`${id}-cancel`}
                  icon={<Icon icon="close-large-fill" size="1rem" />}
                  label="キャンセル"
                  onClick={handleCancelClick(id)}
                />,
              ];
            }
            return [
              <GridActionsCellItem
                key={`${id}-edit`}
                icon={<Icon icon="pencil-fill" size="1rem" />}
                label="編集"
                onClick={handleEditClick(id)}
              />,
              <GridActionsCellItem
                key={`${id}-delete`}
                icon={<Icon icon="delete-bin-7-fill" size="1rem" />}
                label="削除"
                onClick={handleDeleteClick(id)}
              />,
            ];
          },
        },
      ];
    }
    if (table === 'nurt') {
      return [
        {
          field: 'energy',
          headerName: '熱量(kcal)',
          type: 'number',
          width: 100,
          editable: true,
          headerAlign: 'center',
        },
        {
          field: 'protein',
          headerName: 'たんぱく質(g)',
          type: 'number',
          width: 110,
          editable: true,
          headerAlign: 'center',
          renderCell: params => <>{params.value !== null && params.value}</>,
        },
        {
          field: 'fat',
          headerName: '脂質(g)',
          type: 'number',
          width: 100,
          editable: true,
          headerAlign: 'center',
          renderCell: params => <>{params.value !== null && params.value}</>,
        },
        {
          field: 'carb',
          headerName: '炭水化物(g)',
          type: 'number',
          width: 100,
          editable: true,
          headerAlign: 'center',
          renderCell: params => <>{params.value !== null && params.value}</>,
        },
        {
          field: 'salt',
          headerName: '食塩相当量(g)',
          type: 'number',
          width: 120,
          editable: true,
          headerAlign: 'center',
          renderCell: params => <>{params.value !== null && params.value}</>,
        },
        {
          field: 'actions',
          type: 'actions',
          headerName: '編集',
          width: 100,
          cellClassName: 'actions',
          getActions: ({ id }) => {
            const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
            if (isInEditMode) {
              return [
                <GridActionsCellItem
                  key={`${id}-save`}
                  icon={<Icon icon="save-3-fill" size="1.15rem" />}
                  label="保存"
                  onClick={handleSaveClick(id)}
                />,
                <GridActionsCellItem
                  key={`${id}-cancel`}
                  icon={<Icon icon="close-large-fill" size="1rem" />}
                  label="キャンセル"
                  onClick={handleCancelClick(id)}
                />,
              ];
            }
            return [
              <GridActionsCellItem
                key={`${id}-edit`}
                icon={<Icon icon="pencil-fill" size="1rem" />}
                label="編集"
                onClick={handleEditClick(id)}
              />,
            ];
          },
        },
      ];
    }
    return [];
  };

  return (
    <Box
      sx={{
        width: '100%',
      }}
    >
      <DataGrid
        rows={rows}
        columns={columns()}
        editMode="row"
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        slots={{
          toolbar: props => (
            <CustomToolbar {...props} rows={rows} table={table} currentMonth={currentMonth} />
          ),
        }}
        slotProps={{
          toolbar: { setRows, setRowModesModel, showQuickFilter: true },
        }}
        getRowHeight={() => (table === 'file' ? 'auto' : 52)}
        initialState={{
          pagination: { paginationModel: { pageSize: 25 } },
        }}
        loading={!gridRows}
        sx={{
          '& .MuiCircularProgress-root.MuiCircularProgress-indeterminate': {
            width: '30px !important',
            height: '30px !important',
            color: theme.palette.secondary.main,
          },
        }}
        localeText={{
          // Root
          noRowsLabel: 'データがありません。',
          noResultsOverlayLabel: '結果がありません。',
          // Density selector toolbar button text
          toolbarDensity: '行間隔',
          toolbarDensityLabel: '行間隔',
          toolbarDensityCompact: 'コンパクト',
          toolbarDensityStandard: '標準',
          toolbarDensityComfortable: '広め',
          // Columns selector toolbar button text
          toolbarColumns: '列の表示',
          toolbarColumnsLabel: '列選択を表示',
          // Filters toolbar button text
          toolbarFilters: 'フィルター',
          toolbarFiltersLabel: 'フィルター',
          toolbarFiltersTooltipHide: 'フィルター非表示',
          toolbarFiltersTooltipShow: 'フィルターを表示',
          toolbarFiltersTooltipActive: count => `${count}件のフィルターを適用中`,
          // Quick filter toolbar field
          toolbarQuickFilterPlaceholder: '検索…',
          toolbarQuickFilterLabel: '検索',
          toolbarQuickFilterDeleteIconLabel: 'クリア',
          // Export selector toolbar button text
          toolbarExport: 'エクスポート',
          toolbarExportLabel: 'エクスポート',
          toolbarExportCSV: 'CSVダウンロード',
          toolbarExportPrint: '印刷',
          toolbarExportExcel: 'Excelダウンロード',
          // Columns management text
          columnsManagementSearchTitle: '検索',
          columnsManagementNoColumns: 'カラムなし',
          columnsManagementShowHideAllText: 'すべて表示/非表示',
          columnsManagementReset: 'リセット',
          // columnsManagementDeleteIconLabel: 'Clear',
          // Filter panel text
          filterPanelAddFilter: 'フィルター追加',
          filterPanelRemoveAll: 'すべて削除',
          filterPanelDeleteIconLabel: '削除',
          filterPanelLogicOperator: '論理演算子',
          filterPanelOperator: '演算子',
          filterPanelOperatorAnd: 'And',
          filterPanelOperatorOr: 'Or',
          filterPanelColumns: '列',
          filterPanelInputLabel: '値',
          filterPanelInputPlaceholder: '値を入力…',
          // Filter operators text
          filterOperatorContains: '...を含む',
          filterOperatorDoesNotContain: '...を含まない',
          filterOperatorEquals: '...に等しい',
          filterOperatorDoesNotEqual: '...に等しくない',
          filterOperatorStartsWith: '...で始まる',
          filterOperatorEndsWith: '...で終わる',
          filterOperatorIs: '...である',
          filterOperatorNot: '...でない',
          filterOperatorAfter: '...より後ろ',
          filterOperatorOnOrAfter: '...以降',
          filterOperatorBefore: '...より前',
          filterOperatorOnOrBefore: '...以前',
          filterOperatorIsEmpty: '...空である',
          filterOperatorIsNotEmpty: '...空でない',
          filterOperatorIsAnyOf: '...のいずれか',
          'filterOperator=': '=',
          'filterOperator!=': '!=',
          'filterOperator>': '>',
          'filterOperator>=': '>=',
          'filterOperator<': '<',
          'filterOperator<=': '<=',
          // Header filter operators text
          headerFilterOperatorContains: '含む',
          headerFilterOperatorDoesNotContain: '含まない',
          headerFilterOperatorEquals: '等しい',
          headerFilterOperatorDoesNotEqual: '等しくない',
          headerFilterOperatorStartsWith: 'で始まる',
          headerFilterOperatorEndsWith: 'で終わる',
          headerFilterOperatorIs: 'である',
          headerFilterOperatorNot: 'ではない',
          headerFilterOperatorAfter: '...より後ろ',
          headerFilterOperatorOnOrAfter: '...以降',
          headerFilterOperatorBefore: '...より前',
          headerFilterOperatorOnOrBefore: '...以前',
          headerFilterOperatorIsEmpty: '空白',
          headerFilterOperatorIsNotEmpty: '空白ではない',
          headerFilterOperatorIsAnyOf: 'いずれか',
          'headerFilterOperator=': '等しい',
          'headerFilterOperator!=': '等しくない',
          'headerFilterOperator>': 'より大きい',
          'headerFilterOperator>=': '以上',
          'headerFilterOperator<': '未満',
          'headerFilterOperator<=': '以下',
          // Filter values text
          filterValueAny: 'いずれか',
          filterValueTrue: '真',
          filterValueFalse: '偽',
          // Column menu text
          columnMenuLabel: 'メニュー',
          columnMenuShowColumns: '列表示',
          columnMenuManageColumns: '列管理',
          columnMenuFilter: 'フィルター',
          columnMenuHideColumn: '列非表示',
          columnMenuUnsort: 'ソート解除',
          columnMenuSortAsc: '昇順ソート',
          columnMenuSortDesc: '降順ソート',
          // Column header text
          columnHeaderFiltersTooltipActive: count => `${count}件のフィルターを適用中`,
          columnHeaderFiltersLabel: 'フィルター表示',
          columnHeaderSortIconLabel: 'ソート',
          // Rows selected footer text
          footerRowSelected: count => `${count}行を選択中`,
          // Total row amount footer text
          footerTotalRows: '総行数:',
          // Total visible row amount footer text
          footerTotalVisibleRows: (visibleCount, totalCount) =>
            `${visibleCount.toLocaleString()} / ${totalCount.toLocaleString()}`,
          // Checkbox selection text
          checkboxSelectionHeaderName: 'チェックボックス',
          checkboxSelectionSelectAllRows: 'すべての行を選択',
          checkboxSelectionUnselectAllRows: 'すべての行選択を解除',
          checkboxSelectionSelectRow: '行を選択',
          checkboxSelectionUnselectRow: '行選択を解除',
          // Boolean cell text
          booleanCellTrueLabel: '真',
          booleanCellFalseLabel: '偽',
          // Actions cell more text
          actionsCellMore: 'もっと見る',
          // Column pinning text
          pinToLeft: '左側に固定',
          pinToRight: '右側に固定',
          unpin: '固定解除',
          // Tree Data
          treeDataGroupingHeaderName: 'グループ',
          treeDataExpand: '展開',
          treeDataCollapse: '折りたたみ',
          // Grouping columns
          groupingColumnHeaderName: 'グループ',
          groupColumn: name => `${name}でグループ化`,
          unGroupColumn: name => `${name}のグループを解除`,
          // Master/detail
          detailPanelToggle: '詳細パネルの切り替え',
          expandDetailPanel: '展開',
          collapseDetailPanel: '折りたたみ',
          // Row reordering text
          rowReorderingHeaderName: '行並び替え',
          // Aggregation
          aggregationMenuItemHeader: '合計',
          aggregationFunctionLabelSum: '和',
          aggregationFunctionLabelAvg: '平均',
          aggregationFunctionLabelMin: '最小値',
          aggregationFunctionLabelMax: '最大値',
          aggregationFunctionLabelSize: 'サイズ',
        }}
      />
    </Box>
  );
};

export default memo(DataTable) as typeof DataTable;
