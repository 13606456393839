import { format } from 'date-fns';

export const formatDateWithTime = (date: Date): string => {
  const timeUtc = new Date(date);
  timeUtc.setHours(timeUtc.getHours() - 9);
  return format(timeUtc, "yyyy-MM-dd'T'HH:mm:ss");
};

export const formatDateWithOptionalTime = (date: Date): string => {
  const timeUtc = new Date(date);
  timeUtc.setHours(timeUtc.getHours() - 9);
  const isMidnight = format(timeUtc, 'HH:mm:ss') === '00:00:00';
  return isMidnight ? format(timeUtc, 'yyyy-MM-dd') : format(timeUtc, "yyyy-MM-dd'T'HH:mm:ss");
};

export const formatDateTime = (date: string): string => {
  const timeUtc = new Date(date);
  timeUtc.setHours(timeUtc.getHours() - 9);
  return format(timeUtc, 'HH:mm');
};

export const formatMinutes = (minutes: number): string => {
  if (minutes < 0) {
    throw new Error('分数は正の値である必要があります');
  }

  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;

  let result = '';
  if (hours > 0) result += `${hours}時間`;
  if (remainingMinutes > 0 || result === '') result += `${remainingMinutes}分`;

  return result;
};

export const formatSeconds = (seconds: number): string | null => {
  if (seconds < 0) {
    throw new Error('秒数は正の値である必要があります');
  }

  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = Math.floor(seconds % 60);

  let result = '';
  if (hours > 0) result += `${hours}時間`;
  if (minutes > 0) result += `${minutes}分`;
  if (remainingSeconds > 0 || result === '') result += `${remainingSeconds}秒`;

  return result.length === 0 ? null : result;
};

export const formatTime = (time: string | null): string | null => {
  if (!time) return null;

  const parts = time.split(':'); // ':'で分割

  let hours = 0;
  let minutes = 0;

  if (parts.length === 3) {
    // HH:MM:SS形式の場合
    hours = parseInt(parts[0], 10);
    minutes = parseInt(parts[1], 10);
  } else if (parts.length === 2) {
    // HH:MM形式の場合
    hours = parseInt(parts[0], 10);
    minutes = parseInt(parts[1], 10);
  } else {
    throw new Error('Invalid time format');
  }

  // 時間と分の形式に変換
  const hourText = hours > 0 ? `${hours}時間` : '';
  const minuteText = minutes > 0 ? `${minutes}分` : '';

  return `${hourText}${minuteText}` || null; // 空の場合はnullを返す
};
