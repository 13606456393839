import { CSSProperties, useEffect } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import { useNavigate, NavLink } from 'react-router-dom';
import axios from 'axios';
import {
  Box,
  Container,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import EventNoteSharpIcon from '@mui/icons-material/EventNoteSharp';
import PaymentsSharpIcon from '@mui/icons-material/PaymentsSharp';
import SearchSharpIcon from '@mui/icons-material/SearchSharp';
import LogoutSharpIcon from '@mui/icons-material/LogoutSharp';
import AccessibilityNewSharpIcon from '@mui/icons-material/AccessibilityNewSharp';
import FormatListBulletedSharpIcon from '@mui/icons-material/FormatListBulletedSharp';
import CollectionsSharpIcon from '@mui/icons-material/CollectionsSharp';
import SettingsSharpIcon from '@mui/icons-material/SettingsSharp';
import FolderSharpIcon from '@mui/icons-material/FolderSharp';
import WorkSharpIcon from '@mui/icons-material/WorkSharp';
import FeedSharpIcon from '@mui/icons-material/FeedSharp';
import { LogoutResponse } from '../../types/index';
import {
  zNavigation,
  scrollbarBlack,
  bgBlack,
  fontSerif,
  navHeight,
  navWidth,
} from '../../styles/commonStyles';

interface NavigationProps {
  setUserId: React.Dispatch<React.SetStateAction<string>>;
  setCsrfToken: React.Dispatch<React.SetStateAction<string>>;
  setLoginAuth: React.Dispatch<React.SetStateAction<boolean>>;
  setEntryURL: React.Dispatch<React.SetStateAction<string>>;
}

interface NavigationItemType {
  text: string;
  path: string;
  icon: React.ReactNode;
}

const iconStyle = {
  color: 'inherit',
  filter: 'drop-shadow(-1px -1px 0px #000)',
  fontSize: { xs: '1.25rem', md: '1.5rem' },
};

const navigationItems: NavigationItemType[] = [
  {
    text: 'calendar',
    path: '/',
    icon: <EventNoteSharpIcon sx={{ ...iconStyle }} />,
  },
  {
    text: 'project',
    path: '/project',
    icon: <WorkSharpIcon sx={{ ...iconStyle }} />,
  },
  {
    text: 'memo',
    path: '/memo',
    icon: <FeedSharpIcon sx={{ ...iconStyle }} />,
  },
  {
    text: 'todo',
    path: '/todo',
    icon: <FormatListBulletedSharpIcon sx={{ ...iconStyle }} />,
  },
  {
    text: 'money',
    path: '/money',
    icon: <PaymentsSharpIcon sx={{ ...iconStyle }} />,
  },
  {
    text: 'health',
    path: '/health',
    icon: <AccessibilityNewSharpIcon sx={{ ...iconStyle }} />,
  },
  {
    text: 'file',
    path: '/file',
    icon: <FolderSharpIcon sx={{ ...iconStyle }} />,
  },
  {
    text: 'gallery',
    path: '/gallery',
    icon: <CollectionsSharpIcon sx={{ ...iconStyle }} />,
  },
  {
    text: 'search',
    path: '/search',
    icon: <SearchSharpIcon sx={{ ...iconStyle }} />,
  },
  {
    text: 'setting',
    path: '/setting',
    icon: <SettingsSharpIcon sx={{ ...iconStyle }} />,
  },
];

const listItemStyle = {
  width: { xs: `calc(${navHeight} - 16px)`, md: `calc(${navWidth} - 24px)` },
  height: { xs: `calc(${navHeight} - 16px)`, md: `calc(${navWidth} - 24px)` },
};

const navLinkStyle: CSSProperties = {
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  margin: '0 auto',
  color: '#ffffff73',
  textDecoration: 'none',
  textAlign: 'center',
  borderRadius: '3px',
  backgroundColor: 'transparent',
  transition: 'backgroundColor 0.3s ease-out',
};

const activeNavLinkStyle: CSSProperties = {
  pointerEvents: 'none',
  color: '#ffffff',
};

const ListItemIconStyle = {
  textAlign: 'center',
  color: 'inherit',
  display: 'block',
  width: '100%',
  marginRight: 0,
};

const ListItemTextStyle = {
  ...fontSerif,
  display: 'block',
  margin: '0 auto',
  flexGrow: 0,
  fontSize: 11,
  color: 'inherit',
  textAlign: 'center',
  textTransform: 'capitalize',
  fontWeight: '500',
  letterSpacing: '0.1em',
  filter: 'drop-shadow(-1px -1px 0px #000)',
  visibility: { xs: 'hidden', md: 'visible' },
  height: { xs: 0, md: 'auto' },
};

const Navigation = ({ setUserId, setCsrfToken, setLoginAuth, setEntryURL }: NavigationProps) => {
  const navigate = useNavigate();

  useEffect(() => {
    console.log('Navigation：初回レンダリング');
  }, []);

  console.log('Navigation：レンダリング');

  const handleLogout = () => {
    // PHP側でCookiesに保存したtoken、csrfTokenを削除
    const apiUrl = process.env.REACT_APP_LOGOUT_API;
    if (!apiUrl) {
      alert('APIが設定されていないため、ログアウトできません');
      return;
    }
    axios
      .post<LogoutResponse>(
        apiUrl,
        {},
        {
          withCredentials: true,
        }
      )
      .then(response => {
        if (response.data.success) {
          unstable_batchedUpdates(() => {
            setUserId('');
            setCsrfToken('');
            setLoginAuth(false);
            setEntryURL('/');
          });
          navigate('/login');
        } else {
          alert('ログアウトに失敗しました');
          console.error(response.data.error);
        }
      })
      .catch(error => {
        alert('ログアウトに失敗しました');
        console.error(error);
      });
  };

  return (
    <Container
      disableGutters
      component="header"
      sx={{
        height: { xs: navHeight, md: '100vh' },
        width: { xs: '100%', md: navWidth },
        backgroundColor: '#fff',
        position: 'fixed',
        top: { xs: 'auto', md: 0 },
        bottom: { xs: 0, md: 'auto' },
        left: 0,
        ...zNavigation,
      }}
    >
      <Box
        sx={{
          // スクロールバーを含むため、ここにpaddingを含めない
          ...bgBlack,
          width: '100%',
          height: '100%',
          overflowY: { xs: 'hidden', md: 'auto' },
          overflowX: { xs: 'auto', md: 'hidden' },
          ...scrollbarBlack,
        }}
      >
        <List
          role="navigation"
          aria-label="メイン"
          sx={{
            padding: { xs: 1, md: 1.5 },
            height: 'fit-content',
            minHeight: '100%',
            width: 'fit-content',
            minWidth: '100%',
            display: 'flex',
            justifyContent: { xs: 'space-between', md: 'flex-start' },
            flexDirection: { xs: 'row', md: 'column' },
            '& li + li': {
              margin: { xs: '0 0 0 6px', md: '6px 0 0 0' },
            },
          }}
        >
          {navigationItems.map((item, index) => (
            <ListItem
              disablePadding
              key={index}
              sx={{
                ...listItemStyle,
                ...(index + 1 === navigationItems.length && {
                  margin: { md: '6px 0 !important' },
                }),
              }}
            >
              <NavLink
                className={'navigationLink'}
                to={item.path}
                style={({ isActive }) => ({
                  ...navLinkStyle,
                  ...(isActive ? activeNavLinkStyle : {}),
                })}
              >
                <ListItemIcon sx={{ ...ListItemIconStyle }}>{item.icon}</ListItemIcon>
                <ListItemText
                  disableTypography
                  primary={item.text}
                  sx={{
                    ...ListItemTextStyle,
                  }}
                />
              </NavLink>
            </ListItem>
          ))}
          <ListItem
            disablePadding
            sx={{
              ...listItemStyle,
              marginTop: 'auto !important',
            }}
          >
            <ListItemButton
              onClick={handleLogout}
              className={'navigationLink'}
              sx={{
                ...navLinkStyle,
                padding: 0,
              }}
            >
              <ListItemIcon sx={{ ...ListItemIconStyle }}>
                <LogoutSharpIcon sx={{ ...iconStyle }} />
              </ListItemIcon>
              <ListItemText
                disableTypography
                primary={'logout'}
                sx={{
                  ...ListItemTextStyle,
                }}
              />
            </ListItemButton>
          </ListItem>
        </List>
      </Box>
    </Container>
  );
};

export default Navigation;
