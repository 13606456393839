import { memo, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, CircularProgress, Typography } from '@mui/material';
import { JSONContent } from '@tiptap/core';
import { useMemoContext } from '../contexts/MemoContext';
import BlockEditor from '../components/common/BlockEditor/BlockEditor';
import { center, fontSerif } from '../styles/commonStyles';
import useGetRequest from '../hooks/useGetRequest';
import PageTitle from '../components/ui/PageTitle';

const MemoPost = () => {
  useEffect(() => {
    console.log('MemoPost：初回レンダリング');
  }, []);

  console.log('MemoPost：レンダリング');

  const { postId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [content, setContent] = useState<JSONContent | null>(null);
  const { menuItems, setMenuItems } = useMemoContext();
  const getRequest = useGetRequest();

  // データの取得
  useEffect(() => {
    if (content) return;
    getRequest<string>({
      apiUrl: process.env.REACT_APP_MEMO_API,
      id: String(postId),
    })
      .then(response => {
        if (response.content) {
          setContent(JSON.parse(response.content) as JSONContent);
        }
      })
      .catch(error => {
        console.error('メモの取得に失敗しました:', error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [postId]);

  return (
    <>
      {isLoading ? (
        <Box
          sx={{
            width: '100%',
            height: '100%',
            ...center,
          }}
        >
          <CircularProgress color="secondary" size={30} />
        </Box>
      ) : (
        <>
          {content ? (
            <Box
              sx={{
                margin: '1rem auto',
                width: '100%',
                maxWidth: '1000px',
              }}
            >
              <PageTitle
                title={menuItems?.get(Number(postId)) || ''} // あとで変更
                postId={Number(postId)}
                apiUrl={process.env.REACT_APP_MEMO_API || ''}
                setData={setMenuItems} // あとで変更
              />
              <BlockEditor
                content={content}
                id={postId as string}
                apiUrl={process.env.REACT_APP_MEMO_API || ''}
                tableOfContents
              />
            </Box>
          ) : (
            <Box
              sx={{
                height: '100%',
                ...center,
              }}
            >
              <Typography
                variant="h6"
                component="p"
                fontWeight={700}
                sx={{
                  ...fontSerif,
                }}
              >
                該当するメモが見つかりません
              </Typography>
            </Box>
          )}
        </>
      )}
    </>
  );
};

export default memo(MemoPost);
