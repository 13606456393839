import {
  Backdrop,
  Box,
  Fade,
  IconButton,
  Modal as MuiModal,
  Paper,
  Typography,
} from '@mui/material';
import { ReactNode, useEffect, useRef } from 'react';
import { scrollbarWhite } from '../../styles/commonStyles';
import Icon from './Icon';

interface ModalProps {
  title?: string;
  children: ReactNode;
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const Modal = ({ title, children, isModalOpen, setIsModalOpen }: ModalProps) => {
  const handleClose = () => setIsModalOpen(false);
  const iconButtonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    // 開いたときにIconButtonにフォーカスを移す
    if (isModalOpen && iconButtonRef.current) {
      iconButtonRef.current.focus();
    }
  }, [isModalOpen]);

  return (
    <>
      <MuiModal
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        open={isModalOpen}
        onClose={handleClose}
        disableRestoreFocus={true}
        closeAfterTransition // モーダルが閉じた後にフォーカスを元に戻さない
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={isModalOpen} timeout={250}>
          <Paper
            variant="elevation"
            elevation={24}
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              p: 3,
              maxWidth: '90vw',
              width: '600px',
              maxHeight: '80vh',
              overflowY: 'auto',
              outline: 'none',
              ...scrollbarWhite,
            }}
          >
            <IconButton
              aria-label="閉じる"
              onClick={handleClose}
              ref={iconButtonRef}
              sx={{
                position: 'absolute',
                top: 0,
                right: 0,
                m: 1,
                zIndex: 1,
              }}
            >
              <Icon icon="close-large-line" size="1rem" />
            </IconButton>
            {title && (
              <Typography id="modal-title" variant="h6" component="h3">
                {title}
              </Typography>
            )}
            <Box id="modal-description" sx={{ mt: 2 }}>
              {children}
            </Box>
          </Paper>
        </Fade>
      </MuiModal>
    </>
  );
};

export default Modal;
