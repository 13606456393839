import { ChangeEvent, memo, useCallback, useMemo, useState } from 'react';
import { Box, Button, CircularProgress } from '@mui/material';
import { useDropZone, useFileUpload, useUploader } from './hooks';
import Icon from '../../../../../ui/Icon';
import { center } from '../../../../../../styles/commonStyles';

export const ImageUploader = memo(({ onUpload }: { onUpload: (url: string) => void }) => {
  const { loading, uploadFile } = useUploader({ onUpload });
  const { handleUploadClick, ref } = useFileUpload();

  // useDropZoneにvoidの関数として渡すためのラッパー関数
  const wrappedUploadFile = (file: File): void => {
    uploadFile(file).catch(error => {
      console.error('アップロード中にエラーが発生しました:', error);
    });
  };
  const { draggedInside, onDrop, onDragEnter, onDragLeave } = useDropZone({
    uploader: wrappedUploadFile,
  });

  const onFileChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.files) {
        uploadFile(e.target.files[0]).catch(error => {
          console.error('アップロード中にエラーが発生しました:', error);
        });
      }
    },
    [uploadFile]
  );

  const [url, setUrl] = useState('');
  const onChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setUrl(event.target.value);
  }, []);
  const isValidUrl = useMemo(() => {
    const regex = /^(https?:\/\/\S+|\S*\/)\S+\.(jpg|jpeg|png|webp|gif)$/i;
    return regex.test(url);
  }, [url]);

  const handleSubmit = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault();
      if (isValidUrl) {
        onUpload(url);
      }
    },
    [url, isValidUrl]
  );

  if (loading) {
    return (
      <Box
        sx={{
          ...center,
          borderRadius: '0.25rem',
          minHeight: '10rem',
          backgroundColor: 'rgba(0, 0, 0, 0.08)',
        }}
      >
        <CircularProgress color="secondary" size={20} />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        ...center,
        flexDirection: 'column',
        gap: '0.75rem',
        padding: '1rem',
        borderRadius: '0.25rem',
        backgroundColor: 'rgba(0, 0, 0, 0.08)',
        ...(draggedInside && { backgroundColor: 'rgba(0, 0, 0, 0.3)' }),
      }}
      onDrop={onDrop}
      onDragOver={onDragEnter}
      onDragLeave={onDragLeave}
      contentEditable={false}
    >
      <Icon icon="image-add-line" size="1.5rem" />
      <div
        style={{
          ...center,
          flexDirection: 'column',
          gap: '0.75rem',
        }}
      >
        <div
          style={{
            fontSize: '0.85rem',
            fontWeight: '500',
            textAlign: 'center',
            color: 'rgba(0, 0, 0, 0.5)',
          }}
        >
          {draggedInside ? '画像をドロップ' : '画像をドラッグするか、アップロードまたはURLから挿入'}
        </div>
        <div>
          <Button
            size="small"
            variant="contained"
            startIcon={<Icon icon="file-upload-line" color="#fff" size="1rem" />}
            disabled={draggedInside}
            onClick={handleUploadClick}
          >
            画像のアップロード
          </Button>
        </div>
        <form
          onSubmit={handleSubmit}
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '0.25rem',
          }}
        >
          <input
            aria-label="画像URL"
            type="url"
            style={{
              fontSize: '0.875rem',
            }}
            placeholder="URL"
            value={url}
            onChange={onChange}
          />
          <Button
            type="submit"
            size="small"
            variant="contained"
            startIcon={<Icon icon="link" color="#fff" size="1rem" />}
            sx={{
              flexShrink: 0,
            }}
            disabled={!isValidUrl || draggedInside}
          >
            挿入
          </Button>
        </form>
      </div>
      <input
        style={{ width: '0', height: '0', overflow: 'hidden', opacity: '0' }}
        ref={ref}
        type="file"
        accept=".jpg,.jpeg,.png,.webp,.gif"
        onChange={onFileChange}
      />
    </Box>
  );
});

ImageUploader.displayName = 'ImageUploader';
export default ImageUploader;
