import { forwardRef } from 'react';
import { ListItemIcon, MenuItem as MuiMenuItem } from '@mui/material';
import Icon from './Icon';

interface MenuItemProps {
  label: string;
  icon: string;
  disabled?: boolean;
  onClick: (event?: Event | React.SyntheticEvent) => void;
  selected?: boolean;
}

const MenuItem = forwardRef<HTMLLIElement, MenuItemProps>(
  ({ label, icon, disabled, onClick, selected }, ref) => (
    <MuiMenuItem
      onClick={onClick}
      disabled={disabled || false}
      ref={ref}
      selected={selected}
      tabIndex={0}
    >
      <ListItemIcon>
        <Icon icon={icon} size="1rem" />
      </ListItemIcon>
      {label}
    </MuiMenuItem>
  )
);

MenuItem.displayName = 'MenuItem';

export default MenuItem;
