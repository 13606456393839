import { debounce } from 'lodash';
import { useEffect, useRef, useState } from 'react';

const useGetElementHeight = (selector: string) => {
  const [elementHeight, setElementHeight] = useState<number | null>(null);
  const observerRef = useRef<MutationObserver | null>(null);

  useEffect(() => {
    const updateHeight = () => {
      const element = document.querySelector(selector);
      if (element) {
        const newHeight = Math.floor(element.getBoundingClientRect().height) + 30;
        if (newHeight !== elementHeight) {
          setElementHeight(newHeight);
        }
      }
    };

    // lodashのdebounceを使う
    const debouncedUpdateHeight = debounce(updateHeight, 100);

    // MutationObserverを設定
    observerRef.current = new MutationObserver(debouncedUpdateHeight);
    observerRef.current.observe(document.body, { childList: true, subtree: true });

    // 初期高さを設定
    updateHeight();

    return () => {
      // クリーンアップ処理
      if (observerRef.current) {
        observerRef.current.disconnect();
        observerRef.current = null;
      }
      // debounceのクリーンアップ
      debouncedUpdateHeight.cancel();
    };
  }, [selector, elementHeight]);

  return elementHeight;
};

export default useGetElementHeight;
