import React, { memo, useCallback, useMemo, useState } from 'react';
import axios, { AxiosError } from 'axios';
import { Box, Button, IconButton, Paper, Stack } from '@mui/material';
import { FileWithPath, useDropzone } from 'react-dropzone';
import { theme } from '../../theme';
import Icon from '../ui/Icon';
import { useAuthContext } from '../../contexts/AuthContext';
import useSessionExpiration from '../../hooks/useSessionExpiration';

interface FileUploaderProps {
  setIsUploading: React.Dispatch<React.SetStateAction<boolean>>;
}

interface UploadResponse {
  success: boolean;
  result: string[];
  error?: string;
}

const FileUploader = ({ setIsUploading }: FileUploaderProps) => {
  const [currentFiles, setCurrentFiles] = useState<File[]>([]);
  const { userId, csrfToken } = useAuthContext();
  const sessionExpiration = useSessionExpiration();

  const onDropAccepted = useCallback(
    (files: File[]) => {
      // 1度にアップロードできるのは、最大5ファイル
      if (currentFiles.length < 5) {
        const mixFiles = [...files, ...currentFiles];
        const uniqueFiles = Array.from(new Map(mixFiles.map(file => [file.name, file])).values());
        setCurrentFiles(uniqueFiles);
      }
    },
    [currentFiles]
  );

  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragActive,
    inputRef,
  } = useDropzone({
    maxFiles: 5,
    onDropAccepted,
  });

  const baseStyle = useMemo(
    () => ({
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      minHeight: '150px',
      height: '100%',
      padding: '1rem',
      border: `2px dashed`,
      borderRadius: '6px',
      borderColor: `${theme.palette.divider}`,
      backgroundColor: 'rgba(255, 255, 255, 0.8)',
      color: `${theme.palette.secondary.main}`,
      outline: 'none',
    }),
    []
  );

  const focusedStyle = useMemo(
    () => ({
      borderColor: `${theme.palette.secondary.dark}`,
    }),
    []
  );

  const acceptStyle = useMemo(
    () => ({
      borderColor: `${theme.palette.secondary.dark}`,
      backgroundColor: `rgba(0, 0, 0, 0.15)`,
      color: `${theme.palette.text.primary}`,
    }),
    []
  );

  const style = useMemo(
    () =>
      ({
        ...baseStyle,
        ...(isFocused ? focusedStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
      }) as React.CSSProperties,
    [isFocused, isDragAccept, baseStyle, focusedStyle, acceptStyle]
  );

  const clearFile = useCallback(() => {
    if (inputRef?.current?.value) {
      inputRef.current.value = '';
    }
    setCurrentFiles([]);
  }, [inputRef]);

  const handleRemoveFile = useCallback(
    (file: File) => {
      const newFiles = [...currentFiles];
      newFiles.splice(newFiles.indexOf(file), 1);
      setCurrentFiles(newFiles);
      (acceptedFiles as FileWithPath[]).splice(acceptedFiles.indexOf(file), 1);
      if (newFiles.length <= 0) {
        clearFile();
      }
    },
    [currentFiles, clearFile, acceptedFiles]
  );

  const handleRemoveAll = useCallback(() => {
    clearFile();
    (acceptedFiles as FileWithPath[]).length = 0;
    (acceptedFiles as FileWithPath[]).splice(0, acceptedFiles.length);
  }, [clearFile, acceptedFiles]);

  const handleSubmit = useCallback(() => {
    if (currentFiles.length === 0) return;
    const apiUrl = process.env.REACT_APP_UPLOAD_API;
    if (!apiUrl) {
      alert('APIが設定されていないため、アップロードできません');
      return;
    }
    setIsUploading(true);
    const formData = new FormData();
    formData.append('userId', userId);
    formData.append('csrfToken', csrfToken);
    formData.append('type', 'file');
    currentFiles.forEach(file => {
      formData.append('files[]', file, file.name);
    });
    axios
      .post<UploadResponse>(apiUrl, formData, {
        withCredentials: true,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(response => {
        if (response.data.success) {
          if (response.data.result.length > 0) {
            alert(`下記ファイルのアップロードに失敗しました\n${response.data.result.join('\n')}`);
          }
        } else {
          console.error(response);
          alert('ファイルのアップロードに失敗しました');
        }
      })
      .catch(response => {
        const responseError = response as AxiosError<UploadResponse>;
        if (responseError.status === 401 || responseError.status === 403) {
          console.error(`${responseError.status}:`, responseError.response?.data.error);
          sessionExpiration();
          return;
        }
        console.error(response);
        alert(`ファイルのアップロードに失敗しました`);
      })
      .finally(() => {
        handleRemoveAll();
        setIsUploading(false);
      });
  }, [currentFiles]);

  return (
    <>
      <Box>
        <div {...getRootProps({ style })}>
          <input {...getInputProps()} />
          {isDragActive ? (
            <p>ここにファイルをドロップしてください</p>
          ) : (
            <>
              <p>ファイルをドラッグ＆ドロップするか、クリックして選択してください</p>
              <p
                style={{
                  fontSize: '0.875rem',
                }}
              >
                （1度にアップロードできるのは、最大5ファイル）
              </p>
            </>
          )}
        </div>
        {currentFiles && currentFiles.length > 0 && (
          <Stack component={'ul'} spacing={1} sx={{ marginTop: '20px' }}>
            {currentFiles.map((file, index) => {
              const fileSizeInKB = (file.size / 1024).toFixed(2);
              return (
                <Paper
                  key={index}
                  component="li"
                  sx={{ padding: '8px 8px 8px 12px', marginBottom: '10px' }}
                >
                  <Stack direction={'row'} alignItems={'center'} flexWrap={'nowrap'}>
                    <Icon
                      icon="circle-fill"
                      size="0.5rem"
                      color="primary"
                      style={{
                        flexShrink: 0,
                        marginRight: '8px',
                      }}
                    />
                    <p style={{ marginRight: '8px' }}>
                      {file.name ?? ''} / {fileSizeInKB} KB
                    </p>
                    <IconButton
                      aria-label="削除"
                      onClick={() => {
                        handleRemoveFile(file);
                      }}
                      sx={{ marginLeft: 'auto', flexShrink: 0 }}
                    >
                      <Icon icon="close-large-line" size="1rem" />
                    </IconButton>
                  </Stack>
                </Paper>
              );
            })}
          </Stack>
        )}
        {currentFiles && currentFiles.length > 0 && (
          <Button variant="contained" onClick={handleSubmit} sx={{ marginTop: '20px' }}>
            ファイルをアップロード
          </Button>
        )}
      </Box>
    </>
  );
};

export default memo(FileUploader);
