import { createContext, useContext, useState } from 'react';
import {
  FolderMap,
  FolderOrderMap,
  MenuItemMap,
  MenuItemOrderMap,
  SetFolderMap,
  SetFolderOrderMap,
  SetIsDataFetch,
  SetMenuItemMap,
  SetMenuItemOrderMap,
} from '../types';

interface MemoContextType {
  page: string;
  isMenuDataFetch: boolean;
  setIsMenuDataFetch: SetIsDataFetch;
  menuItems: MenuItemMap;
  setMenuItems: SetMenuItemMap;
  menuItemOrder: MenuItemOrderMap;
  setMenuItemOrder: SetMenuItemOrderMap;
  folders: FolderMap;
  setFolders: SetFolderMap;
  folderOrder: FolderOrderMap;
  setFolderOrder: SetFolderOrderMap;
}

export const MemoContext = createContext<MemoContextType | undefined>(undefined);

export const MemoContextProvider = ({ children }: { children: React.ReactNode }) => {
  const page = 'メモ';
  const [isMenuDataFetch, setIsMenuDataFetch] = useState<boolean>(false);
  const [menuItems, setMenuItems] = useState<MenuItemMap | null>(null);
  const [menuItemOrder, setMenuItemOrder] = useState<MenuItemOrderMap | null>(null);
  const [folders, setFolders] = useState<FolderMap | null>(null);
  const [folderOrder, setFolderOrder] = useState<FolderOrderMap | null>(null);

  return (
    <MemoContext.Provider
      value={{
        page,
        isMenuDataFetch,
        setIsMenuDataFetch,
        menuItems,
        setMenuItems,
        menuItemOrder,
        setMenuItemOrder,
        folders,
        setFolders,
        folderOrder,
        setFolderOrder,
      }}
    >
      {children}
    </MemoContext.Provider>
  );
};

export const useMemoContext = () => {
  const context = useContext(MemoContext);
  if (!context) {
    throw new Error('MemoContextをプロバイダーの中で取得してください');
  }
  return context;
};
