import { memo } from 'react';
import { Editor, FloatingMenu } from '@tiptap/react';
import { Node } from '@tiptap/pm/model';
import useContentItemActions from '../hooks/useContentItemActions';
import { Toolbar } from '../../../ui/Toolbar';
import { DropDownMenu } from '../../../ui/DropDownMenu';
import { zEditorMenu } from '../../../../styles/commonStyles';

interface ContentItemMenuProps {
  editor: Editor;
  currentNode: Node | null;
  currentNodePos: number;
  currentNodeDepth: number;
}

const ContentItemMenu = ({
  editor,
  currentNode,
  currentNodePos,
  currentNodeDepth,
}: ContentItemMenuProps) => {
  const actions = useContentItemActions(editor, currentNode, currentNodePos, currentNodeDepth);

  return (
    <FloatingMenu
      pluginKey="ContentItemMenu"
      editor={editor}
      tippyOptions={{
        placement: 'top-start',
        appendTo: () => document.querySelector('.blockEditor') as HTMLElement,
        offset: [-30, 0],
        zIndex: zEditorMenu.zIndex,
        getReferenceClientRect: () => {
          const element = document.querySelector('.has-focus');
          if (element) {
            const rect = element.getBoundingClientRect();
            // DOMRect を ClientRect に適した形式に変換し、toJSON を追加
            return {
              top: rect.top,
              left: rect.left,
              width: rect.width,
              height: rect.height,
              bottom: rect.bottom,
              right: rect.right,
              x: rect.left,
              y: rect.top,
              toJSON() {
                return {
                  top: this.top,
                  left: this.left,
                  width: this.width,
                  height: this.height,
                  bottom: this.bottom,
                  right: this.right,
                  x: this.x,
                  y: this.y,
                };
              },
            };
          }
          return {
            top: 0,
            left: 0,
            width: 0,
            height: 0,
            bottom: 0,
            right: 0,
            x: 0,
            y: 0,
            toJSON() {
              return {
                top: 0,
                left: 0,
                width: 0,
                height: 0,
                bottom: 0,
                right: 0,
                x: 0,
                y: 0,
              };
            },
          };
        },
      }}
      shouldShow={({ state }) => {
        const element = document.querySelector('.has-focus');
        return element !== null && state.selection.$anchor.parent.content.size > 0;
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '0.25rem',
          visibility: 'visible',
          position: 'absolute',
          top: '0px',
          left: '0px',
          zIndex: 1,
        }}
      >
        <Toolbar.Button
          icon="add-line"
          iconSize="0.9rem"
          onClick={actions.handleAdd}
          isActive={false}
          ariaLabel="ブロックの追加"
        />
        <DropDownMenu
          menuName="contentItemMoreMenu"
          ariaLabel="その他のメニュー"
          icon="more-2-line"
          iconSize="0.9rem"
          menuItems={[
            {
              icon: 'format-clear',
              label: '書式のクリア',
              onClick: actions.resetTextFormatting,
            },
            {
              icon: 'delete-bin-line',
              label: '削除',
              onClick: actions.deleteNode,
            },
          ]}
        />
      </div>
    </FloatingMenu>
  );
};

export default memo(ContentItemMenu);
