import { memo, useCallback, useRef } from 'react';
import { Divider } from '@mui/material';
import { BubbleMenu as BaseBubbleMenu, useEditorState } from '@tiptap/react';
import { Instance, sticky } from 'tippy.js';
import { v4 as uuid } from 'uuid';
import { ImageBlockWidth } from './ImageBlockWidth';
import { MenuProps } from '../../../types';
import { getRenderContainer } from '../../../utils/getRenderContainer';
import { Toolbar } from '../../../../../ui/Toolbar';
import { zEditorMenu } from '../../../../../../styles/commonStyles';

export const ImageBlockMenu = memo(({ editor, appendTo }: MenuProps): JSX.Element => {
  const menuRef = useRef<HTMLDivElement>(null);
  const tippyInstance = useRef<Instance | null>(null);

  const getReferenceClientRect = useCallback(() => {
    const renderContainer = getRenderContainer(editor, 'node-imageBlock');
    const rect = renderContainer?.getBoundingClientRect() || new DOMRect(-1000, -1000, 0, 0);

    return rect;
  }, [editor]);

  const shouldShow = useCallback(() => {
    const isActive = editor.isActive('imageBlock');

    return isActive;
  }, [editor]);

  const onAlignImageLeft = useCallback(() => {
    editor.chain().focus(undefined, { scrollIntoView: false }).setImageBlockAlign('left').run();
  }, [editor]);

  const onAlignImageCenter = useCallback(() => {
    editor.chain().focus(undefined, { scrollIntoView: false }).setImageBlockAlign('center').run();
  }, [editor]);

  const onAlignImageRight = useCallback(() => {
    editor.chain().focus(undefined, { scrollIntoView: false }).setImageBlockAlign('right').run();
  }, [editor]);

  const onWidthChange = useCallback(
    (value: number) => {
      editor.chain().focus(undefined, { scrollIntoView: false }).setImageBlockWidth(value).run();
    },
    [editor]
  );
  const { isImageCenter, isImageLeft, isImageRight, width } = useEditorState({
    editor,
    selector: ctx => ({
      isImageLeft: ctx.editor.isActive('imageBlock', { align: 'left' }),
      isImageCenter: ctx.editor.isActive('imageBlock', { align: 'center' }),
      isImageRight: ctx.editor.isActive('imageBlock', { align: 'right' }),
      width: parseInt(String(ctx.editor.getAttributes('imageBlock')?.width) || '0', 10),
    }),
  });

  return (
    <BaseBubbleMenu
      editor={editor}
      pluginKey={`imageBlockMenu-${uuid()}`}
      shouldShow={shouldShow}
      updateDelay={0} // デフォルトで 250 ミリ秒遅延しているが、0 に設定することで遅延をなくす
      tippyOptions={{
        zIndex: zEditorMenu.zIndex + 1,
        offset: [0, 8],
        popperOptions: {
          modifiers: [{ name: 'flip', enabled: false }],
        },
        getReferenceClientRect,
        onCreate: (instance: Instance) => {
          tippyInstance.current = instance;
        },
        appendTo: () => appendTo?.current as HTMLElement,
        plugins: [sticky],
        sticky: 'popper',
      }}
    >
      <Toolbar.Wrapper isShow={shouldShow()} ref={menuRef}>
        <Toolbar.Button
          icon="align-item-left-fill"
          onClick={onAlignImageLeft}
          ariaLabel="画像の左揃え"
          tooltip="左揃え"
          isActive={isImageLeft}
        />
        <Toolbar.Button
          icon="align-item-vertical-center-fill"
          onClick={onAlignImageCenter}
          ariaLabel="画像の中央揃え"
          tooltip="中央揃え"
          isActive={isImageCenter}
        />
        <Toolbar.Button
          icon="align-item-right-fill"
          onClick={onAlignImageRight}
          ariaLabel="画像の右揃え"
          tooltip="右揃え"
          isActive={isImageRight}
        />
        <Divider
          orientation="vertical"
          flexItem
          sx={{
            marginRight: '0.5rem',
          }}
        />
        <ImageBlockWidth onChange={onWidthChange} value={width} />
      </Toolbar.Wrapper>
    </BaseBubbleMenu>
  );
});

ImageBlockMenu.displayName = 'ImageBlockMenu';
export default ImageBlockMenu;
