import { memo, useEffect, useState } from 'react';
import { Box, CircularProgress, Paper, Stack } from '@mui/material';
import useGetRequest from '../hooks/useGetRequest';
import DataTable from '../components/common/DataTable';
import MainContainer from '../components/ui/MainContainer';
import SectionTitle from '../components/ui/SectionTitle';
import {
  MoneyCategoryResponse,
  ExpenseCategory,
  HealthCategory,
  IncomeCategory,
  FoodDB,
  ResponseFoodDB,
  Nutrition,
} from '../types';
import { center } from '../styles/commonStyles';

const Setting = () => {
  const [incomeCategory, setIncomeCategory] = useState<IncomeCategory[] | null>(null);
  const [expenseCategory, setExpenseCategory] = useState<ExpenseCategory[] | null>(null);
  const [healthCategory, setHealthCategory] = useState<HealthCategory[] | null>(null);
  const [foodDB, setFoodDB] = useState<FoodDB[] | null>(null);
  const [baseNutrition, setBaseNutrition] = useState<Nutrition | null>(null);
  const getRequest = useGetRequest();

  // データの取得 - MoneyCategory
  useEffect(() => {
    if (incomeCategory && expenseCategory) return;
    getRequest<MoneyCategoryResponse>({
      apiUrl: process.env.REACT_APP_MONEY_API,
      target: 'category',
    })
      .then(response => {
        if (response.content) {
          setIncomeCategory(response.content.income);
          setExpenseCategory(response.content.expense);
        }
      })
      .catch(error => {
        console.error('MoneyCategoryの取得に失敗しました:', error);
      });
  }, []);

  // データの取得 - HealthCategory
  useEffect(() => {
    if (healthCategory) return;
    getRequest<HealthCategory[]>({
      apiUrl: process.env.REACT_APP_HEALTH_API,
      target: 'category',
    })
      .then(response => {
        if (response.content) {
          setHealthCategory(response.content);
        }
      })
      .catch(error => {
        console.error('HealthCategoryの取得に失敗しました:', error);
      });
  }, []);

  // データの取得 - foodDB
  useEffect(() => {
    if (foodDB) return;
    getRequest<ResponseFoodDB[]>({
      apiUrl: process.env.REACT_APP_FOOD_API,
      target: 'DB',
    })
      .then(response => {
        if (response.content) {
          const DB = response.content.map(item => ({
            ...item,
            perItem: item.perItem !== 0,
          }));
          setFoodDB(DB);
        }
      })
      .catch(error => {
        console.error('foodDBの取得に失敗しました:', error);
      });
  }, []);

  // データの取得 - baseNutrition
  useEffect(() => {
    if (baseNutrition) return;
    getRequest<Nutrition>({
      apiUrl: process.env.REACT_APP_NUTR_API,
    })
      .then(response => {
        if (response.content) {
          setBaseNutrition(response.content);
        }
      })
      .catch(error => {
        console.error('Nutritionの取得に失敗しました:', error);
      });
  }, []);

  return (
    <MainContainer>
      <Stack
        spacing={6}
        sx={{
          height: '100%',
        }}
      >
        <Stack spacing={1} component={'ul'}>
          <li>
            <a href="https://remixicon.com/" target="_blank" rel="noopener noreferrer">
              Remix Icon（使用バージョンは4.6.0）
            </a>
          </li>
          <li>
            <a href="https://fooddb.mext.go.jp/" target="_blank" rel="noopener noreferrer">
              食品成分データベース
            </a>
          </li>
        </Stack>
        <Box>
          <SectionTitle title="収入カテゴリー" />
          {incomeCategory ? (
            <DataTable
              table="income"
              apiUrl={process.env.REACT_APP_MONEY_API || ''}
              gridRows={incomeCategory}
            />
          ) : (
            <Paper
              variant="outlined"
              sx={{
                width: '100%',
                height: '250px',
                borderRadius: '6px',
                ...center,
              }}
            >
              <CircularProgress color="secondary" size={20} />
            </Paper>
          )}
        </Box>
        <Box>
          <SectionTitle title="支出カテゴリー" />
          {expenseCategory ? (
            <DataTable
              table="expense"
              apiUrl={process.env.REACT_APP_MONEY_API || ''}
              gridRows={expenseCategory}
            />
          ) : (
            <Paper
              variant="outlined"
              sx={{
                width: '100%',
                height: '260px',
                borderRadius: '6px',
                ...center,
              }}
            >
              <CircularProgress color="secondary" size={20} />
            </Paper>
          )}
        </Box>
        <Box>
          <SectionTitle title="体調カテゴリー" />
          {healthCategory ? (
            <DataTable
              table="healthCategory"
              apiUrl={process.env.REACT_APP_HEALTH_API || ''}
              gridRows={healthCategory}
            />
          ) : (
            <Paper
              variant="outlined"
              sx={{
                width: '100%',
                height: '260px',
                borderRadius: '6px',
                ...center,
              }}
            >
              <CircularProgress color="secondary" size={20} />
            </Paper>
          )}
        </Box>
        <Box>
          <SectionTitle title="食品栄養データベース" />
          {foodDB ? (
            <DataTable
              table="foodDB"
              apiUrl={process.env.REACT_APP_FOOD_API || ''}
              gridRows={foodDB}
            />
          ) : (
            <Paper
              variant="outlined"
              sx={{
                width: '100%',
                height: '260px',
                borderRadius: '6px',
                ...center,
              }}
            >
              <CircularProgress color="secondary" size={20} />
            </Paper>
          )}
        </Box>
        <Box>
          <SectionTitle title="1日の目標栄養値" />
          {baseNutrition ? (
            <DataTable
              table="nurt"
              apiUrl={process.env.REACT_APP_NUTR_API || ''}
              gridRows={[baseNutrition]}
            />
          ) : (
            <Paper
              variant="outlined"
              sx={{
                width: '100%',
                height: '250px',
                borderRadius: '6px',
                ...center,
              }}
            >
              <CircularProgress color="secondary" size={20} />
            </Paper>
          )}
        </Box>
      </Stack>
    </MainContainer>
  );
};

export default memo(Setting);
