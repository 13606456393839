import { Node, mergeAttributes } from '@tiptap/core';

// カスタムノード column を定義
export const Column = Node.create({
  name: 'column',
  content: 'block+', // このノード内で許可されるコンテンツを定義。1つ以上のブロック要素を含めることができる
  isolating: true, // このノードをエディタ内で独立した単位として扱い、隣接ノードとの結合を防ぐ
  addAttributes() {
    // ノードに追加するカスタム属性を定義
    return {
      position: {
        default: '',
        parseHTML: element => element.getAttribute('data-position'), // HTML を解析する際に、ノードの属性として data-position を取り込む
        renderHTML: attributes => ({ 'data-position': attributes.position as string }), // ノードがレンダリングされる際に、data-position 属性として出力
      },
    };
  },
  // ノードが HTML に変換されるときの構造を定義
  renderHTML({ HTMLAttributes }) {
    return ['div', mergeAttributes(HTMLAttributes, { 'data-type': 'column' }), 0];
  },

  // エディタが HTML を解析する際に、このノードとして認識する条件を定義
  // div[data-type="column"] をこのノードと見なす
  parseHTML() {
    return [
      {
        tag: 'div[data-type="column"]',
      },
    ];
  },
});

export default Column;
