import { memo, useCallback, useState } from 'react';
import { Paper } from '@mui/material';
import { BubbleMenu as BaseBubbleMenu, useEditorState } from '@tiptap/react';
import { MenuProps } from '../types';
import { LinkEditorPanel } from './EditLinkPopover';
import LinkPreviewPanel from './LinkPreviewPanel';
import { zEditorMenu } from '../../../../styles/commonStyles';

const LinkMenu = ({ editor, appendTo }: MenuProps): JSX.Element => {
  const [showEdit, setShowEdit] = useState(false);

  const { link, target } = useEditorState<{
    link: string;
    target: string;
  }>({
    editor,
    selector: ctx => {
      const attrs = ctx.editor.getAttributes('link');
      return { link: attrs.href as string, target: attrs.target as string };
    },
  });

  const shouldShow = useCallback(() => {
    const isActive = editor.isActive('link');
    return isActive;
  }, [editor]);

  const handleEdit = useCallback(() => {
    setShowEdit(true);
  }, []);

  const onSetLink = useCallback(
    (url: string, openInNewTab?: boolean) => {
      editor
        .chain()
        .focus()
        .extendMarkRange('link')
        .setLink({ href: url, target: openInNewTab ? '_blank' : '' })
        .run();
      setShowEdit(false);
    },
    [editor]
  );

  const onUnsetLink = useCallback(() => {
    editor.chain().focus().extendMarkRange('link').unsetLink().run();
    setShowEdit(false);
    return null;
  }, [editor]);

  return (
    <BaseBubbleMenu
      editor={editor}
      pluginKey="textMenu"
      shouldShow={shouldShow}
      updateDelay={0}
      tippyOptions={{
        zIndex: zEditorMenu.zIndex + 1,
        popperOptions: {
          modifiers: [{ name: 'flip', enabled: false }],
        },
        appendTo: () => appendTo?.current as Element,
        onHidden: () => {
          setShowEdit(false);
        },
      }}
    >
      {showEdit ? (
        <Paper
          variant="outlined"
          sx={{
            padding: '0.5rem',
          }}
        >
          <LinkEditorPanel
            initialUrl={link}
            initialOpenInNewTab={target === '_blank'}
            onSetLink={onSetLink}
          />
        </Paper>
      ) : (
        <LinkPreviewPanel
          url={link}
          initialOpenInNewTab={target === '_blank'}
          onClear={onUnsetLink}
          onEdit={handleEdit}
        />
      )}
    </BaseBubbleMenu>
  );
};

export default memo(LinkMenu);
