import { memo, useCallback } from 'react';
import { BubbleMenu as BaseBubbleMenu } from '@tiptap/react';
import { isRowGripSelected } from './utils';
import { MenuProps, ShouldShowProps } from '../../../../types';
import { Toolbar } from '../../../../../../ui/Toolbar';
import { zEditorMenu } from '../../../../../../../styles/commonStyles';

export const TableRowMenu = memo(({ editor, appendTo }: MenuProps): JSX.Element => {
  const shouldShow = useCallback(
    ({ view, state, from }: ShouldShowProps) => {
      if (!state || !from) {
        return false;
      }

      return isRowGripSelected({ editor, view, state, from });
    },
    [editor]
  );

  const onAddRowBefore = useCallback(() => {
    editor.chain().focus().addRowBefore().run();
  }, [editor]);

  const onAddRowAfter = useCallback(() => {
    editor.chain().focus().addRowAfter().run();
  }, [editor]);

  const onDeleteRow = useCallback(() => {
    editor.chain().focus().deleteRow().run();
  }, [editor]);

  return (
    <BaseBubbleMenu
      editor={editor}
      pluginKey="tableRowMenu"
      updateDelay={0}
      tippyOptions={{
        zIndex: zEditorMenu.zIndex + 1,
        appendTo: () => appendTo?.current as HTMLElement,
        placement: 'left',
        offset: [0, 15],
        popperOptions: {
          modifiers: [{ name: 'flip', enabled: false }],
        },
      }}
      shouldShow={shouldShow}
    >
      <Toolbar.Wrapper isVertical>
        <Toolbar.Button
          icon="insert-row-bottom"
          ariaLabel="上に行を追加"
          onClick={onAddRowBefore}
          tooltip="上に行を追加"
          isActive={false}
          placement="left"
        />
        <Toolbar.Button
          icon="insert-row-top"
          ariaLabel="下に行を追加"
          onClick={onAddRowAfter}
          tooltip="下に行を追加"
          isActive={false}
          placement="left"
        />
        <Toolbar.Button
          icon="delete-row"
          ariaLabel="行を削除"
          onClick={onDeleteRow}
          tooltip="行を削除"
          isActive={false}
          placement="left"
        />
      </Toolbar.Wrapper>
    </BaseBubbleMenu>
  );
});

TableRowMenu.displayName = 'TableRowMenu';

export default TableRowMenu;
