import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Container, Box, Typography, TextField, Stack, Paper } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import LockSharpIcon from '@mui/icons-material/LockSharp';
import { theme } from '../theme';
import { center, bgBlack } from '../styles/commonStyles';
import { useAuthContext } from '../contexts/AuthContext';
import { LoginResponse } from '../types/index';

interface LoginProps {
  loginAuth: boolean;
  setLoginAuth: React.Dispatch<React.SetStateAction<boolean>>;
  entryURL: string;
}

const Login = ({ loginAuth, setLoginAuth, entryURL }: LoginProps) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const initialErrorMessage = { userName: '', password: '', login: '' };
  const [errorMessage, setErrorMessage] = useState(initialErrorMessage);
  const { setUserId, setCsrfToken } = useAuthContext();

  // ページ遷移の処理
  const handleNavigation = (url: string) => {
    navigate(url === '/login' ? '/' : url);
  };

  useEffect(() => {
    if (loginAuth) {
      handleNavigation(entryURL);
    }
  }, []);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    setIsLoading(true);
    e.preventDefault();
    setErrorMessage(initialErrorMessage);
    let isError = false;
    const validationRegex = /^[a-zA-Z0-9_/@-]+$/;
    const form = new FormData(e.currentTarget);
    const userName = form.get('userName') as string;
    const password = form.get('password') as string;

    // userNameのバリデーション
    if (userName === '') {
      setErrorMessage(prev => ({ ...prev, userName: 'ユーザー名を入力してください' }));
      isError = true;
    } else if (userName.length > 20) {
      setErrorMessage(prev => ({ ...prev, userName: 'ユーザー名は20文字以内で入力してください' }));
      isError = true;
    } else if (!validationRegex.test(userName)) {
      setErrorMessage(prev => ({
        ...prev,
        userName: '半角英数字と記号（_/@-）で入力してください',
      }));
      isError = true;
    }

    // passwordのバリデーション
    if (password === '') {
      setErrorMessage(prev => ({ ...prev, password: 'パスワードを入力してください' }));
      isError = true;
    } else if (password.length > 20) {
      setErrorMessage(prev => ({ ...prev, password: 'パスワードは20文字以内で入力してください' }));
      isError = true;
    } else if (!validationRegex.test(password)) {
      setErrorMessage(prev => ({
        ...prev,
        password: '半角英数字と記号（_/@-）で入力してください',
      }));
      isError = true;
    }

    // バリデーションエラーがある場合は処理を中断
    if (isError) {
      setIsLoading(false);
      return;
    }

    const apiUrl = process.env.REACT_APP_LOGIN_API;
    if (!apiUrl) {
      setIsLoading(false);
      setErrorMessage(prev => ({ ...prev, login: 'データベースへの接続に失敗しました' }));
      return;
    }
    axios
      .post<LoginResponse>(
        apiUrl,
        {
          userName,
          password,
        },
        {
          withCredentials: true,
        }
      )
      .then(response => {
        if (response.data.success) {
          setIsLoading(false);
          setUserId(response.data.userId);
          setCsrfToken(response.data.csrfToken);
          setLoginAuth(true);
          handleNavigation(entryURL);
        } else {
          setIsLoading(false);
          setErrorMessage(prev => ({ ...prev, login: response.data.error }));
        }
      })
      .catch(error => {
        setIsLoading(false);
        setErrorMessage(prev => ({ ...prev, login: 'データベースへの接続に失敗しました' }));
        console.error(error);
      });
  };

  return (
    <Container
      component="main"
      sx={{
        ...center,
        p: 3,
        minHeight: '100vh',
      }}
    >
      <Paper
        variant="outlined"
        sx={{
          backgroundColor: '#fff',
          width: '100%',
          maxWidth: '400px',
          borderColor: theme.palette.primary.main,
        }}
      >
        <Box
          padding={1.5}
          sx={{
            ...bgBlack,
            textAlign: 'center',
          }}
        >
          <LockSharpIcon sx={{ color: '#fff', verticalAlign: 'bottom' }} />
        </Box>
        <Stack
          component="form"
          noValidate
          onSubmit={handleSubmit}
          spacing={3}
          sx={{
            padding: {
              xs: 3,
              sm: 4,
            },
          }}
        >
          <TextField
            label="UserName"
            id="userName"
            name="userName"
            variant="outlined"
            autoComplete="off"
            helperText={errorMessage.userName}
            error={errorMessage.userName !== ''}
            required
            fullWidth
            inputProps={{
              'aria-label': 'userName',
            }}
          />
          <TextField
            label="Password"
            id="password"
            name="password"
            variant="outlined"
            type="password"
            autoComplete="off"
            helperText={errorMessage.password}
            error={errorMessage.password !== ''}
            required
            fullWidth
            inputProps={{
              'aria-label': 'password',
            }}
          />
          {errorMessage.login !== '' && (
            <Typography variant="caption" color="error">
              {errorMessage.login}
            </Typography>
          )}
          <LoadingButton type="submit" variant="contained" loading={isLoading} fullWidth>
            {isLoading ? '　' : 'ログイン'}
          </LoadingButton>
        </Stack>
      </Paper>
    </Container>
  );
};

export default Login;
