import { createContext, useContext, useState } from 'react';

interface AuthContextType {
  userId: string;
  setUserId: React.Dispatch<React.SetStateAction<string>>;
  csrfToken: string;
  setCsrfToken: React.Dispatch<React.SetStateAction<string>>;
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  loginAuth: boolean;
  setLoginAuth: React.Dispatch<React.SetStateAction<boolean>>;
  entryURL: string;
  setEntryURL: React.Dispatch<React.SetStateAction<string>>;
}

export const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [userId, setUserId] = useState('');
  const [csrfToken, setCsrfToken] = useState('');
  const [loginAuth, setLoginAuth] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [entryURL, setEntryURL] = useState('/');

  return (
    <AuthContext.Provider
      value={{
        userId,
        setUserId,
        csrfToken,
        setCsrfToken,
        isLoading,
        setIsLoading,
        loginAuth,
        setLoginAuth,
        entryURL,
        setEntryURL,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('AuthContextをプロバイダーの中で取得してください');
  }
  return context;
};
