import { useCallback, useEffect, useState } from 'react';
import { Autocomplete, Button, MenuItem, Stack, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { alpha } from '@mui/system';
import { zodResolver } from '@hookform/resolvers/zod';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { foodSchema, FoodSchema } from '../../validations/schema';
import useSaveRequest from '../../hooks/useSaveRequest';
import useDeleteRequest from '../../hooks/useDeleteRequest';
import { Food, FoodDB } from '../../types';
import { theme } from '../../theme';
import {
  autocompleteTextFieldStyle,
  selectMenuItem,
  textFieldOutlinedStyleWithLabel,
  textFieldOutlinedStyleWithLabel2,
  textFieldStandardStyleWithLabel,
} from '../../styles/commonStyles';

interface FoodFormProps {
  currentDay: string;
  currentFoodId: number;
  foodData: Map<number, Food>;
  foodDB: Map<string, FoodDB>;
  setFoodData: React.Dispatch<React.SetStateAction<Food[] | null>>;
  setCurrentFoodId: React.Dispatch<React.SetStateAction<number>>;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setIsDataFetch: React.Dispatch<React.SetStateAction<boolean>>;
}

const FoodForm = ({
  currentDay,
  currentFoodId,
  foodData,
  foodDB,
  setFoodData,
  setCurrentFoodId,
  setIsModalOpen,
  setIsDataFetch,
}: FoodFormProps) => {
  const [isSaveLoading, setIsSaveLoading] = useState(false);
  const [isDelLoading, setIsDelLoading] = useState(false);
  const [autoFillData, setAutoFillData] = useState<{
    DB: FoodDB;
    quantity: number;
    unit: string;
  } | null>(null);
  const saveRequest = useSaveRequest();
  const deleteRequest = useDeleteRequest();

  // ReactHookFormの基本設定
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
    reset,
    getValues,
    watch,
  } = useForm<FoodSchema>({
    defaultValues: {
      date: currentDay,
      name: '',
      quantity: null,
      unit: 'g',
      energy: null,
      protein: null,
      fat: null,
      carb: null,
      salt: null,
    },
    resolver: zodResolver(foodSchema),
    mode: 'onChange',
  });

  // 現在のフォームの項目を監視
  const currentName = watch('name');
  const currentQuantity = watch('quantity');
  const currentUnit = watch('unit');
  useEffect(() => {
    if (!currentName) {
      setAutoFillData(null);
      return;
    }
    const DBData = foodDB.get(currentName);
    if (!DBData || !currentQuantity || !currentUnit) {
      setAutoFillData(null);
      return;
    }
    const DBunit = DBData.perItem ? '個' : 'g';
    if (currentUnit !== DBunit) {
      setAutoFillData(null);
      return;
    }
    setAutoFillData({
      DB: DBData,
      quantity: currentQuantity,
      unit: currentUnit,
    });
  }, [currentName, currentQuantity, currentUnit]);

  const handleAutoFill = () => {
    if (!autoFillData) return;

    const data = autoFillData.DB;
    const currentQuantity = autoFillData.quantity;
    const currentUnit = autoFillData.unit;

    const roundToThreeDecimals = (value: number | null): number | null => {
      if (value === null) return null;
      return Math.round(value * 1000) / 1000;
    };

    const calculateValue = (baseValue: number | null, multiplier: number): number | null => {
      if (baseValue === null) return null; // データが未登録の場合
      return roundToThreeDecimals(baseValue * multiplier); // 計算結果を返す
    };

    if (currentUnit === '個') {
      setValue('energy', calculateValue(data.energy, currentQuantity) ?? 0);
      setValue('protein', calculateValue(data.protein, currentQuantity));
      setValue('fat', calculateValue(data.fat, currentQuantity));
      setValue('carb', calculateValue(data.carb, currentQuantity));
      setValue('salt', calculateValue(data.salt, currentQuantity));
    }

    if (currentUnit === 'g') {
      const ratio = currentQuantity / 100;
      setValue('energy', calculateValue(data.energy, ratio) ?? 0);
      setValue('protein', calculateValue(data.protein, ratio));
      setValue('fat', calculateValue(data.fat, ratio));
      setValue('carb', calculateValue(data.carb, ratio));
      setValue('salt', calculateValue(data.salt, ratio));
    }
  };

  // 既存データがあれば、フォームにデータをセット
  useEffect(() => {
    const data = foodData.get(currentFoodId);
    if (currentFoodId !== 0 && foodData && data) {
      setValue('date', data.date);
      setValue('name', data.name);
      setValue('quantity', data.quantity);
      setValue('unit', data.unit);
      setValue('energy', data.energy);
      setValue('protein', data.protein);
      setValue('fat', data.fat);
      setValue('carb', data.carb);
      setValue('salt', data.salt);
    } else {
      reset({
        date: currentDay,
        name: '',
        quantity: null,
        unit: 'g',
        energy: null,
        protein: null,
        fat: null,
        carb: null,
        salt: null,
      });
    }
  }, [currentFoodId, foodData]);

  // 新規保存・更新処理
  const onSubmit: SubmitHandler<FoodSchema> = useCallback(() => {
    setIsSaveLoading(true);
    setIsDataFetch(false);
    const sendData = getValues();
    saveRequest({
      apiUrl: process.env.REACT_APP_FOOD_API,
      id: String(currentFoodId),
      data: sendData,
    })
      .then(response => {
        setFoodData(prev => {
          if (!prev) return null;
          const newData = prev.filter(item => item.id !== Number(response.id));
          const addData = { ...sendData, id: Number(response.id) } as Food;
          return [...newData, addData];
        });
      })
      .catch(error => {
        console.error('Foodの更新に失敗しました:', error);
      })
      .finally(() => {
        setIsSaveLoading(false);
        setIsModalOpen(false);
      });
  }, []);

  // 削除
  const handleDelete = useCallback(() => {
    setIsDelLoading(true);
    setIsDataFetch(false);
    deleteRequest({
      apiUrl: process.env.REACT_APP_FOOD_API,
      id: String(currentFoodId),
    })
      .then(() => {
        setFoodData(prev => {
          if (!prev) return null;
          return prev.filter(item => item.id !== currentFoodId);
        });
      })
      .catch(error => {
        console.error('Foodの削除に失敗しました:', error);
      })
      .finally(() => {
        setIsDelLoading(false);
        setIsModalOpen(false);
        setCurrentFoodId(0);
      });
  }, []);

  return (
    <Stack
      component="form"
      noValidate
      spacing={2.5}
      onSubmit={e => {
        e.preventDefault(); // デフォルトのフォーム送信を防ぐ
        handleSubmit(onSubmit)(e).catch(error => {
          console.error('フォーム送信エラー:', error);
        });
      }}
    >
      <Controller
        name="date"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            id="date"
            required
            type="date"
            variant="standard"
            label="日付"
            placeholder="日付を設定"
            sx={{
              ...textFieldStandardStyleWithLabel,
              width: 'fit-content',
            }}
            error={!!errors.date}
            helperText={errors.date?.message}
          />
        )}
      />
      <Controller
        name="name"
        control={control}
        render={({ field }) => (
          <Autocomplete
            {...field}
            id="name"
            disablePortal
            options={Array.from(foodDB.keys()) || []}
            getOptionLabel={option => foodDB.get(option)?.name || option || ''}
            onKeyDown={event => {
              const input = event.target as HTMLInputElement;
              const { value } = input;
              const num = Number(value);
              const isNumber = !Number.isNaN(Number(num)) && Number.isInteger(Number(num)); // 数値であれば true を返す
              if (isNumber) return; // もし入力値が数値であれば何もしない
              field.onChange(value !== null ? value : '');
            }}
            onChange={(event, newValue) => {
              field.onChange(newValue !== null ? newValue : '');
            }}
            freeSolo
            noOptionsText="該当するデータはありません"
            renderInput={params => (
              <TextField
                {...params}
                variant="filled"
                label="名称を選択 or 入力"
                placeholder="名称を選択 or 入力"
                size="small"
                error={!!errors.name}
                helperText={errors.name?.message}
                sx={{
                  ...autocompleteTextFieldStyle,
                  '.MuiFormLabel-root[data-shrink="false"]': {
                    left: '-4px',
                    top: '-4px',
                    color: alpha(theme.palette.secondary.main, 0.5),
                    transition: 'none',
                  },
                  '.MuiFilledInput-root.MuiInputBase-sizeSmall .MuiFilledInput-input': {
                    padding: '5px !important',
                  },
                }}
              />
            )}
          />
        )}
      />

      <Stack direction={'row'} spacing={1} flexWrap={'nowrap'}>
        <Controller
          name="quantity"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              id="quantity"
              required
              type="number"
              label="量"
              placeholder="量を設定"
              size="small"
              value={field.value ?? ''}
              onChange={event => {
                const newValue = event.target.value;
                field.onChange(newValue === '' ? null : Number(newValue));
              }}
              sx={{
                flexGrow: 1,
                ...textFieldOutlinedStyleWithLabel,
                '.MuiInputBase-root.MuiOutlinedInput-root': {
                  width: 'calc(100% - 2.5rem)',
                },
              }}
              error={!!errors.quantity}
              helperText={errors.quantity?.message}
            />
          )}
        />
        <Controller
          name="unit"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              id="unit"
              required
              select
              variant="filled"
              size="small"
              label="単位を選択"
              error={!!errors.unit}
              helperText={errors.unit?.message}
              sx={{
                width: 'fit-content',
                minWidth: '5rem',
                flexShrink: 0,
                '.MuiInputBase-root': {
                  backgroundColor: theme.palette.secondary.light,
                },
                '.MuiInputBase-input.MuiFilledInput-input:focus-visible': {
                  outline: 'solid 2px',
                  borderRadius: '1px',
                },
                '.MuiSelect-select.MuiSelect-filled': {
                  paddingTop: '0.5rem',
                  paddingBottom: '0.5rem',
                  paddingLeft: '0.75rem',
                  fontWeight: 500,
                },
                '.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl': {
                  visibility: 'hidden',
                },
                '.MuiFormLabel-asterisk': {
                  display: 'none',
                },
                '.MuiFormHelperText-root': {
                  marginLeft: 0,
                },
              }}
            >
              {['g', '個'].map((item, index) => (
                <MenuItem
                  key={index}
                  value={item}
                  sx={{
                    ...selectMenuItem,
                  }}
                >
                  {item}
                </MenuItem>
              ))}
            </TextField>
          )}
        />
      </Stack>

      <Button
        variant="contained"
        disabled={autoFillData === null}
        sx={{
          width: 'fit-content',
        }}
        onClick={handleAutoFill}
      >
        データベースから自動入力
      </Button>

      <Controller
        name="energy"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            id="energy"
            required
            type="number"
            size="small"
            variant="outlined"
            label="熱量（kcal）"
            placeholder="熱量を設定"
            value={field.value ?? ''}
            sx={{
              ...textFieldOutlinedStyleWithLabel,
              '.MuiInputBase-root.MuiOutlinedInput-root': {
                width: 'calc(100% - 6.5rem)',
              },
            }}
            onChange={event => {
              const newValue = event.target.value;
              field.onChange(newValue === '' ? null : Number(newValue));
            }}
            error={!!errors.energy}
            helperText={errors.energy?.message}
          />
        )}
      />

      <Controller
        name="protein"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            id="protein"
            variant="outlined"
            type="number"
            size="small"
            label="たんぱく質（g）"
            placeholder="たんぱく質を設定"
            value={field.value !== null ? field.value : ''}
            sx={{
              ...textFieldOutlinedStyleWithLabel2,
              '.MuiInputBase-root.MuiOutlinedInput-root': {
                width: 'calc(100% - 8rem)',
              },
            }}
            onChange={event => {
              const newValue = event.target.value;
              field.onChange(newValue === '' ? null : Number(newValue));
            }}
            error={!!errors.protein}
            helperText={errors.protein?.message}
          />
        )}
      />

      <Controller
        name="fat"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            id="fat"
            variant="outlined"
            type="number"
            size="small"
            label="脂質（g）"
            placeholder="脂質を設定"
            value={field.value !== null ? field.value : ''}
            sx={{
              ...textFieldOutlinedStyleWithLabel2,
              '.MuiInputBase-root.MuiOutlinedInput-root': {
                width: 'calc(100% - 5.5rem)',
              },
            }}
            onChange={event => {
              const newValue = event.target.value;
              field.onChange(newValue === '' ? null : Number(newValue));
            }}
            error={!!errors.fat}
            helperText={errors.fat?.message}
          />
        )}
      />

      <Controller
        name="carb"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            id="carb"
            variant="outlined"
            type="number"
            size="small"
            label="炭水化物（g）"
            placeholder="炭水化物を設定"
            value={field.value !== null ? field.value : ''}
            sx={{
              ...textFieldOutlinedStyleWithLabel2,
              '.MuiInputBase-root.MuiOutlinedInput-root': {
                width: 'calc(100% - 7.25rem)',
              },
            }}
            onChange={event => {
              const newValue = event.target.value;
              field.onChange(newValue === '' ? null : Number(newValue));
            }}
            error={!!errors.carb}
            helperText={errors.carb?.message}
          />
        )}
      />

      <Controller
        name="salt"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            id="salt"
            variant="outlined"
            type="number"
            size="small"
            label="食塩相当量（g）"
            placeholder="食塩相当量を設定"
            value={field.value !== null ? field.value : ''}
            sx={{
              ...textFieldOutlinedStyleWithLabel2,
              '.MuiInputBase-root.MuiOutlinedInput-root': {
                width: 'calc(100% - 8rem)',
              },
            }}
            onChange={event => {
              const newValue = event.target.value;
              field.onChange(newValue === '' ? null : Number(newValue));
            }}
            error={!!errors.salt}
            helperText={errors.salt?.message}
          />
        )}
      />

      <Stack spacing={1.5}>
        <LoadingButton type="submit" variant="contained" loading={isSaveLoading} fullWidth>
          {isSaveLoading ? '　' : currentFoodId !== 0 ? '更新' : '保存'}
        </LoadingButton>
        {currentFoodId !== 0 && (
          <LoadingButton
            onClick={handleDelete}
            variant="outlined"
            color={'secondary'}
            loading={isDelLoading}
            fullWidth
          >
            {isDelLoading ? '　' : '削除'}
          </LoadingButton>
        )}
      </Stack>
    </Stack>
  );
};
export default FoodForm;
