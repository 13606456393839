import { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { Box, CircularProgress, Container } from '@mui/material';
import Navigation from './Navigation';
import DrawerLeft from './DrawerLeft';
import { center, navWidth } from '../../styles/commonStyles';

interface LayoutProps {
  isLoading: boolean;
  setUserId: React.Dispatch<React.SetStateAction<string>>;
  setCsrfToken: React.Dispatch<React.SetStateAction<string>>;
  setLoginAuth: React.Dispatch<React.SetStateAction<boolean>>;
  setEntryURL: React.Dispatch<React.SetStateAction<string>>;
}

const Layout = ({ isLoading, setUserId, setCsrfToken, setLoginAuth, setEntryURL }: LayoutProps) => {
  const pathname = useLocation().pathname.split('/')[1];
  const isDrawer = ['memo', 'gallery', 'project'].includes(pathname);

  useEffect(() => {
    console.log('Layout：初回レンダリング');
  }, []);

  console.log('Layout：レンダリング');

  return (
    <>
      {isLoading ? (
        <Box
          sx={{
            width: '100%',
            height: '100vh',
            position: 'fixed',
            top: 0,
            left: 0,
            zIndex: 9999,
            ...center,
          }}
        >
          <CircularProgress color="secondary" size={30} />
        </Box>
      ) : (
        <>
          <Navigation
            setUserId={setUserId}
            setCsrfToken={setCsrfToken}
            setLoginAuth={setLoginAuth}
            setEntryURL={setEntryURL}
          ></Navigation>
          <Container
            disableGutters // パディング削除
            maxWidth={false}
            sx={{
              margin: '0 0 0 auto',
              width: { xs: '100%', md: `calc(100% - ${navWidth})` },
            }}
          >
            {isDrawer ? (
              <DrawerLeft>
                <Outlet />
              </DrawerLeft>
            ) : (
              <Outlet />
            )}
          </Container>
        </>
      )}
    </>
  );
};

export default Layout;
