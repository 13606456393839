import { memo, useCallback } from 'react';
import { Editor, NodeViewWrapper } from '@tiptap/react';
import { ImageUploader } from './ImageUploader';

export const ImageUpload = memo(({ getPos, editor }: { getPos: () => number; editor: Editor }) => {
  const onUpload = useCallback(
    (url: string) => {
      if (url) {
        editor
          .chain()
          .setImageBlock({ src: url })
          .deleteRange({ from: getPos(), to: getPos() })
          .focus()
          .run();
      }
    },
    [getPos, editor]
  );

  return (
    <NodeViewWrapper>
      <div
        style={{
          padding: '0',
          margin: '0',
        }}
        data-drag-handle
      >
        <ImageUploader onUpload={onUpload} />
      </div>
    </NodeViewWrapper>
  );
});

ImageUpload.displayName = 'ImageUpload';
export default ImageUpload;
