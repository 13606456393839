import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Box, Container } from '@mui/material';
import { differenceInSeconds, format, parseISO } from 'date-fns';
import DrawerRight from '../components/Home/DrawerRight';
import Calendar from '../components/Home/Calendar/Calendar';
import Modal from '../components/ui/Modal';
import { Project, Section, TaskTime, Todo } from '../types';
import TodoForm from '../components/common/TodoForm';
import useGetRequest from '../hooks/useGetRequest';
import { useProjectContext } from '../contexts/ProjectContext';
import { useTodoContext } from '../contexts/TodoContext';
import { createDndKitMap, createNumberIdMap, createStringIdMap } from '../utils/createMaps';

/* eslint-disable */
const Home = () => {
  useEffect(() => {
    console.log('Home:初回レンダリング');
  }, []);

  console.log('Home:レンダリング');
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const toDay = useMemo(() => format(new Date(), 'yyyy-MM-dd'), []);
  const [currentDay, setCurrentDay] = useState(toDay);
  const [currentTodoId, setCurrentTodoId] = useState<number>(0);
  const lastFocusedButtonRef = useRef<HTMLButtonElement | null>(null);
  const { todoData, setTodoData, taskTimeData, setTaskTimeData } = useTodoContext();
  const {
    menuItems: projectData,
    setMenuItems: setProjectData,
    sectionData,
    setSectionData,
  } = useProjectContext();
  const getRequest = useGetRequest();

  // データの取得 - Todo
  useEffect(() => {
    if (todoData) return;
    getRequest<Todo[]>({
      apiUrl: process.env.REACT_APP_TODO_API,
    })
      .then(response => {
        if (response.content) {
          setTodoData(createNumberIdMap(response.content));
        }
      })
      .catch(error => {
        console.error('Todoの取得に失敗しました:', error);
      });
  }, []);

  // データの取得 - TaskTime
  useEffect(() => {
    if (taskTimeData) return;
    getRequest<TaskTime[]>({
      apiUrl: process.env.REACT_APP_TASKTIME_API,
    })
      .then(response => {
        if (response.content) {
          setTaskTimeData(createNumberIdMap(response.content));
        }
      })
      .catch(error => {
        console.error('TaskTimeの取得に失敗しました:', error);
      });
  }, []);

  // データの取得 - Project
  useEffect(() => {
    if (projectData) return;
    getRequest<Project[]>({
      apiUrl: process.env.REACT_APP_PROJECT_API,
    })
      .then(response => {
        if (response.content) {
          setProjectData(createDndKitMap(response.content, 'title'));
        }
      })
      .catch(error => {
        console.error('Projectの取得に失敗しました:', error);
      });
  }, []);

  // データの取得 - Section
  useEffect(() => {
    if (sectionData) return;
    getRequest<Section[]>({
      apiUrl: process.env.REACT_APP_SECTION_API,
    })
      .then(response => {
        if (response.content) {
          setSectionData(createStringIdMap(response.content));
        }
      })
      .catch(error => {
        console.error('Sectionの取得に失敗しました:', error);
      });
  }, []);

  // 選択中の日付に紐づくTodoのIDを取得
  const getCurrentDayTodoIds = useCallback(
    (map: Map<number, any>, targetDate: string): number[] => {
      const getDateWithoutTime = (date: Date): Date => {
        return new Date(date.getFullYear(), date.getMonth(), date.getDate());
      };
      const target = getDateWithoutTime(new Date(targetDate)); // yyyy-MM-dd を Date に変換
      return Array.from(map.values())
        .filter(item => {
          const startDate = getDateWithoutTime(new Date(item.start));
          const endDate = item.end ? getDateWithoutTime(new Date(item.end)) : null;
          if (endDate) {
            // start <= target <= end の場合
            return startDate <= target && target <= endDate;
          } else {
            // start === target の場合
            return startDate.getTime() === target.getTime();
          }
        })
        .map(item => item.id); // 該当するデータの id を抽出
    },
    []
  );

  const currentDayTodoIds = useMemo(() => {
    if (!todoData) return [];
    return getCurrentDayTodoIds(todoData, currentDay);
  }, [todoData, currentDay]);

  // 時間差を累積する関数
  const calculateCumulativeTime = useCallback(
    (taskTimeData: Map<number, TaskTime>, currentDayTodoIds: number[]): Map<number, number> => {
      const cumulativeTime = new Map<number, number>();
      // Map の各エントリを処理
      taskTimeData.forEach(task => {
        if (currentDayTodoIds.includes(task.todoId)) {
          if (!task.start || !task.end) return;
          const startTime = parseISO(task.start);
          const endTime = parseISO(task.end);
          // 差分が負なら無視
          const durationInSeconds = differenceInSeconds(endTime, startTime);
          if (durationInSeconds > 0) {
            cumulativeTime.set(
              task.todoId,
              (cumulativeTime.get(task.todoId) || 0) + durationInSeconds
            );
          }
        }
      });
      return cumulativeTime;
    },
    []
  );

  const totalTaskTimes = useMemo(() => {
    if (!taskTimeData) return new Map<number, number>();
    return calculateCumulativeTime(taskTimeData, currentDayTodoIds);
  }, [taskTimeData, currentDayTodoIds]);

  const setButtonElement = (target: HTMLButtonElement | null) => {
    lastFocusedButtonRef.current = target;
  };

  useEffect(() => {
    if (!isModalOpen && lastFocusedButtonRef.current) {
      setTimeout(() => {
        lastFocusedButtonRef.current?.focus();
      }, 500); // モーダルが閉じるアニメーションの時間
    }
  }, [isModalOpen]);

  return (
    <Container
      component="main"
      maxWidth={false}
      disableGutters
      sx={{
        display: 'flex',
        minHeight: '100vh',
      }}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexWrap="wrap"
        flexDirection="column"
        sx={{
          flexGrow: 1,
        }}
      >
        <Calendar
          toDay={toDay}
          currentDay={currentDay}
          setCurrentDay={setCurrentDay}
          setCurrentTodoId={setCurrentTodoId}
          setIsDrawerOpen={setIsDrawerOpen}
          setIsModalOpen={setIsModalOpen}
          todoData={todoData}
          setTodoData={setTodoData}
          projectData={projectData}
        />
        {/* <MonthlyMemoArea pageType="home" /> */}
      </Box>
      <DrawerRight
        todoData={todoData}
        setTodoData={setTodoData}
        projectData={projectData}
        totalTaskTimes={totalTaskTimes}
        currentDay={currentDay}
        currentDayTodoIds={currentDayTodoIds}
        setCurrentTodoId={setCurrentTodoId}
        isDrawerOpen={isDrawerOpen}
        setIsDrawerOpen={setIsDrawerOpen}
        setIsModalOpen={setIsModalOpen}
        setButtonElement={setButtonElement}
      />
      <Modal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen}>
        <TodoForm
          currentDay={currentDay}
          currentTodoId={currentTodoId}
          setCurrentTodoId={setCurrentTodoId}
          setIsModalOpen={setIsModalOpen}
        />
      </Modal>
    </Container>
  );
};

export default Home;
