import { memo, useEffect, useMemo, useState } from 'react';
import { endOfYear, format, parseISO, startOfYear } from 'date-fns';
import { Box, Paper, Stack, useMediaQuery } from '@mui/material';
import { GridRowsProp } from '@mui/x-data-grid';
import useGetRequest from '../hooks/useGetRequest';
import MainContainer from '../components/ui/MainContainer';
import MonthSelector from '../components/common/MonthSelector';
import DataTable from '../components/common/DataTable';
import IncomeExpensePieChart from '../components/Money/IncomeExpensePieChart';
import BalanceBarChart from '../components/Money/BalanceBarChart';
import {
  MoneyCategoryResponse,
  ExpenseCategory,
  IncomeCategory,
  Money as MoneyDataType,
} from '../types';
import BalanceSummary from '../components/Money/BalanceSummary';
import SectionTitle from '../components/ui/SectionTitle';
import { theme } from '../theme';
import { center } from '../styles/commonStyles';
import { createNumberIdMap } from '../utils/createMaps';

const Money = () => {
  useEffect(() => {
    console.log('Moneyページ:初回レンダリング');
  }, []);
  console.log('Moneyページ:レンダリング');

  const [currentMonth, setCurrentMonth] = useState<Date>(new Date());
  const [currentYear, setCurrentYear] = useState<number>(0);
  const [moneyData, setMoneyData] = useState<MoneyDataType[] | null>(null); // 1年間のデータ
  const [incomeCategory, setIncomeCategory] = useState<IncomeCategory[] | null>(null);
  const [expenseCategory, setExpenseCategory] = useState<ExpenseCategory[] | null>(null);
  const [incomeCategoryMap, setIncomeCategoryMap] = useState<Map<number, IncomeCategory> | null>(
    null
  );
  const [expenseCategoryMap, setExpenseCategoryMap] = useState<Map<number, ExpenseCategory> | null>(
    null
  );
  const [gridRows, setGridRows] = useState<GridRowsProp | null>(null);
  const [isDataFetch, setIsDataFetch] = useState<boolean>(false);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const getRequest = useGetRequest();

  // 選択月のデータのみにフィルタリング
  const filterDataByMonth = (data: MoneyDataType[], month: Date) =>
    data.filter(item => {
      const itemDate = new Date(item.date);
      return (
        itemDate.getFullYear() === month.getFullYear() && itemDate.getMonth() === month.getMonth()
      );
    });

  // gridRows用のデータを作成
  const formatToGridRows = (
    data: MoneyDataType[],
    incomeCategory: Map<number, IncomeCategory>,
    expenseCategory: Map<number, ExpenseCategory>
  ): GridRowsProp =>
    data.map(item => ({
      ...item,
      date: new Date(item.date),
      category:
        item.type === '収入'
          ? incomeCategory.get(item.category)?.name || 'カテゴリー不明'
          : expenseCategory.get(item.category)?.name || 'カテゴリー不明',
    }));

  // データの取得 - Money
  useEffect(() => {
    setIsDataFetch(false);
    if (currentMonth.getFullYear() === currentYear) {
      // gridRowsのみ更新
      if (moneyData && incomeCategoryMap && expenseCategoryMap) {
        const monthlyData = filterDataByMonth(moneyData, currentMonth);
        const rows = formatToGridRows(monthlyData, incomeCategoryMap, expenseCategoryMap);
        setGridRows(rows);
      }
    } else {
      // 1年分のデータを新規取得
      setMoneyData(null);
      setGridRows(null);
      setCurrentYear(currentMonth.getFullYear());
      const startDate = startOfYear(currentMonth);
      const endDate = endOfYear(currentMonth);
      getRequest<MoneyDataType[]>({
        apiUrl: process.env.REACT_APP_MONEY_API,
        data: {
          start: format(startDate, 'yyyy-MM-dd'),
          end: format(endDate, 'yyyy-MM-dd'),
        },
      })
        .then(response => {
          if (response.content) {
            setMoneyData(response.content);
          }
        })
        .catch(error => {
          console.error('Moneyデータの取得に失敗しました:', error);
        });
    }
  }, [currentMonth]);

  useEffect(() => {
    if (moneyData && incomeCategoryMap && expenseCategoryMap) {
      const monthlyData = filterDataByMonth(moneyData, currentMonth);
      const rows = formatToGridRows(monthlyData, incomeCategoryMap, expenseCategoryMap);
      setGridRows(rows);
    } else {
      setGridRows(null);
    }
  }, [moneyData, incomeCategoryMap, expenseCategoryMap]);

  useEffect(() => {
    if (gridRows) setIsDataFetch(true);
  }, [gridRows]); // gridRowsが更新された場合に発火

  // データの取得 - Category
  useEffect(() => {
    if (incomeCategory && expenseCategory) return;
    getRequest<MoneyCategoryResponse>({
      apiUrl: process.env.REACT_APP_MONEY_API,
      target: 'category',
    })
      .then(response => {
        if (response.content) {
          setIncomeCategory(response.content.income);
          setExpenseCategory(response.content.expense);
          setIncomeCategoryMap(createNumberIdMap(response.content.income));
          setExpenseCategoryMap(createNumberIdMap(response.content.expense));
        }
      })
      .catch(error => {
        console.error('Categoryの取得に失敗しました:', error);
      });
  }, []);

  // 1年間の月別の収入と支出の合計を計算
  const createBalanceByMonthly = (data: MoneyDataType[], year: number) => {
    const monthlyTotals = Array.from({ length: 12 }, (_, index) => ({
      x: `${index + 1}月`,
      income: 0,
      expense: 0,
    }));
    data.forEach(item => {
      const date = parseISO(item.date);
      const month = date.getFullYear() === year ? date.getMonth() : null;
      if (month !== null) {
        if (item.type === '収入') {
          monthlyTotals[month].income += item.amount;
        } else if (item.type === '支出') {
          monthlyTotals[month].expense += item.amount;
        }
      }
    });
    return monthlyTotals;
  };

  const balanceByMonthly = useMemo(() => {
    if (!moneyData) return null;
    return createBalanceByMonthly(moneyData, currentYear);
  }, [moneyData, currentYear]);

  // 年間の累計を計算
  const balanceByYear = useMemo(() => {
    if (!balanceByMonthly) return null;
    const totalIncome = balanceByMonthly.reduce((sum, item) => sum + item.income, 0);
    const totalExpense = balanceByMonthly.reduce((sum, item) => sum + item.expense, 0);
    return [
      { type: 'income', label: '収入', amount: totalIncome, icon: 'arrow-right-up-long-line' },
      { type: 'expense', label: '支出', amount: totalExpense, icon: 'arrow-right-down-long-line' },
      {
        type: 'balance',
        label: '収支',
        amount: totalIncome - totalExpense,
        icon: 'line-chart-line',
      },
    ];
  }, [balanceByMonthly]);

  const monthlyData = useMemo(() => {
    if (!moneyData) return null;
    return filterDataByMonth(moneyData, currentMonth);
  }, [moneyData, currentMonth]);

  return (
    <MainContainer>
      <Stack
        spacing={6}
        sx={{
          height: '100%',
        }}
      >
        <MonthSelector currentMonth={currentMonth} setCurrentMonth={setCurrentMonth} />
        <Stack direction={isMobile ? 'column' : 'row'} spacing={isMobile ? 6 : 2}>
          <Paper
            variant="outlined"
            sx={{
              p: 2,
              borderRadius: '6px',
              width: '100%',
              maxWidth: isMobile ? 'none' : '450px',
              height: '300px',
              flexDirection: 'column',
              ...center,
            }}
          >
            <IncomeExpensePieChart
              moneyData={monthlyData}
              expenseCategoryMap={expenseCategoryMap}
              incomeCategoryMap={incomeCategoryMap}
            />
          </Paper>
          <Paper
            variant="outlined"
            sx={{
              p: 2,
              borderRadius: '6px',
              width: '100%',
              height: '300px',
              ...center,
            }}
          >
            <BalanceBarChart balanceByMonthly={balanceByMonthly} />
          </Paper>
        </Stack>
        {incomeCategory && expenseCategory && (
          <DataTable
            table="money"
            currentMonth={currentMonth}
            apiUrl={process.env.REACT_APP_MONEY_API || ''}
            gridRows={gridRows}
            setData={setMoneyData}
            incomeCategory={incomeCategory}
            expenseCategory={expenseCategory}
            isDataFetch={isDataFetch}
          />
        )}
        <Box>
          <SectionTitle title={`${currentYear}年 収支一覧`} />
          <BalanceSummary
            balanceByYear={balanceByYear}
            balanceByMonthly={balanceByMonthly}
            currentYear={currentYear}
          />
        </Box>
      </Stack>
    </MainContainer>
  );
};

export default memo(Money);
