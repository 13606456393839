import { theme } from '../theme';

export const todoMainColor = (type: string) => {
  switch (type) {
    case '仕事':
      return theme.palette.workColor.main;
    case 'プライベート':
      return theme.palette.privateColor.main;
    case 'ルーティン':
      return theme.palette.routineColor.main;
    default:
      return theme.palette.secondary.main;
  }
};

export const todoDarkColor = (type: string) => {
  switch (type) {
    case '仕事':
      return theme.palette.workColor.dark;
    case 'プライベート':
      return theme.palette.privateColor.dark;
    case 'ルーティン':
      return theme.palette.routineColor.dark;
    default:
      return theme.palette.primary.main;
  }
};

export const todoLightColor = (type: string) => {
  switch (type) {
    case '仕事':
      return theme.palette.workColor.light;
    case 'プライベート':
      return theme.palette.privateColor.light;
    case 'ルーティン':
      return theme.palette.routineColor.light;
    default:
      return theme.palette.secondary.light;
  }
};
