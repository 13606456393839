export const convertHexToRgba = (hex: string, alpha = 1) => {
  // Hexが3桁の場合は6桁に変換
  const normalizedHex =
    hex.length === 4 ? `#${hex[1]}${hex[1]}${hex[2]}${hex[2]}${hex[3]}${hex[3]}` : hex;
  // 正しい6桁形式かチェックして色を分解
  const match = normalizedHex.match(/^#([0-9a-f]{2})([0-9a-f]{2})([0-9a-f]{2})$/i);
  if (!match) {
    throw new Error(`Invalid hex color format: ${hex}`);
  }
  const [, r, g, b] = match.map(value => parseInt(value, 16)); // 16進数から10進数に変換
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

// HEXカラーコードをRGB形式に変換する関数
export const convertHexToRgb = (hex: string) => {
  const match = hex.match(/^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i);
  if (!match) throw new Error('Invalid HEX color');
  return {
    r: parseInt(match[1], 16),
    g: parseInt(match[2], 16),
    b: parseInt(match[3], 16),
  };
};

// RGBをHEXに変換する関数
export const convertRgbToHex = (r: number, g: number, b: number) => {
  const toHex = (x: number) => x.toString(16).padStart(2, '0');
  return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
};
