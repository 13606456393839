import { memo, useCallback, useRef } from 'react';
import { Box } from '@mui/material';
import { Node } from '@tiptap/pm/model';
import { Editor, NodeViewWrapper } from '@tiptap/react';

interface ImageBlockViewProps {
  editor: Editor;
  getPos: () => number;
  node: Node;
  updateAttributes: (attrs: Record<string, string>) => void;
}

export const ImageBlockView = memo((props: ImageBlockViewProps) => {
  const { editor, getPos, node } = props as ImageBlockViewProps & {
    node: Node & {
      attrs: {
        src: string;
      };
    };
  };
  const imageWrapperRef = useRef<HTMLDivElement>(null);
  const { src } = node.attrs;

  const onClick = useCallback(() => {
    editor.commands.setNodeSelection(getPos());
  }, [getPos, editor.commands]);

  return (
    <NodeViewWrapper>
      <Box
        sx={{
          ...(node.attrs.align === 'left' && { marginLeft: '0', marginRight: 'auto' }),
          ...(node.attrs.align === 'right' && { marginRight: '0', marginLeft: 'auto' }),
          ...(node.attrs.align === 'center' && { marginLeft: 'auto', marginRight: 'auto' }),
        }}
        style={{ width: node.attrs.width as string }}
      >
        <div contentEditable={false} ref={imageWrapperRef}>
          <img
            style={{
              display: 'block',
            }}
            src={src}
            alt=""
            onClick={onClick}
          />
        </div>
      </Box>
    </NodeViewWrapper>
  );
});

ImageBlockView.displayName = 'ImageBlockView';
export default ImageBlockView;
