import { Outlet } from 'react-router-dom';

const Gallery = () => (
  <>
    <div>Gallery</div>
    <Outlet />
  </>
);

export default Gallery;
