import { memo, useMemo } from 'react';
import { Box, Chip, CircularProgress, Divider, Stack, Typography } from '@mui/material';
import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied';
import { createMapByKeyAndValue } from '../../utils/createMaps';
import { Health, HealthCategory } from '../../types';
import { mentalIcons } from '../common/Icons';
import Icon from '../ui/Icon';
import { theme } from '../../theme';
import { center, fontSerif } from '../../styles/commonStyles';

interface HealthSummaryProps {
  healthData: Health[] | null;
  items: string[] | null;
  healthCategory: HealthCategory[] | null;
}

const HealthSummary = ({ healthData, items, healthCategory }: HealthSummaryProps) => {
  // 調子の平均を計算
  const createAverageMental = (data: Health[]) => {
    const totalMental = data.reduce(
      (acc, item) => {
        if (item.mental > 0) {
          acc.total += item.mental;
          acc.count += 1;
        }
        return acc;
      },
      { total: 0, count: 0 }
    );
    const averageMental = totalMental.count > 0 ? totalMental.total / totalMental.count : 0;
    const formattedAverage = Math.round(averageMental * 100) / 100;
    const iconNum = Math.trunc(formattedAverage);
    return {
      average: formattedAverage,
      icon: iconNum,
    };
  };

  const averageMental = useMemo(() => {
    if (!healthData) return null;
    return createAverageMental(healthData);
  }, [healthData]);

  const iconMap = useMemo(() => {
    if (!healthCategory) return null;
    return createMapByKeyAndValue(healthCategory, 'name', 'icon');
  }, [healthCategory]);

  return (
    <>
      {averageMental && iconMap && items ? (
        <>
          <Stack direction="row" alignItems="center" sx={{ padding: '0.75rem 0.5rem 1rem' }}>
            <p style={{ fontSize: '3.5rem', marginRight: '1rem', lineHeight: 1 }}>
              {averageMental.icon > 0 ? (
                mentalIcons[averageMental.icon].icon
              ) : (
                <SentimentSatisfiedIcon
                  color="disabled"
                  sx={{
                    fontSize: 'inherit',
                  }}
                />
              )}
            </p>
            <Box>
              <Typography variant="body2" color={theme.palette.secondary.dark} fontWeight={500}>
                調子の平均
              </Typography>
              {averageMental.average !== 0 ? (
                <Typography
                  variant="h5"
                  fontWeight={700}
                  sx={{
                    ...fontSerif,
                  }}
                >
                  {averageMental.average}
                </Typography>
              ) : (
                <Typography variant="h5" color={theme.palette.text.disabled}>
                  --
                </Typography>
              )}
            </Box>
          </Stack>
          <Divider
            textAlign="left"
            sx={{
              marginBottom: '0.75rem',
              '&::before': {
                width: '0%',
              },
              '& >.MuiDivider-wrapper': {
                fontSize: '0.875rem',
                fontWeight: 500,
                color: theme.palette.text.secondary,
              },
            }}
          >
            症状一覧
          </Divider>
          {items.length !== 0 ? (
            <>
              {items.map((item: string, index: number) => (
                <Chip
                  key={index}
                  label={item}
                  icon={
                    <Icon
                      icon={iconMap?.get(item) || 'dossier-line'}
                      style={{
                        flexShrink: 0,
                        marginLeft: '4px',
                        marginRight: '-4px',
                      }}
                    />
                  }
                  size="small"
                  sx={{
                    margin: '4px',
                    fontWeight: 500,
                  }}
                />
              ))}
            </>
          ) : (
            <Typography
              textAlign={'center'}
              variant="body2"
              color={theme.palette.text.disabled}
              sx={{
                padding: '0.25rem 0 0.75rem',
              }}
            >
              症状はありません
            </Typography>
          )}
        </>
      ) : (
        <Box
          sx={{
            width: '100%',
            height: '100%',
            minHeight: '153px',
            ...center,
          }}
        >
          <CircularProgress color="secondary" size={20} />
        </Box>
      )}
    </>
  );
};

export default memo(HealthSummary);
