import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { debounce } from 'lodash';
import { Box, CircularProgress, Stack, Typography, useMediaQuery } from '@mui/material';
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
} from 'chart.js';
import { Radar } from 'react-chartjs-2';
import useGetRequest from '../../hooks/useGetRequest';
import { Food, Nutrition } from '../../types';
import { theme } from '../../theme';
import { center, fontSerif } from '../../styles/commonStyles';

ChartJS.register(RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend);

interface AverageTextProps {
  title: string;
  average: number;
  base: number;
}

const AverageText = memo(({ title, average, base }: AverageTextProps) => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'flex-end',
    }}
  >
    <span
      style={{
        color: theme.palette.text.secondary,
        fontSize: '0.75rem',
        marginRight: '0.75rem',
        padding: '0.35rem 0.75rem',
        lineHeight: 1,
        border: '1px solid',
        borderColor: theme.palette.secondary.main,
        backgroundColor: theme.palette.secondary.light,
        borderRadius: '999px',
        marginTop: '0.25rem',
      }}
    >
      {title}
    </span>
    <div>
      <Typography variant="h6" fontWeight={700} align="right" sx={{ ...fontSerif }}>
        {average} {title === '熱量' ? 'kcal' : 'g'} / 日
      </Typography>
      <Typography
        variant="caption"
        color={theme.palette.text.secondary}
        align="right"
        sx={{
          display: 'block',
        }}
      >
        目標値 {base} {title === '熱量' ? 'kcal' : 'g'} / 日
      </Typography>
    </div>
  </Box>
));
AverageText.displayName = 'AverageText';

interface FoodSummaryProps {
  foodData: Food[] | null;
}

const FoodSummary = ({ foodData }: FoodSummaryProps) => {
  const [baseNutrition, setBaseNutrition] = useState<Nutrition | null>(null);
  const [targetWidth, setTargetWidth] = useState<number | null>(null);
  const boxRef = useRef<HTMLDivElement>(null);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const getRequest = useGetRequest();

  // 横幅を更新する関数
  const updateWidth = useCallback(() => {
    if (boxRef.current) {
      setTargetWidth(boxRef.current.offsetWidth);
    }
  }, []);
  const debouncedUpdateWidth = useMemo(() => debounce(updateWidth, 300), [updateWidth]);

  useEffect(() => {
    // 初期横幅を取得
    updateWidth();
    // DOM変更を監視
    const observer = new MutationObserver(debouncedUpdateWidth);
    if (boxRef.current) {
      observer.observe(boxRef.current, {
        childList: true,
        subtree: true,
        attributes: true,
      });
    }
    // リサイズ対応
    window.addEventListener('resize', debouncedUpdateWidth);
    return () => {
      // クリーンアップ
      observer.disconnect();
      window.removeEventListener('resize', debouncedUpdateWidth);
      debouncedUpdateWidth.cancel(); // デバウンスをキャンセル
    };
  }, [debouncedUpdateWidth]);

  // データの取得 - baseNutrition
  useEffect(() => {
    if (baseNutrition) return;
    getRequest<Nutrition>({
      apiUrl: process.env.REACT_APP_NUTR_API,
    })
      .then(response => {
        if (response.content) {
          setBaseNutrition(response.content);
        }
      })
      .catch(error => {
        console.error('Nutritionの取得に失敗しました:', error);
      });
  }, []);

  const createNutritionData = (foodData: Food[], baseValues: Nutrition) => {
    // 累計値を計算
    const totals = foodData.reduce(
      (total, item) => ({
        energy: total.energy + (item.energy || 0),
        protein: total.protein + (item.protein || 0),
        fat: total.fat + (item.fat || 0),
        carb: total.carb + (item.carb || 0),
        salt: total.salt + (item.salt || 0),
      }),
      { energy: 0, protein: 0, fat: 0, carb: 0, salt: 0 }
    );

    // データのある日数を計算
    const dates = new Set(foodData.map(item => item.date));
    const days = dates.size;

    // 平均値を計算
    const averages = {
      energy: Math.round((totals.energy / days) * 100) / 100 || 0,
      protein: Math.round((totals.protein / days) * 100) / 100 || 0,
      fat: Math.round((totals.fat / days) * 100) / 100 || 0,
      carb: Math.round((totals.carb / days) * 100) / 100 || 0,
      salt: Math.round((totals.salt / days) * 100) / 100 || 0,
    };

    // 割合を計算（目標値を10とした場合）
    const ratios = {
      energy: Math.round((averages.energy / baseValues.energy) * 10 * 10) / 10 || 0,
      protein: Math.round((averages.protein / baseValues.protein) * 10 * 10) / 10 || 0,
      fat: Math.round((averages.fat / baseValues.fat) * 10 * 10) / 10 || 0,
      carb: Math.round((averages.carb / baseValues.carb) * 10 * 10) / 10 || 0,
      salt: Math.round((averages.salt / baseValues.salt) * 10 * 10) / 10 || 0,
    };

    return { totals, averages, ratios, days };
  };

  const nutritionData = useMemo(() => {
    if (!foodData || !baseNutrition) return null;
    return createNutritionData(foodData, baseNutrition);
  }, [foodData, baseNutrition]);

  const chartData = useMemo(() => {
    if (!nutritionData) return null;
    const { energy, protein, fat, carb, salt } = nutritionData.ratios;
    return {
      labels: ['熱量', 'たんぱく質', '脂質', '炭水化物', '食塩'],
      datasets: [
        {
          label: '1日の推奨量',
          data: [10, 10, 10, 10, 10],
          backgroundColor: 'rgba(0, 0, 0, 0.1)',
          borderColor: 'rgba(0, 0, 0, 0.35)',
          borderWidth: 1,
        },
        {
          label: '平均摂取量',
          data: [energy, protein, fat, carb, salt],
          backgroundColor: 'rgba(175, 29, 54, 0.35)',
          borderColor: 'rgba(175, 29, 54, 0.8)',
          borderWidth: 1,
        },
      ],
    };
  }, [nutritionData]);

  return (
    <>
      {chartData && baseNutrition && nutritionData ? (
        <>
          <Stack
            direction={isMobile ? 'column' : 'row'}
            alignItems={'stretch'}
            justifyContent={'space-between'}
            sx={{ height: '100%' }}
          >
            <Box
              sx={{
                width: { xs: '100%', sm: targetWidth ? `calc( 100% - ${targetWidth}px)` : '60%' },
                flexShrink: 1,
                flexGrow: 1,
                height: { xs: '70vw', sm: '100%' },
                maxHeight: { xs: '320px', sm: 'none' },
              }}
            >
              <Radar
                data={chartData}
                options={{
                  maintainAspectRatio: false,
                  responsive: true,
                  plugins: {
                    tooltip: {
                      titleFont: { family: theme.typography.fontFamily },
                      bodyFont: { family: theme.typography.fontFamily },
                      padding: 10,
                    },
                    legend: {
                      position: 'top' as const,
                      labels: {
                        font: {
                          size: 14,
                          family: theme.typography.fontFamily,
                        },
                      },
                    },
                  },
                }}
                style={{
                  width: '100%',
                  height: 'auto',
                }}
              />
            </Box>
            <Stack
              ref={boxRef}
              justifyContent={'space-between'}
              sx={{
                p: { xs: 0, sm: '1rem 1rem 1rem 0' },
                width: 'fit-content',
                flexShrink: 0,
                flexGrow: 0,
                height: '100%',
                margin: { xs: '1rem auto 0', sm: '0' },
              }}
            >
              <AverageText
                title="熱量"
                average={nutritionData.averages.energy}
                base={baseNutrition.energy}
              />
              <AverageText
                title="たんぱく質"
                average={nutritionData.averages.protein}
                base={baseNutrition.protein}
              />
              <AverageText
                title="脂質"
                average={nutritionData.averages.fat}
                base={baseNutrition.fat}
              />
              <AverageText
                title="炭水化物"
                average={nutritionData.averages.carb}
                base={baseNutrition.carb}
              />
              <AverageText
                title="食塩相当量"
                average={nutritionData.averages.salt}
                base={baseNutrition.salt}
              />
            </Stack>
          </Stack>
        </>
      ) : (
        <Box
          sx={{
            width: '100%',
            height: '100%',
            ...center,
          }}
        >
          <CircularProgress color="secondary" size={20} />
        </Box>
      )}
    </>
  );
};

export default memo(FoodSummary);
