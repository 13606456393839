import { memo, ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { ClickAwayListener, Grow, Paper, Popper, Tooltip } from '@mui/material';
import { Toolbar } from './Toolbar';

interface DropDownProps {
  id: string;
  icon: string;
  label: string;
  tooltip?: string;
  padding?: string;
  children: ReactNode;
}

const DropDown = memo(
  ({ id, icon, label, tooltip, padding = '0.25rem', children }: DropDownProps) => {
    const [open, setOpen] = useState(false);
    const anchorRef = useRef<HTMLButtonElement>(null);

    const handleToggle = useCallback(() => {
      setOpen(prevOpen => !prevOpen);
    }, []);

    const handleClose = useCallback((event: Event | React.SyntheticEvent) => {
      if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
        return;
      }
      setOpen(false);
    }, []);

    const handleKeyDown = useCallback((event: React.KeyboardEvent) => {
      if (event.key === 'Escape') {
        setOpen(false);
      }
    }, []);

    // フォーカスが外れたときにドロップダウンを閉じる
    const handleBlur = useCallback((event: React.FocusEvent<HTMLDivElement>) => {
      const { currentTarget } = event;
      // 次のフレームでフォーカス先をチェック
      setTimeout(() => {
        if (!currentTarget.contains(document.activeElement)) {
          setOpen(false);
        }
      }, 0);
    }, []);

    // openがfalseからtrueに変わったときにボタンにフォーカスを戻す
    const prevOpen = useRef(open);
    useEffect(() => {
      if (prevOpen.current === true && open === false) {
        anchorRef.current!.focus();
      }

      prevOpen.current = open;
    }, [open]);

    return (
      <>
        {tooltip ? (
          <Tooltip title={tooltip} placement="top">
            <Toolbar.Button
              ref={anchorRef}
              id={`${id}-button`}
              aria-controls={open ? `${id}-menu` : undefined}
              aria-expanded={open ? 'true' : undefined}
              aria-haspopup="true"
              onClick={handleToggle}
              icon={icon}
              ariaLabel={label}
              tooltip={label}
              isActive={open}
            />
          </Tooltip>
        ) : (
          <Toolbar.Button
            ref={anchorRef}
            id={`${id}-button`}
            aria-controls={open ? `${id}-menu` : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="true"
            onClick={handleToggle}
            icon={icon}
            ariaLabel={label}
            tooltip={label}
            isActive={open}
          />
        )}
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          placement="bottom-start"
          transition
          disablePortal
          sx={{
            zIndex: 1,
          }}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement === 'bottom-start' ? 'left top' : 'left bottom',
              }}
            >
              <Paper variant="outlined">
                <ClickAwayListener onClickAway={handleClose}>
                  <div
                    style={{
                      padding,
                    }}
                    onKeyDown={handleKeyDown}
                    onBlur={handleBlur}
                  >
                    {children}
                  </div>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </>
    );
  }
);

DropDown.displayName = 'DropDown';

export default DropDown;
