import { Box, Typography } from '@mui/material';
import { center, fontSerif } from '../styles/commonStyles';

const MemoIndex = () => (
  <Box
    sx={{
      height: '100%',
      ...center,
    }}
  >
    <Typography
      variant="h6"
      component="p"
      fontWeight={700}
      sx={{
        ...fontSerif,
      }}
    >
      メモを選択してください
    </Typography>
  </Box>
);
export default MemoIndex;
