import { forwardRef, memo, useEffect } from 'react';
import classNames from 'classnames';
import type { DraggableSyntheticListeners } from '@dnd-kit/core';
import type { Transform } from '@dnd-kit/utilities';
import TodoCard from '../../../../common/TodoCard';
import { Todo } from '../../../../../types';
import './Item.scss';

export interface ItemProps<T> {
  dataObj: T | undefined;
  dragOverlay?: boolean;
  disabled?: boolean;
  dragging?: boolean;
  index?: number;
  fadeIn?: boolean;
  transform?: Transform | null;
  listeners?: DraggableSyntheticListeners;
  sorting?: boolean;
  style?: React.CSSProperties;
  transition?: string | null;
  wrapperStyle?: React.CSSProperties;
  setCurrentItemId: React.Dispatch<React.SetStateAction<number>>;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setButtonElement: (target: HTMLButtonElement | null) => void;
  renderItem?(args: {
    dragOverlay: boolean;
    dragging: boolean;
    sorting: boolean;
    index: number | undefined;
    fadeIn: boolean;
    listeners: DraggableSyntheticListeners;
    ref: React.Ref<HTMLElement>;
    style: React.CSSProperties | undefined;
    transform: ItemProps<T>['transform'];
    transition: ItemProps<T>['transition'];
  }): React.ReactElement;
}

const ItemComponent = <T,>(
  {
    dataObj,
    dragOverlay,
    dragging,
    disabled,
    fadeIn,
    index,
    listeners,
    renderItem,
    sorting,
    style,
    transition,
    transform,
    wrapperStyle,
    setCurrentItemId,
    setIsModalOpen,
    setButtonElement,
    ...props
  }: ItemProps<T>,
  ref: React.Ref<HTMLLIElement>
) => {
  useEffect(() => {
    if (!dragOverlay) {
      return;
    }

    document.body.style.cursor = 'grabbing';

    return () => {
      document.body.style.cursor = '';
    };
  }, [dragOverlay]);

  return renderItem ? (
    renderItem({
      dragOverlay: Boolean(dragOverlay),
      dragging: Boolean(dragging),
      sorting: Boolean(sorting),
      index,
      fadeIn: Boolean(fadeIn),
      listeners,
      ref,
      style,
      transform,
      transition,
    })
  ) : (
    <li
      className={classNames(
        'itemWrapper',
        fadeIn && 'fadeIn',
        sorting && 'sorting',
        dragOverlay && 'dragOverlay'
      )}
      style={
        {
          ...wrapperStyle,
          transition: [transition, wrapperStyle?.transition].filter(Boolean).join(', '),
          '--translate-x': transform ? `${Math.round(transform.x)}px` : undefined,
          '--translate-y': transform ? `${Math.round(transform.y)}px` : undefined,
          '--scale-x': transform?.scaleX ? `${transform.scaleX}` : undefined,
          '--scale-y': transform?.scaleY ? `${transform.scaleY}` : undefined,
          '--index': index,
        } as React.CSSProperties
      }
      ref={ref}
    >
      <div
        className={classNames(
          'item',
          dragging && 'dragging',
          dragOverlay && 'dragOverlay',
          disabled && 'disabled'
        )}
        style={style}
        data-cypress="draggable-item"
        {...listeners}
        {...props}
        tabIndex={-1} // キーボード操作でのフォーカスを防ぐ
      >
        <TodoCard
          data={dataObj as Todo}
          setCurrentTodoId={setCurrentItemId}
          setIsModalOpen={setIsModalOpen}
          pageType="project"
          setButtonElement={setButtonElement}
        />
      </div>
    </li>
  );
};

export const Item = memo(forwardRef(ItemComponent));
