import { UniqueIdentifier } from '@dnd-kit/core';

// -----------------------------------------------
// 数値のidをキーとするマップを作成
// -----------------------------------------------
export const createNumberIdMap = <T extends { id: number }>(data: T[]): Map<number, T> =>
  new Map(data.map(item => [item.id, item]));

// -----------------------------------------------
// 文字列のidをキーとするマップを作成
// -----------------------------------------------
export const createStringIdMap = <T extends { id: string }>(data: T[]): Map<string, T> =>
  new Map(data.map(item => [item.id, item]));

// -----------------------------------------------
// 任意のキーを指定してマップを作成
// -----------------------------------------------
export const createMapByKey = <T, K extends keyof T>(data: T[], key: K): Map<T[K], T> =>
  new Map(data.map(item => [item[key], item]));

// -----------------------------------------------
// 任意のキーと値を指定してマップを作成
// -----------------------------------------------
export const createMapByKeyAndValue = <T, K extends keyof T, V extends keyof T>(
  data: T[],
  key: K,
  value: V
): Map<T[K], T[V]> => new Map(data.map(item => [item[key], item[value]]));

// -----------------------------------------------
// dnd-kitで利用するデータのマップを作成（folders, items）
// -----------------------------------------------
export const createDndKitMap = <T extends { id: UniqueIdentifier }>(
  array: T[] | null,
  valueField: keyof T & string
): Map<UniqueIdentifier, string> => {
  const dataMap = new Map<UniqueIdentifier, string>();
  if (!array) {
    return dataMap;
  }
  array.forEach(item => {
    const key = item.id;
    const value = item[valueField] as string;
    dataMap.set(key, value);
  });

  return dataMap;
};
