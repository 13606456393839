import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Box, MenuItem, MenuList, Typography } from '@mui/material';
import { debounce } from 'lodash';
import { Editor, JSONContent } from '@tiptap/core';
import { TextSelection } from '@tiptap/pm/state';
import { fontSerif, scrollbarWhite } from '../../../../styles/commonStyles';

// 文字数のカウント
const getTextLength = (node: JSONContent): number => {
  let textLength = 0;

  // 現在のノードがテキストノードなら、そのテキストの長さを加算
  if (node.type === 'text' && node.text) {
    textLength += node.text.length;
  }

  // 子ノードがあれば、それを再帰的に処理
  if (node.content && Array.isArray(node.content)) {
    node.content.forEach(childNode => {
      if (['tableRow', 'columns'].includes(childNode.type || '')) {
        textLength += 2;
      }
      if (
        ['listItem', 'taskItem', 'quoteCaption', 'quote', 'tableCell', 'column'].includes(
          childNode.type || ''
        )
      ) {
        textLength += 4;
      }
      textLength += getTextLength(childNode); // 再帰的に子ノードの文字数を取得
    });
  }

  return textLength;
};

// 目次の生成
const generateTableOfContents = (editor: Editor) => {
  const content = editor.getJSON();
  const headings = [] as { level: number; text: string; pos: number }[];
  let pos = 1;

  content.content?.forEach(node => {
    if (node.type === 'heading') {
      const level = node.attrs?.level as number;
      const text = node.content?.map(child => child.text).join('') || '';
      headings.push({ level, text, pos });
      pos += text.length + 2;
    } else if (['imageBlock', 'horizontalRule'].includes(node.type || '')) {
      pos += 1;
    } else {
      const textLength = getTextLength(node);
      pos += textLength + 2;
    }
  });

  return headings;
};

const TableOfContents = ({ editor }: { editor: Editor }) => {
  const [toc, setToc] = useState<{ level: number; text: string; pos: number }[]>([]);
  let count = 0; // 見出し番号

  // `debounce` と `handleUpdate` をメモ化
  const handleUpdate = useMemo(
    () =>
      debounce(() => {
        const newToc = generateTableOfContents(editor);
        setToc(newToc);
      }, 1000),
    [editor]
  );

  useEffect(() => {
    if (!editor) return;

    handleUpdate(); // 初期ロード時に目次を生成
    editor.on('update', handleUpdate); // エディターの更新イベントを監視

    return () => {
      editor.off('update', handleUpdate); // クリーンアップ
    };
  }, [editor, handleUpdate]);

  const handleClick = useCallback(
    (pos: number) => {
      if (!editor) return;
      const transaction = editor.view.state.tr
        .setSelection(TextSelection.near(editor.view.state.doc.resolve(pos)))
        .scrollIntoView(); // ProseMirror のトランザクションを使ってスクロール
      editor.view.dispatch(transaction); // トランザクションを適用
    },
    [editor]
  );

  return (
    <Box
      sx={{
        position: { xs: 'static', sm: 'sticky' },
        top: 16, // 上部からの距離
        padding: 1,
        height: 'fit-content',
        maxHeight: 'calc(100vh - 32px)',
        maxWidth: { xs: '100%', sm: '250px' },
        flexBasis: { xs: '100%', sm: '30%' },
        ...scrollbarWhite,
      }}
    >
      <Typography
        variant="h6"
        sx={{
          fontWeight: 700,
          textTransform: 'uppercase',
          letterSpacing: '0.085em',
          position: 'relative',
          ...fontSerif,
          '&:before': {
            content: '""',
            display: 'block',
            width: 'calc(100% - 3.75rem)',
            height: '1px',
            position: 'absolute',
            top: '48%',
            right: 0,
            marginRight: '0.5rem',
            backgroundColor: 'currentColor',
          },
          '&:after': {
            content: '""',
            display: 'block',
            width: 'calc(100% - 3.75rem)',
            height: '1px',
            position: 'absolute',
            top: 'calc(48% + 2px)',
            right: 0,
            marginRight: '0.5rem',
            backgroundColor: 'currentColor',
          },
        }}
      >
        目次
      </Typography>
      <MenuList>
        {toc.map((heading, index) => {
          if (heading.level === 1) {
            count += 1;
          }
          return (
            <MenuItem
              key={index}
              sx={{
                display: 'flex',
                justifyContent: 'flex-start',
                flexWrap: 'nowrap',
                alignItems: 'flex-start',
                ...fontSerif,
                ...(heading.level === 1 ? { fontWeight: 700 } : { fontWeight: 500 }),
                marginLeft: `${(heading.level - 1) * 16}px`,
              }}
              onClick={() => handleClick(heading.pos)}
            >
              {heading.level === 1 && (
                <span
                  style={{
                    flexShrink: 0,
                    marginRight: '0.25rem',
                  }}
                >
                  {count}.
                </span>
              )}
              <span
                style={{
                  flexGrow: 1,
                }}
              >
                {heading.text}
              </span>
            </MenuItem>
          );
        })}
      </MenuList>
    </Box>
  );
};

export default memo(TableOfContents);
