import axios, { AxiosError, AxiosResponse } from 'axios';
import { UniqueIdentifier } from '@dnd-kit/core';
import useSessionExpiration from './useSessionExpiration';
import { useAuthContext } from '../contexts/AuthContext';

interface SaveMenuRequestResponse {
  success: boolean;
  message?: string;
  error?: string;
  todoIds?: number[];
  sectionIds?: string[];
}

const useSaveMenuRequest = () => {
  const { userId, csrfToken } = useAuthContext();
  const sessionExpireHandler = useSessionExpiration();

  const saveMenuRequest = async ({
    apiUrl,
    id,
    action,
    orderType,
    tableType,
    data,
  }: {
    apiUrl: string | undefined;
    id?: UniqueIdentifier;
    tableType: string;
    action: 'sort' | 'add' | 'remove' | 'edit';
    orderType: 'folder' | 'item';
    data?: string | number | object | Array<string | number | object>;
  }): Promise<SaveMenuRequestResponse> => {
    if (!apiUrl) {
      alert('APIが設定されていないため、保存できません');
      return Promise.reject(new Error('API URL is not defined'));
    }
    try {
      const response: AxiosResponse<SaveMenuRequestResponse> = await axios.post(
        apiUrl,
        {
          userId,
          csrfToken,
          ...{ ...(id ? { id } : {}) },
          tableType,
          action,
          orderType,
          data,
        },
        {
          withCredentials: true,
        }
      );
      if (response.data.success) {
        console.log(response.data.message); // 確認用
        return response.data; // 全データを返す
      }
      throw new Error(response.data.error || 'Update failed');
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const axiosError = error as AxiosError<SaveMenuRequestResponse>;
        if (axiosError.response) {
          const { status, data } = axiosError.response;
          if (status === 403 || status === 401) {
            console.error(`${status}:`, data.error);
            sessionExpireHandler();
          }
        }
      } else {
        alert('データの更新に失敗しました');
        console.error(error);
      }
      throw error;
    }
  };

  return saveMenuRequest;
};
export default useSaveMenuRequest;
