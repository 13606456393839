import { createContext, useContext, useState } from 'react';
import {
  FolderMap,
  FolderOrderMap,
  MenuItemMap,
  MenuItemOrderMap,
  Section,
  SectionOrder,
  SetFolderMap,
  SetFolderOrderMap,
  SetIsDataFetch,
  SetMenuItemMap,
  SetMenuItemOrderMap,
  TodoOrder,
} from '../types';

interface ProjectContextType {
  page: string;
  isMenuDataFetch: boolean;
  setIsMenuDataFetch: SetIsDataFetch;
  menuItems: MenuItemMap;
  setMenuItems: SetMenuItemMap;
  menuItemOrder: MenuItemOrderMap;
  setMenuItemOrder: SetMenuItemOrderMap;
  folders: FolderMap;
  setFolders: SetFolderMap;
  folderOrder: FolderOrderMap;
  setFolderOrder: SetFolderOrderMap;
  sectionData: Map<string, Section> | null;
  setSectionData: React.Dispatch<React.SetStateAction<Map<string, Section> | null>>;
  sectionOrder: Map<string, SectionOrder> | null;
  setSectionOrder: React.Dispatch<React.SetStateAction<Map<string, SectionOrder> | null>>;
  todoOrder: Map<number, TodoOrder> | null;
  setTodoOrder: React.Dispatch<React.SetStateAction<Map<number, TodoOrder> | null>>;
  isOrderFetch: boolean;
  setIsOrderFetch: React.Dispatch<React.SetStateAction<boolean>>;
  isOrderChange: boolean;
  setIsOrderChange: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ProjectContext = createContext<ProjectContextType | undefined>(undefined);

export const ProjectContextProvider = ({ children }: { children: React.ReactNode }) => {
  const page = 'プロジェクト';
  const [isMenuDataFetch, setIsMenuDataFetch] = useState<boolean>(false);
  const [menuItems, setMenuItems] = useState<MenuItemMap | null>(null);
  const [menuItemOrder, setMenuItemOrder] = useState<MenuItemOrderMap | null>(null);
  const [folders, setFolders] = useState<FolderMap | null>(null);
  const [folderOrder, setFolderOrder] = useState<FolderOrderMap | null>(null);

  // kanbanBoard
  const [sectionData, setSectionData] = useState<Map<string, Section> | null>(null);
  const [isOrderFetch, setIsOrderFetch] = useState<boolean>(false);
  const [sectionOrder, setSectionOrder] = useState<Map<string, SectionOrder> | null>(null);
  const [todoOrder, setTodoOrder] = useState<Map<number, TodoOrder> | null>(null);
  const [isOrderChange, setIsOrderChange] = useState<boolean>(false);

  return (
    <ProjectContext.Provider
      value={{
        page,
        isMenuDataFetch,
        setIsMenuDataFetch,
        menuItems,
        setMenuItems,
        menuItemOrder,
        setMenuItemOrder,
        folders,
        setFolders,
        folderOrder,
        setFolderOrder,
        sectionData,
        setSectionData,
        sectionOrder,
        setSectionOrder,
        todoOrder,
        setTodoOrder,
        isOrderFetch,
        setIsOrderFetch,
        isOrderChange,
        setIsOrderChange,
      }}
    >
      {children}
    </ProjectContext.Provider>
  );
};

export const useProjectContext = () => {
  const context = useContext(ProjectContext);
  if (!context) {
    throw new Error('ProjectContextをプロバイダーの中で取得してください');
  }
  return context;
};
