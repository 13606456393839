import { useState, useCallback, useMemo, memo } from 'react';
import { styled } from '@mui/system';
import { Button, FormControlLabel, Switch } from '@mui/material';
import DropDown from '../../../ui/DropDown';
import Icon from '../../../ui/Icon';
import { theme } from '../../../../theme';

interface LinkEditorPanelProps {
  initialUrl?: string;
  initialOpenInNewTab?: boolean;
  onSetLink: (url: string, openInNewTab?: boolean) => void;
}

const useLinkEditorState = ({
  initialUrl,
  initialOpenInNewTab,
  onSetLink,
}: LinkEditorPanelProps) => {
  const [url, setUrl] = useState(initialUrl || '');
  const [openInNewTab, setOpenInNewTab] = useState(initialOpenInNewTab || false);

  const onChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setUrl(event.target.value);
  }, []);

  const isValidUrl = useMemo(() => /^(\S+):(\/\/)?\S+$/.test(url), [url]);

  const handleSubmit = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault();
      if (isValidUrl) {
        onSetLink(url, openInNewTab);
      }
    },
    [url, isValidUrl, openInNewTab, onSetLink]
  );

  return {
    url,
    setUrl,
    openInNewTab,
    setOpenInNewTab,
    onChange,
    handleSubmit,
    isValidUrl,
  };
};

const AntSwitch = styled(Switch)(() => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
      },
    },
  },

  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: 'width 200ms ease-out',
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
  },
}));

export const LinkEditorPanel = memo(
  ({ onSetLink, initialOpenInNewTab, initialUrl }: LinkEditorPanelProps) => {
    const state = useLinkEditorState({
      onSetLink,
      initialOpenInNewTab,
      initialUrl,
    });

    return (
      <>
        <form
          onSubmit={state.handleSubmit}
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '0.25rem',
          }}
        >
          <label
            aria-label="URL"
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '0.25rem',
              padding: '0.5rem',
              borderRadius: '0.25rem',
              backgroundColor: theme.palette.secondary.light,
              color: 'rgba(0, 0, 0, 0.5)',
              cursor: 'text',
            }}
          >
            <Icon icon="link" size="1rem" />
            <input
              type="url"
              style={{
                fontSize: '0.875rem',
                minWidth: '12rem',
              }}
              placeholder="URL"
              value={state.url}
              onChange={state.onChange}
            />
          </label>
          <Button variant="contained" type="submit" size="small" disabled={!state.isValidUrl}>
            リンクの挿入
          </Button>
        </form>
        <FormControlLabel
          control={
            <AntSwitch
              checked={state.openInNewTab}
              onChange={e => {
                state.setOpenInNewTab(e.target.checked);
              }}
              onKeyDown={e => {
                if (e.key === 'Enter') {
                  state.setOpenInNewTab(!state.openInNewTab);
                }
              }}
            />
          }
          label="新規タブで開く"
          labelPlacement="start"
          sx={{
            marginRight: 0,
            marginLeft: 0,
            marginTop: '0.5rem',
            '& .MuiFormControlLabel-label': {
              fontSize: '0.875rem',
              fontWeight: 500,
              color: 'rgba(0, 0, 0, 0.5)',
              marginRight: '0.5rem',
            },
          }}
        />
      </>
    );
  }
);

LinkEditorPanel.displayName = 'LinkEditorPanel';

interface EditLinkPopoverProps {
  onSetLink: (link: string, openInNewTab?: boolean) => void;
}

export const EditLinkPopover = memo(({ onSetLink }: EditLinkPopoverProps) => (
  <DropDown id="editLink" icon="link" label="リンクの挿入" tooltip="リンクの挿入" padding="0.5rem">
    <LinkEditorPanel onSetLink={onSetLink} />
  </DropDown>
));

EditLinkPopover.displayName = 'EditLinkPopover';
