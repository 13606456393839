import { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Box, CircularProgress, Drawer, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import { UniqueIdentifier } from '@dnd-kit/core';
import { rectSortingStrategy } from '@dnd-kit/sortable';
import usePageContext from '../../hooks/usePageContext';
import useGetRequest from '../../hooks/useGetRequest';
import { createMapByKey, createMapByKeyAndValue } from '../../utils/createMaps';
import {
  zdrawerLeft,
  bgWhite,
  navWidth,
  navHeight,
  drawerWidth,
  center,
} from '../../styles/commonStyles';
import {
  Folder,
  FolderOrder,
  FolderOrderMap,
  MenuItem,
  MenuItemOrder,
  MenuItemOrderMap,
} from '../../types';
import { DraggableMenu } from './DraggableMenu/DraggableMenu';

interface MenuResponse {
  items: MenuItem[];
  itemOrder: MenuItemOrder[];
  folders: Folder[];
  folderOrder: FolderOrder[];
}

const Main = styled('main', { shouldForwardProp: prop => prop !== 'open' })<{
  open?: boolean;
}>(({ theme }) => ({
  minHeight: '100vh',
  flexGrow: 1,
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  padding: `64px 24px calc(${navHeight} + 24px) 24px`,
  marginLeft: `calc(${drawerWidth} * -1)`,
  [theme.breakpoints.up('md')]: {
    marginLeft: `calc((${drawerWidth} + ${navWidth}) * -1)`,
    padding: '64px 24px 24px 24px',
  },
  variants: [
    {
      props: ({ open }) => open,
      style: {
        transition: theme.transitions.create('margin', {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: `calc(${drawerWidth} * -1) !important`,
        [theme.breakpoints.up('sm')]: {
          marginLeft: `0 !important`,
          paddingTop: '24px !important',
        },
      },
    },
  ],
}));

interface DrawerLeftProps {
  children: ReactNode;
}

const DrawerLeft = ({ children }: DrawerLeftProps) => {
  const pathname = useLocation().pathname.split('/')[1];
  const [open, setOpen] = useState(true);
  const [itemIdsByFolder, setItemIdsByFolder] = useState<{ [key: string]: UniqueIdentifier[] }>({});
  const {
    page,
    isMenuDataFetch,
    setIsMenuDataFetch,
    menuItems,
    setMenuItems,
    menuItemOrder,
    setMenuItemOrder,
    folders,
    setFolders,
    folderOrder,
    setFolderOrder,
  } = usePageContext();
  const getRequest = useGetRequest();

  useEffect(() => {
    console.log('DrawerLeft：初回レンダリング');
  }, []);

  console.log('DrawerLeft：レンダリング');

  const iconButtonRef = useRef<HTMLButtonElement>(null);
  useEffect(() => {
    // ドロワーが閉じたときにIconButtonにフォーカスを戻す
    if (!open && iconButtonRef.current) {
      iconButtonRef.current.focus();
    }
  }, [open]);

  const createItemIdsByFolder = useCallback(
    (folderOrderMap: FolderOrderMap, menuItemOrderMap: MenuItemOrderMap) => {
      const newItemIdsByFolder: { [key: string]: UniqueIdentifier[] } = {};

      if (!folderOrderMap || !menuItemOrderMap) {
        return itemIdsByFolder;
      }
      // folderOrder を配列に変換してソート
      const sortedFolderOrder = Array.from(folderOrderMap.entries())
        .map(([folderId, sort]) => ({ folderId, sort }))
        .sort((a, b) => a.sort - b.sort);

      sortedFolderOrder.forEach(folder => {
        const { folderId } = folder;
        // menuItemOrder を配列に変換してフィルタリング・ソート
        const sortedItems = Array.from(menuItemOrderMap.values())
          .filter(item => item.folderId === folderId)
          .sort((a, b) => a.sort - b.sort)
          .map(item => item.itemId);

        newItemIdsByFolder[folderId] = sortedItems;
      });

      return newItemIdsByFolder;
    },
    []
  );

  // データの取得 - Menu
  useEffect(() => {
    setItemIdsByFolder({}); // 初期化
    if (isMenuDataFetch) {
      const newItemIdsByFolder = createItemIdsByFolder(folderOrder, menuItemOrder);
      setItemIdsByFolder(newItemIdsByFolder);
    } else {
      getRequest<MenuResponse>({
        apiUrl: process.env.REACT_APP_GET_MENU_API,
        tableType: pathname as 'project' | 'memo' | 'gallery',
      })
        .then(response => {
          if (response.content) {
            setMenuItems(createMapByKeyAndValue(response.content?.items, 'id', 'title'));
            setMenuItemOrder(createMapByKey(response.content?.itemOrder, 'itemId'));
            setFolders(createMapByKeyAndValue(response.content?.folders, 'id', 'name'));
            setFolderOrder(
              createMapByKeyAndValue(response.content?.folderOrder, 'folderId', 'sort')
            );
            const newItemIdsByFolder: { [key: string]: UniqueIdentifier[] } = {};
            // folderOrderをソート
            const sortedFolderOrder = response.content?.folderOrder.sort((a, b) => a.sort - b.sort);
            sortedFolderOrder.forEach(folder => {
              const { folderId } = folder;
              // menuItemOrderをフィルタリング・ソート
              const sortedItems =
                response.content?.itemOrder
                  .filter(item => item.folderId === folderId)
                  .sort((a, b) => a.sort - b.sort)
                  .map(item => item.itemId) ?? [];

              newItemIdsByFolder[folderId] = sortedItems;
            });
            setItemIdsByFolder(newItemIdsByFolder);
            setIsMenuDataFetch(true);
          }
        })
        .catch(error => {
          console.error('Menuの取得に失敗しました:', error);
        });
    }
  }, [pathname, page]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <IconButton
          ref={iconButtonRef}
          aria-label={`${page}一覧を開く`}
          aria-expanded={open ? 'true' : 'false'}
          aria-hidden={open}
          aria-controls="drawer"
          onClick={handleDrawerOpen}
          sx={[
            {
              ...bgWhite,
              ...zdrawerLeft,
              border: '1px solid',
              borderColor: 'primary.main',
              position: 'fixed',
              top: 15,
              left: { xs: 20, md: `calc(${navWidth} + 20px)` },
              '@media (hover: hover)': {
                '&:hover': {
                  filter: 'brightness(0.8)',
                },
              },
            },
            open && { display: 'none' },
          ]}
        >
          <MenuIcon fontSize="small" color="primary" />
        </IconButton>
        <Drawer
          id="drawer"
          aria-hidden={!open}
          variant="persistent"
          anchor="left"
          open={open}
          sx={{
            width: { xs: drawerWidth, md: `calc(${navWidth} + ${drawerWidth})` },
            height: '100vh',
            marginLeft: { xs: 0, md: open ? `calc(${navWidth} * -1)` : '0' },
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              ...zdrawerLeft,
              height: '100vh',
              borderRadius: 0,
              position: 'fixed',
              top: 0,
              right: 0,
              width: { xs: drawerWidth, md: `calc(${navWidth} + ${drawerWidth})` },
              paddingLeft: { xs: 0, md: navWidth },
              overflow: 'hidden',
            },
          }}
        >
          {Object.keys(itemIdsByFolder).length > 0 && isMenuDataFetch ? (
            <DraggableMenu
              strategy={rectSortingStrategy}
              open={open}
              page={page}
              items={itemIdsByFolder}
              folderIds={Object.keys(itemIdsByFolder)}
              menuItems={menuItems}
              setMenuItems={setMenuItems}
              setMenuItemOrder={setMenuItemOrder}
              folders={folders}
              setFolders={setFolders}
              folderOrder={folderOrder}
              setFolderOrder={setFolderOrder}
              handleDrawerClose={handleDrawerClose}
            />
          ) : (
            <Box
              sx={{
                ...center,
                width: drawerWidth,
                height: '100vh',
                position: 'absolute',
                top: 0,
                left: { xs: 0, md: navWidth },
                zIndex: 9999,
              }}
            >
              <CircularProgress color="secondary" size={20} />
            </Box>
          )}
        </Drawer>
        <Main open={open}>{children}</Main>
      </Box>
    </>
  );
};

export default DrawerLeft;
