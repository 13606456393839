import { memo, useCallback, useEffect, useState } from 'react';

export type ImageBlockWidthProps = {
  onChange: (value: number) => void;
  value: number;
};

export const ImageBlockWidth = memo(({ onChange, value }: ImageBlockWidthProps) => {
  const [currentValue, setCurrentValue] = useState(value);

  useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const nextValue = parseInt(e.target.value, 10);
      onChange(nextValue);
      setCurrentValue(nextValue);
    },
    [onChange]
  );

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        gap: '0.5rem',
      }}
    >
      <input
        aria-label="画像サイズ"
        type="range"
        min="25"
        max="100"
        step="25"
        onChange={handleChange}
        value={currentValue}
      />
      <span
        style={{
          marginRight: '0.25rem',
          flexShrink: 0,
          fontSize: '0.85rem',
          fontWeight: '500',
          color: 'rgba(0, 0, 0, 0.6)',
          userSelect: 'none',
        }}
      >
        {value}%
      </span>
    </div>
  );
});

ImageBlockWidth.displayName = 'ImageBlockWidth';
