import { useState } from 'react';
import { Node } from '@tiptap/pm/model';
import { useEditor } from '@tiptap/react';
import type { Editor, JSONContent } from '@tiptap/core';
import { ExtensionKit } from '../extensions/extension-kit';

declare global {
  interface Window {
    editor: Editor | null;
    currentNode: Node | null;
    currentNodePos: number;
    currentNodeDepth: number;
  }
}

export const useBlockEditor = ({
  content,
  autofocus,
}: {
  content: JSONContent | null;
  autofocus: boolean;
}) => {
  const [currentNode, setCurrentNode] = useState<Node | null>(null);
  const [currentNodePos, setCurrentNodePos] = useState<number>(-1);
  const [currentNodeDepth, setCurrentNodeDepth] = useState<number>(-1);

  const editor = useEditor(
    {
      immediatelyRender: true,
      shouldRerenderOnTransaction: false,
      autofocus,
      onCreate: ctx => {
        if (content) {
          ctx.editor.commands.setContent(content);
        }
        // ノードの選択状態を追跡
        ctx.editor.on('selectionUpdate', ({ editor }) => {
          const { selection } = editor.state;
          const node = selection.$anchor.parent;
          const { pos, depth } = selection.$anchor;
          setCurrentNode(node);
          setCurrentNodePos(pos);
          setCurrentNodeDepth(depth);
        });
      },
      extensions: [...ExtensionKit()].filter(e => e !== undefined),
      editorProps: {
        attributes: {
          spellcheck: 'false',
          autocomplete: 'off',
          autocorrect: 'off',
          autocapitalize: 'off',
        },
      },
    },
    []
  );

  window.editor = editor;

  return { editor, currentNode, currentNodePos, currentNodeDepth };
};
