/* eslint-disable */
import { CSSProperties, Dispatch, SetStateAction, useState } from 'react';
import { DropDownMenu } from '../../../../ui/DropDownMenu';

interface EditOptionsProps {
  setIsEdit: Dispatch<SetStateAction<boolean>>;
  onRemove(): void;
  style?: CSSProperties;
}

const EditOptions = ({ setIsEdit, style, onRemove }: EditOptionsProps) => {
  const onEdit = () => {
    // 時間差をつけないとすぐに、onBlurイベントが発火してしまう
    setTimeout(() => {
      setIsEdit(true);
    }, 100);
  };

  return (
    <DropDownMenu
      menuName="folderEdit"
      ariaLabel="フォルダの編集メニュー"
      icon="pencil-fill"
      iconSize="11px"
      style={{
        borderRadius: '2px',
        padding: '6px 3px',
        ...style,
      }}
      menuItems={[
        {
          icon: 'pencil-line',
          label: 'フォルダ名の変更',
          onClick: onEdit,
        },
        {
          icon: 'delete-bin-line',
          label: 'フォルダの削除',
          onClick: onRemove,
        },
      ]}
    />
  );
};

export default EditOptions;
