import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { useTodoContext } from '../contexts/TodoContext';
import { useProjectContext } from '../contexts/ProjectContext';
import useGetRequest from '../hooks/useGetRequest';
import { createMapByKey, createNumberIdMap, createStringIdMap } from '../utils/createMaps';
import { Section, SectionOrder, TaskTime, Todo, TodoOrder } from '../types';

interface SectionAndTodoOrder {
  sectionOrder: SectionOrder[];
  todoOrder: TodoOrder[];
}

const Project = () => {
  const { todoData, setTodoData, taskTimeData, setTaskTimeData } = useTodoContext();
  const {
    sectionData,
    setSectionData,
    setSectionOrder,
    setTodoOrder,
    isOrderFetch,
    setIsOrderFetch,
  } = useProjectContext();
  const getRequest = useGetRequest();

  // データの取得 - Section
  useEffect(() => {
    if (sectionData) return;
    getRequest<Section[]>({
      apiUrl: process.env.REACT_APP_SECTION_API,
    })
      .then(response => {
        if (response.content) {
          setSectionData(createStringIdMap(response.content));
        }
      })
      .catch(error => {
        console.error('Sectionの取得に失敗しました:', error);
      });
  }, []);

  // データの取得 - SectionOrder, TodoOrder
  useEffect(() => {
    if (isOrderFetch) return;
    getRequest<SectionAndTodoOrder>({
      apiUrl: process.env.REACT_APP_SECTION_API,
      target: 'order',
    })
      .then(response => {
        if (response.content) {
          setSectionOrder(createMapByKey(response.content.sectionOrder, 'sectionId'));
          setTodoOrder(createMapByKey(response.content.todoOrder, 'todoId'));
          setIsOrderFetch(true);
        }
      })
      .catch(error => {
        console.error('SectionOrder, TodoOrderの取得に失敗しました:', error);
      });
  }, []);

  // データの取得 - Todo
  useEffect(() => {
    if (todoData) return;
    getRequest<Todo[]>({
      apiUrl: process.env.REACT_APP_TODO_API,
    })
      .then(response => {
        if (response.content) {
          setTodoData(createNumberIdMap(response.content));
        }
      })
      .catch(error => {
        console.error('Todoの取得に失敗しました:', error);
      });
  }, []);

  // データの取得 - TaskTime
  useEffect(() => {
    if (taskTimeData) return;
    getRequest<TaskTime[]>({
      apiUrl: process.env.REACT_APP_TASKTIME_API,
    })
      .then(response => {
        if (response.content) {
          setTaskTimeData(createNumberIdMap(response.content));
        }
      })
      .catch(error => {
        console.error('TaskTimeの取得に失敗しました:', error);
      });
  }, []);

  return <Outlet />;
};

export default Project;
