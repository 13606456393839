import React, { useCallback, useMemo } from 'react';
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Checkbox,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { differenceInSeconds, format, parseISO } from 'date-fns';
import useSaveRequest from '../../hooks/useSaveRequest';
import { todoDarkColor, todoMainColor } from '../../utils/getColors';
import { formatSeconds } from '../../utils/dateFormatters';
import { useProjectContext } from '../../contexts/ProjectContext';
import { useTodoContext } from '../../contexts/TodoContext';
import { TaskTime, Todo } from '../../types';
import { theme } from '../../theme';
import Icon from '../ui/Icon';

interface TodoCardProps {
  data: Todo;
  changeColor?: boolean;
  pageType: 'home' | 'project';
  setCurrentTodoId: React.Dispatch<React.SetStateAction<number>>;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setButtonElement: (target: HTMLButtonElement | null) => void;
}

const TodoCard = ({
  data,
  changeColor = false,
  pageType,
  setCurrentTodoId,
  setIsModalOpen,
  setButtonElement,
}: TodoCardProps) => {
  if (!data) return null;

  const { menuItems: projectData } = useProjectContext();
  const { setTodoData, taskTimeData } = useTodoContext();
  const saveRequest = useSaveRequest();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const start = data.start || '';
  const time = format(start, 'HH:mm');
  const projectName = projectData?.get(data.projectId || 0) || '';

  // 時間差を累積する関数
  const calculateCumulativeTime = useCallback(
    (taskTimeData: Map<number, TaskTime>, id: number): number => {
      let cumulativeTime = 0;
      taskTimeData.forEach(task => {
        if (id === task.todoId) {
          if (!task.start || !task.end) return;
          const startTime = parseISO(task.start);
          const endTime = parseISO(task.end);
          // 差分が負なら無視
          const durationInSeconds = differenceInSeconds(endTime, startTime);
          if (durationInSeconds > 0) {
            cumulativeTime += durationInSeconds;
          }
        }
      });
      return cumulativeTime;
    },
    []
  );

  const totalTaskTime = useMemo(() => {
    if (!taskTimeData) return 0;
    return calculateCumulativeTime(taskTimeData, data.id);
  }, [taskTimeData, data.id]);

  const toggleCompleted = useCallback((checked: number, id: number) => {
    const newCompleted = checked === 0 ? 1 : 0;
    saveRequest({
      apiUrl: process.env.REACT_APP_TODO_API,
      id: String(id),
      target: 'completed',
      data: newCompleted,
    })
      .then(() => {
        setTodoData(prev => {
          const newMap = new Map(prev);
          newMap.set(id, {
            ...(newMap.get(id) as Todo),
            completed: newCompleted,
          });
          return newMap;
        });
      })
      .catch(error => {
        console.error('完了の更新に失敗しました:', error);
      });
  }, []);

  return (
    <>
      <Card
        sx={{
          width: '100%',
          borderLeft: `solid 3px`,
          position: 'relative',
          borderColor: todoDarkColor(data.type),
          backgroundColor: changeColor
            ? isMobile
              ? theme.palette.secondary.light
              : '#fff'
            : '#fff',
          filter: data.completed === 1 ? 'brightness(0.85)' : 'none',
        }}
        onClick={e => {
          if ((e.target as Element).tagName === 'INPUT') return;
          const buttonElement = (e.currentTarget as HTMLElement).querySelector('button');
          setButtonElement(buttonElement);
          setCurrentTodoId(data.id);
          setIsModalOpen(true);
        }}
      >
        <Checkbox
          checked={Boolean(data.completed === 1)}
          onClick={() => {
            toggleCompleted(data.completed, data.id);
          }}
          sx={{
            padding: 0,
            position: 'absolute',
            top: '9px',
            left: '10px',
            color: todoDarkColor(data.type),
            '.MuiSvgIcon-root': {
              color: todoDarkColor(data.type),
            },
            '.Mui-checked': {
              color: todoDarkColor(data.type),
            },
          }}
        />
        <CardActionArea>
          <CardContent sx={{ padding: '12px' }}>
            <Stack direction={'row'} alignItems="flex-start" justifyContent="space-between">
              <Typography
                variant="body2"
                component={'h3'}
                lineHeight={1.35}
                fontWeight={'bold'}
                sx={{
                  flexGrow: 1,
                  paddingLeft: 3.5,
                }}
              >
                {data.content}
              </Typography>
              {time !== '00:00' && pageType === 'home' && (
                <Typography
                  variant="caption"
                  textAlign="right"
                  fontWeight={700}
                  sx={{
                    flexGrow: 0,
                    flexShrink: 0,
                    ml: 1,
                  }}
                >
                  {time}
                </Typography>
              )}
            </Stack>
            {pageType === 'project' && (
              <Stack direction="row" alignItems="center" justifyContent="flex-start" marginTop={1}>
                <Icon
                  icon="calendar-todo-fill"
                  color={todoMainColor(data.type)}
                  size="1rem"
                  style={{ marginRight: '0.5rem', flexShrink: 0, flexGrow: 0 }}
                />
                <Typography
                  lineHeight={1.35}
                  letterSpacing={0.5}
                  variant="caption"
                  sx={{
                    flexShrink: 1,
                    flexGrow: 1,
                  }}
                >
                  {format(start, 'yyyy年MM月dd日 H時mm分')}
                </Typography>
              </Stack>
            )}

            {(totalTaskTime !== 0 ||
              (data.estimated !== null && data.estimated !== '00:00:00')) && (
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                marginTop={0.75}
              >
                <Icon
                  icon="timer-fill"
                  color={todoMainColor(data.type)}
                  size="1rem"
                  style={{ marginRight: '0.5rem', flexShrink: 0, flexGrow: 0 }}
                />
                {totalTaskTime !== 0 && (
                  <Typography
                    lineHeight={1.35}
                    letterSpacing={0.5}
                    variant="caption"
                    sx={{
                      flexShrink: 1,
                      flexGrow: 1,
                    }}
                  >
                    {formatSeconds(totalTaskTime)}
                  </Typography>
                )}
                {data.estimated !== null && data.estimated !== '00:00:00' && (
                  <Box flexGrow={0} flexShrink={0} ml={2}>
                    <Typography
                      color="secondary"
                      variant="caption"
                      mr={0.75}
                      sx={{
                        padding: '1px 6px',
                        border: '1px solid',
                        borderColor: 'secondary',
                        borderRadius: '999px',
                        fontSize: '0.6rem',
                        verticalAlign: 'middle',
                      }}
                    >
                      見積
                    </Typography>
                    <Typography variant="caption" lineHeight={1.35}>
                      {(() => {
                        const estimated = data.estimated || '';
                        const formattedEstimated = estimated?.split(':').slice(0, 2).join(':');
                        return formattedEstimated;
                      })()}
                    </Typography>
                  </Box>
                )}
              </Stack>
            )}

            {projectName !== '' && (
              <Stack direction={'row'} alignItems="flex-start" marginTop={0.75}>
                <Icon
                  icon="folder-fill"
                  color={todoMainColor(data.type)}
                  size="1rem"
                  style={{ marginRight: '0.5rem', flexShrink: 0, flexGrow: 0 }}
                />
                <Typography
                  lineHeight={1.35}
                  letterSpacing={0.5}
                  variant="caption"
                  sx={{ flexGrow: 1 }}
                >
                  {projectName}
                </Typography>
              </Stack>
            )}

            {data.memo !== '' && (
              <Stack direction={'row'} alignItems="flex-start" marginTop={0.75}>
                <Icon
                  icon="file-text-fill"
                  color={todoMainColor(data.type)}
                  size="1rem"
                  style={{ marginRight: '0.5rem', flexShrink: 0, flexGrow: 0 }}
                />
                <Box>
                  <Typography
                    variant="caption"
                    lineHeight={1.35}
                    display={'block'}
                    sx={{ flexGrow: 1 }}
                  >
                    {data.memo.split('\n').map((line, memoIndex) => (
                      <React.Fragment key={memoIndex}>
                        {line}
                        <br />
                      </React.Fragment>
                    ))}
                  </Typography>
                </Box>
              </Stack>
            )}
          </CardContent>
        </CardActionArea>
      </Card>
    </>
  );
};

export default TodoCard;
