import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, CircularProgress, Paper, Stack, TextField, Typography } from '@mui/material';
import { differenceInDays, format } from 'date-fns';
import { JSONContent } from '@tiptap/core';
import { rectSortingStrategy } from '@dnd-kit/sortable';
import { useProjectContext } from '../contexts/ProjectContext';
import { useTodoContext } from '../contexts/TodoContext';
import useSaveRequest from '../hooks/useSaveRequest';
import useGetRequest from '../hooks/useGetRequest';
import BlockEditor from '../components/common/BlockEditor/BlockEditor';
import { KanbanBoard } from '../components/Project/KanbanBoard/KanbanBoard';
import TodoForm from '../components/common/TodoForm';
import TaskTimeBar from '../components/Project/TaskTimeBar';
import Modal from '../components/ui/Modal';
import PageTitle from '../components/ui/PageTitle';
import SectionTitle from '../components/ui/SectionTitle';
import { Project } from '../types';
import { theme } from '../theme';
import { center, fontSerif, textFieldStandardStyleWithLabel } from '../styles/commonStyles';

const ProjectPost = () => {
  const toDay = useMemo(() => format(new Date(), 'yyyy-MM-dd'), []);
  const { postId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentTodoId, setCurrentTodoId] = useState<number>(0);
  const [projectData, setProjectData] = useState<Project | null>(null);
  const [isSectionId, setIsSectionId] = useState<string | null>(null);
  const lastFocusedButtonRef = useRef<HTMLButtonElement | null>(null);
  const { menuItems, setMenuItems, sectionData, sectionOrder, todoOrder } = useProjectContext();
  const { todoData } = useTodoContext();
  const saveRequest = useSaveRequest();
  const getRequest = useGetRequest();

  useEffect(() => {
    console.log('ProjectPost:初回レンダリング');
  }, []);
  console.log('ProjectPost:レンダリング');

  // データの取得 - Project
  useEffect(() => {
    if (projectData) return;
    getRequest<Project>({
      apiUrl: process.env.REACT_APP_PROJECT_API,
      id: String(postId),
    })
      .then(response => {
        if (response.content) {
          setProjectData({
            ...response.content,
            content: JSON.parse(response.content.content as string) as JSONContent,
          });
        }
      })
      .catch(error => {
        console.error('Projectの取得に失敗しました:', error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [postId]);

  // モーダルを開く前にフォーカスしたボタン要素を記録
  const setButtonElement = (target: HTMLButtonElement | null) => {
    lastFocusedButtonRef.current = target;
  };

  // モーダルを閉じたら、フォーカスを最後にフォーカスしたボタン要素に戻す
  useEffect(() => {
    if (!isModalOpen && lastFocusedButtonRef.current) {
      setTimeout(() => {
        lastFocusedButtonRef.current?.focus();
      }, 500); // モーダルが閉じるアニメーションのための時間差
    }
  }, [isModalOpen]);

  // プロジェクトに紐づくセクションのidを取得
  const createSectionIds = useCallback(() => {
    if (!postId || !sectionData || !sectionOrder) return [];
    const parsedId = parseInt(postId, 10);
    const isValidId = !Number.isNaN(parsedId) && String(parsedId) === postId;
    let sectionIds: string[];

    if (isValidId && parsedId !== 0) {
      sectionIds = Array.from(sectionData.values())
        .filter(section => section.projectId === parsedId)
        .map(item => item.id);
    } else {
      sectionIds = [];
    }
    sectionIds.push('sec_0');

    return sectionIds.slice().sort((a, b) => {
      if (a === 'sec_0') return 1; // "sec_0" は常に最後
      if (b === 'sec_0') return -1;
      const sortA = sectionOrder.get(a)?.sort ?? Infinity; // 該当がない場合は最後に送る
      const sortB = sectionOrder.get(b)?.sort ?? Infinity;
      return sortA - sortB;
    });
  }, [postId, sectionData, sectionOrder]);

  const sectionIds = useMemo(
    () => createSectionIds(),
    [createSectionIds, postId, sectionData, sectionOrder]
  );

  // プロジェクトに紐づくセクションのidから紐づくtodoのidを取得
  const createTodoIdsBySection = useCallback(() => {
    if (!todoData || !todoOrder || sectionIds.length === 0) return {};
    // sectionIdsからオブジェクトを生成
    const result = sectionIds.reduce(
      (acc, curr) => {
        acc[curr] = [];
        return acc;
      },
      {} as Record<string, number[]>
    );
    todoData.forEach(todo => {
      if (todo.projectId === Number(postId)) {
        const { id, sectionId } = todo;
        // sectionIdがnullの場合はsec_0として扱う
        const key = sectionId === null ? 'sec_0' : sectionId;
        result[key].push(id);
      }
    });
    // 各セクションのtodoIdsを並び替え
    Object.keys(result).forEach(sectionId => {
      result[sectionId] = result[sectionId].sort((a, b) => {
        const sortA = todoOrder.get(a)?.sort ?? Infinity; // 存在しない場合は末尾
        const sortB = todoOrder.get(b)?.sort ?? Infinity;
        return sortA - sortB;
      });
    });
    return result;
  }, [todoData]);

  const todoIdsBySection = useMemo(
    () => createTodoIdsBySection(),
    [createTodoIdsBySection, todoData, todoOrder, sectionIds]
  );

  // 締切の更新
  const endUpdate = useCallback((e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    saveRequest({
      apiUrl: process.env.REACT_APP_PROJECT_API,
      id: String(postId),
      target: 'end',
      data: e.target.value,
    })
      .then(() => {
        setProjectData(prev => {
          if (prev) {
            return {
              ...prev,
              end: e.target.value,
            };
          }
          return null;
        });
      })
      .catch(error => {
        console.error('締切の更新に失敗しました:', error);
      });
  }, []);

  // 締切までの残り日数を計算
  const remainingDays = useMemo(() => {
    if (!projectData?.end) return null;
    const today = new Date();
    // 与えられた日付をDateオブジェクトに変換
    const targetDate = new Date(projectData?.end);
    // 日付の差を計算
    const remainingDays = differenceInDays(targetDate, today);
    if (remainingDays < 0) {
      return 0;
    }
    return remainingDays;
  }, [projectData?.end]);

  return (
    <>
      {isLoading ? (
        <Box
          sx={{
            width: '100%',
            height: '100%',
            ...center,
          }}
        >
          <CircularProgress color="secondary" size={30} />
        </Box>
      ) : (
        <>
          {projectData ? (
            <>
              <Box
                sx={{
                  margin: '1rem auto',
                  width: '100%',
                  maxWidth: '1000px',
                }}
              >
                <PageTitle
                  title={menuItems?.get(Number(postId)) || ''} // あとで変更
                  postId={Number(postId)}
                  apiUrl={process.env.REACT_APP_PROJECT_API || ''}
                  setData={setMenuItems} // あとで変更
                  marginBottom="1rem"
                />
                <Stack spacing={6}>
                  <Stack direction="row" alignItems="center" spacing={0.5}>
                    <TextField
                      variant="standard"
                      label="締切"
                      type="date"
                      autoComplete="off"
                      name="end"
                      id="end"
                      defaultValue={projectData?.end}
                      onChange={e => {
                        endUpdate(e);
                      }}
                      sx={{
                        ...textFieldStandardStyleWithLabel,
                        width: 'fit-content',
                        '.MuiInputBase-input.MuiInput-input': {
                          backgroundColor: 'transparent',
                          border: 'none',
                          ...(projectData.end
                            ? { color: `${theme.palette.text.primary} !important` }
                            : {}),
                        },
                      }}
                    />
                    {projectData.end && (
                      <Typography
                        variant="body1"
                        component="p"
                        color="textSecondary"
                        fontWeight={500}
                      >
                        （{remainingDays}日後）
                      </Typography>
                    )}
                  </Stack>
                  {Object.keys(todoIdsBySection).length > 0 && (
                    <KanbanBoard
                      strategy={rectSortingStrategy}
                      postId={postId}
                      sectionIds={sectionIds}
                      items={todoIdsBySection}
                      createTodoIdsBySection={createTodoIdsBySection}
                      setIsModalOpen={setIsModalOpen}
                      setCurrentItemId={setCurrentTodoId}
                      setIsSectionId={setIsSectionId}
                      setButtonElement={setButtonElement}
                    />
                  )}
                  <Box>
                    <SectionTitle title="作業累計" />
                    {Object.keys(todoIdsBySection).length > 0 && (
                      <Paper
                        variant="outlined"
                        sx={{
                          p: 2,
                          borderRadius: '6px',
                          width: '100%',
                          height: '260px',
                          ...center,
                        }}
                      >
                        <TaskTimeBar sectionIds={sectionIds} todoIdsBySection={todoIdsBySection} />
                      </Paper>
                    )}
                  </Box>
                  <Box>
                    <SectionTitle title="メモ" />
                    <BlockEditor
                      content={projectData.content as JSONContent}
                      id={postId as string}
                      apiUrl={process.env.REACT_APP_PROJECT_API || ''}
                    />
                  </Box>
                </Stack>
              </Box>
              <Modal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen}>
                <TodoForm
                  currentDay={toDay}
                  currentTodoId={currentTodoId}
                  setCurrentTodoId={setCurrentTodoId}
                  setIsModalOpen={setIsModalOpen}
                  isProjectId={Number(postId)}
                  isSectionId={isSectionId !== null ? isSectionId : undefined}
                />
              </Modal>
            </>
          ) : (
            <Box
              sx={{
                height: '100%',
                ...center,
              }}
            >
              <Typography
                variant="h6"
                component="p"
                fontWeight={700}
                sx={{
                  ...fontSerif,
                }}
              >
                該当するプロジェクトが見つかりません
              </Typography>
            </Box>
          )}
        </>
      )}
    </>
  );
};

export default memo(ProjectPost);
