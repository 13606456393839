import { memo, useMemo } from 'react';
import { DropDownMenu, MenuItemProps } from '../../../ui/DropDownMenu';

interface ContentTypePickerOption {
  label: string;
  id: string;
  type: 'option';
  disabled: () => boolean;
  isActive: () => boolean;
  onClick: () => void;
  icon: string;
}

interface ContentTypePickerCategory {
  label: string;
  id: string;
  type: 'category';
  icon?: string;
}

export type ContentPickerOptions = Array<ContentTypePickerOption | ContentTypePickerCategory>;

interface ContentTypePickerProps {
  options: ContentPickerOptions;
}

const isOption = (
  option: ContentTypePickerOption | ContentTypePickerCategory
): option is ContentTypePickerOption => option.type === 'option';

export const ContentTypePicker = memo(({ options }: ContentTypePickerProps) => {
  const activeItem = useMemo(
    () => options.find(option => option.type === 'option' && option.isActive()),
    [options]
  );

  const menuItems = useMemo(
    () =>
      options
        .map(option => {
          if (isOption(option)) {
            return {
              icon: option.icon,
              label: option.label,
              disabled: option.disabled() || false,
              selected: activeItem?.icon === option.icon,
              onClick: option.onClick,
            } as MenuItemProps;
          }
          return null;
        })
        .filter((item): item is MenuItemProps => item !== null),
    [options, activeItem]
  );

  return (
    <DropDownMenu
      tooltip="書式"
      menuName="contentTypePicker"
      ariaLabel="書式"
      arrow
      icon={activeItem?.icon || 'paragraph'}
      menuItems={menuItems}
    />
  );
});

ContentTypePicker.displayName = 'ContentTypePicker';
