import { forwardRef, memo, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';
import type { DraggableSyntheticListeners, UniqueIdentifier } from '@dnd-kit/core';
import type { Transform } from '@dnd-kit/utilities';
import { theme } from '../../../../../theme';
import Icon from '../../../../ui/Icon';
import { Remove } from '../Action';
import './Item.scss';

export interface ItemProps {
  id: UniqueIdentifier;
  pathname: string;
  label: string;
  dragOverlay?: boolean;
  disabled?: boolean;
  dragging?: boolean;
  index?: number;
  fadeIn?: boolean;
  transform?: Transform | null;
  listeners?: DraggableSyntheticListeners;
  sorting?: boolean;
  style?: React.CSSProperties;
  transition?: string | null;
  wrapperStyle?: React.CSSProperties;
  onRemove(): void;
  renderItem?(args: {
    dragOverlay: boolean;
    dragging: boolean;
    sorting: boolean;
    index: number | undefined;
    fadeIn: boolean;
    listeners: DraggableSyntheticListeners;
    ref: React.Ref<HTMLElement>;
    style: React.CSSProperties | undefined;
    transform: ItemProps['transform'];
    transition: ItemProps['transition'];
  }): React.ReactElement;
}

const ItemComponent = (
  {
    id,
    pathname,
    label,
    dragOverlay,
    dragging,
    disabled,
    fadeIn,
    index,
    listeners,
    renderItem,
    sorting,
    style,
    transition,
    transform,
    wrapperStyle,
    onRemove,
    ...props
  }: ItemProps,
  ref: React.Ref<HTMLLIElement>
) => {
  useEffect(() => {
    if (!dragOverlay) {
      return;
    }

    document.body.style.cursor = 'grabbing';

    return () => {
      document.body.style.cursor = '';
    };
  }, [dragOverlay]);

  return renderItem ? (
    renderItem({
      dragOverlay: Boolean(dragOverlay),
      dragging: Boolean(dragging),
      sorting: Boolean(sorting),
      index,
      fadeIn: Boolean(fadeIn),
      listeners,
      ref,
      style,
      transform,
      transition,
    })
  ) : (
    <li
      className={classNames(
        'draggableMenuItemWrapper',
        fadeIn && 'fadeIn',
        sorting && 'sorting',
        dragOverlay && 'dragOverlay'
      )}
      style={
        {
          ...wrapperStyle,
          transition: [transition, wrapperStyle?.transition].filter(Boolean).join(', '),
          '--translate-x': transform ? `${Math.round(transform.x)}px` : undefined,
          '--translate-y': transform ? `${Math.round(transform.y)}px` : undefined,
          '--scale-x': transform?.scaleX ? `${transform.scaleX}` : undefined,
          '--scale-y': transform?.scaleY ? `${transform.scaleY}` : undefined,
          '--index': index,
        } as React.CSSProperties
      }
      ref={ref}
    >
      <div
        className={classNames(
          'draggableMenuItem',
          dragging && 'dragging',
          dragOverlay && 'dragOverlay',
          disabled && 'disabled'
        )}
        style={{
          ...style,
        }}
        data-cypress="draggable-item"
        {...listeners}
        {...props}
        tabIndex={-1} // キーボード操作でのフォーカスを防ぐ
      >
        <NavLink
          to={`/${pathname}/${id}`}
          style={({ isActive }) => ({
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            borderRadius: '3px',
            padding: '6px 20px 6px 6px',
            fontSize: '14px',
            lineHeight: 1.35,
            flexGrow: 1,
            ...(isActive
              ? { backgroundColor: theme.palette.secondary.light, fontWeight: 500 }
              : { backgroundColor: 'transparent' }),
          })}
        >
          <Icon
            icon="file-text-fill"
            size="0.9rem"
            style={{
              marginTop: '3px',
              marginRight: '5px',
              flexShrink: 0,
            }}
          />
          <span>{label}</span>
        </NavLink>
        <Remove
          onClick={onRemove}
          style={{
            opacity: 0,
            padding: '0 7px',
            height: 'calc(100% - 8px)',
            position: 'absolute',
            right: '4px',
            top: '4px',
            zIndex: 1,
          }}
        />
      </div>
    </li>
  );
};

export const Item = memo(forwardRef(ItemComponent));
