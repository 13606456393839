import { memo } from 'react';
import { addMonths } from 'date-fns';
import { ja } from 'date-fns/locale/ja';
import { Button, Stack } from '@mui/material';
import { CSSProperties } from '@mui/material/styles/createMixins';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import Icon from '../ui/Icon';
import { fontSerif } from '../../styles/commonStyles';

interface MonthSelectorProps {
  currentMonth: Date;
  setCurrentMonth: React.Dispatch<React.SetStateAction<Date>>;
}

const MonthSelector = ({ currentMonth, setCurrentMonth }: MonthSelectorProps) => {
  const handlePreviousMonth = () => {
    const previousMonth = addMonths(currentMonth, -1);
    setCurrentMonth(previousMonth);
  };

  const handleNextMonth = () => {
    const nextMonth = addMonths(currentMonth, 1);
    setCurrentMonth(nextMonth);
  };

  const handleDateChange = (date: Date | null) => {
    if (date) {
      setCurrentMonth(date);
    }
  };

  const buttonStyle: CSSProperties = {
    maxWidth: '53px',
    minWidth: 0,
    width: '35vw',
    padding: '6px',
  };

  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      adapterLocale={ja}
      dateFormats={{ year: 'yyyy年', month: 'M月' }}
    >
      <Stack direction="row" spacing={2} justifyContent="center">
        <Button
          variant="contained"
          color="primary"
          onClick={handlePreviousMonth}
          sx={{
            ...buttonStyle,
          }}
        >
          <Icon icon="arrow-left-s-line" color="#fff" />
        </Button>
        <DatePicker
          onChange={handleDateChange}
          value={currentMonth}
          sx={{
            backgroundColor: 'white',
            '.MuiInputBase-input': {
              ...fontSerif,
              textAlign: 'center',
              fontSize: '1.35rem',
              fontWeight: 700,
              letterSpacing: '0.025em',
              padding: '10px 0 10px 12px',
            },
            '.MuiButtonBase-root.MuiIconButton-root': {
              borderRadius: '999px',
            },
            '.MuiButtonBase-root.MuiIconButton-root .MuiSvgIcon-root': {
              fontSize: '1.35rem',
            },
            '.MuiButtonBase-root.MuiIconButton-root .MuiTouchRipple-root': {
              borderRadius: '999px !important',
            },
          }}
          views={['year', 'month']}
          format="yyyy年M月"
          slotProps={{
            toolbar: {
              toolbarFormat: 'yyyy年M月',
            },
          }}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleNextMonth}
          sx={{
            ...buttonStyle,
          }}
        >
          <Icon icon="arrow-right-s-line" color="#fff" />
        </Button>
      </Stack>
    </LocalizationProvider>
  );
};

export default memo(MonthSelector);
