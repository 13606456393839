import React, { memo, useEffect, useState } from 'react';
import { format } from 'date-fns';
import { Box, Button, alpha, Divider, Chip } from '@mui/material';
import {
  GridRowsProp,
  DataGrid,
  GridColDef,
  GridToolbarContainer,
  GridActionsCellItem,
  GridRowId,
  GridSlotProps,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
  GridRowModel,
} from '@mui/x-data-grid';
import useDeleteRequest from '../../hooks/useDeleteRequest';
import { mentalIcons } from './Icons';
import Icon from '../ui/Icon';
import { theme } from '../../theme';
import { HealthItem } from '../../types';

declare module '@mui/x-data-grid' {
  interface ToolbarPropsOverrides {
    setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
  }
}

const CustomToolbar = (
  props: GridSlotProps['toolbar'] & {
    table: string;
    setIsModalOpen?: React.Dispatch<React.SetStateAction<boolean>>;
    setCurrentId?: React.Dispatch<React.SetStateAction<number>>;
    setFormType?: React.Dispatch<React.SetStateAction<string>>;
  }
) => {
  const { table, setIsModalOpen, setCurrentId, setFormType } = props;

  const addNewRow = () => {
    if (setCurrentId) setCurrentId(0);
    if (setFormType) setFormType(table);
    if (setIsModalOpen) setIsModalOpen(true);
  };

  return (
    <GridToolbarContainer>
      <Button
        startIcon={
          <Icon
            icon="add-circle-line"
            size="1rem"
            color={alpha(theme.palette.secondary.light, 0.8)}
          />
        }
        onClick={addNewRow}
        sx={{
          color: alpha(theme.palette.secondary.light, 0.8),
          '@media (hover: hover)': {
            '&:hover': {
              bgcolor: 'rgba(255, 255, 255, 0.12)',
            },
          },
        }}
      >
        データの追加
      </Button>
      <Divider
        orientation="vertical"
        variant="middle"
        flexItem
        sx={{
          borderColor: alpha(theme.palette.secondary.light, 0.6),
        }}
      />
      <GridToolbarColumnsButton
        slotProps={{
          button: {
            startIcon: (
              <Icon
                icon="kanban-view-2"
                size="1rem"
                color={alpha(theme.palette.secondary.light, 0.8)}
              />
            ),
            sx: {
              color: alpha(theme.palette.secondary.light, 0.8),
              '@media (hover: hover)': {
                '&:hover': {
                  bgcolor: 'rgba(255, 255, 255, 0.12)',
                },
              },
            },
          },
        }}
      />
      <Divider
        orientation="vertical"
        variant="middle"
        flexItem
        sx={{
          borderColor: alpha(theme.palette.secondary.light, 0.6),
        }}
      />
      <GridToolbarFilterButton
        slotProps={{
          button: {
            startIcon: (
              <Icon
                icon="filter-2-line"
                size="1rem"
                color={alpha(theme.palette.secondary.light, 0.8)}
              />
            ),
            sx: {
              color: alpha(theme.palette.secondary.light, 0.8),
              '@media (hover: hover)': {
                '&:hover': {
                  bgcolor: 'rgba(255, 255, 255, 0.12)',
                },
              },
            },
          },
        }}
      />
      <Box sx={{ flexGrow: 1 }} />
      <GridToolbarQuickFilter
        autoComplete="off"
        spellCheck={false}
        sx={{
          paddingBottom: 0,
          caretColor: theme.palette.secondary.light,
          '& .MuiInputBase-root': {
            color: theme.palette.secondary.light,
          },
          '.MuiSvgIcon-root': {
            color: alpha(theme.palette.secondary.light, 0.8),
          },
          '.MuiInput-underline:before': {
            borderBottom: `solid 1px transparent`,
          },
          '.MuiInputBase-root.MuiInput-root:hover:not(.Mui-disabled, .Mui-error):before': {
            borderBottom: `solid 1px ${theme.palette.secondary.dark}`,
          },
          '.MuiInputBase-root.MuiInput-root:after': {
            borderBottom: `1px solid ${theme.palette.secondary.light}`,
          },
        }}
      />
    </GridToolbarContainer>
  );
};

interface HasId {
  id: number;
}

// 型ガード関数
const hasId = <T,>(data: T): data is T & HasId => (data as T & HasId).id !== undefined;

interface TableProps<T> {
  table: string;
  apiUrl: string;
  gridRows: GridRowsProp | null;
  isDataFetch?: boolean;
  setData?: React.Dispatch<React.SetStateAction<T[] | null>>;
  iconMap?: Map<string, string>;
  setCurrentId?: React.Dispatch<React.SetStateAction<number>>;
  setIsModalOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  setHealthItem?: React.Dispatch<React.SetStateAction<HealthItem[] | null>>;
  setFormType?: React.Dispatch<React.SetStateAction<string>>;
}

const Table = <T,>({
  table,
  apiUrl,
  gridRows,
  isDataFetch,
  setData,
  iconMap,
  setCurrentId,
  setIsModalOpen,
  setHealthItem,
  setFormType,
}: TableProps<T>) => {
  useEffect(() => {
    console.log('Table.tsx：初回レンダリング');
  }, []);
  console.log('Table.tsx：レンダリング');

  const [rows, setRows] = useState<GridRowsProp>(gridRows || []);
  const deleteRequest = useDeleteRequest();

  // isDataFetchがある場合、データを更新
  useEffect(() => {
    if (isDataFetch) setRows(gridRows || []);
  }, [isDataFetch]);

  // 行の編集
  const handleEditClick = (id: GridRowId) => () => {
    if (setCurrentId) setCurrentId(id as number);
    if (setFormType) setFormType(table);
    if (setIsModalOpen) setIsModalOpen(true);
  };

  // 行の削除
  const handleDeleteClick = (id: GridRowId) => () => {
    // eslint-disable-next-line no-restricted-globals, no-alert
    const result = confirm('データを削除しますか？');
    if (result) {
      deleteRequest({
        apiUrl,
        id: String(id),
        target: table,
      })
        .then(() => {
          setRows(rows.filter(row => row.id !== id));
          if (setData) {
            setData(prev => {
              if (!prev) return null;
              const newData = prev.filter(data => {
                if (hasId(data)) {
                  // 型ガードを使って、idがある場合にのみ処理
                  return data.id !== id;
                }
                return false;
              });
              return newData;
            });
          }
          if (setHealthItem) {
            setHealthItem(prev => {
              if (!prev) return null;
              return prev.filter(item => item.healthId !== id);
            });
          }
        })
        .catch(error => {
          console.error(`${table}のテーブルの削除に失敗しました:`, error);
        });
    }
  };

  // ページによってカラムを変更
  const columns = (): GridColDef[] => {
    if (table === 'health') {
      return [
        {
          field: 'date',
          headerName: '日付',
          type: 'date',
          width: 100,
          renderCell: params => (
            <Box
              display={'flex'}
              alignItems={'center'}
              sx={{
                minHeight: '52px',
                height: '100%',
                padding: '8px 0',
              }}
            >
              {format(params.value as Date, 'yyyy/M/dd')}
            </Box>
          ),
        },
        {
          field: 'item',
          headerName: '症状',
          width: 170,
          renderCell: params => {
            // 正規表現で不要な空白をトリムして分割
            const items = (params.value as string)
              .split(',')
              .map((text: string) => text.trim())
              .filter((text: string) => text !== ''); // 空文字列を除外
            return (
              <Box
                display="flex"
                alignItems="center"
                flexWrap="wrap"
                sx={{
                  minHeight: '52px',
                  height: '100%',
                  padding: '4px 0',
                }}
              >
                {items.map((item: string, index: number) => (
                  <Chip
                    key={index}
                    label={item}
                    icon={
                      <Icon
                        icon={iconMap?.get(item) || 'dossier-line'}
                        style={{
                          flexShrink: 0,
                          marginLeft: '4px',
                          marginRight: '-4px',
                        }}
                      />
                    }
                    size="small"
                    sx={{
                      margin: '4px',
                    }}
                  />
                ))}
              </Box>
            );
          },
        },
        {
          field: 'mental',
          headerName: '調子',
          width: 80,
          type: 'number',
          headerAlign: 'center',
          renderCell: params => (
            <Box
              display={'flex'}
              alignItems="center"
              justifyContent="center"
              sx={{
                minHeight: '52px',
                height: '100%',
                padding: '8px 0',
              }}
            >
              {params.value > 0 && (
                <p
                  style={{
                    fontSize: '1.5rem',
                  }}
                >
                  {mentalIcons[params.value as number].icon}
                </p>
              )}
            </Box>
          ),
        },
        {
          field: 'memo',
          headerName: 'メモ',
          width: 200,
          renderCell: params => (
            <Box
              display="flex"
              alignItems="center"
              sx={{
                minHeight: '52px',
                height: '100%',
                padding: '8px 0',
              }}
            >
              <p>
                {(params.value as string).split('\n').map((line: string, index: number) => (
                  <React.Fragment key={index}>
                    {line}
                    <br />
                  </React.Fragment>
                ))}
              </p>
            </Box>
          ),
        },
        {
          field: 'actions',
          type: 'actions',
          headerName: '編集',
          width: 100,
          cellClassName: 'actions',
          getActions: ({ id }) => [
            <GridActionsCellItem
              key={`${id}-edit`}
              icon={<Icon icon="pencil-fill" size="1rem" />}
              label="編集"
              onClick={handleEditClick(id)}
            />,
            <GridActionsCellItem
              key={`${id}-delete`}
              icon={<Icon icon="delete-bin-7-fill" size="1rem" />}
              label="削除"
              onClick={handleDeleteClick(id)}
            />,
          ],
        },
      ];
    }
    if (table === 'food') {
      return [
        {
          field: 'date',
          headerName: '日付',
          type: 'date',
          width: 100,
        },
        {
          field: 'name',
          headerName: '食事',
          width: 150,
        },
        {
          field: 'quantity',
          headerName: '量',
          width: 90,
          type: 'number',
          headerAlign: 'center',
          renderCell: params => {
            const row = params.row as GridRowModel;
            return (
              <>
                {params.value} {row.unit}
              </>
            );
          },
        },
        {
          field: 'energy',
          headerName: '熱量(kcal)',
          type: 'number',
          width: 90,
          headerAlign: 'center',
        },
        {
          field: 'protein',
          headerName: 'たんぱく質(g)',
          type: 'number',
          width: 90,
          headerAlign: 'center',
          renderCell: params => <>{params.value !== null && params.value}</>,
        },
        {
          field: 'fat',
          headerName: '脂質(g)',
          type: 'number',
          width: 90,
          headerAlign: 'center',
          renderCell: params => <>{params.value !== null && params.value}</>,
        },
        {
          field: 'carb',
          headerName: '炭水化物(g)',
          type: 'number',
          width: 90,
          headerAlign: 'center',
          renderCell: params => <>{params.value !== null && params.value}</>,
        },
        {
          field: 'salt',
          headerName: '食塩相当量(g)',
          type: 'number',
          width: 90,
          headerAlign: 'center',
          renderCell: params => <>{params.value !== null && params.value}</>,
        },
        {
          field: 'actions',
          type: 'actions',
          headerName: '編集',
          width: 100,
          cellClassName: 'actions',
          getActions: ({ id }) => [
            <GridActionsCellItem
              key={`${id}-edit`}
              icon={<Icon icon="pencil-fill" size="1rem" />}
              label="編集"
              onClick={handleEditClick(id)}
            />,
            <GridActionsCellItem
              key={`${id}-delete`}
              icon={<Icon icon="delete-bin-7-fill" size="1rem" />}
              label="削除"
              onClick={handleDeleteClick(id)}
            />,
          ],
        },
      ];
    }
    return [];
  };

  return (
    <Box
      sx={{
        width: '100%',
      }}
    >
      <DataGrid
        rows={rows}
        columns={columns()}
        editMode="row"
        slots={{
          toolbar: props => (
            <CustomToolbar
              {...props}
              table={table}
              setIsModalOpen={setIsModalOpen}
              setCurrentId={setCurrentId}
              setFormType={setFormType}
            />
          ),
        }}
        slotProps={{
          toolbar: { setRows, showQuickFilter: true },
        }}
        getRowHeight={() => (table === 'health' ? 'auto' : 52)}
        initialState={{
          pagination: { paginationModel: { pageSize: 25 } },
        }}
        loading={!gridRows}
        sx={{
          '& .MuiCircularProgress-root.MuiCircularProgress-indeterminate': {
            width: '30px !important',
            height: '30px !important',
            color: theme.palette.secondary.main,
          },
        }}
        localeText={{
          // Root
          noRowsLabel: 'データがありません。',
          noResultsOverlayLabel: '結果がありません。',
          // Density selector toolbar button text
          toolbarDensity: '行間隔',
          toolbarDensityLabel: '行間隔',
          toolbarDensityCompact: 'コンパクト',
          toolbarDensityStandard: '標準',
          toolbarDensityComfortable: '広め',
          // Columns selector toolbar button text
          toolbarColumns: '列の表示',
          toolbarColumnsLabel: '列選択を表示',
          // Filters toolbar button text
          toolbarFilters: 'フィルター',
          toolbarFiltersLabel: 'フィルター',
          toolbarFiltersTooltipHide: 'フィルター非表示',
          toolbarFiltersTooltipShow: 'フィルターを表示',
          toolbarFiltersTooltipActive: count => `${count}件のフィルターを適用中`,
          // Quick filter toolbar field
          toolbarQuickFilterPlaceholder: '検索…',
          toolbarQuickFilterLabel: '検索',
          toolbarQuickFilterDeleteIconLabel: 'クリア',
          // Export selector toolbar button text
          toolbarExport: 'エクスポート',
          toolbarExportLabel: 'エクスポート',
          toolbarExportCSV: 'CSVダウンロード',
          toolbarExportPrint: '印刷',
          toolbarExportExcel: 'Excelダウンロード',
          // Columns management text
          columnsManagementSearchTitle: '検索',
          columnsManagementNoColumns: 'カラムなし',
          columnsManagementShowHideAllText: 'すべて表示/非表示',
          columnsManagementReset: 'リセット',
          // columnsManagementDeleteIconLabel: 'Clear',
          // Filter panel text
          filterPanelAddFilter: 'フィルター追加',
          filterPanelRemoveAll: 'すべて削除',
          filterPanelDeleteIconLabel: '削除',
          filterPanelLogicOperator: '論理演算子',
          filterPanelOperator: '演算子',
          filterPanelOperatorAnd: 'And',
          filterPanelOperatorOr: 'Or',
          filterPanelColumns: '列',
          filterPanelInputLabel: '値',
          filterPanelInputPlaceholder: '値を入力…',
          // Filter operators text
          filterOperatorContains: '...を含む',
          filterOperatorDoesNotContain: '...を含まない',
          filterOperatorEquals: '...に等しい',
          filterOperatorDoesNotEqual: '...に等しくない',
          filterOperatorStartsWith: '...で始まる',
          filterOperatorEndsWith: '...で終わる',
          filterOperatorIs: '...である',
          filterOperatorNot: '...でない',
          filterOperatorAfter: '...より後ろ',
          filterOperatorOnOrAfter: '...以降',
          filterOperatorBefore: '...より前',
          filterOperatorOnOrBefore: '...以前',
          filterOperatorIsEmpty: '...空である',
          filterOperatorIsNotEmpty: '...空でない',
          filterOperatorIsAnyOf: '...のいずれか',
          'filterOperator=': '=',
          'filterOperator!=': '!=',
          'filterOperator>': '>',
          'filterOperator>=': '>=',
          'filterOperator<': '<',
          'filterOperator<=': '<=',
          // Header filter operators text
          headerFilterOperatorContains: '含む',
          headerFilterOperatorDoesNotContain: '含まない',
          headerFilterOperatorEquals: '等しい',
          headerFilterOperatorDoesNotEqual: '等しくない',
          headerFilterOperatorStartsWith: 'で始まる',
          headerFilterOperatorEndsWith: 'で終わる',
          headerFilterOperatorIs: 'である',
          headerFilterOperatorNot: 'ではない',
          headerFilterOperatorAfter: '...より後ろ',
          headerFilterOperatorOnOrAfter: '...以降',
          headerFilterOperatorBefore: '...より前',
          headerFilterOperatorOnOrBefore: '...以前',
          headerFilterOperatorIsEmpty: '空白',
          headerFilterOperatorIsNotEmpty: '空白ではない',
          headerFilterOperatorIsAnyOf: 'いずれか',
          'headerFilterOperator=': '等しい',
          'headerFilterOperator!=': '等しくない',
          'headerFilterOperator>': 'より大きい',
          'headerFilterOperator>=': '以上',
          'headerFilterOperator<': '未満',
          'headerFilterOperator<=': '以下',
          // Filter values text
          filterValueAny: 'いずれか',
          filterValueTrue: '真',
          filterValueFalse: '偽',
          // Column menu text
          columnMenuLabel: 'メニュー',
          columnMenuShowColumns: '列表示',
          columnMenuManageColumns: '列管理',
          columnMenuFilter: 'フィルター',
          columnMenuHideColumn: '列非表示',
          columnMenuUnsort: 'ソート解除',
          columnMenuSortAsc: '昇順ソート',
          columnMenuSortDesc: '降順ソート',
          // Column header text
          columnHeaderFiltersTooltipActive: count => `${count}件のフィルターを適用中`,
          columnHeaderFiltersLabel: 'フィルター表示',
          columnHeaderSortIconLabel: 'ソート',
          // Rows selected footer text
          footerRowSelected: count => `${count}行を選択中`,
          // Total row amount footer text
          footerTotalRows: '総行数:',
          // Total visible row amount footer text
          footerTotalVisibleRows: (visibleCount, totalCount) =>
            `${visibleCount.toLocaleString()} / ${totalCount.toLocaleString()}`,
          // Checkbox selection text
          checkboxSelectionHeaderName: 'チェックボックス',
          checkboxSelectionSelectAllRows: 'すべての行を選択',
          checkboxSelectionUnselectAllRows: 'すべての行選択を解除',
          checkboxSelectionSelectRow: '行を選択',
          checkboxSelectionUnselectRow: '行選択を解除',
          // Boolean cell text
          booleanCellTrueLabel: '真',
          booleanCellFalseLabel: '偽',
          // Actions cell more text
          actionsCellMore: 'もっと見る',
          // Column pinning text
          pinToLeft: '左側に固定',
          pinToRight: '右側に固定',
          unpin: '固定解除',
          // Tree Data
          treeDataGroupingHeaderName: 'グループ',
          treeDataExpand: '展開',
          treeDataCollapse: '折りたたみ',
          // Grouping columns
          groupingColumnHeaderName: 'グループ',
          groupColumn: name => `${name}でグループ化`,
          unGroupColumn: name => `${name}のグループを解除`,
          // Master/detail
          detailPanelToggle: '詳細パネルの切り替え',
          expandDetailPanel: '展開',
          collapseDetailPanel: '折りたたみ',
          // Row reordering text
          rowReorderingHeaderName: '行並び替え',
          // Aggregation
          aggregationMenuItemHeader: '合計',
          aggregationFunctionLabelSum: '和',
          aggregationFunctionLabelAvg: '平均',
          aggregationFunctionLabelMin: '最小値',
          aggregationFunctionLabelMax: '最大値',
          aggregationFunctionLabelSize: 'サイズ',
        }}
      />
    </Box>
  );
};

export default memo(Table) as typeof Table;
