import { memo, useCallback } from 'react';
import { BubbleMenu as BaseBubbleMenu, useEditorState } from '@tiptap/react';
import { sticky } from 'tippy.js';
import { v4 as uuid } from 'uuid';
import { MenuProps } from '../../../types';
import { getRenderContainer } from '../../../utils/getRenderContainer';
import { ColumnLayout } from '../Columns';
import { Toolbar } from '../../../../../ui/Toolbar';
import { zEditorMenu } from '../../../../../../styles/commonStyles';

export const ColumnsMenu = memo(({ editor, appendTo }: MenuProps) => {
  const getReferenceClientRect = useCallback(() => {
    const renderContainer = getRenderContainer(editor, 'columns');
    const rect = renderContainer?.getBoundingClientRect() || new DOMRect(-1000, -1000, 0, 0);
    return rect;
  }, [editor]);

  const shouldShow = useCallback(() => {
    const isColumns = editor.isActive('columns');
    return isColumns;
  }, [editor]);

  const onColumnLeft = useCallback(() => {
    editor.chain().focus().setLayout(ColumnLayout.SidebarLeft).run();
  }, [editor]);

  const onColumnRight = useCallback(() => {
    editor.chain().focus().setLayout(ColumnLayout.SidebarRight).run();
  }, [editor]);

  const onColumnTwo = useCallback(() => {
    editor.chain().focus().setLayout(ColumnLayout.TwoColumn).run();
  }, [editor]);
  const { isColumnLeft, isColumnRight, isColumnTwo } = useEditorState({
    editor,
    selector: ctx => ({
      isColumnLeft: ctx.editor.isActive('columns', { layout: ColumnLayout.SidebarLeft }),
      isColumnRight: ctx.editor.isActive('columns', { layout: ColumnLayout.SidebarRight }),
      isColumnTwo: ctx.editor.isActive('columns', { layout: ColumnLayout.TwoColumn }),
    }),
  });

  return (
    <BaseBubbleMenu
      editor={editor}
      pluginKey={`columnsMenu-${uuid()}`}
      shouldShow={shouldShow}
      updateDelay={0}
      tippyOptions={{
        zIndex: zEditorMenu.zIndex + 1,
        offset: [0, 8],
        popperOptions: {
          modifiers: [{ name: 'flip', enabled: false }],
        },
        getReferenceClientRect,
        appendTo: () => appendTo?.current as HTMLElement,
        plugins: [sticky],
        sticky: 'popper',
      }}
    >
      <Toolbar.Wrapper>
        <Toolbar.Button
          icon="layout-left-fill"
          ariaLabel="左サイド"
          tooltip="左サイド"
          isActive={isColumnLeft}
          onClick={onColumnLeft}
        />
        <Toolbar.Button
          icon="layout-column-fill"
          ariaLabel="中央"
          tooltip="中央"
          isActive={isColumnTwo}
          onClick={onColumnTwo}
        />
        <Toolbar.Button
          icon="layout-right-fill"
          ariaLabel="右サイド"
          tooltip="右サイド"
          isActive={isColumnRight}
          onClick={onColumnRight}
        />
      </Toolbar.Wrapper>
    </BaseBubbleMenu>
  );
});

ColumnsMenu.displayName = 'ColumnsMenu';
export default ColumnsMenu;
