import { useLocation } from 'react-router-dom';
import { useMemoContext } from '../contexts/MemoContext';
import { useProjectContext } from '../contexts/ProjectContext';
import { useGalleryContext } from '../contexts/GalleryContext';

const usePageContext = () => {
  const pathname = useLocation().pathname.split('/')[1];

  // ページごとのContextを切り替え
  switch (pathname) {
    case 'memo':
      return useMemoContext();
    case 'project':
      return useProjectContext();
    case 'gallery':
      return useGalleryContext();
    default:
      throw new Error('ページに該当するContextがありません');
  }
};

export default usePageContext;
