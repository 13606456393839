import React, { forwardRef, KeyboardEvent, useEffect, useState } from 'react';
import { UniqueIdentifier } from '@dnd-kit/core';
import classNames from 'classnames';
import styled from '@emotion/styled';
import { Accordion, AccordionDetails, AccordionSummaryProps, TextField } from '@mui/material';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import useSaveMenuRequest from '../../../../../hooks/useSaveMenuRequest';
import { Handle } from '../Action';
import EditOptions from '../Action/EditOptions';
import { SetFolderMap } from '../../../../../types';
import './Container.scss';

export interface ContainerProps {
  children: React.ReactNode;
  id: UniqueIdentifier;
  label: string;
  pathname: string;
  style?: React.CSSProperties;
  hover?: boolean;
  handleProps?: React.HTMLAttributes<HTMLButtonElement>;
  shadow?: boolean;
  onClick?(): void;
  onRemove(): void;
  setFolders: SetFolderMap;
}

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '11px' }} />}
    {...props}
  />
))`
  min-height: 0;
  padding: 6px;
  align-items: flex-start;
  flex-direction: row-reverse;
  border-radius: 4px;

  & .MuiAccordionSummary-expandIconWrapper {
    margin-top: 6px;
  }

  & .MuiAccordionSummary-expandIconWrapper.Mui-expanded {
    transform: rotate(90deg);
  }

  & .MuiAccordionSummary-content {
    margin: 0 0 0 4px;
    justify-content: space-between;
    align-items: flex-start;
  }
`;

export const Container = forwardRef<HTMLDivElement, ContainerProps>(
  (
    {
      children,
      id,
      handleProps,
      hover,
      onClick,
      onRemove,
      label,
      style,
      shadow,
      setFolders,
      pathname,
      ...props
    }: ContainerProps,
    ref
  ) => {
    const [open, setOpen] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [isError, setIsError] = useState(false);
    const [folderName, setFolderName] = useState(label);
    const [folderNameClone, setFolderNameClone] = useState(label);
    const saveRequest = useSaveMenuRequest();

    useEffect(() => {
      if (isEdit) {
        setFolderNameClone(folderName);
      } else {
        if (isError) {
          setFolderName(folderNameClone);
          return;
        }
        if (folderNameClone === folderName) return;
        saveRequest({
          apiUrl: process.env.REACT_APP_SAVE_MENU_API,
          tableType: pathname,
          id,
          action: 'edit',
          orderType: 'folder',
          data: folderName,
        })
          .then(() => {
            setFolders(prev => {
              const newMap = new Map(prev);
              newMap.set(id, folderName);
              return newMap;
            });
          })
          .catch(error => {
            console.error('フォルダ名の変更に失敗しました:', error);
          });
      }
    }, [isEdit]);

    // Enterキーを押したときに編集を確定する
    const handleKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
      if (e.key === 'Enter') {
        setIsEdit(false);
      }
    };

    return (
      <div
        {...props}
        ref={ref}
        style={
          {
            ...style,
            // ドラッグ中のCSS
            // transition: transform 200ms ease;
            // transform: translate(Xpx, Ypx) scale(1, 1);
            // opacity: 0.5;
          } as React.CSSProperties
        }
        className={classNames(
          'draggableMenuContainer',
          hover && 'hover',
          shadow && 'shadow',
          id === 'noCategory' && 'noCategory'
        )}
        onClick={onClick}
        tabIndex={-1} // キーボード操作でのフォーカスを無効化
      >
        {id !== 'noCategory' ? (
          <Accordion
            expanded={open}
            sx={{
              boxShadow: 'none',
              backgroundColor: 'transparent',
            }}
            disableGutters
          >
            <AccordionSummary
              className="draggableMenuContainerHeader"
              aria-controls={`${id}-content`}
              id={`${id}-header`}
              onClick={e => {
                const target = e.target as HTMLElement;
                const button = target.closest('#folderEdit-button');
                const menu = target.closest('#folderEdit-menu');
                if (button || menu) return;
                setOpen(prev => !prev);
              }}
            >
              {isEdit ? (
                <TextField
                  fullWidth
                  multiline
                  autoComplete="off"
                  hiddenLabel
                  onChange={e => {
                    setFolderName(e.target.value);
                    if (e.target.value.length < 100) {
                      setIsError(false);
                    } else {
                      setIsError(true);
                    }
                  }}
                  defaultValue={label}
                  onKeyDown={e => handleKeyDown(e)}
                  onBlur={() => setIsEdit(false)}
                  autoFocus
                  error={isError}
                  helperText={isError ? '100文字以内で入力してください' : ''}
                  sx={{
                    backgroundColor: 'transparent',
                    '& .MuiInputBase-root': {
                      padding: '4px',
                    },
                    '& .MuiInputBase-input': {
                      lineHeight: 1.35,
                      fontSize: '14px',
                    },
                    '.MuiFormHelperText-root.Mui-error': {
                      marginLeft: 0,
                    },
                  }}
                />
              ) : (
                <span
                  style={{
                    fontSize: '14px',
                    display: 'block',
                    marginTop: '2px',
                    marginRight: '2px',
                    lineHeight: 1.35,
                    flexGrow: 1,
                  }}
                >
                  {label}
                </span>
              )}
              {id !== 'noCategory' && (
                <div className="draggableMenuContainerActions">
                  <EditOptions setIsEdit={setIsEdit} onRemove={onRemove} />
                  <Handle {...handleProps} />
                </div>
              )}
            </AccordionSummary>
            <AccordionDetails sx={{ padding: '6px' }}>
              <ul>{children}</ul>
            </AccordionDetails>
          </Accordion>
        ) : (
          <ul>{children}</ul>
        )}
      </div>
    );
  }
);

Container.displayName = 'Container';
