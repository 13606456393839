import React, { useCallback, useEffect, useRef, useState } from 'react';
import { createPortal, unstable_batchedUpdates } from 'react-dom';
import { Box, Button } from '@mui/material';
import AddCircleSharpIcon from '@mui/icons-material/AddCircleSharp';
import {
  CancelDrop,
  closestCenter,
  pointerWithin,
  rectIntersection,
  CollisionDetection,
  DndContext,
  DragOverlay,
  DropAnimation,
  getFirstCollision,
  MouseSensor,
  TouchSensor,
  Modifiers,
  UniqueIdentifier,
  useSensors,
  useSensor,
  MeasuringStrategy,
  defaultDropAnimationSideEffects,
} from '@dnd-kit/core';
import {
  AnimateLayoutChanges,
  SortableContext,
  useSortable,
  arrayMove,
  defaultAnimateLayoutChanges,
  verticalListSortingStrategy,
  SortingStrategy,
  horizontalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { useTodoContext } from '../../../contexts/TodoContext';
import { useProjectContext } from '../../../contexts/ProjectContext';
import useSaveRequest from '../../../hooks/useSaveRequest';
import useDeleteRequest from '../../../hooks/useDeleteRequest';
import { Container, ContainerProps } from './components/Container';
import { Item } from './components/Item';
import { Section } from '../../../types';

const animateLayoutChanges: AnimateLayoutChanges = args =>
  defaultAnimateLayoutChanges({ ...args, wasDragging: true });

const DroppableContainer = ({
  children,
  disabled,
  id,
  items,
  style,
  setCurrentTodoId,
  setIsModalOpen,
  setIsSectionId,
  setButtonElement,
  ...props
}: ContainerProps & {
  disabled?: boolean;
  id: UniqueIdentifier;
  items: UniqueIdentifier[];
  style?: React.CSSProperties;
}) => {
  const { active, attributes, isDragging, listeners, over, setNodeRef, transition, transform } =
    useSortable({
      id,
      data: {
        type: 'container',
        children: items,
      },
      animateLayoutChanges,
    });
  const isOverContainer = over
    ? (id === over.id && active?.data.current?.type !== 'container') || items.includes(over.id)
    : false;

  return (
    <Container
      ref={disabled ? undefined : setNodeRef}
      id={id}
      style={{
        ...style,
        transition,
        transform: CSS.Translate.toString(transform),
        opacity: isDragging ? 0.5 : undefined,
      }}
      hover={isOverContainer}
      handleProps={{
        ...attributes,
        ...listeners,
      }}
      setCurrentTodoId={setCurrentTodoId}
      setIsModalOpen={setIsModalOpen}
      setIsSectionId={setIsSectionId}
      setButtonElement={setButtonElement}
      {...props}
    >
      {children}
    </Container>
  );
};

const useMountStatus = () => {
  const [isMounted, setIsMounted] = useState(false);
  useEffect(() => {
    const timeout = setTimeout(() => setIsMounted(true), 500);
    return () => clearTimeout(timeout);
  }, []);
  return isMounted;
};

interface StyleArgs {
  index: number;
  value: UniqueIdentifier;
  isDragging: boolean;
  isSorting: boolean;
  overIndex: number;
  containerId: UniqueIdentifier;
}
interface SortableItemProps<T> {
  containerId: UniqueIdentifier;
  id: UniqueIdentifier;
  index: number;
  dataObj: T | undefined;
  disabled?: boolean;
  style(args: StyleArgs): React.CSSProperties;
  getIndex(id: UniqueIdentifier): number;
  renderItem(): React.ReactElement;
  wrapperStyle({ index }: { index: number }): React.CSSProperties;
  setCurrentItemId: React.Dispatch<React.SetStateAction<number>>;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setButtonElement: (target: HTMLButtonElement | null) => void;
}

const SortableItem = <T,>({
  disabled,
  id,
  index,
  dataObj,
  renderItem,
  style,
  containerId,
  getIndex,
  wrapperStyle,
  setCurrentItemId,
  setIsModalOpen,
  setButtonElement,
}: SortableItemProps<T>) => {
  const { setNodeRef, listeners, isDragging, isSorting, over, overIndex, transform, transition } =
    useSortable({
      id,
    });
  const mounted = useMountStatus();
  const mountedWhileDragging = isDragging && !mounted;

  return (
    <Item
      ref={disabled ? undefined : setNodeRef}
      dataObj={dataObj}
      dragging={isDragging}
      sorting={isSorting}
      index={index}
      wrapperStyle={wrapperStyle({ index })}
      style={style({
        index,
        value: id,
        isDragging,
        isSorting,
        overIndex: over ? getIndex(over.id) : overIndex,
        containerId,
      })}
      transition={transition}
      transform={transform}
      fadeIn={mountedWhileDragging}
      listeners={listeners}
      renderItem={renderItem}
      setCurrentItemId={setCurrentItemId}
      setIsModalOpen={setIsModalOpen}
      setButtonElement={setButtonElement}
    />
  );
};

const dropAnimation: DropAnimation = {
  sideEffects: defaultDropAnimationSideEffects({
    styles: {
      active: {
        opacity: '0.5',
      },
    },
  }),
};

type Items = Record<UniqueIdentifier, UniqueIdentifier[]>;

interface KanbanBoardProps {
  adjustScale?: boolean;
  cancelDrop?: CancelDrop;
  containerStyle?: React.CSSProperties;
  getItemStyles?(args: {
    value: UniqueIdentifier;
    index: number;
    overIndex: number;
    isDragging: boolean;
    containerId: UniqueIdentifier;
    isSorting: boolean;
    isDragOverlay: boolean;
  }): React.CSSProperties;
  wrapperStyle?(args: { index: number }): React.CSSProperties;
  renderItem?: () => React.ReactElement;
  strategy?: SortingStrategy;
  modifiers?: Modifiers;
  postId: string | undefined;
  items: Items;
  sectionIds: UniqueIdentifier[];
  createTodoIdsBySection: () => Record<string, number[]>;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setCurrentItemId: React.Dispatch<React.SetStateAction<number>>;
  setIsSectionId: React.Dispatch<React.SetStateAction<string | null>>;
  setButtonElement: (target: HTMLButtonElement | null) => void;
}

export const KanbanBoard = ({
  adjustScale = false,
  cancelDrop,
  containerStyle,
  getItemStyles = () => ({}),
  wrapperStyle = () => ({}),
  modifiers,
  renderItem,
  strategy = verticalListSortingStrategy,
  postId,
  sectionIds,
  items: todoIdsBySection,
  createTodoIdsBySection,
  setIsModalOpen,
  setCurrentItemId,
  setIsSectionId,
  setButtonElement,
}: KanbanBoardProps) => {
  const { todoData, setTodoData } = useTodoContext();
  const {
    sectionData,
    setSectionData,
    setSectionOrder,
    todoOrder,
    setTodoOrder,
    isOrderChange,
    setIsOrderChange,
  } = useProjectContext();

  const [containers, setContainers] = useState(sectionIds);
  const [items, setItems] = useState<Items>(todoIdsBySection);

  useEffect(() => {
    console.log('KanbanBoard.tsx:初回レンダリング');
  }, []);

  console.log('KanbanBoard.tsx:レンダリング');

  // Todoデータの更新に伴いtodoOrderが更新された場合に、itemsを更新する
  useEffect(() => {
    if (isOrderChange) {
      const newTodoIdsBySection = createTodoIdsBySection();
      setItems(newTodoIdsBySection);
      setIsOrderChange(false);
    }
  }, [todoOrder]);

  const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null);
  const lastOverId = useRef<UniqueIdentifier | null>(null);
  const recentlyMovedToNewContainer = useRef(false);
  const isSortingContainer = activeId ? containers.includes(activeId) : false;
  const [clonedItems, setClonedItems] = useState<Items | null>(null);
  // キーボード操作は利用しない
  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 10,
      },
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 250,
        tolerance: 5,
      },
    })
  );
  const saveRequest = useSaveRequest();
  const deleteRequest = useDeleteRequest();

  /**
   * Custom collision detection strategy optimized for multiple containers
   *
   * - First, find any droppable containers intersecting with the pointer.
   * - If there are none, find intersecting containers with the active draggable.
   * - If there are no intersecting containers, return the last matched intersection
   *
   */
  const collisionDetectionStrategy: CollisionDetection = useCallback(
    args => {
      if (activeId && activeId in items) {
        return closestCenter({
          ...args,
          droppableContainers: args.droppableContainers.filter(container => container.id in items),
        });
      }

      // Start by finding any intersecting droppable
      const pointerIntersections = pointerWithin(args);
      const intersections =
        pointerIntersections.length > 0
          ? // If there are droppables intersecting with the pointer, return those
            pointerIntersections
          : rectIntersection(args);
      let overId = getFirstCollision(intersections, 'id');

      if (overId != null) {
        if (overId in items) {
          const containerItems = items[overId];

          // If a container is matched and it contains items (columns 'A', 'B', 'C')
          if (containerItems.length > 0) {
            // Return the closest droppable within that container
            overId = closestCenter({
              ...args,
              droppableContainers: args.droppableContainers.filter(
                container => container.id !== overId && containerItems.includes(container.id)
              ),
            })[0]?.id;
          }
        }

        lastOverId.current = overId;

        return [{ id: overId }];
      }

      // When a draggable item moves to a new container, the layout may shift
      // and the `overId` may become `null`. We manually set the cached `lastOverId`
      // to the id of the draggable item that was moved to the new container, otherwise
      // the previous `overId` will be returned which can cause items to incorrectly shift positions
      if (recentlyMovedToNewContainer.current) {
        lastOverId.current = activeId;
      }

      // If no droppable is matched, return the last match
      return lastOverId.current ? [{ id: lastOverId.current }] : [];
    },
    [activeId, items]
  );

  const findContainer = (id: UniqueIdentifier) => {
    if (id in items) {
      return id;
    }
    return Object.keys(items).find(key => items[key].includes(id));
  };

  const getIndex = (id: UniqueIdentifier) => {
    const container = findContainer(id);
    if (!container) {
      return -1;
    }
    const index = items[container].indexOf(id);
    return index;
  };

  const onDragCancel = () => {
    if (clonedItems) {
      // Reset items to their original state in case items have been
      // Dragged across containers
      setItems(clonedItems);
    }
    setActiveId(null);
    setClonedItems(null);
  };

  useEffect(() => {
    requestAnimationFrame(() => {
      recentlyMovedToNewContainer.current = false;
    });
  }, [items]);

  const sectionUpdate = useCallback((ids: UniqueIdentifier[]) => {
    saveRequest({
      apiUrl: process.env.REACT_APP_SECTION_API,
      target: 'sectionOrder',
      data: ids,
    }).catch(error => {
      console.error('Sectionの並びの更新に失敗しました:', error);
    });
  }, []);

  const itemUpdate = useCallback((ids: UniqueIdentifier[]) => {
    saveRequest({
      apiUrl: process.env.REACT_APP_SECTION_API,
      target: 'todoOrder',
      data: ids,
    }).catch(error => {
      console.error('Itemの並びの更新に失敗しました:', error);
    });
  }, []);

  const todoDataUpdate = useCallback((id: number, sectionId: UniqueIdentifier | null) => {
    saveRequest({
      apiUrl: process.env.REACT_APP_TODO_API,
      id: String(id),
      target: 'sectionId',
      data: sectionId as string | null,
    }).catch(error => {
      console.error('Todoの更新に失敗しました:', error);
    });
  }, []);

  const itemUpdateOverSection = useCallback(
    (activeIds: UniqueIdentifier[], overIds: UniqueIdentifier[]) => {
      saveRequest({
        apiUrl: process.env.REACT_APP_SECTION_API,
        target: 'todoOrders',
        data: {
          activeIds,
          overIds,
        },
      }).catch(error => {
        console.error('Itemの並びの更新に失敗しました:', error);
      });
    },
    []
  );

  const handleRemove = useCallback(
    (containerID: UniqueIdentifier) => {
      const itemsArray = items[containerID];
      if (itemsArray.length > 0) {
        alert('セクションにアイテムが含まれているため削除できません');
        return;
      }
      deleteRequest({
        apiUrl: process.env.REACT_APP_SECTION_API,
        id: containerID as string,
      })
        .then(() => {
          unstable_batchedUpdates(() => {
            setSectionData(prev => {
              const newMap = new Map(prev);
              newMap.delete(containerID as string);
              return newMap;
            });
            setSectionOrder(prev => {
              const newMap = new Map(prev);
              newMap.delete(containerID as string);
              return newMap;
            });
            setContainers(prev => prev.filter(id => id !== containerID));
            setItems(prev => {
              const newItems = Object.fromEntries(
                Object.entries(prev).filter(([key]) => key !== containerID)
              );
              return newItems;
            });
          });
        })
        .catch(error => {
          console.error('Sectionの削除に失敗しました:', error);
        });
    },
    [items]
  );

  // 新規セクションのIDを生成
  const generateNewSecId = useCallback((sectionData: Map<string, Section> | null): string => {
    if (!sectionData) {
      throw new Error('sectionData が null です');
    }
    // Map のキーを配列として取得
    const keys = Array.from(sectionData.keys());
    // sec_○○ の最大値を取得
    const maxId = keys
      .map(key => {
        // "sec_" を取り除いて数値に変換
        const match = key.match(/^sec_(\d+)$/);
        return match ? parseInt(match[1], 10) : 0; // 数値に変換できない場合は 0 を返す
      })
      .reduce((max, current) => Math.max(max, current), 0);
    return `sec_${maxId + 1}`;
  }, []);

  const handleAddColumn = useCallback(() => {
    const newSectionId = generateNewSecId(sectionData);
    saveRequest({
      apiUrl: process.env.REACT_APP_SECTION_API,
      id: newSectionId,
      data: {
        projectId: Number(postId),
        name: '新規セクション',
        sort: containers.length,
      },
    })
      .then(() => {
        unstable_batchedUpdates(() => {
          setSectionData(prev => {
            const newMap = new Map(prev);
            newMap.set(newSectionId, {
              id: newSectionId,
              projectId: Number(postId),
              name: '新規セクション',
            });
            return newMap;
          });
          setSectionOrder(prev => {
            const newMap = new Map(prev);
            newMap.set(newSectionId, {
              sectionId: newSectionId,
              sort: containers.length,
            });
            return newMap;
          });
          setContainers(prev => {
            const newContainers = prev.filter(section => section !== 'sec_0');
            return [...newContainers, newSectionId, 'sec_0'];
          });
          setItems(items => ({
            ...items,
            [newSectionId]: [],
          }));
        });
      })
      .catch(error => {
        console.error('Sectionの追加に失敗しました:', error);
      });
  }, []);

  const renderSortableItemDragOverlay = (id: UniqueIdentifier) => (
    <Item
      dataObj={todoData?.get(Number(id))}
      style={getItemStyles({
        containerId: findContainer(id) as UniqueIdentifier,
        overIndex: -1,
        index: getIndex(id),
        value: id,
        isSorting: true,
        isDragging: true,
        isDragOverlay: true,
      })}
      wrapperStyle={wrapperStyle({ index: 0 })}
      renderItem={renderItem}
      dragOverlay
      setCurrentItemId={setCurrentItemId}
      setIsModalOpen={setIsModalOpen}
      setButtonElement={setButtonElement}
    />
  );

  const renderContainerDragOverlay = (containerId: UniqueIdentifier) => (
    <Container
      id={containerId as string}
      label={`${sectionData?.get(containerId as string)?.name || '未分類'}`}
      style={{
        height: '100%',
      }}
      shadow
      setCurrentTodoId={setCurrentItemId}
      setIsModalOpen={setIsModalOpen}
      setIsSectionId={setIsSectionId}
      setButtonElement={setButtonElement}
    >
      {items[containerId].map((item, index) => (
        <Item
          key={item}
          dataObj={todoData?.get(Number(item))}
          style={getItemStyles({
            containerId,
            overIndex: -1,
            index: getIndex(item),
            value: item,
            isDragging: false,
            isSorting: false,
            isDragOverlay: false,
          })}
          wrapperStyle={wrapperStyle({ index })}
          renderItem={renderItem}
          setCurrentItemId={setCurrentItemId}
          setIsModalOpen={setIsModalOpen}
          setButtonElement={setButtonElement}
        />
      ))}
    </Container>
  );

  return (
    <>
      {Object.keys(items).length > 0 && (
        <DndContext
          sensors={sensors}
          collisionDetection={collisionDetectionStrategy}
          measuring={{
            droppable: {
              strategy: MeasuringStrategy.Always,
            },
          }}
          onDragStart={({ active }) => {
            setActiveId(active.id);
            setClonedItems(items);
          }}
          onDragOver={({ active, over }) => {
            const overId = over?.id;
            if (overId == null || active.id in items) {
              return;
            }
            const overContainer = findContainer(overId);
            const activeContainer = findContainer(active.id);
            if (!overContainer || !activeContainer) {
              return;
            }
            // 別コンテナから別コンテナにアイテムを移動する場合
            if (activeContainer !== overContainer) {
              setItems(items => {
                const activeItems = items[activeContainer];
                const overItems = items[overContainer];
                const overIndex = overItems.indexOf(overId);
                const activeIndex = activeItems.indexOf(active.id);
                let newIndex: number;
                if (overId in items) {
                  newIndex = overItems.length + 1;
                } else {
                  const isBelowOverItem =
                    over &&
                    active.rect.current.translated &&
                    active.rect.current.translated.top > over.rect.top + over.rect.height;
                  const modifier = isBelowOverItem ? 1 : 0;
                  newIndex = overIndex >= 0 ? overIndex + modifier : overItems.length + 1;
                }
                recentlyMovedToNewContainer.current = true;
                const activeContainerItems = items[activeContainer].filter(
                  item => item !== active.id
                );
                const overContainerItems = [
                  ...items[overContainer].slice(0, newIndex),
                  items[activeContainer][activeIndex],
                  ...items[overContainer].slice(newIndex, items[overContainer].length),
                ];
                // todoDataの更新
                setTodoData(prev => {
                  const newMap = new Map(prev);
                  const todo = newMap.get(Number(active.id));
                  if (todo) {
                    newMap.set(Number(active.id), {
                      ...todo,
                      sectionId: overContainer === 'sec_0' ? null : (overContainer as string),
                    });
                  }
                  todoDataUpdate(
                    Number(active.id),
                    overContainer === 'sec_0' ? null : overContainer
                  );
                  return newMap;
                });
                // todoOrderの更新
                setTodoOrder(prev => {
                  const newMap = new Map(prev);
                  activeContainerItems.forEach((id, index) => {
                    const todoOrder = newMap.get(Number(id));
                    if (todoOrder) {
                      newMap.set(Number(id), {
                        ...todoOrder,
                        sort: index,
                      });
                    }
                  });
                  overContainerItems.forEach((id, index) => {
                    const todoOrder = newMap.get(Number(id));
                    if (todoOrder) {
                      newMap.set(Number(id), {
                        ...todoOrder,
                        sort: index,
                      });
                    }
                  });
                  itemUpdateOverSection(activeContainerItems, overContainerItems);
                  return newMap;
                });
                return {
                  ...items,
                  [activeContainer]: activeContainerItems,
                  [overContainer]: overContainerItems,
                };
              });
            }
          }}
          onDragEnd={({ active, over }) => {
            if (active.id in items && over?.id) {
              if (over.id === 'sec_0' || active.id === over.id) {
                return;
              }
              // セクションの並び替え
              setContainers(containers => {
                const activeIndex = containers.indexOf(active.id);
                const overIndex = containers.indexOf(over.id);
                const movedContainers = arrayMove(containers, activeIndex, overIndex);
                setSectionOrder(prev => {
                  const newMap = new Map(prev);
                  movedContainers.forEach((id, index) => {
                    const section = newMap.get(id as string);
                    if (section) {
                      newMap.set(String(id), {
                        ...section,
                        sort: index,
                      });
                    }
                  });
                  sectionUpdate(movedContainers);
                  return newMap;
                });
                return movedContainers;
              });
            }

            const activeContainer = findContainer(active.id);
            if (!activeContainer) {
              setActiveId(null);
              return;
            }
            const overId = over?.id;
            if (overId == null) {
              setActiveId(null);
              return;
            }
            const overContainer = findContainer(overId);
            if (overContainer) {
              const activeIndex = items[activeContainer].indexOf(active.id);
              const overIndex = items[overContainer].indexOf(overId);
              if (activeIndex !== overIndex) {
                // 同じセクション内でアイテムの並び替え
                setItems(items => {
                  const movedItems = arrayMove(items[overContainer], activeIndex, overIndex);
                  setTodoOrder(prev => {
                    const newMap = new Map(prev);
                    movedItems.forEach((id, index) => {
                      newMap.set(Number(id), {
                        todoId: Number(id),
                        sort: index,
                      });
                    });
                    return newMap;
                  });
                  itemUpdate(movedItems);
                  return {
                    ...items,
                    [overContainer]: movedItems,
                  };
                });
              }
            }
            setActiveId(null);
          }}
          cancelDrop={cancelDrop}
          onDragCancel={onDragCancel}
          modifiers={modifiers}
        >
          <Box
            display={'grid'}
            gap={1}
            sx={{
              gridTemplateColumns: {
                sx: `repeat(1, 1fr)`,
                sm: `repeat(2, 1fr)`,
                md: `repeat(3, 1fr)`,
              },
            }}
          >
            <SortableContext items={containers} strategy={horizontalListSortingStrategy}>
              {containers.map(containerId => (
                <DroppableContainer
                  key={containerId}
                  id={containerId as string}
                  label={`${sectionData?.get(containerId as string)?.name || '未分類'}`}
                  items={items[containerId]}
                  style={containerStyle}
                  onRemove={() => handleRemove(containerId)}
                  setCurrentTodoId={setCurrentItemId}
                  setIsModalOpen={setIsModalOpen}
                  setIsSectionId={setIsSectionId}
                  setButtonElement={setButtonElement}
                >
                  <SortableContext items={items[containerId]} strategy={strategy}>
                    {items[containerId].map((value, index) => (
                      <SortableItem
                        key={value}
                        id={value}
                        index={index}
                        dataObj={todoData?.get(Number(value))}
                        disabled={isSortingContainer}
                        style={getItemStyles}
                        wrapperStyle={wrapperStyle}
                        renderItem={renderItem as () => React.ReactElement}
                        containerId={containerId}
                        getIndex={getIndex}
                        setCurrentItemId={setCurrentItemId}
                        setIsModalOpen={setIsModalOpen}
                        setButtonElement={setButtonElement}
                      />
                    ))}
                  </SortableContext>
                </DroppableContainer>
              ))}
            </SortableContext>
            <Button
              fullWidth
              startIcon={<AddCircleSharpIcon color="primary" />}
              onClick={handleAddColumn}
              variant="outlined"
              sx={{ textTransform: 'none', letterSpacing: 0.5, height: 'fit-content' }}
            >
              セクションを追加
            </Button>
          </Box>
          {createPortal(
            <DragOverlay adjustScale={adjustScale} dropAnimation={dropAnimation}>
              {activeId
                ? containers.includes(activeId)
                  ? renderContainerDragOverlay(activeId)
                  : renderSortableItemDragOverlay(activeId)
                : null}
            </DragOverlay>,
            document.body
          )}
        </DndContext>
      )}
    </>
  );
};
