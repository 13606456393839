import React, { memo, useCallback, useState } from 'react';
import { TextField } from '@mui/material';
import { debounce } from 'lodash';
import useSaveRequest from '../../hooks/useSaveRequest';
import { fontSerif } from '../../styles/commonStyles';
import { SetMenuItemMap } from '../../types';

interface PageTitleProps {
  title: string;
  postId: number;
  apiUrl: string;
  setData: SetMenuItemMap;
  marginBottom?: string;
}

const PageTitle = ({ title, postId, apiUrl, setData, marginBottom = '2rem' }: PageTitleProps) => {
  const [isError, setIsError] = useState(false);
  const saveRequest = useSaveRequest();

  const debouncedSaveRequest = useCallback(
    debounce((data: string) => {
      saveRequest({
        apiUrl,
        id: String(postId),
        target: 'title',
        data,
      })
        .then(() => {
          setData(prev => {
            const newMap = new Map(prev);
            newMap.set(postId, data);
            return newMap;
          });
        })
        .catch(error => {
          console.error('ページタイトルの更新に失敗しました:', error);
        });
    }, 1000),
    [postId, saveRequest]
  );

  // titleの更新
  const titleUpdate = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (e.target.value.length < 100) {
        debouncedSaveRequest(e.target.value);
        setIsError(false);
      } else {
        setIsError(true);
      }
    },
    [debouncedSaveRequest]
  );

  return (
    <TextField
      fullWidth
      multiline
      hiddenLabel
      spellCheck="false"
      autoComplete="off"
      variant="outlined"
      name="title"
      id="title"
      defaultValue={title}
      error={isError}
      helperText={isError ? '100文字以内で入力してください' : ''}
      onChange={e => titleUpdate(e)}
      sx={{
        backgroundColor: 'transparent',
        marginBottom,
        '& .MuiInputBase-root': {
          padding: 0,
          backgroundColor: 'transparent',
        },
        '& .MuiInputBase-input': {
          ...fontSerif,
          fontWeight: 700,
          lineHeight: 1.35,
          fontSize: 'clamp(1.85rem, 7vw, 2.5rem)',
        },
        '& .MuiOutlinedInput-notchedOutline': {
          border: 'none !important',
        },
        '.MuiFormHelperText-root.Mui-error': {
          marginLeft: 0,
        },
      }}
    />
  );
};

export default memo(PageTitle);
