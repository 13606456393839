import { useCallback, useState, memo, useMemo } from 'react';
import { HexColorPicker } from 'react-colorful';
import { Toolbar } from '../../../ui/Toolbar';

const themeColors = ['#fb7185', '#fdba74', '#d9f99d', '#a7f3d0', '#a5f3fc', '#a5b4fc', '#f3f3f3'];

interface ColorButtonProps {
  color: string;
  active: boolean;
  onColorChange?: (color: string) => void;
}

const ColorButton = memo(({ color, active, onColorChange }: ColorButtonProps) => {
  // 選択した色から透明度を変更した色を作成
  const convertHexToRgba = useCallback((hex: string, alpha = 1) => {
    // Hexが3桁の場合は6桁に変換
    const normalizedHex =
      hex.length === 4 ? `#${hex[1]}${hex[1]}${hex[2]}${hex[2]}${hex[3]}${hex[3]}` : hex;
    // 正しい6桁形式かチェックして色を分解
    const match = normalizedHex.match(/^#([0-9a-f]{2})([0-9a-f]{2})([0-9a-f]{2})$/i);
    if (!match) {
      throw new Error(`Invalid hex color format: ${hex}`);
    }
    const [, r, g, b] = match.map(value => parseInt(value, 16)); // 16進数から10進数に変換
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  }, []);

  // outlineColorを計算してメモ化
  const outlineColor = useMemo(() => {
    if (!active) return undefined;
    return convertHexToRgba(String(color), 0.5);
  }, [color, active, convertHexToRgba]);

  const handleClick = useCallback(() => {
    if (onColorChange) {
      onColorChange(color || '');
    }
  }, [onColorChange, color]);

  return (
    <button
      aria-label={color}
      onClick={handleClick}
      style={{
        cursor: 'pointer',
        borderRadius: '3px',
        width: '1.25rem',
        height: '1.25rem',
        border: 'none',
        backgroundColor: color,
        color,
        ...(active && { outline: `2px solid ${outlineColor}` }),
      }}
    />
  );
});

ColorButton.displayName = 'ColorButton';

interface ColorPickerProps {
  color?: string;
  onChange?: (color: string) => void;
  onClear?: () => void;
}

const ColorPicker = ({ color, onChange, onClear }: ColorPickerProps) => {
  const [colorInputValue, setColorInputValue] = useState(color || '');

  const handleColorUpdate = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setColorInputValue(event.target.value);
  }, []);

  const handleColorChange = useCallback(() => {
    const isCorrectColor = /^#([0-9A-F]{3}){1,2}$/i.test(colorInputValue);
    if (!isCorrectColor) {
      if (onChange) {
        onChange('');
      }
      return;
    }

    if (onChange) {
      onChange(colorInputValue);
    }
  }, [colorInputValue, onChange]);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '0.5rem',
      }}
    >
      <HexColorPicker
        style={{
          width: '100%',
        }}
        color={color || ''}
        onChange={onChange}
      />
      <input
        type="text"
        aria-label="カラーコード"
        placeholder="#000000"
        value={colorInputValue}
        onChange={handleColorUpdate}
        onBlur={handleColorChange}
      />
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '0.25rem',
          flexWrap: 'wrap',
        }}
      >
        {themeColors.map(currentColor => (
          <ColorButton
            key={currentColor}
            active={currentColor === color}
            color={currentColor}
            onColorChange={onChange}
          />
        ))}
        <Toolbar.Button
          icon="arrow-go-back-line"
          iconSize="1rem"
          ariaLabel="リセット"
          tooltip="リセット"
          isActive={false}
          onClick={onClear || (() => {})}
        ></Toolbar.Button>
      </div>
    </div>
  );
};

export default memo(ColorPicker);
