import React, { forwardRef, CSSProperties } from 'react';
import classNames from 'classnames';
import './Action.scss';

export interface ActionProps extends React.HTMLAttributes<HTMLButtonElement> {
  active?: {
    fill: string;
    background: string;
  };
  cursor?: CSSProperties['cursor'];
  focus?: boolean;
}

export const Action = forwardRef<HTMLButtonElement, ActionProps>(
  ({ active, className, cursor, focus = true, style, ...props }, ref) => (
    <button
      ref={ref}
      {...props}
      className={classNames('action', className)}
      tabIndex={focus ? 0 : -1}
      style={
        {
          ...style,
          cursor,
          '--fill': active?.fill,
          '--background': active?.background,
        } as CSSProperties
      }
    />
  )
);

Action.displayName = 'Action';
