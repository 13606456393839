import { memo } from 'react';
import { Divider } from '@mui/material';
import { Toolbar } from '../../../ui/Toolbar';

interface LinkPreviewPanelProps {
  url: string;
  initialOpenInNewTab: boolean;
  onEdit: () => void;
  onClear: () => void;
}

const LinkPreviewPanel = ({ onClear, onEdit, url, initialOpenInNewTab }: LinkPreviewPanelProps) => (
  <Toolbar.Wrapper>
    <a
      href={url}
      {...(initialOpenInNewTab ? { target: '_blank', rel: 'noopener noreferrer' } : {})}
      style={{
        fontSize: '0.875rem',
        textDecoration: 'underline',
        wordBreak: 'break-all',
        fontWeight: 700,
        padding: '7px',
      }}
    >
      {url}
    </a>
    <Divider orientation="vertical" flexItem />
    <Toolbar.Button
      icon="pencil-line"
      tooltip="編集"
      ariaLabel="リンクの編集"
      onClick={onEdit}
      isActive={false}
    />
    <Toolbar.Button
      icon="delete-bin-line"
      tooltip="削除"
      ariaLabel="リンクの削除"
      onClick={onClear}
      isActive={false}
    />
  </Toolbar.Wrapper>
);

LinkPreviewPanel.displayName = 'LinkPreviewPanel';

export default memo(LinkPreviewPanel);
