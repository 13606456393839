import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { debounce } from 'lodash';
import { endOfYear, format, startOfYear } from 'date-fns';
import { GridRowsProp } from '@mui/x-data-grid';
import { Box, Paper, Stack, useMediaQuery } from '@mui/material';
import MainContainer from '../components/ui/MainContainer';
import MonthSelector from '../components/common/MonthSelector';
import {
  Health as HealthDataType,
  HealthCategory,
  HealthItem,
  Food,
  ResponseFoodDB,
  FoodDB,
  Fitness,
} from '../types';
import useGetRequest from '../hooks/useGetRequest';
import Table from '../components/common/Table';
import { createMapByKey, createMapByKeyAndValue, createNumberIdMap } from '../utils/createMaps';
import Modal from '../components/ui/Modal';
import HealthForm from '../components/common/HealthForm';
import SectionTitle from '../components/ui/SectionTitle';
import FoodForm from '../components/common/FoodForm';
import DataTable from '../components/common/DataTable';
import HealthSummary from '../components/Health/HealthSummary';
import FitnessSummary from '../components/Health/FitnessSummary';
import FoodSummary from '../components/Health/FoodSummary';
import { theme } from '../theme';

interface HealthResponse {
  health: HealthDataType[];
  item: HealthItem[];
}

type HasDate = {
  date: string;
};

const Health = () => {
  useEffect(() => {
    console.log('Healthページ:初回レンダリング');
  }, []);
  console.log('Healthページ:レンダリング');

  const [currentMonth, setCurrentMonth] = useState<Date>(new Date());
  const [currentYear, setCurrentYear] = useState<number>(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formType, setFormType] = useState<string>('health');
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const getRequest = useGetRequest();
  // Healthデータ
  const [healthData, setHealthData] = useState<HealthDataType[] | null>(null);
  const [healthCategory, setHealthCategory] = useState<HealthCategory[] | null>(null);
  const [healthItem, setHealthItem] = useState<HealthItem[] | null>(null);
  const [healthGridRows, setHealthGridRows] = useState<GridRowsProp | null>(null);
  const [monthlyItem, setMonthlyItem] = useState<string[] | null>(null);
  const [currentHealthId, setCurrentHealthId] = useState<number>(0);
  const [isHealthDataFetch, setIsHealthDataFetch] = useState(false);
  // foodデータ
  const [foodData, setFoodData] = useState<Food[] | null>(null);
  const [foodDB, setFoodDB] = useState<Map<string, FoodDB> | null>(null);
  const [foodGridRows, setFoodGridRows] = useState<GridRowsProp | null>(null);
  const [currentFoodId, setCurrentFoodId] = useState<number>(0);
  const [isFoodDataFetch, setIsFoodDataFetch] = useState(false);
  // Fitnessデータ
  const [fitnessData, setFitnessData] = useState<Fitness[] | null>(null);
  const [fitnessGridRows, setFitnessGridRows] = useState<GridRowsProp | null>(null);
  const [isFitnessDataFetch, setIsFitnessDataFetch] = useState(false);
  // summaryの高さを揃えるための要素
  const boxRef = useRef<HTMLDivElement>(null);
  const [targetHeight, setTargetHeight] = useState<number | null>(null);
  // 高さを更新
  const updateHeight = useCallback(() => {
    if (boxRef.current) {
      setTargetHeight(boxRef.current.offsetHeight);
    }
  }, []);
  // デバウンスした高さ更新関数
  const debouncedUpdateHeight = useMemo(() => debounce(updateHeight, 300), [updateHeight]);

  useEffect(() => {
    // 初期高さ取得
    updateHeight();
    // DOM変更を監視
    const observer = new MutationObserver(debouncedUpdateHeight);
    if (boxRef.current) {
      observer.observe(boxRef.current, {
        childList: true,
        subtree: true,
        attributes: true,
      });
    }
    // リサイズ対応
    window.addEventListener('resize', debouncedUpdateHeight);
    return () => {
      // クリーンアップ
      observer.disconnect();
      window.removeEventListener('resize', debouncedUpdateHeight);
      debouncedUpdateHeight.cancel(); // デバウンスをキャンセル
    };
  }, [debouncedUpdateHeight]);

  // 選択月のデータのみにフィルタリング
  const filterDataByMonth = <T extends HasDate>(data: T[], month: Date): T[] =>
    data.filter(item => {
      const date = new Date(item.date); // item.date は string であることが保証される
      return date.getFullYear() === month.getFullYear() && date.getMonth() === month.getMonth();
    });

  // gridRows用のデータを作成
  const formatToGridRows = (
    data: HealthDataType[],
    items: HealthItem[],
    categories: HealthCategory[]
  ): { rows: GridRowsProp; itemArray: string[] } => {
    // id をキーにしてカテゴリーをMap化
    const categoryMap = createMapByKeyAndValue(categories, 'id', 'name');

    // healthId ごとにアイテムをグループ化
    const itemMap = new Map<number, string[]>();
    items.forEach(item => {
      const categoryName = categoryMap.get(item.categoryId) || '';
      if (!itemMap.has(item.healthId)) {
        itemMap.set(item.healthId, []);
      }
      itemMap.get(item.healthId)?.push(categoryName);
    });

    let itemStrings = '';
    const rows = data.map(entry => {
      const itemsForHealthId = itemMap.get(entry.id) || [];
      const combinedItems = itemsForHealthId.join(', ');
      const itemString =
        entry.other !== ''
          ? combinedItems.length !== 0
            ? `${combinedItems}, ${entry.other}`
            : entry.other
          : combinedItems;
      itemStrings += itemStrings.length === 0 ? itemString : `, ${itemString}`;
      return {
        ...entry,
        date: new Date(entry.date),
        item: itemString, // 文字検索で使用するため、あえて文字列で保持
      };
    });
    const itemArray = itemStrings.length > 0 ? Array.from(new Set(itemStrings.split(', '))) : [];
    return { rows, itemArray };
  };

  // データの取得 - Health
  useEffect(() => {
    setIsHealthDataFetch(false);
    if (currentMonth.getFullYear() === currentYear) {
      // gridRowsのみ更新
      if (healthData && healthItem && healthCategory) {
        const monthlyData = filterDataByMonth(healthData, currentMonth);
        const { rows, itemArray } = formatToGridRows(monthlyData, healthItem, healthCategory);
        setHealthGridRows(rows);
        setMonthlyItem(itemArray);
      }
    } else {
      // 1年分のデータを新規取得
      setHealthData(null);
      setHealthItem(null);
      setCurrentYear(currentMonth.getFullYear());
      const startDate = startOfYear(currentMonth);
      const endDate = endOfYear(currentMonth);
      getRequest<HealthResponse>({
        apiUrl: process.env.REACT_APP_HEALTH_API,
        data: {
          start: format(startDate, 'yyyy-MM-dd'),
          end: format(endDate, 'yyyy-MM-dd'),
        },
      })
        .then(response => {
          if (response.content) {
            setHealthData(response.content.health);
            setHealthItem(response.content.item);
          }
        })
        .catch(error => {
          console.error('Healthデータの取得に失敗しました:', error);
        });
    }
  }, [currentMonth]);

  // データの取得 - HealthCategory
  useEffect(() => {
    if (healthCategory) return;
    getRequest<HealthCategory[]>({
      apiUrl: process.env.REACT_APP_HEALTH_API,
      target: 'category',
    })
      .then(response => {
        if (response.content) {
          setHealthCategory(response.content);
        }
      })
      .catch(error => {
        console.error('HealthCategoryの取得に失敗しました:', error);
      });
  }, []);

  useEffect(() => {
    if (healthData && healthItem && healthCategory) {
      const monthlyData = filterDataByMonth(healthData, currentMonth);
      const { rows, itemArray } = formatToGridRows(monthlyData, healthItem, healthCategory);
      setHealthGridRows(rows);
      setMonthlyItem(itemArray);
    } else {
      setHealthGridRows(null);
      setMonthlyItem(null);
    }
  }, [healthData, healthItem, healthCategory]);

  useEffect(() => {
    if (healthGridRows) setIsHealthDataFetch(true);
  }, [healthGridRows]); // healthGridRowsが更新された場合に発火

  // データの取得 - Food
  useEffect(() => {
    setIsFoodDataFetch(false);
    if (currentMonth.getFullYear() === currentYear) {
      // gridRowsのみ更新
      if (foodData) {
        const monthlyData = filterDataByMonth(foodData, currentMonth);
        const rows = monthlyData.map(entry => ({
          ...entry,
          date: new Date(entry.date),
        }));
        setFoodGridRows(rows);
      }
    } else {
      // 1年分のデータを新規取得
      setFoodData(null);
      setFoodGridRows(null);
      setCurrentYear(currentMonth.getFullYear());
      const startDate = startOfYear(currentMonth);
      const endDate = endOfYear(currentMonth);
      getRequest<Food[]>({
        apiUrl: process.env.REACT_APP_FOOD_API,
        data: {
          start: format(startDate, 'yyyy-MM-dd'),
          end: format(endDate, 'yyyy-MM-dd'),
        },
      })
        .then(response => {
          if (response.content) {
            setFoodData(response.content);
          }
        })
        .catch(error => {
          console.error('Foodデータの取得に失敗しました:', error);
        });
    }
  }, [currentMonth]);

  useEffect(() => {
    if (foodData) {
      const monthlyData = filterDataByMonth(foodData, currentMonth);
      const rows = monthlyData.map(entry => ({
        ...entry,
        date: new Date(entry.date),
      }));
      setFoodGridRows(rows);
    } else {
      setFoodGridRows(null);
    }
  }, [foodData]);

  useEffect(() => {
    if (foodGridRows) setIsFoodDataFetch(true);
  }, [foodGridRows]); // foodGridRowsが更新された場合に発火

  // データの取得 - foodDB
  useEffect(() => {
    if (foodDB) return;
    getRequest<ResponseFoodDB[]>({
      apiUrl: process.env.REACT_APP_FOOD_API,
      target: 'DB',
    })
      .then(response => {
        if (response.content) {
          const DB = response.content.map(item => ({
            ...item,
            perItem: item.perItem !== 0, // perItem が 0 の場合は false に変換
          }));
          const DBMap = createMapByKey(DB, 'name');
          setFoodDB(DBMap);
        }
      })
      .catch(error => {
        console.error('foodDBの取得に失敗しました:', error);
      });
  }, []);

  // データの取得 - Fitness
  useEffect(() => {
    setIsFitnessDataFetch(false);
    if (currentMonth.getFullYear() === currentYear) {
      // gridRowsのみ更新
      if (fitnessData) {
        const monthlyData = filterDataByMonth(fitnessData, currentMonth);
        const rows = monthlyData.map(entry => ({
          ...entry,
          date: new Date(entry.date),
        }));
        setFitnessGridRows(rows);
      }
    } else {
      // 1年分のデータを新規取得
      setFitnessData(null);
      setFitnessGridRows(null);
      setCurrentYear(currentMonth.getFullYear());
      const startDate = startOfYear(currentMonth);
      const endDate = endOfYear(currentMonth);
      getRequest<Fitness[]>({
        apiUrl: process.env.REACT_APP_FITNESS_API,
        data: {
          start: format(startDate, 'yyyy-MM-dd'),
          end: format(endDate, 'yyyy-MM-dd'),
        },
      })
        .then(response => {
          if (response.content) {
            setFitnessData(response.content);
            const monthlyData = filterDataByMonth(response.content, currentMonth);
            const rows = monthlyData.map(entry => ({
              ...entry,
              date: new Date(entry.date),
            }));
            setFitnessGridRows(rows);
          }
        })
        .catch(error => {
          console.error('Fitnessデータの取得に失敗しました:', error);
        });
    }
  }, [currentMonth]);

  useEffect(() => {
    if (fitnessGridRows) setIsFitnessDataFetch(true);
  }, [fitnessGridRows]); // fitnessGridRowsが更新された場合に発火

  // 1か月分のHealthのデータ
  const monthlyHealth = useMemo(() => {
    if (!healthData) return null;
    return filterDataByMonth(healthData, currentMonth);
  }, [healthData, currentMonth]);

  // 1か月分のFoodのデータ
  const monthlyFood = useMemo(() => {
    if (!foodData) return null;
    return filterDataByMonth(foodData, currentMonth);
  }, [foodData, currentMonth]);

  // 1か月分のFitnessのデータ
  const monthlyFitness = useMemo(() => {
    if (!fitnessData) return null;
    return filterDataByMonth(fitnessData, currentMonth);
  }, [fitnessData, currentMonth]);

  return (
    <>
      <MainContainer>
        <Stack
          spacing={6}
          sx={{
            height: '100%',
          }}
        >
          <MonthSelector currentMonth={currentMonth} setCurrentMonth={setCurrentMonth} />
          <Stack direction={isMobile ? 'column' : 'row'} spacing={2} alignItems={'flex-start'}>
            <Paper
              variant="outlined"
              sx={{
                p: 2,
                borderRadius: '6px',
                width: { xs: '100%', md: '60%' },
                height: {
                  xs: 'auto',
                  sm: '350px',
                  md: targetHeight ? `${targetHeight}px` : '350px',
                },
              }}
            >
              <FoodSummary foodData={monthlyFood} />
            </Paper>
            <Stack
              spacing={2}
              sx={{ width: { xs: '100%', md: '40%' }, minWidth: { xs: 'none', md: '280px' } }}
              ref={boxRef}
            >
              <Paper
                variant="outlined"
                sx={{
                  p: 2,
                  borderRadius: '6px',
                  width: '100%',
                }}
              >
                <HealthSummary
                  healthData={monthlyHealth}
                  items={monthlyItem}
                  healthCategory={healthCategory}
                />
              </Paper>
              <Paper
                variant="outlined"
                sx={{
                  p: 2,
                  borderRadius: '6px',
                  width: '100%',
                }}
              >
                <FitnessSummary fitnessData={monthlyFitness} currentMonth={currentMonth} />
              </Paper>
            </Stack>
          </Stack>
          <Box>
            <SectionTitle title="食事記録" />
            <Table
              table="food"
              apiUrl={process.env.REACT_APP_FOOD_API || ''}
              gridRows={foodGridRows}
              setIsModalOpen={setIsModalOpen}
              setCurrentId={setCurrentFoodId}
              isDataFetch={isFoodDataFetch}
              setData={setFoodData}
              setFormType={setFormType}
            />
          </Box>
          <Box>
            <SectionTitle title="運動記録" />
            <DataTable
              table="fitness"
              currentMonth={currentMonth}
              apiUrl={process.env.REACT_APP_FITNESS_API || ''}
              gridRows={fitnessGridRows}
              setData={setFitnessData}
              isDataFetch={isFitnessDataFetch}
            />
          </Box>
          <Box>
            <SectionTitle title="体調" />
            {healthCategory && (
              <Table
                table="health"
                apiUrl={process.env.REACT_APP_HEALTH_API || ''}
                gridRows={healthGridRows}
                setIsModalOpen={setIsModalOpen}
                setCurrentId={setCurrentHealthId}
                iconMap={createMapByKeyAndValue(healthCategory, 'name', 'icon')}
                isDataFetch={isHealthDataFetch}
                setData={setHealthData}
                setHealthItem={setHealthItem}
                setFormType={setFormType}
              />
            )}
          </Box>
        </Stack>
      </MainContainer>
      <Modal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen}>
        {formType === 'food' && (
          <>
            {foodData && foodDB && (
              <FoodForm
                currentDay={format(currentMonth, 'yyyy-MM-dd')}
                foodData={createNumberIdMap(foodData)}
                setFoodData={setFoodData}
                foodDB={foodDB}
                setIsModalOpen={setIsModalOpen}
                currentFoodId={currentFoodId}
                setCurrentFoodId={setCurrentFoodId}
                setIsDataFetch={setIsFoodDataFetch}
              />
            )}
          </>
        )}
        {formType === 'health' && (
          <>
            {healthData && healthItem && healthCategory && (
              <HealthForm
                currentDay={format(currentMonth, 'yyyy-MM-dd')}
                healthData={createNumberIdMap(healthData)}
                setHealthData={setHealthData}
                healthItem={healthItem}
                setHealthItem={setHealthItem}
                healthCategory={healthCategory}
                currentHealthId={currentHealthId}
                setCurrentHealthId={setCurrentHealthId}
                setIsModalOpen={setIsModalOpen}
                setIsDataFetch={setIsHealthDataFetch}
              />
            )}
          </>
        )}
      </Modal>
    </>
  );
};

export default memo(Health);
