import { HTMLProps, forwardRef, memo } from 'react';
import { IconButton, Paper, Tooltip, TooltipProps } from '@mui/material';
import Icon from './Icon';

type ToolbarWrapperProps = {
  isShow?: boolean;
  isVertical?: boolean;
} & HTMLProps<HTMLDivElement>;

const ToolbarWrapper = memo(
  forwardRef<HTMLDivElement, ToolbarWrapperProps>(
    ({ isShow = true, isVertical = false, children, className, ...rest }, ref) => {
      if (!isShow) {
        return null;
      }

      return (
        <Paper
          ref={ref}
          // elevation={0}
          variant="outlined"
          sx={{
            lineHeight: 1,
            p: '0.25rem',
            gap: '0.25rem',
            display: 'flex',
            flexWrap: 'wrap',
            ...(isVertical && {
              flexDirection: 'column',
              alignItems: 'center',
            }),
          }}
          {...rest}
        >
          {children}
        </Paper>
      );
    }
  )
);

ToolbarWrapper.displayName = 'ToolbarWrapper';

type ToolbarButtonProps = {
  icon: string;
  iconSize?: string;
  iconColor?: 'primary' | 'secondary';
  ariaLabel: string;
  isActive: boolean;
  tooltip?: string;
  placement?: TooltipProps['placement'];
  onClick: () => void;
} & HTMLProps<HTMLButtonElement>;

const ToolbarButton = memo(
  forwardRef<HTMLButtonElement, ToolbarButtonProps>(
    (
      {
        icon,
        iconSize,
        iconColor,
        ariaLabel,
        isActive,
        tooltip,
        placement = 'top',
        onClick,
        style,
      },
      ref
    ) => {
      const Content = (
        <IconButton
          size="small"
          onClick={onClick}
          aria-label={ariaLabel}
          sx={{
            ...(isActive && { backgroundColor: 'var(--IconButton-hoverBg)' }),
          }}
          ref={ref}
        >
          <Icon
            icon={icon}
            size={iconSize}
            color={iconColor}
            style={{
              ...style,
            }}
          />
        </IconButton>
      );

      return tooltip ? (
        <Tooltip title={tooltip} placement={placement}>
          {Content}
        </Tooltip>
      ) : (
        Content
      );
    }
  )
);

ToolbarButton.displayName = 'ToolbarButton';

export const Toolbar = {
  Wrapper: ToolbarWrapper,
  Button: ToolbarButton,
};
