import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Paper, Typography } from '@mui/material';
import { Command, MenuListProps } from './types';
import { scrollbarWhite } from '../../../../../styles/commonStyles';
import MenuItem from '../../../../ui/MenuItem';

export const MenuList = React.forwardRef((props: MenuListProps, ref) => {
  const scrollContainer = useRef<HTMLDivElement>(null);
  const activeItem = useRef<HTMLLIElement>(null);
  const [selectedGroupIndex, setSelectedGroupIndex] = useState(0);
  const [selectedCommandIndex, setSelectedCommandIndex] = useState(0);

  // グループが変更されるたび、つまり、ユーザーが入力して選択肢を絞り込むたびに、
  // 現在の選択を最初のメニュー項目にリセットする
  useEffect(() => {
    setSelectedGroupIndex(0);
    setSelectedCommandIndex(0);
  }, [props.items]);

  const selectItem = useCallback(
    (groupIndex: number, commandIndex: number) => {
      const command = props.items[groupIndex].commands[commandIndex];
      props.command(command);
    },
    [props]
  );

  React.useImperativeHandle(ref, () => ({
    onKeyDown: ({ event }: { event: React.KeyboardEvent }) => {
      if (event.key === 'ArrowDown') {
        if (!props.items.length) {
          return false;
        }

        const { commands } = props.items[selectedGroupIndex];

        let newCommandIndex = selectedCommandIndex + 1;
        let newGroupIndex = selectedGroupIndex;

        if (commands.length - 1 < newCommandIndex) {
          newCommandIndex = 0;
          newGroupIndex = selectedGroupIndex + 1;
        }

        if (props.items.length - 1 < newGroupIndex) {
          newGroupIndex = 0;
        }

        setSelectedCommandIndex(newCommandIndex);
        setSelectedGroupIndex(newGroupIndex);

        return true;
      }

      if (event.key === 'ArrowUp') {
        if (!props.items.length) {
          return false;
        }

        let newCommandIndex = selectedCommandIndex - 1;
        let newGroupIndex = selectedGroupIndex;

        if (newCommandIndex < 0) {
          newGroupIndex = selectedGroupIndex - 1;
          newCommandIndex = (props.items[newGroupIndex]?.commands.length ?? 0) - 1 || 0;
        }

        if (newGroupIndex < 0) {
          newGroupIndex = props.items.length - 1;
          newCommandIndex = props.items[newGroupIndex].commands.length - 1;
        }

        setSelectedCommandIndex(newCommandIndex);
        setSelectedGroupIndex(newGroupIndex);

        return true;
      }

      if (event.key === 'Enter') {
        if (!props.items.length || selectedGroupIndex === -1 || selectedCommandIndex === -1) {
          return false;
        }

        selectItem(selectedGroupIndex, selectedCommandIndex);

        return true;
      }

      return false;
    },
  }));

  useEffect(() => {
    if (activeItem.current && scrollContainer.current) {
      const { offsetTop, offsetHeight } = activeItem.current;

      scrollContainer.current.scrollTop = offsetTop - offsetHeight;
    }
  }, [selectedCommandIndex, selectedGroupIndex]);

  const createCommandClickHandler = useCallback(
    (groupIndex: number, commandIndex: number) => () => {
      selectItem(groupIndex, commandIndex);
    },
    [selectItem]
  );

  if (!props.items.length) {
    return null;
  }

  return (
    <Paper
      ref={scrollContainer}
      variant="outlined"
      sx={{
        padding: '0.25rem',
        width: 'max-content',
        maxHeight: '250px',
        overflowY: 'auto',
        ...scrollbarWhite,
      }}
    >
      {props.items.map((group, groupIndex: number) => (
        <React.Fragment key={`${group.title}-wrapper`}>
          <Typography
            key={`${group.title}`}
            color="secondary"
            variant="caption"
            sx={{
              paddingLeft: '0.25rem',
              fontWeight: 700,
            }}
          >
            {group.title}
          </Typography>
          <ul
            id={`${group.title}-menu`}
            aria-labelledby={`${group.title}-button`}
            role="menu"
            style={{
              padding: 0,
              marginTop: '0.25rem',
              display: 'flex',
              flexDirection: 'column',
              gap: '0.25rem',
              flexWrap: 'wrap',
            }}
          >
            {group.commands.map((command: Command, commandIndex: number) => (
              <MenuItem
                key={`${command.label}`}
                icon={command.iconName}
                onClick={createCommandClickHandler(groupIndex, commandIndex)}
                label={command.label}
                ref={
                  selectedGroupIndex === groupIndex && selectedCommandIndex === commandIndex
                    ? activeItem
                    : null
                }
                selected={
                  selectedGroupIndex === groupIndex && selectedCommandIndex === commandIndex
                }
              />
            ))}
          </ul>
        </React.Fragment>
      ))}
    </Paper>
  );
});

MenuList.displayName = 'MenuList';

export default MenuList;
