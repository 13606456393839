import { useCallback } from 'react';
import { Node } from '@tiptap/pm/model';
import { Editor } from '@tiptap/react';

const useContentItemActions = (
  editor: Editor,
  currentNode: Node | null,
  currentNodePos: number,
  currentNodeDepth: number
) => {
  // 書式のクリア
  const resetTextFormatting = useCallback(() => {
    const chain = editor.chain();
    const { doc } = editor.state;
    const resolvedPos = doc.resolve(currentNodePos);

    if (currentNodeDepth === 0) {
      chain.setNodeSelection(currentNodePos).unsetAllMarks();
    }

    if (currentNodeDepth === 1) {
      const nodeStart = currentNodePos - resolvedPos.parentOffset - 1;
      chain.setNodeSelection(nodeStart).unsetAllMarks();
      if (currentNode?.type.name !== 'paragraph') {
        chain.setParagraph();
      }
    }

    if (currentNodeDepth > 1) {
      let currentDepth = resolvedPos.depth;
      while (currentDepth > 0) {
        if (currentDepth === 1) {
          const nodeStart = resolvedPos.start(currentDepth) - 1;
          chain.setNodeSelection(nodeStart).unsetAllMarks();
        }
        currentDepth -= 1;
      }
    }

    chain.run();
  }, [editor, currentNodePos, currentNode?.type.name]);

  // ブロックの削除
  const deleteNode = useCallback(() => {
    const { doc } = editor.state;
    if (currentNodePos === -1) return;
    const resolvedPos = doc.resolve(currentNodePos);
    const node = resolvedPos.node();

    if (currentNodeDepth === 0) {
      editor.chain().setNodeSelection(currentNodePos).deleteSelection().run();
      return;
    }

    if (currentNodeDepth === 1) {
      let nodeStart = currentNodePos - resolvedPos.parentOffset - 1;
      if (nodeStart < 0) nodeStart = 0;
      const nodeEnd = nodeStart + node.nodeSize + 1;
      editor.chain().deleteRange({ from: nodeStart, to: nodeEnd }).run();
      return;
    }

    if (currentNodeDepth > 1) {
      let currentDepth = resolvedPos.depth;
      while (currentDepth > 0) {
        if (currentDepth === 1) {
          const currentParentNode = resolvedPos.node(currentDepth);
          const nodeStart = resolvedPos.start(currentDepth) - 1;
          const nodeEnd = nodeStart + currentParentNode.nodeSize + 1;
          editor.chain().deleteRange({ from: nodeStart, to: nodeEnd }).run();
        }
        currentDepth -= 1;
      }
    }
  }, [editor, currentNodePos]);

  // ブロックの追加
  const handleAdd = useCallback(() => {
    const { doc } = editor.state;
    const resolvedPos = doc.resolve(currentNodePos);
    const node = resolvedPos.node();
    if (currentNodePos !== -1) {
      let insertPos = 0;

      if (currentNodeDepth === 0) {
        insertPos = currentNodePos + 1;
      }

      if (currentNodeDepth === 1) {
        const nodeStart = currentNodePos - resolvedPos.parentOffset;
        insertPos = nodeStart + node.nodeSize - 2;
      }

      if (currentNodeDepth > 1) {
        let currentDepth = resolvedPos.depth;
        while (currentDepth > 0) {
          if (currentDepth === 1) {
            const currentParentNode = resolvedPos.node(currentDepth);
            const nodeStart = resolvedPos.start(currentDepth);
            insertPos = nodeStart + currentParentNode.nodeSize - 2;
          }
          currentDepth -= 1;
        }
      }

      const currentNodeIsEmptyParagraph =
        currentNode?.type.name === 'paragraph' && currentNode?.content?.size === 0;
      const focusPos = currentNodeIsEmptyParagraph
        ? currentNodePos + 2
        : currentNodeDepth === 0
          ? insertPos + 2
          : insertPos + 3;

      editor
        .chain()
        .command(({ dispatch, tr, state }) => {
          if (dispatch) {
            if (currentNodeIsEmptyParagraph) {
              tr.insertText('/', currentNodePos, currentNodePos + 1);
            } else {
              tr.insert(
                insertPos,
                state.schema.nodes.paragraph.create(null, [state.schema.text('/')])
              );
            }
            // eslint-disable-next-line @typescript-eslint/no-unsafe-return
            return dispatch(tr);
          }
          return true;
        })
        .focus(focusPos)
        .run();
    }
  }, [currentNode, currentNodePos, editor]);

  return {
    resetTextFormatting,
    deleteNode,
    handleAdd,
  };
};

export default useContentItemActions;
