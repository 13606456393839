import { useEffect, useState } from 'react';
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  IconContainerProps,
  Rating,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { zodResolver } from '@hookform/resolvers/zod';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { healthSchema, HealthSchema } from '../../validations/schema';
import useSaveRequest from '../../hooks/useSaveRequest';
import useDeleteRequest from '../../hooks/useDeleteRequest';
import { convertHexToRgba } from '../../utils/convertColor';
import { Health, HealthCategory, HealthItem } from '../../types';
import Icon from '../ui/Icon';
import { mentalIcons } from './Icons';
import { theme } from '../../theme';
import {
  textFieldOutlinedStyleWithLabel2,
  textFieldStandardStyleWithLabel,
} from '../../styles/commonStyles';

interface HealthFormProps {
  currentDay: string;
  currentHealthId: number;
  healthData: Map<number, Health>;
  setHealthData: React.Dispatch<React.SetStateAction<Health[] | null>>;
  healthItem: HealthItem[];
  setHealthItem: React.Dispatch<React.SetStateAction<HealthItem[] | null>>;
  healthCategory: HealthCategory[];
  setCurrentHealthId: React.Dispatch<React.SetStateAction<number>>;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setIsDataFetch: React.Dispatch<React.SetStateAction<boolean>>;
}

const HealthForm = ({
  currentDay,
  currentHealthId,
  healthData,
  setHealthData,
  healthItem,
  setHealthItem,
  healthCategory,
  setCurrentHealthId,
  setIsModalOpen,
  setIsDataFetch,
}: HealthFormProps) => {
  const [isSaveLoading, setIsSaveLoading] = useState(false);
  const [isDelLoading, setIsDelLoading] = useState(false);
  const saveRequest = useSaveRequest();
  const deleteRequest = useDeleteRequest();

  // ReactHookFormの基本設定
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
    reset,
    getValues,
  } = useForm<HealthSchema>({
    defaultValues: {
      date: currentDay,
      mental: 0,
      other: '',
      memo: '',
      item: [],
    },
    resolver: zodResolver(healthSchema),
    mode: 'onChange',
  });

  // currentHealthIdが選択された場合に既存データがあれば、フォームにデータをセット
  useEffect(() => {
    if (currentHealthId !== 0 && healthData) {
      setValue('date', healthData.get(currentHealthId)?.date || currentDay);
      setValue('mental', healthData.get(currentHealthId)?.mental || 0);
      setValue('other', healthData.get(currentHealthId)?.other || '');
      setValue('memo', healthData.get(currentHealthId)?.memo || '');
      setValue(
        'item',
        healthItem.filter(item => item.healthId === currentHealthId).map(item => item.categoryId)
      );
    } else {
      reset({
        date: currentDay,
        mental: 0,
        other: '',
        memo: '',
        item: [],
      });
    }
  }, [currentHealthId, healthData, healthItem]);

  // 新規保存・更新処理
  const onSubmit: SubmitHandler<HealthSchema> = () => {
    setIsSaveLoading(true);
    setIsDataFetch(false);
    const sendData = getValues();
    saveRequest({
      apiUrl: process.env.REACT_APP_HEALTH_API,
      id: String(currentHealthId),
      data: sendData,
    })
      .then(response => {
        if (response.message?.indexOf('保存できませんでした') === -1) {
          const { item, ...rest } = sendData;
          setHealthData(prev => {
            if (!prev) return null;
            const newData = prev.filter(item => item.id !== Number(response.id));
            return [...newData, { ...rest, id: Number(response.id) }];
          });
          const ids = response.ids || [];
          const newItems = ids.map((id, index) => ({
            id: Number(id),
            healthId: Number(response.id),
            categoryId: sendData.item[index] as number,
          }));
          setHealthItem(prev => {
            if (!prev) return null;
            const newData = prev.filter(item => item.healthId !== Number(response.id));
            return [...newData, ...newItems];
          });
        } else {
          // 保存できなかった場合に通知
          alert(response.message);
        }
      })
      .catch(error => {
        console.error('Healthの更新に失敗しました:', error);
      })
      .finally(() => {
        setIsSaveLoading(false);
        setIsModalOpen(false);
      });
  };

  // Healthの削除
  const handleDelete = () => {
    setIsDelLoading(true);
    setIsDataFetch(false);
    deleteRequest({
      apiUrl: process.env.REACT_APP_HEALTH_API,
      id: String(currentHealthId),
    })
      .then(() => {
        setHealthData(prev => {
          if (!prev) return null;
          return prev.filter(item => item.id !== currentHealthId);
        });
        setHealthItem(prev => {
          if (!prev) return null;
          return prev.filter(item => item.healthId !== currentHealthId);
        });
      })
      .catch(error => {
        console.error('Healthの削除に失敗しました:', error);
      })
      .finally(() => {
        setIsDelLoading(false);
        setIsModalOpen(false);
        setCurrentHealthId(0);
      });
  };

  const IconContainer = (props: IconContainerProps) => {
    const { value, ...other } = props;
    return <span {...other}>{mentalIcons[value].icon}</span>;
  };

  return (
    <Stack
      component="form"
      noValidate
      spacing={3.5}
      onSubmit={e => {
        e.preventDefault(); // デフォルトのフォーム送信を防ぐ
        handleSubmit(onSubmit)(e).catch(error => {
          console.error('フォーム送信エラー:', error);
        });
      }}
    >
      <Controller
        name="date"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            id="date"
            required
            variant="standard"
            label="日付"
            type="date"
            disabled={currentHealthId !== 0}
            placeholder="日付を設定"
            sx={{
              ...textFieldStandardStyleWithLabel,
              width: 'fit-content',
            }}
            error={!!errors.date}
            helperText={
              errors.date?.message || currentHealthId !== 0 ? '更新時は日付の変更はできません' : ''
            }
          />
        )}
      />
      <Stack spacing={1}>
        <Typography
          variant="subtitle2"
          component="p"
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Icon
            icon="first-aid-kit-fill"
            size="1.2rem"
            color={theme.palette.secondary.dark}
            style={{
              marginRight: '0.35rem',
            }}
          />
          症状
        </Typography>
        <Controller
          name="item"
          control={control}
          render={({ field }) => {
            const val = field.value as number[];
            return (
              <FormGroup
                sx={{
                  marginTop: '0.25rem !important',
                  flexDirection: 'row',
                  rowGap: 0,
                  columnGap: '0.5rem',
                }}
              >
                {healthCategory?.map(category => {
                  const isChecked = val.includes(category.id);
                  return (
                    <FormControlLabel
                      key={category.id}
                      control={
                        <Checkbox
                          checked={isChecked}
                          onChange={() => {
                            const updatedValue = isChecked
                              ? val.filter(id => id !== category.id)
                              : [...val, category.id];
                            field.onChange(updatedValue); // 更新された配列を onChange 経由で反映
                          }}
                        />
                      }
                      label={category.name}
                    />
                  );
                })}
              </FormGroup>
            );
          }}
        />
        <Controller
          name="other"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              id="other"
              size="small"
              label="その他"
              type="text"
              placeholder="その他の症状を入力"
              variant="outlined"
              error={!!errors.other}
              helperText={errors.other?.message || '複数ある場合はカンマ区切りで入力'}
              sx={{
                ...textFieldOutlinedStyleWithLabel2,
                width: '100%',
                '& .MuiInputBase-root.MuiOutlinedInput-root': {
                  width: 'calc(100% - 4.125rem)',
                },
              }}
            />
          )}
        />
      </Stack>
      <Stack spacing={1.5}>
        <Typography
          variant="subtitle2"
          component="p"
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Icon
            icon="empathize-fill"
            size="1.2rem"
            color={theme.palette.secondary.dark}
            style={{
              marginRight: '0.35rem',
            }}
          />
          気分
        </Typography>
        <Controller
          name="mental"
          control={control}
          render={({ field }) => (
            <Rating
              {...field}
              id="mental"
              value={field.value ? Number(field.value) : 0}
              onChange={(_, value) => field.onChange(value)}
              IconContainerComponent={IconContainer}
              getLabelText={(value: number) => mentalIcons[value].label}
              highlightSelectedOnly
              sx={{
                gap: '0.5rem',
                '& .MuiRating-iconEmpty .MuiSvgIcon-root': {
                  color: theme.palette.action.disabled,
                },
              }}
            />
          )}
        />
      </Stack>
      <Controller
        name="memo"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            id="memo"
            label="メモ"
            placeholder="メモを入力（500文字以内）"
            spellCheck="false"
            autoComplete="off"
            multiline
            error={!!errors.memo}
            helperText={errors.memo?.message}
            sx={{
              width: '100%',
              alignItems: 'flex-start',
              flexWrap: 'wrap',
              '.MuiFormLabel-root': {
                maxWidth: '100% !important',
                flexGrow: 1,
                position: 'static !important',
                padding: '0.5rem 0.5rem 0.5rem 1.6rem',
                marginBottom: '0.5rem',
                fontSize: '0.875rem',
                fontWeight: 500,
                color: `${theme.palette.text.primary} !important`,
                lineHeight: 1,
                transform: 'none !important',
                pointerEvents: 'auto',
              },
              '.MuiFormLabel-root::before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: '0.4rem',
                left: 0,
                width: '1.2rem',
                height: '1.2rem',
                backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='${convertHexToRgba(theme.palette.secondary.dark)}'%3E%3Cpath d='M21 9V20.9925C21 21.5511 20.5552 22 20.0066 22H3.9934C3.44495 22 3 21.556 3 21.0082V2.9918C3 2.45531 3.44694 2 3.99826 2H14V8C14 8.55228 14.4477 9 15 9H21ZM21 7H16V2.00318L21 7ZM8 7V9H11V7H8ZM8 11V13H16V11H8ZM8 15V17H16V15H8Z'%3E%3C/path%3E%3C/svg%3E")`,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
              },
              ".MuiFormLabel-root[data-shrink='false']": {
                position: 'static !important',
                transform: 'none !important',
              },
              '.MuiInputBase-root': {
                width: '100%',
                backgroundColor: '#fff',
                lineHeight: 1.5,
                flexGrow: 1,
                padding: '0.75rem',
              },
              '.MuiFormHelperText-root': {
                width: '100%',
              },
              legend: {
                display: 'none',
              },
              fieldset: {
                top: '0 !important',
              },
            }}
          />
        )}
      />
      <Stack spacing={1.5}>
        <LoadingButton type="submit" variant="contained" loading={isSaveLoading} fullWidth>
          {isSaveLoading ? '　' : currentHealthId !== 0 ? '更新' : '保存'}
        </LoadingButton>
        {currentHealthId !== 0 && (
          <LoadingButton
            onClick={handleDelete}
            variant="outlined"
            color={'secondary'}
            loading={isDelLoading}
            fullWidth
          >
            {isDelLoading ? '　' : '削除'}
          </LoadingButton>
        )}
      </Stack>
    </Stack>
  );
};
export default HealthForm;
