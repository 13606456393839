import { memo } from 'react';
import { Box, CircularProgress } from '@mui/material';
import { BarChart } from '@mui/x-charts/BarChart';
import { theme } from '../../theme';
import useGetElementHeight from '../../hooks/useGetElementHeight';
import { center } from '../../styles/commonStyles';

const valueFormatter = (value: number | null) => `¥ ${value?.toLocaleString('ja-JP')}`;

interface BalanceBarChartProps {
  balanceByMonthly: { x: string; income: number; expense: number }[] | null;
}

const BalanceBarChart = ({ balanceByMonthly }: BalanceBarChartProps) => {
  const chartsLegendHeight = useGetElementHeight('.MuiChartsLegend-root.MuiChartsLegend-row');

  return (
    <>
      {balanceByMonthly ? (
        <BarChart
          colors={[theme.palette.incomeColor.dark, theme.palette.expenseColor.dark]}
          dataset={balanceByMonthly}
          xAxis={[
            {
              scaleType: 'band',
              data: balanceByMonthly.map(data => data.x),
            },
          ]}
          series={[
            { dataKey: 'income', label: '収入', valueFormatter },
            { dataKey: 'expense', label: '支出', valueFormatter },
          ]}
          slotProps={{
            legend: {
              itemMarkWidth: 15,
              itemMarkHeight: 15,
              labelStyle: {
                fontSize: 12,
                fontWeight: 500,
                lineHeight: 1.35,
              },
            },
          }}
          margin={{ left: 40, right: 16, bottom: 30, top: chartsLegendHeight || 50 }}
          sx={{
            width: '100%',
            height: 'auto',
          }}
        />
      ) : (
        <Box
          sx={{
            width: '100%',
            height: '100%',
            ...center,
          }}
        >
          <CircularProgress color="secondary" size={20} />
        </Box>
      )}
    </>
  );
};

export default memo(BalanceBarChart);
