import {
  BlockquoteFigure,
  CharacterCount,
  CodeBlock,
  Color,
  Document,
  Figcaption,
  Focus,
  Heading,
  Highlight,
  HorizontalRule,
  ImageBlock,
  Link,
  Placeholder,
  Selection,
  SlashCommand,
  StarterKit,
  Subscript,
  Superscript,
  Table,
  TableCell,
  TableHeader,
  TableRow,
  TextAlign,
  TextStyle,
  TrailingNode,
  Typography,
  Underline,
  Columns,
  Column,
  TaskItem,
  TaskList,
} from '.';
import { ImageUpload } from './ImageUpload';

export const ExtensionKit = () => [
  StarterKit.configure({
    document: false,
    heading: false,
    horizontalRule: false,
    blockquote: false,
    codeBlock: false,
  }),
  Document,
  Highlight.configure({ multicolor: true }), // 複数の色のサポートを追加
  CharacterCount, // 最大文字数、デフォルトはnull
  Underline,
  Placeholder.configure({
    includeChildren: true,
    showOnlyCurrent: false,
    placeholder: () => '',
  }),
  TaskList,
  TaskItem.configure({
    nested: true, // タスク項目を相互にネストできるかどうか
  }),
  TextAlign.extend().configure({
    types: ['heading', 'paragraph'],
  }),
  TextStyle, // font-family、font-size、color などのスタイル関連属性のリストを追加できるようにする
  Typography,
  Color,
  Focus.configure({
    mode: 'shallowest',
  }),
  Subscript, // 添え字
  Superscript, // 上付き文字
  Columns,
  Column,
  Selection,
  Heading.configure({
    levels: [1, 2, 3, 4, 5, 6],
  }),
  HorizontalRule,
  CodeBlock,
  TrailingNode,
  Link.configure({
    openOnClick: false,
  }),
  ImageUpload,
  ImageBlock,
  Table,
  TableCell,
  TableHeader,
  TableRow,
  SlashCommand,
  Figcaption,
  BlockquoteFigure,
];

export default ExtensionKit;
