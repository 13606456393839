import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Autocomplete,
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  ListItemIcon,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { alpha, useMediaQuery } from '@mui/system';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { zodResolver } from '@hookform/resolvers/zod';
import { Controller, SubmitHandler, useFieldArray, useForm } from 'react-hook-form';
import { differenceInMinutes, parseISO } from 'date-fns';
import { TodoSchema, todoSchema } from '../../validations/schema';
import useSaveRequest from '../../hooks/useSaveRequest';
import useDeleteRequest from '../../hooks/useDeleteRequest';
import { todoDarkColor, todoLightColor, todoMainColor } from '../../utils/getColors';
import { convertHexToRgba } from '../../utils/convertColor';
import { useTodoContext } from '../../contexts/TodoContext';
import { useProjectContext } from '../../contexts/ProjectContext';
import { TodoTypeIcons } from './Icons';
import Icon from '../ui/Icon';
import { TaskTime, TodoType } from '../../types';
import { theme } from '../../theme';
import {
  autocompleteStyle,
  autocompleteTextFieldStyle,
  selectMenuItem,
  textFieldStandardStyle,
  textFieldStandardStyleWithLabel,
  textFieldStandardStyleWithLabel2,
} from '../../styles/commonStyles';

interface TodoFormProps {
  currentDay: string;
  currentTodoId: number;
  setCurrentTodoId: React.Dispatch<React.SetStateAction<number>>;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isProjectId?: number;
  isSectionId?: string;
}

interface TypeItem {
  label: string;
  icon: JSX.Element;
}

const TodoForm = ({
  currentDay,
  currentTodoId,
  setCurrentTodoId,
  setIsModalOpen,
  isProjectId,
  isSectionId,
}: TodoFormProps) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [isSaveLoading, setIsSaveLoading] = useState(false);
  const [isDelLoading, setIsDelLoading] = useState(false);
  const [sectionIds, setSectionIds] = useState<string[]>([]);
  const { todoData, setTodoData, taskTimeData, setTaskTimeData } = useTodoContext();
  const {
    menuItems: projectData,
    sectionData,
    todoOrder,
    setTodoOrder,
    setIsOrderChange,
  } = useProjectContext();
  const saveRequest = useSaveRequest();
  const deleteRequest = useDeleteRequest();

  // TodoTypeIconsからタイプの選択肢を取得
  const todoTypes: TypeItem[] = useMemo(
    () =>
      Object.entries(TodoTypeIcons({})).map(([label, icon]) => ({
        label,
        icon,
      })),
    []
  );

  // 現在時刻を取得
  const getNowTime = useCallback(() => {
    const now = new Date();
    const nowHours = now.getHours().toString().padStart(2, '0'); // 時（2桁）
    const nowMinutes = now.getMinutes().toString().padStart(2, '0'); // 分（2桁）
    return `${nowHours}:${nowMinutes}`;
  }, []);

  // 選択中のTodoの作業時間のデータを取得
  const getCurrentTaskTimeIds = useCallback(
    (taskTimeData: Map<number, TaskTime>, currentTodoId: number): number[] =>
      Array.from(taskTimeData.values())
        .filter(task => task.todoId === currentTodoId) // todoId が一致するデータを抽出
        .map(task => task.id),
    []
  );

  const currentTaskTimeIds = useMemo(() => {
    if (!taskTimeData) return [];
    return getCurrentTaskTimeIds(taskTimeData, currentTodoId);
  }, [taskTimeData, currentTodoId]);

  // ReactHookFormの基本設定
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
    reset,
    getValues,
    watch,
  } = useForm<TodoSchema>({
    defaultValues: {
      content: '',
      start: `${currentDay} ${getNowTime()}`,
      end: '',
      type: '',
      project: isProjectId ?? null,
      section: isSectionId ?? null,
      estimated: '',
      completed: false,
      memo: '',
      taskTime: [],
    },
    resolver: zodResolver(todoSchema),
    mode: 'onChange',
  });

  // inputを動的に増減させるための設定
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'taskTime',
  });

  // 現在のprojectを監視して、sectionの選択肢を変更する
  const currentProject = watch('project');
  useEffect(() => {
    if (!currentProject || currentProject === 0) {
      setSectionIds([]);
      return;
    }
    if (!sectionData) return;
    const newSectionIds = Array.from(sectionData.values())
      .filter(section => section.projectId === currentProject)
      .map(item => item.id);
    setSectionIds(newSectionIds);
  }, [currentProject]);

  // Todoが選択された場合に既存データがあれば、フォームにデータをセット
  useEffect(() => {
    if (currentTodoId !== 0 && todoData) {
      const projectId = todoData.get(currentTodoId)?.projectId;
      const sectionId = todoData.get(currentTodoId)?.sectionId;
      setValue('content', todoData.get(currentTodoId)?.content || '');
      setValue('start', todoData.get(currentTodoId)?.start || `${currentDay} ${getNowTime()}`);
      setValue('end', todoData.get(currentTodoId)?.end || '');
      setValue('type', todoData.get(currentTodoId)?.type || '');
      setValue('project', projectId && projectData?.has(projectId) ? projectId : null);
      setValue('section', sectionId && sectionData?.has(sectionId) ? sectionId : null);
      setValue('estimated', todoData.get(currentTodoId)?.estimated || '');
      setValue('completed', Boolean(todoData.get(currentTodoId)?.completed));
      setValue('memo', todoData.get(currentTodoId)?.memo || '');
      setValue(
        'taskTime',
        currentTaskTimeIds.map(id => ({
          id,
          start: taskTimeData?.get(id)?.start || '',
          end: taskTimeData?.get(id)?.end || '',
        }))
      );
    } else {
      reset({
        content: '',
        start: `${currentDay} ${getNowTime()}`,
        end: '',
        type: '',
        project: isProjectId ?? null,
        section: isSectionId ?? null,
        estimated: '',
        completed: false,
        memo: '',
        taskTime: [],
      });
    }
  }, [currentTodoId, todoData, currentTaskTimeIds]);

  // 新規保存・更新処理
  const onSubmit: SubmitHandler<TodoSchema> = useCallback(() => {
    setIsSaveLoading(true);
    const taskTime = getValues().taskTime.map(data => ({
      id: data?.id,
      todoId: currentTodoId,
      start: data?.start === '' ? null : data?.start,
      end: data?.end === '' ? null : data?.end,
    }));
    const sendData = {
      content: getValues().content,
      start: getValues().start,
      end: getValues().end === '' ? null : getValues().end,
      type: getValues().type as TodoType,
      projectId: getValues().project,
      sectionId: getValues().section,
      estimated: getValues().estimated === '' ? null : getValues().estimated,
      completed: getValues().completed ? 1 : 0,
      memo: getValues().memo,
      taskTime,
    };
    const currentProjectId = todoData?.get(currentTodoId)?.projectId;
    const currentSectionId = todoData?.get(currentTodoId)?.sectionId;
    const newProjectId = getValues().project;
    const newSectionId = getValues().section;
    let orderCase = '';
    if (currentTodoId === 0) {
      // 新規保存
      if (newProjectId) {
        orderCase = 'save';
        console.log('新規保存: todoOrderに登録');
      } else {
        console.log('新規保存: ProjectがnullのためtodoOrderに登録しない');
      }
    } else {
      // 既存データの更新
      const isProjectChanged = currentProjectId !== newProjectId;
      const isSectionChanged = currentSectionId !== newSectionId;
      if (isProjectChanged && newProjectId === null) {
        // ProjectIdが変更かつ、新しいProjectIdがnullの場合
        orderCase = 'delete';
        console.log('更新: Projectがnullになった → todoOrderから削除');
      } else if (isProjectChanged || isSectionChanged) {
        // ProjectまたはSectionが変更された場合
        orderCase = 'save';
        console.log('更新: ProjectまたはSectionが変更 → 順番リセット');
      } else {
        console.log('更新: ProjectまたはSectionが変更なし');
      }
    }
    saveRequest({
      apiUrl: process.env.REACT_APP_TODO_API,
      id: String(currentTodoId),
      data: sendData,
    })
      .then(response => {
        const newTaskTime = taskTime.map((item, index) => {
          if (item.id === 0) {
            return {
              id: Number(response.taskTimeIds?.[index]),
              todoId: Number(response.id),
              start: item.start,
              end: item.end,
            };
          }
          return {
            id: item.id,
            todoId: Number(response.id),
            start: item.start,
            end: item.end,
          };
        });
        setTaskTimeData(prev => {
          const newMap = new Map(prev);
          newTaskTime.forEach(item => {
            if (item.id != null) {
              newMap.set(item.id, item as TaskTime);
            }
          });
          return newMap;
        });
        setTodoData(prev => {
          const newMap = new Map(prev);
          newMap.set(Number(response.id), {
            id: Number(response.id),
            ...sendData,
            completed: sendData.completed ? 1 : 0,
          });
          return newMap;
        });
        return Number(response.id);
      })
      .then(id => {
        if (orderCase === 'save') {
          saveRequest({
            apiUrl: process.env.REACT_APP_SECTION_API,
            id: String(id),
            target: 'todoOrder',
            data: todoData?.size || 100,
          })
            .then(() => {
              if (todoOrder) {
                setTodoOrder(prev => {
                  const newMap = new Map(prev);
                  newMap.set(id, {
                    todoId: id,
                    sort: todoData?.size || 100,
                  });
                  return newMap;
                });
                setIsOrderChange(true);
              }
            })
            .catch(error => {
              console.error('TodoOrderの更新に失敗しました:', error);
            });
        } else if (orderCase === 'delete') {
          deleteRequest({
            apiUrl: process.env.REACT_APP_SECTION_API,
            id: String(id),
            target: 'todoOrder',
          })
            .then(() => {
              if (todoOrder) {
                setTodoOrder(prev => {
                  const newMap = new Map(prev);
                  newMap.delete(id);
                  return newMap;
                });
                setIsOrderChange(true);
              }
            })
            .catch(error => {
              console.error('TodoOrderの削除に失敗しました:', error);
            });
        }
      })
      .catch(error => {
        console.error('Todoの更新に失敗しました:', error);
      })
      .finally(() => {
        setIsSaveLoading(false);
        setIsModalOpen(false);
      });
  }, []);

  // Todoの削除
  const handleDelete = useCallback(() => {
    setIsDelLoading(true);
    deleteRequest({
      apiUrl: process.env.REACT_APP_TODO_API,
      id: String(currentTodoId),
    })
      .then(response => {
        const deleteTaskTimeIds = response.deletedIds || [];
        setTaskTimeData(prev => {
          const newMap = new Map(prev);
          deleteTaskTimeIds.forEach(id => {
            newMap.delete(id);
          });
          return newMap;
        });
        if (todoOrder) {
          setTodoOrder(prev => {
            const newMap = new Map(prev);
            newMap.delete(currentTodoId);
            return newMap;
          });
        }
        setTodoData(prev => {
          const newMap = new Map(prev);
          newMap.delete(currentTodoId);
          return newMap;
        });
        if (todoOrder) {
          setIsOrderChange(true);
        }
      })
      .catch(error => {
        console.error('Todoの削除に失敗しました:', error);
      })
      .finally(() => {
        setIsDelLoading(false);
        setIsModalOpen(false);
        setCurrentTodoId(0);
      });
  }, []);

  // TaskTimeの削除
  const handleTaskTimeDelete = useCallback((id: number, index: number) => {
    deleteRequest({
      apiUrl: process.env.REACT_APP_TASKTIME_API,
      id: String(id),
    })
      .then(() => {
        remove(index);
        setTaskTimeData(prev => {
          const newMap = new Map(prev);
          newMap.delete(id);
          return newMap;
        });
      })
      .catch(error => {
        console.error('TaskTimeの削除に失敗しました:', error);
      });
  }, []);

  // TaskTimeの合計時間を計算
  const totalTaskTime = useMemo(() => {
    const totalMinutes = fields.reduce((total, _, index) => {
      const startValue = getValues(`taskTime.${index}.start`);
      const endValue = getValues(`taskTime.${index}.end`);
      if (startValue && endValue) {
        const start = parseISO(startValue);
        const end = parseISO(endValue);
        if (start < end) {
          return total + differenceInMinutes(end, start);
        }
      }
      return total;
    }, 0);

    const totalHours = Math.floor(totalMinutes / 60);
    const remainingMinutes = totalMinutes % 60;
    return `${totalHours}時間${remainingMinutes}分`;
  }, [fields, getValues]);

  // TaskTimeの時間を計算
  const diffTaskTime = useCallback((index: number) => {
    const startValue = getValues(`taskTime.${index}.start`);
    const endValue = getValues(`taskTime.${index}.end`);
    if (startValue && endValue) {
      const start = parseISO(startValue);
      const end = parseISO(endValue);
      if (start < end) {
        const minutes = differenceInMinutes(end, start);
        const hours = Math.floor(minutes / 60);
        const remainingMinutes = minutes % 60;
        return `${hours}時間${remainingMinutes}分`;
      }
      return '無効な時間';
    }
    return '未設定';
  }, []);

  return (
    <Stack
      component="form"
      noValidate
      spacing={2.5}
      onSubmit={e => {
        e.preventDefault(); // デフォルトのフォーム送信を防ぐ
        handleSubmit(onSubmit)(e).catch(error => {
          console.error('フォーム送信エラー:', error);
        });
      }}
    >
      <Controller
        name="content"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            id="content"
            required
            fullWidth
            multiline
            hiddenLabel
            spellCheck="false"
            autoComplete="off"
            variant="standard"
            type="text"
            placeholder="タスク名"
            error={!!errors.content}
            helperText={errors.content?.message}
            sx={{
              ...textFieldStandardStyle,
              '& .MuiInputBase-input': {
                fontWeight: 700,
                lineHeight: 1.35,
                fontSize: '1.35rem',
              },
            }}
          />
        )}
      />

      <Stack
        direction={isMobile ? 'column' : 'row'}
        justifyContent="space-between"
        alignItems="flex-start"
        spacing={isMobile ? 1 : 2}
      >
        <Controller
          name="start"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              id="start"
              required
              variant="standard"
              label="開始"
              type="datetime-local"
              placeholder="開始日を設定"
              sx={{
                flexBasis: isMobile ? '100%' : 'calc(50% - 1.5rem)',
                ...textFieldStandardStyleWithLabel,
              }}
              error={!!errors.start}
              helperText={errors.start?.message}
            />
          )}
        />
        <div
          style={{
            width: '1rem',
            height: '1px',
            marginTop: '1rem',
            backgroundColor: theme.palette.secondary.dark,
            display: isMobile ? 'none' : 'block',
          }}
        ></div>
        <Controller
          name="end"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              id="end"
              label="終了"
              variant="standard"
              type="datetime-local"
              placeholder="終了日を設定"
              sx={{
                flexBasis: isMobile ? '100%' : 'calc(50% - 1.5rem)',
                ...textFieldStandardStyleWithLabel,
              }}
              error={!!errors.end}
              helperText={errors.end?.message}
            />
          )}
        />
      </Stack>

      <Controller
        name="type"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            id="type"
            required
            select
            variant="filled"
            size="small"
            label="タイプを選択"
            error={!!errors.type}
            helperText={errors.type?.message}
            sx={{
              width: 'fit-content',
              minWidth: '10rem',
              '.MuiInputBase-root': {
                backgroundColor: todoLightColor(field.value || ''),
              },
              '.MuiInputBase-input.MuiFilledInput-input:focus-visible': {
                outline: 'solid 2px',
                borderRadius: '1px',
              },
              '.MuiSelect-select.MuiSelect-filled': {
                paddingTop: '0.35rem',
                paddingBottom: '0.35rem',
                paddingLeft: '0.75rem',
                fontWeight: 500,
                color: theme.palette.text.secondary,
                display: 'flex',
                alignItems: 'center',
              },
              '.MuiFormLabel-root[data-shrink="false"]': {
                top: '-7px',
                left: '-2px',
                color: alpha(theme.palette.secondary.main, 0.5),
                transition: 'none',
              },
              '.MuiFormLabel-root[data-shrink="true"]': {
                color: 'transparent',
                opacity: 0,
                transition: 'none',
              },
              '.MuiFormLabel-asterisk': {
                display: 'none',
              },
              '.MuiListItemIcon-root>svg': {
                marginTop: '1px',
                fill: `${todoDarkColor(field.value || '')} !important`,
              },
              '.MuiFormHelperText-root': {
                marginLeft: 0,
              },
            }}
          >
            {todoTypes.map((todoType, index) => (
              <MenuItem
                key={index}
                value={todoType.label}
                sx={{
                  ...selectMenuItem,
                }}
              >
                <ListItemIcon sx={{ verticalAlign: 'text-bottom' }}>{todoType.icon}</ListItemIcon>
                {todoType.label}
              </MenuItem>
            ))}
          </TextField>
        )}
      />

      <Stack spacing={1}>
        <Controller
          name="project"
          control={control}
          render={({ field }) => (
            <Autocomplete
              {...field}
              id="project"
              disablePortal
              options={projectData ? Array.from(projectData.keys()) : []}
              getOptionLabel={option => (projectData && projectData.get(option)) || ''}
              onChange={(event, newValue) => {
                if (newValue === null) {
                  field.onChange(null);
                  setValue('section', null);
                } else {
                  field.onChange(newValue);
                  setValue('section', null);
                }
              }}
              noOptionsText="プロジェクトが見つかりません"
              sx={{
                ...autocompleteStyle,
              }}
              renderInput={params => (
                <>
                  <Icon
                    icon="folder-fill"
                    size="1.2rem"
                    color={todoMainColor(getValues('type'))}
                    style={{
                      marginRight: '0.5rem',
                    }}
                  />
                  <TextField
                    {...params}
                    label="プロジェクトを選択"
                    placeholder="プロジェクトを選択"
                    variant="filled"
                    size="small"
                    error={!!errors.project}
                    helperText={errors.project?.message}
                    sx={{
                      ...autocompleteTextFieldStyle,
                    }}
                  />
                </>
              )}
            />
          )}
        />

        <Controller
          name="section"
          control={control}
          render={({ field }) => (
            <Autocomplete
              {...field}
              id="section"
              disablePortal
              options={sectionIds}
              getOptionLabel={option => sectionData?.get(option)?.name || ''}
              onChange={(event, newValue) => {
                if (newValue === null) {
                  field.onChange(null);
                } else {
                  field.onChange(newValue);
                }
              }}
              noOptionsText="セクションがありません"
              sx={{
                ...autocompleteStyle,
              }}
              renderInput={params => (
                <>
                  <Icon
                    icon="archive-2-fill"
                    size="1.2rem"
                    color={todoMainColor(getValues('type'))}
                    style={{
                      marginRight: '0.5rem',
                    }}
                  />
                  <TextField
                    {...params}
                    id="section"
                    label="セクションを選択"
                    placeholder="セクションを選択"
                    variant="filled"
                    size="small"
                    error={!!errors.section}
                    helperText={errors.section?.message}
                    sx={{
                      ...autocompleteTextFieldStyle,
                    }}
                  />
                </>
              )}
            />
          )}
        />
      </Stack>

      <Stack spacing={1.5}>
        <Typography
          variant="subtitle2"
          component="p"
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Icon
            icon="timer-fill"
            size="1.2rem"
            color={todoMainColor(getValues('type'))}
            style={{
              marginRight: '0.35rem',
            }}
          />
          作業時間
        </Typography>
        <Stack spacing={3} alignItems="center" direction="row">
          <Controller
            name="estimated"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                id="estimated"
                variant="standard"
                label="見積"
                type="time"
                error={!!errors.estimated}
                helperText={errors.estimated?.message}
                sx={{
                  ...textFieldStandardStyleWithLabel2,
                  width: 'fit-content',
                }}
              />
            )}
          />
          <Typography
            component="p"
            sx={{
              width: 'fit-content',
            }}
          >
            <span
              style={{
                padding: '0.3rem 0.5rem',
                fontSize: '0.825rem',
                letterSpacing: '0.035em',
                lineHeight: 1,
                borderRadius: '2px',
                marginRight: '0.5rem',
                backgroundColor: theme.palette.secondary.light,
                color: theme.palette.text.secondary,
              }}
            >
              合計
            </span>
            {totalTaskTime}
          </Typography>
        </Stack>
        {fields.map((fieldItem, index) => (
          <Stack
            key={fieldItem.id}
            direction="row"
            spacing={1}
            flexWrap="nowrap"
            alignItems="center"
          >
            <Controller
              name={`taskTime.${index}.start`}
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  size="small"
                  label="開始時間"
                  type="datetime-local"
                  sx={{
                    ".MuiFormLabel-root[data-shrink='false']": {
                      color: 'transparent',
                    },
                    ".MuiFormLabel-root[data-shrink='true']": {
                      transform: 'translate(15px, -8px) scale(0.7) !important',
                    },
                  }}
                />
              )}
            />
            <Controller
              name={`taskTime.${index}.end`}
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  size="small"
                  label="終了時間"
                  type="datetime-local"
                  sx={{
                    ".MuiFormLabel-root[data-shrink='false']": {
                      color: 'transparent',
                    },
                    ".MuiFormLabel-root[data-shrink='true']": {
                      transform: 'translate(15px, -8px) scale(0.7) !important',
                    },
                  }}
                />
              )}
            />
            <Typography
              variant="caption"
              sx={{
                fontWeight: 500,
                flexShrink: 0,
              }}
            >
              {diffTaskTime(index)}
            </Typography>
            <IconButton
              aria-label="delete"
              size="small"
              onClick={() => {
                const id = getValues(`taskTime.${index}.id`);
                if (id !== 0) {
                  const response = window.confirm('作業時間を削除しますか？');
                  if (response) {
                    handleTaskTimeDelete(id, index);
                  }
                } else {
                  remove(index);
                }
              }}
              sx={{
                flexShrink: 0,
              }}
            >
              <Icon icon="delete-bin-line" size="1.125rem" />
            </IconButton>
          </Stack>
        ))}
        <Button
          startIcon={<AddCircleIcon color="primary" />}
          variant="outlined"
          color="primary"
          onClick={() => {
            append({ id: 0, start: '', end: '' });
          }}
        >
          作業時間を追加
        </Button>
      </Stack>

      <Controller
        name="memo"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            id="memo"
            label="メモ"
            placeholder="メモを入力（500文字以内）"
            spellCheck="false"
            autoComplete="off"
            multiline
            error={!!errors.memo}
            helperText={errors.memo?.message}
            sx={{
              width: '100%',
              alignItems: 'flex-start',
              flexWrap: 'wrap',
              '.MuiFormLabel-root': {
                maxWidth: '100% !important',
                flexGrow: 1,
                position: 'static !important',
                padding: '0.5rem 0.5rem 0.5rem 1.6rem',
                marginBottom: '0.25rem',
                fontSize: '0.875rem',
                fontWeight: 500,
                color: `${theme.palette.text.primary} !important`,
                lineHeight: 1,
                transform: 'none !important',
                pointerEvents: 'auto',
              },
              '.MuiFormLabel-root::before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: '0.4rem',
                left: 0,
                width: '1.2rem',
                height: '1.2rem',
                backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='${convertHexToRgba(todoMainColor(getValues('type')))}'%3E%3Cpath d='M21 9V20.9925C21 21.5511 20.5552 22 20.0066 22H3.9934C3.44495 22 3 21.556 3 21.0082V2.9918C3 2.45531 3.44694 2 3.99826 2H14V8C14 8.55228 14.4477 9 15 9H21ZM21 7H16V2.00318L21 7ZM8 7V9H11V7H8ZM8 11V13H16V11H8ZM8 15V17H16V15H8Z'%3E%3C/path%3E%3C/svg%3E")`,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
              },
              ".MuiFormLabel-root[data-shrink='false']": {
                position: 'static !important',
                transform: 'none !important',
              },
              '.MuiInputBase-root': {
                width: '100%',
                backgroundColor: '#fff',
                lineHeight: 1.5,
                flexGrow: 1,
                padding: '0.75rem',
              },
              '.MuiFormHelperText-root': {
                width: '100%',
              },
              legend: {
                display: 'none',
              },
              fieldset: {
                top: '0 !important',
              },
            }}
          />
        )}
      />

      <Controller
        name="completed"
        control={control}
        render={({ field }) => (
          <FormControlLabel
            {...field}
            control={
              <Checkbox
                id="completed"
                size="small"
                sx={{
                  color: todoDarkColor(getValues('type')),
                  '.MuiSvgIcon-root': {
                    color: todoDarkColor(getValues('type')),
                  },
                  '.Mui-checked': {
                    color: todoDarkColor(getValues('type')),
                  },
                }}
              />
            }
            label="完了"
            checked={field.value}
            onChange={field.onChange}
            sx={{
              '.MuiTypography-root': {
                marginTop: '-2px',
                fontWeight: 500,
              },
            }}
          />
        )}
      />

      <Stack spacing={1.5}>
        <LoadingButton type="submit" variant="contained" loading={isSaveLoading} fullWidth>
          {isSaveLoading ? '　' : currentTodoId !== 0 ? '更新' : '保存'}
        </LoadingButton>
        {currentTodoId !== 0 && (
          <LoadingButton
            onClick={handleDelete}
            variant="outlined"
            color={'secondary'}
            loading={isDelLoading}
            fullWidth
          >
            {isDelLoading ? '　' : '削除'}
          </LoadingButton>
        )}
      </Stack>
    </Stack>
  );
};
export default TodoForm;
