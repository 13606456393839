import { DragEvent, useCallback, useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { useAuthContext } from '../../../../../../contexts/AuthContext';
import useSessionExpiration from '../../../../../../hooks/useSessionExpiration';

// ファイルをアップロードするための関数を返す
export const useUploader = ({ onUpload }: { onUpload: (url: string) => void }) => {
  const [loading, setLoading] = useState(false);
  const { userId, csrfToken } = useAuthContext();

  const handleError = (error: unknown) => {
    console.error(error);
    alert(
      (error as { response?: { data?: { error?: string } } })?.response?.data?.error ||
        'Something went wrong'
    );
  };

  const handleResponse = (response: {
    status: number;
    data: { success: boolean; url: string; error?: string };
  }) => {
    if (response.data.success) {
      onUpload(response.data.url);
    } else if (response.status === 401 || response.status === 403) {
      useSessionExpiration();
    } else {
      console.error(response);
      console.error(response.data.error);
      alert('アップロードに失敗しました');
    }
  };

  const uploadFile = useCallback(
    async (file: File) => {
      const apiUrl = process.env.REACT_APP_UPLOAD_API;
      if (!apiUrl) {
        alert('APIが設定されていないため、アップロードできません');
        return;
      }
      setLoading(true);
      try {
        const formData = new FormData();
        formData.append('userId', userId);
        formData.append('csrfToken', csrfToken);
        formData.append('type', 'img');
        formData.append('file', file);
        const response = await axios.post(apiUrl, formData, {
          withCredentials: true,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        handleResponse(response);
      } catch (error) {
        handleError(error);
      } finally {
        setLoading(false);
      }
    },
    [csrfToken, onUpload, userId]
  );

  return { loading, uploadFile };
};

// ファイルの選択ダイアログを開くための関数を返す
export const useFileUpload = () => {
  const fileInput = useRef<HTMLInputElement>(null);

  const handleUploadClick = useCallback(() => {
    fileInput.current?.click();
  }, []);

  return { ref: fileInput, handleUploadClick };
};

export const useDropZone = ({ uploader }: { uploader: (file: File) => void }) => {
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [draggedInside, setDraggedInside] = useState<boolean>(false);

  useEffect(() => {
    const dragStartHandler = () => {
      setIsDragging(true);
    };

    const dragEndHandler = () => {
      setIsDragging(false);
    };

    document.body.addEventListener('dragstart', dragStartHandler);
    document.body.addEventListener('dragend', dragEndHandler);

    return () => {
      document.body.removeEventListener('dragstart', dragStartHandler);
      document.body.removeEventListener('dragend', dragEndHandler);
    };
  }, []);

  const onDrop = useCallback(
    (e: DragEvent<HTMLDivElement>) => {
      setDraggedInside(false);
      if (e.dataTransfer.files.length === 0) {
        return;
      }
      const fileList = e.dataTransfer.files;
      const files: File[] = [];
      for (let i = 0; i < fileList.length; i += 1) {
        const item = fileList.item(i);
        if (item) {
          files.push(item);
        }
      }
      if (files.some(file => file.type.indexOf('image') === -1)) {
        return;
      }
      e.preventDefault();
      const filteredFiles = files.filter(f => f.type.indexOf('image') !== -1);
      const file = filteredFiles.length > 0 ? filteredFiles[0] : undefined;
      if (file) {
        uploader(file);
      }
    },
    [uploader]
  );

  const onDragEnter = () => {
    setDraggedInside(true);
  };

  const onDragLeave = () => {
    setDraggedInside(false);
  };

  return { isDragging, draggedInside, onDragEnter, onDragLeave, onDrop };
};
