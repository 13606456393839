import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

const Memo = () => {
  useEffect(() => {
    console.log('Memo：初回レンダリング');
  }, []);

  console.log('Memo：レンダリング');
  return <Outlet />;
};

export default Memo;
