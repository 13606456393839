import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAltOutlined';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';
import { TodoType } from '../../types';
import Icon from '../ui/Icon';

interface IconsProps {
  size?: string;
  color?: string;
}

export const TodoTypeIcons = ({
  size = '1.2rem',
  color = 'secondary',
}: IconsProps): Record<TodoType, JSX.Element> => ({
  仕事: <Icon icon="suitcase-line" size={size} color={color} />,
  プライベート: <Icon icon="user-line" size={size} color={color} />,
  ルーティン: <Icon icon="calendar-schedule-line" size={size} color={color} />,
});

// 拡張子に対応するアイコン
export const extensionIconMap = new Map<string, string>([
  // コード
  ['html', 'html5-line'],
  ['css', 'css3-fill'],
  ['js', 'javascript-line'],
  ['php', 'php-line'],
  ['tsx', 'reactjs-fill'],
  ['jsx', 'reactjs-fill'],
  ['sql', 'database-2-line'],
  // Adobe
  ['pdf', 'file-pdf-2-line'],
  // office
  ['doc', 'file-word-line'],
  ['docx', 'file-word-line'],
  ['docm', 'file-word-line'],
  ['dot', 'file-word-line'],
  ['xls', 'file-excel-line'],
  ['xlsm', 'file-excel-line'],
  ['xlsx', 'file-excel-line'],
  ['pptx', 'file-ppt-line'],
  ['pptm', 'file-ppt-line'],
  ['ppt', 'file-ppt-line'],
  ['potx', 'file-ppt-line'],
  // 画像
  ['mp4', 'file-video-line'],
  ['webm', 'file-video-line'],
  ['mp3', 'file-music-line'],
  // その他
  ['txt', 'file-text-line'],
  ['zip', 'folder-zip-line'],
]);

export const mentalIcons: {
  [index: string]: {
    icon: React.ReactElement;
    label: string;
  };
} = {
  1: {
    icon: (
      <SentimentVeryDissatisfiedIcon
        color="error"
        sx={{
          fontSize: 'inherit',
        }}
      />
    ),
    label: 'Very Dissatisfied',
  },
  2: {
    icon: (
      <SentimentDissatisfiedIcon
        color="error"
        sx={{
          fontSize: 'inherit',
        }}
      />
    ),
    label: 'Dissatisfied',
  },
  3: {
    icon: (
      <SentimentSatisfiedIcon
        color="warning"
        sx={{
          fontSize: 'inherit',
        }}
      />
    ),
    label: 'Neutral',
  },
  4: {
    icon: (
      <SentimentSatisfiedAltIcon
        color="success"
        sx={{
          fontSize: 'inherit',
        }}
      />
    ),
    label: 'Satisfied',
  },
  5: {
    icon: (
      <SentimentVerySatisfiedIcon
        color="success"
        sx={{
          fontSize: 'inherit',
        }}
      />
    ),
    label: 'Very Satisfied',
  },
};
