import { memo, useEffect, useState } from 'react';
import { Backdrop, Box, CircularProgress, Paper, Stack, Typography } from '@mui/material';
import useGetRequest from '../hooks/useGetRequest';
import SectionTitle from '../components/ui/SectionTitle';
import DataTable from '../components/common/DataTable';
import { center } from '../styles/commonStyles';
import MainContainer from '../components/ui/MainContainer';
import FileUploader from '../components/File/FileUploader';

interface BaseFileList {
  id: number;
  name: string;
  extension: string;
  size: number;
  url: string;
}

interface FileList extends BaseFileList {
  date: Date;
}

interface ResponseFileList extends BaseFileList {
  date: string;
}

const File = () => {
  useEffect(() => {
    console.log('Fileページ:初回レンダリング');
  }, []);
  console.log('Fileページ:レンダリング');

  const [fileList, setFileList] = useState<FileList[] | null>(null);
  const [isUploading, setIsUploading] = useState(false);
  const [isDataFetch, setIsDataFetch] = useState(false);
  const getRequest = useGetRequest();

  // データの取得
  useEffect(() => {
    if (fileList && isUploading) return;
    setIsDataFetch(false);
    getRequest<ResponseFileList[]>({
      apiUrl: process.env.REACT_APP_FILE_API,
    })
      .then(response => {
        if (response.content) {
          const newFileList = response.content.map(file => ({
            ...file,
            date: new Date(file.date),
          }));
          setFileList(newFileList);
        }
      })
      .catch(error => {
        console.error('ファイル一覧の取得に失敗しました:', error);
      })
      .finally(() => {
        setIsDataFetch(true);
      });
  }, [isUploading]);

  return (
    <>
      <MainContainer>
        <Stack
          spacing={6}
          sx={{
            height: '100%',
          }}
        >
          <FileUploader setIsUploading={setIsUploading} />
          <Box>
            <SectionTitle title="アップロード済みのファイル" />
            {fileList ? (
              <DataTable
                table="file"
                apiUrl={process.env.REACT_APP_FILE_API || ''}
                gridRows={fileList}
                isDataFetch={isDataFetch}
              />
            ) : (
              <Paper
                variant="outlined"
                sx={{
                  width: '100%',
                  height: '260px',
                  borderRadius: '6px',
                  ...center,
                }}
              >
                <CircularProgress color="secondary" size={20} />
              </Paper>
            )}
          </Box>
        </Stack>
      </MainContainer>
      <Backdrop
        sx={theme => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
        open={isUploading}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <CircularProgress color="inherit" />
          <Typography variant="body2" color="inherit" sx={{ mt: 2 }}>
            アップロード中...
          </Typography>
        </Box>
      </Backdrop>
    </>
  );
};

export default memo(File);
