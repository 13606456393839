import { memo, useMemo } from 'react';
import { Box, CircularProgress, Stack, Typography } from '@mui/material';
import { differenceInCalendarDays, endOfMonth, startOfMonth } from 'date-fns';
import { formatSeconds } from '../../utils/dateFormatters';
import { Fitness } from '../../types';
import { theme } from '../../theme';
import Icon from '../ui/Icon';
import { center, fontSerif } from '../../styles/commonStyles';

interface FitnessSummaryProps {
  fitnessData: Fitness[] | null;
  currentMonth: Date;
}

const FitnessSummary = ({ fitnessData, currentMonth }: FitnessSummaryProps) => {
  const createFitnessStats = (fitnessData: Fitness[], currentMonth: Date) => {
    // 月の開始日と終了日を取得
    const startOfCurrentMonth = startOfMonth(currentMonth);
    const endOfCurrentMonth = endOfMonth(currentMonth);
    // 月の日数を計算
    const totalDaysInMonth = differenceInCalendarDays(endOfCurrentMonth, startOfCurrentMonth) + 1;
    // 毎日の運動時間を記録するMap
    const dailyFitnessTime = new Map<string, number>();

    fitnessData.forEach(entry => {
      const { date, time } = entry;
      if (time) {
        // 時間を秒に変換
        const timeParts = time.split(':').map(Number);
        const seconds =
          timeParts.length === 3
            ? timeParts[0] * 3600 + timeParts[1] * 60 + timeParts[2]
            : timeParts[0] * 3600 + timeParts[1] * 60;

        // 日付がすでにMapに存在する場合、加算
        if (dailyFitnessTime.has(date)) {
          dailyFitnessTime.set(date, dailyFitnessTime.get(date)! + seconds);
        } else {
          dailyFitnessTime.set(date, seconds);
        }
      }
    });

    // 累計運動時間（秒）
    const totalSeconds = Array.from(dailyFitnessTime.values()).reduce(
      (sum, seconds) => sum + seconds,
      0
    );

    // 平均運動時間（秒）: 月の日数で割る
    const averageSeconds = totalSeconds / totalDaysInMonth;

    return {
      totalTime: formatSeconds(totalSeconds),
      averageTime: formatSeconds(averageSeconds),
    };
  };

  const fitnessStats = useMemo(() => {
    if (!fitnessData) return null;
    return createFitnessStats(fitnessData, currentMonth);
  }, [fitnessData, currentMonth]);

  return (
    <>
      {fitnessStats ? (
        <>
          <Stack direction="row" alignItems="center" sx={{ mb: '1.25rem' }}>
            <Icon icon="run-fill" size="1.5rem" style={{ marginRight: '0.25rem' }} />
            <Typography variant="body2" color={theme.palette.secondary.dark} fontWeight={500}>
              平均運動時間
            </Typography>
          </Stack>
          <Typography
            variant="h5"
            fontWeight={700}
            textAlign={'right'}
            sx={{ ...fontSerif, mb: '0.5rem' }}
          >
            {`${fitnessStats.averageTime} / 日`}
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
            <span
              style={{
                color: theme.palette.text.secondary,
                fontSize: '0.75rem',
                marginRight: '0.5rem',
                padding: '0.35rem 0.75rem',
                lineHeight: 1,
                border: '1px solid',
                borderColor: theme.palette.secondary.main,
                backgroundColor: theme.palette.secondary.light,
                borderRadius: '999px',
              }}
            >
              累計時間
            </span>
            <Typography fontWeight={700} sx={{ ...fontSerif, fontSize: '1.15rem' }}>
              {fitnessStats.totalTime}
            </Typography>
          </Box>
        </>
      ) : (
        <Box
          sx={{
            width: '100%',
            height: '100%',
            minHeight: '111px',
            ...center,
          }}
        >
          <CircularProgress color="secondary" size={20} />
        </Box>
      )}
    </>
  );
};

export default memo(FitnessSummary);
