import { isTextSelection } from '@tiptap/core';
import { Editor } from '@tiptap/react';

// 現在、テキストが選択されているかどうかの判定
export const isTextSelected = ({ editor }: { editor: Editor }) => {
  const {
    state: {
      doc,
      selection,
      selection: { empty, from, to },
    },
  } = editor;

  // `empty`のチェックだけでは、空の段落をダブルクリックした場合にノードサイズが2になる
  // そのため、空のテキストサイズも確認している
  const isEmptyTextBlock = !doc.textBetween(from, to).length && isTextSelection(selection);

  if (empty || isEmptyTextBlock || !editor.isEditable) {
    return false;
  }

  return true;
};

export default isTextSelected;
