import { memo, useCallback } from 'react';
import { BubbleMenu as BaseBubbleMenu } from '@tiptap/react';
import { isColumnGripSelected } from './utils';
import { MenuProps, ShouldShowProps } from '../../../../types';
import { Toolbar } from '../../../../../../ui/Toolbar';
import { zEditorMenu } from '../../../../../../../styles/commonStyles';

export const TableColumnMenu = memo(({ editor, appendTo }: MenuProps): JSX.Element => {
  const shouldShow = useCallback(
    ({ view, state, from }: ShouldShowProps) => {
      if (!state) {
        return false;
      }

      return isColumnGripSelected({ editor, view, state, from: from || 0 });
    },
    [editor]
  );

  const onAddColumnBefore = useCallback(() => {
    editor.chain().focus().addColumnBefore().run();
  }, [editor]);

  const onAddColumnAfter = useCallback(() => {
    editor.chain().focus().addColumnAfter().run();
  }, [editor]);

  const onDeleteColumn = useCallback(() => {
    editor.chain().focus().deleteColumn().run();
  }, [editor]);
  return (
    <BaseBubbleMenu
      editor={editor}
      pluginKey="tableColumnMenu"
      updateDelay={0}
      tippyOptions={{
        zIndex: zEditorMenu.zIndex + 1,
        appendTo: () => appendTo?.current as HTMLElement,
        offset: [0, 15],
        popperOptions: {
          modifiers: [{ name: 'flip', enabled: false }],
        },
      }}
      shouldShow={shouldShow}
    >
      <Toolbar.Wrapper>
        <Toolbar.Button
          icon="insert-column-right"
          ariaLabel="前に列を追加"
          onClick={onAddColumnBefore}
          tooltip="前に列を追加"
          isActive={false}
        />
        <Toolbar.Button
          icon="insert-column-left"
          ariaLabel="後ろに列を追加"
          onClick={onAddColumnAfter}
          tooltip="後ろに列を追加"
          isActive={false}
        />
        <Toolbar.Button
          icon="delete-column"
          ariaLabel="列の削除"
          onClick={onDeleteColumn}
          tooltip="列の削除"
          isActive={false}
        />
      </Toolbar.Wrapper>
    </BaseBubbleMenu>
  );
});

TableColumnMenu.displayName = 'TableColumnMenu';

export default TableColumnMenu;
