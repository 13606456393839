import { memo } from 'react';
import { Typography } from '@mui/material';
import { bgBlack, fontSerif } from '../../styles/commonStyles';

type SectionTitleProps = {
  title: string;
};

const SectionTitle = ({ title }: SectionTitleProps) => (
  <Typography
    variant="h5"
    component="h3"
    fontWeight={700}
    mb={2}
    sx={{
      ...fontSerif,
      lineHeight: 1.35,
      paddingLeft: '1rem',
      position: 'relative',
      '&:before': {
        content: '""',
        position: 'absolute',
        top: '50%',
        left: 0,
        transform: 'translateY(-50%)',
        width: '4px',
        height: '90%',
        ...bgBlack,
      },
    }}
  >
    {title}
  </Typography>
);

export default memo(SectionTitle);
