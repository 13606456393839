import axios, { AxiosError, AxiosResponse } from 'axios';
import useSessionExpiration from './useSessionExpiration';
import { useAuthContext } from '../contexts/AuthContext';

interface GetRequestResponse<T> {
  success: boolean;
  content: T | null;
  error?: string;
}

const useGetRequest = () => {
  const { userId, csrfToken } = useAuthContext();
  const sessionExpireHandler = useSessionExpiration();

  const getRequest = async <T>({
    apiUrl,
    id,
    target,
    tableType,
    data,
  }: {
    apiUrl: string | undefined;
    id?: string;
    target?: 'order' | 'category' | 'DB';
    tableType?: 'project' | 'memo' | 'gallery';
    data?: object;
  }): Promise<GetRequestResponse<T>> => {
    if (!apiUrl) {
      alert('APIが設定されていないため、データを取得できません');
      return Promise.reject(new Error('API URL is not defined'));
    }
    try {
      const response: AxiosResponse<GetRequestResponse<T>> = await axios.post(
        apiUrl,
        {
          userId,
          csrfToken,
          action: 'get',
          ...{ ...(id ? { id } : {}) },
          ...{ ...(target ? { target } : {}) },
          ...{ ...(tableType ? { tableType } : {}) },
          ...{ ...(data ? { data } : {}) },
        },
        {
          withCredentials: true,
        }
      );
      if (response.data.success) {
        return response.data; // 全データを返す
      }
      throw new Error(response.data.error || 'Data acquisition failed');
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const axiosError = error as AxiosError<GetRequestResponse<T>>;
        if (axiosError.response) {
          const { status, data } = axiosError.response;
          if (status === 403 || status === 401) {
            console.error(`${status}:`, data.error);
            sessionExpireHandler();
          }
        }
      } else {
        alert('データの取得に失敗しました');
        console.error(error);
      }
      throw error;
    }
  };
  return getRequest;
};

export default useGetRequest;
