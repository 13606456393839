import axios, { AxiosError, AxiosResponse } from 'axios';
import useSessionExpiration from './useSessionExpiration';
import { useAuthContext } from '../contexts/AuthContext';

interface SaveRequestResponse {
  success: boolean;
  message?: string;
  error?: string;
  id?: number;
  ids?: number[];
  taskTimeIds?: number[];
  name?: string;
}

const useSaveRequest = () => {
  const { userId, csrfToken } = useAuthContext();
  const sessionExpireHandler = useSessionExpiration();

  const saveRequest = async ({
    apiUrl,
    id,
    target,
    data,
  }: {
    apiUrl: string | undefined;
    id?: string;
    target?: string;
    data: string | number | object | null;
  }): Promise<SaveRequestResponse> => {
    if (!apiUrl) {
      alert('APIが設定されていないため、保存できません');
      return Promise.reject(new Error('API URL is not defined'));
    }
    try {
      const response: AxiosResponse<SaveRequestResponse> = await axios.post(
        apiUrl,
        {
          userId,
          csrfToken,
          id,
          action: 'save',
          ...{ ...(target ? { target } : {}) },
          data,
        },
        {
          withCredentials: true,
        }
      );
      if (response.data.success) {
        console.log(response.data.message); // 確認用
        return response.data; // 全データを返す
      }
      throw new Error(response.data.error || 'Update failed');
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const axiosError = error as AxiosError<SaveRequestResponse>;
        if (axiosError.response) {
          const { status, data } = axiosError.response;
          if (status === 403 || status === 401) {
            console.error(`${status}:`, data.error);
            sessionExpireHandler();
          }
        }
      } else {
        alert('データの更新に失敗しました');
        console.error(error);
      }
      throw error;
    }
  };

  return saveRequest;
};

export default useSaveRequest;
