import { memo, useCallback, useEffect, useRef } from 'react';
import { Box } from '@mui/material';
import { debounce } from 'lodash';
import { EditorContent, JSONContent } from '@tiptap/react';
import ImageBlockMenu from './extensions/ImageBlock/components/ImageBlockMenu';
import { TableColumnMenu, TableRowMenu } from './extensions/Table/menus';
import { ColumnsMenu } from './extensions/MultiColumn/menus';
import { useBlockEditor } from './hooks/useBlockEditor';
import useSaveRequest from '../../../hooks/useSaveRequest';
import TableOfContents from './components/TableOfContents';
import ContentItemMenu from './components/ContentItemMenu';
import TextMenu from './components/TextMenu';
import LinkMenu from './components/LinkMenu';
import './style.scss';

interface BlockEditorProps {
  id: string;
  content: JSONContent;
  apiUrl: string;
  tableOfContents?: boolean;
}

const BlockEditor = ({ id, content, apiUrl, tableOfContents = false }: BlockEditorProps) => {
  const menuContainerRef = useRef(null);
  const { editor, currentNode, currentNodePos, currentNodeDepth } = useBlockEditor({
    content,
    autofocus: tableOfContents,
  });
  const saveRequest = useSaveRequest();

  if (!editor) {
    return null;
  }

  const handleUpdate = useCallback(
    debounce(() => {
      saveRequest({
        apiUrl,
        id,
        target: 'content',
        data: JSON.stringify(editor.getJSON()),
      }).catch(error => {
        console.error('BlockEditorの更新に失敗しました:', error);
      });
    }, 1000),
    [id, saveRequest, apiUrl]
  );

  useEffect(() => {
    if (!editor) return;
    // エディターの内容が変更されるたびに保存
    editor.on('update', handleUpdate);
    return () => {
      editor.off('update', handleUpdate); // クリーンアップ
    };
  }, []);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: { xs: 'column-reverse', sm: 'row' },
        gap: '1rem',
        height: '100%',
        width: '100%',
      }}
      ref={menuContainerRef}
    >
      <Box
        sx={{
          position: 'relative',
          flexGrow: 1,
          flexShrink: 1,
          flexBasis: { xs: '100%', sm: '70%' },
          height: '100%',
        }}
      >
        <EditorContent editor={editor} className="blockEditor" />
        <ImageBlockMenu editor={editor} appendTo={menuContainerRef} />
        <TableColumnMenu editor={editor} appendTo={menuContainerRef} />
        <TableRowMenu editor={editor} appendTo={menuContainerRef} />
        <ColumnsMenu editor={editor} appendTo={menuContainerRef} />
        <LinkMenu editor={editor} appendTo={menuContainerRef} />
        <TextMenu editor={editor} />
        <ContentItemMenu
          editor={editor}
          currentNode={currentNode}
          currentNodePos={currentNodePos}
          currentNodeDepth={currentNodeDepth}
        />
      </Box>
      {tableOfContents && <TableOfContents editor={editor} />}
    </Box>
  );
};

export default memo(BlockEditor);
