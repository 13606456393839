import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

interface Post {
  id: number;
  title: string;
  body: string;
}

const GalleryPost = () => {
  const { postId } = useParams(); // postIdの数字取得
  const [post, setPost] = useState<Post | null>(null); // 初期値をnullに変更し、型をPost | nullに

  useEffect(() => {
    /* eslint-disable */
    // useEffect内で関数の定義
    const fetchPost = async () => {
      const res = await fetch(
        `https://jsonplaceholder.typicode.com/posts/${postId}` // ここでpostIdを使用する
      );
      const data = await res.json();
      setPost(data); // postで使えるようにセットする
    };
    fetchPost(); // 関数起動
  }, [postId]);
  /* eslint-disable */
  return (
    <div>
      <h2>記事のページ</h2>
      <div>
        {post ? (
          <>
            <p>postId: {post.id}</p>
            <p>タイトル: {post.title}</p>
            <p>body: {post.body}</p>
          </>
        ) : (
          <p>Loading...</p> // データがまだない場合の表示
        )}
      </div>
    </div>
  );
};

export default GalleryPost;
