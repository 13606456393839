import { forwardRef, useCallback, useState } from 'react';
import { Button, TextField } from '@mui/material';
import AddCircleSharpIcon from '@mui/icons-material/AddCircleSharp';
import { debounce } from 'lodash';
import classNames from 'classnames';
import useSaveRequest from '../../../../../hooks/useSaveRequest';
import { useProjectContext } from '../../../../../contexts/ProjectContext';
import { Handle, Remove } from '../Action';
import './Container.scss';

export interface ContainerProps {
  children: React.ReactNode;
  id: string;
  label?: string;
  style?: React.CSSProperties;
  hover?: boolean;
  handleProps?: React.HTMLAttributes<HTMLButtonElement>;
  shadow?: boolean;
  onClick?(): void;
  onRemove?(): void;
  setCurrentTodoId: React.Dispatch<React.SetStateAction<number>>;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setIsSectionId: React.Dispatch<React.SetStateAction<string | null>>;
  setButtonElement: (target: HTMLButtonElement | null) => void;
}

export const Container = forwardRef<HTMLDivElement, ContainerProps>(
  (
    {
      children,
      id,
      handleProps,
      hover,
      onClick,
      onRemove,
      label,
      style,
      shadow,
      setCurrentTodoId,
      setIsModalOpen,
      setIsSectionId,
      setButtonElement,
      ...props
    }: ContainerProps,
    ref
  ) => {
    const [isError, setIsError] = useState(false);
    const { setSectionData } = useProjectContext();
    const saveRequest = useSaveRequest();

    const debouncedSaveRequest = useCallback(
      debounce((data: string) => {
        saveRequest({
          apiUrl: process.env.REACT_APP_SECTION_API,
          id,
          target: 'name',
          data,
        })
          .then(() => {
            setSectionData(prev => {
              const newMap = new Map(prev);
              newMap.set(id, {
                ...newMap.get(id)!,
                name: data,
              });
              return newMap;
            });
          })
          .catch(error => {
            console.error('セクション名の更新に失敗しました:', error);
          });
      }, 1000),
      [id, saveRequest]
    );

    // セクション名の更新
    const nameUpdate = useCallback(
      (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (e.target.value.length < 100) {
          debouncedSaveRequest(e.target.value);
          setIsError(false);
        } else {
          setIsError(true);
        }
      },
      [debouncedSaveRequest]
    );

    return (
      <div
        {...props}
        ref={ref}
        style={
          {
            ...style,
          } as React.CSSProperties
        }
        className={classNames('container', hover && 'hover', shadow && 'shadow')}
        onClick={onClick}
        tabIndex={-1} // キーボード操作でのフォーカスを無効化
      >
        {label ? (
          <div className="containerHeader">
            {id === 'sec_0' ? (
              <>
                <p
                  style={{
                    fontWeight: 500,
                    lineHeight: 1.5,
                  }}
                >
                  {label}
                </p>
              </>
            ) : (
              <TextField
                fullWidth
                multiline
                hiddenLabel
                spellCheck="false"
                autoComplete="off"
                variant="outlined"
                name="name"
                id="name"
                defaultValue={label}
                error={isError}
                helperText={isError ? '100文字以内で入力してください' : ''}
                onChange={e => nameUpdate(e)}
                sx={{
                  backgroundColor: 'transparent',
                  '& .MuiInputBase-root': {
                    padding: 0,
                    backgroundColor: 'transparent',
                  },
                  '& .MuiInputBase-input': {
                    fontWeight: 500,
                    lineHeight: 1.5,
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    border: 'none !important',
                  },
                  '.MuiFormHelperText-root.Mui-error': {
                    marginLeft: 0,
                  },
                }}
              />
            )}
            {id !== 'sec_0' && (
              <div className="containerActions">
                {onRemove ? <Remove onClick={onRemove} /> : undefined}
                <Handle {...handleProps} />
              </div>
            )}
          </div>
        ) : null}
        <ul>
          {children}
          <li>
            <Button
              fullWidth
              size="small"
              startIcon={<AddCircleSharpIcon color="primary" />}
              onClick={e => {
                setButtonElement(e.currentTarget);
                setIsSectionId(id === 'sec_0' ? null : id);
                setCurrentTodoId(0);
                setIsModalOpen(true);
              }}
              sx={{ textTransform: 'none', letterSpacing: 0.5 }}
            >
              ToDoを追加
            </Button>
          </li>
        </ul>
      </div>
    );
  }
);

Container.displayName = 'Container';
