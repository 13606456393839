import remixiconUrl from 'remixicon/fonts/remixicon.symbol.svg';
import { theme } from '../../theme';

const Icon = ({
  icon,
  color = 'secondary',
  size = '1.25rem',
  style,
}: {
  icon: string;
  size?: string;
  color?: string;
  style?: React.CSSProperties;
}) => {
  const fillColor =
    color === 'primary'
      ? theme.palette.primary.main
      : color === 'secondary'
        ? theme.palette.secondary.main
        : color;
  return (
    <svg
      className="remix"
      style={{
        width: size,
        height: size,
        fill: fillColor,
        ...style,
      }}
    >
      <use xlinkHref={`${remixiconUrl}#ri-${icon}`} />
    </svg>
  );
};

export default Icon;
