import { PaletteColor, PaletteColorOptions, createTheme } from '@mui/material';
import { CSSObject } from '@mui/system';

declare module '@mui/material/styles' {
  interface Palette {
    incomeColor: PaletteColor;
    expenseColor: PaletteColor;
    balanceColor: PaletteColor;
    privateColor: PaletteColor;
    workColor: PaletteColor;
    routineColor: PaletteColor;
  }
  interface PaletteOptions {
    incomeColor?: PaletteColorOptions;
    expenseColor?: PaletteColorOptions;
    balanceColor?: PaletteColorOptions;
    privateColor?: PaletteColorOptions;
    workColor?: PaletteColorOptions;
    routineColor?: PaletteColorOptions;
  }
  interface Components {
    MuiLoadingButton?: {
      styleOverrides?: {
        root?: CSSObject;
        loading?: CSSObject;
      };
    };
  }
}

// 色とフォントを設定
// eslint-disable-next-line import/no-mutable-exports
let theme = createTheme({
  typography: {
    fontFamily:
      "'Zen Kaku Gothic New', 'Helvetica Neue','Helvetica', 'Arial', 'Hiragino Sans', 'ヒラギノ角ゴシック', YuGothic, 'Yu Gothic', Meiryo, sans-serif",
  },
  palette: {
    text: {
      primary: '#333333',
    },
    action: {
      hoverOpacity: 0.075,
      hover: 'rgba(0, 0, 0, 0.05)',
      focus: 'rgba(0, 0, 0, 0.05)',
      selectedOpacity: 0.075,
    },
    divider: 'rgba(0, 0, 0, 0.18)',
    primary: {
      main: '#444444',
    },
    secondary: {
      main: '#999999',
      light: '#f3f3f3',
      dark: '#707070',
    },
    incomeColor: {
      main: '#44577B',
      light: '#A0BEDC',
      dark: '#2B3955',
    },
    expenseColor: {
      main: '#994a54',
      light: '#D4827E',
      dark: '#790011',
    },
    balanceColor: {
      main: '#6E989B',
      light: '#C1DBD9',
      dark: '#235658',
    },
    privateColor: {
      main: '#788BA9',
      light: '#bad0e5',
      dark: '#364b7b',
    },
    workColor: {
      main: '#c18585',
      light: '#ebcfcf',
      dark: '#923535',
    },
    routineColor: {
      main: '#6E989B',
      light: '#c1dbd9',
      dark: '#235658',
    },
  },
});

// テーマの上書き
theme = createTheme(theme, {
  components: {
    MuiSvgIcon: {
      defaultProps: {
        color: 'secondary',
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          '.MuiSvgIcon-root': {
            color: theme.palette.primary.main,
          },
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: 0,
          lineHeight: 0,
          marginRight: '0.5rem',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: '0.875rem',
          minHeight: 0,
          padding: '0.25rem 0.5rem',
          whiteSpace: 'normal',
          borderRadius: '3px',
          '& .MuiListItemIcon-root': {
            minWidth: 0,
            marginTop: '1px',
            marginRight: '0.4rem',
            marginLeft: '-0.15rem',
          },
          '& .MuiListItemIcon-root .MuiSvgIcon-root': {
            fontSize: '0.875rem',
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          boxShadow: 'none !important',
          outlineColor: '#000',
          textTransform: 'capitalize',
        },
        contained: {
          '&.MuiButton-containedPrimary:not(.Mui-disabled)': {
            backgroundColor: 'transparent',
            backgroundImage:
              'linear-gradient(45deg,rgba(40, 40, 40, 0.93),rgba(40, 40, 40, 0.93)),url(/img/noise.webp)',
            backgroundSize: 'auto, 125px',
            color: 'white',
            '@media (hover: hover)': {
              '&.MuiButton-contained:not(.Mui-disabled):hover': {
                boxShadow: 'none',
                backgroundImage:
                  'linear-gradient(180deg, rgba(80, 80, 80, 0.93), rgba(80, 80, 80, 0.93)), url(/img/noise.webp)',
              },
            },
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          borderRadius: '3px',
          '& .MuiTouchRipple-child': {
            borderRadius: '3px !important',
          },
        },
      },
    },
    MuiLoadingButton: {
      styleOverrides: {
        root: {
          borderRadius: '3px',
          '&.MuiButton-contained': {
            boxShadow: 'none !important',
            padding: '0.5rem 1rem',
            position: 'relative',
            overflow: 'hidden',
            backgroundColor: 'transparent',
            backgroundImage:
              'linear-gradient(45deg,rgba(40, 40, 40, 0.93),rgba(40, 40, 40, 0.93)),url(/img/noise.webp)',
            backgroundSize: 'auto, 125px',
            color: 'white',
          },
          '&.MuiButton-contained::before': {
            content: "''",
            position: 'absolute',
            top: '50%',
            left: '50%',
            width: 'calc(100% - 6px)',
            height: 'calc(100% - 6px)',
            border: '1px solid #fff',
            borderRadius: '2px',
            transform: 'translate(-50%, -50%)',
            zIndex: 2,
          },
          '@media (hover: hover)': {
            '&.MuiButton-contained:hover': {
              boxShadow: 'none',
              backgroundImage:
                'linear-gradient(180deg, rgba(100, 100, 100, 0.93), rgba(100, 100, 100, 0.93)), url(/img/noise.webp)',
            },
          },
          '&.MuiButton-contained.MuiLoadingButton-loading': {
            backgroundImage:
              'linear-gradient(180deg, rgba(100, 100, 100, 0.93), rgba(100, 100, 100, 0.93)), url(/img/noise.webp)',
          },
          '&.MuiButton-contained .MuiCircularProgress-root': {
            color: '#fff',
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: '10px',
        },
        head: {
          borderBottom: '1px solid #444444',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          backgroundColor: '#fff',
          borderRadius: '3px',
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.secondary.light,
          borderRadius: '3px',
          '&::before': {
            display: 'none',
          },
          '&::after': {
            display: 'none',
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: '4px',
        },
        variant: {
          outlined: {
            border: '1px solid',
            borderColor: theme.palette.divider,
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          '& .MuiFilledInput-root': {
            paddingTop: '0.25rem !important',
            paddingBottom: '0.25rem !important',
            paddingLeft: '0.25rem !important',
          },
          '& .MuiFilledInput-root.MuiInputBase-sizeSmall .MuiFilledInput-input': {
            backgroundColor: 'transparent',
            padding: '2.5px !important',
            border: 'none !important',
          },
        },
        popper: {
          '.MuiAutocomplete-listbox': {
            fontSize: '0.875rem',
          },
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          backgroundColor: '#fff',
          borderRadius: '6px',
          '.MuiDataGrid-toolbarContainer': {
            padding: '6px',
            backgroundColor: 'transparent',
            backgroundImage:
              'linear-gradient(45deg,rgba(40, 40, 40, 0.93),rgba(40, 40, 40, 0.93)),url(/img/noise.webp)',
            backgroundSize: 'auto, 125px',
            color: 'white',
          },
          '.MuiDataGrid-row--borderBottom .MuiDataGrid-columnHeader': {
            borderBottom: '1px solid',
            borderColor: theme.palette.text.primary,
          },
          '.MuiDataGrid-row--borderBottom .MuiDataGrid-filler': {
            borderBottom: '1px solid',
            borderColor: theme.palette.text.primary,
          },
          '.MuiDataGrid-cell.MuiDataGrid-cell--editable.MuiDataGrid-cell--editing': {
            height: 'var(--height)',
            padding: '4px 2px',
          },
          '.MuiDataGrid-cell.MuiDataGrid-cell--editing:focus-within': {
            outline: 'none',
          },
          '.MuiDataGrid-row.MuiDataGrid-row--editable.MuiDataGrid-row--editing input[type="text"].MuiInputBase-input':
            {
              padding: '0 8px',
            },
          '.MuiDataGrid-row.MuiDataGrid-row--editable.MuiDataGrid-row--editing input[type="date"].MuiInputBase-input':
            {
              padding: '0 8px',
              height: 'calc(100% - 1px)',
            },
          '.MuiDataGrid-row.MuiDataGrid-row--editable.MuiDataGrid-row--editing input[type="number"].MuiInputBase-input':
            {
              padding: '0 8px',
            },
          '.MuiSelect-select.MuiInputBase-input': {
            fontSize: '0.875rem',
          },
          'input.MuiInputBase-input:focus': {
            outline: `solid 2px ${theme.palette.text.primary}`,
            outlineOffset: '-1px',
          },
          '.MuiDataGrid-cell:focus': {
            borderRadius: '3px',
          },
          '.MuiDataGrid-cell.actions': {
            outline: 'none',
          },
        },
      },
    },
    MuiPopper: {
      styleOverrides: {
        root: {
          '&.MuiDataGrid-panel input': {
            padding: '4px 0 5px',
            border: 'none',
          },
          '&.MuiDataGrid-menu .MuiButtonBase-root.MuiMenuItem-root': {
            borderRadius: '0',
          },
        },
      },
    },
  },
});

export { theme };
