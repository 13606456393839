import { useMemo } from 'react';
import { BarChart } from '@mui/x-charts/BarChart';
import { differenceInSeconds, parseISO } from 'date-fns';
import { useProjectContext } from '../../contexts/ProjectContext';
import { useTodoContext } from '../../contexts/TodoContext';
import useGetElementHeight from '../../hooks/useGetElementHeight';
import { formatMinutes } from '../../utils/dateFormatters';
import { chartColor } from '../../styles/commonStyles';

const valueFormatter = (value: number | null) => {
  if (value === null) return '';
  const minutes = Math.round(value * 60);
  return `${formatMinutes(minutes)}`;
};

const yLabels = ['見積\n時間', '作業\n時間'];

interface TaskTimeBarProps {
  sectionIds: string[];
  todoIdsBySection: Record<string, number[]>;
}

const TaskTimeBar = ({ todoIdsBySection, sectionIds }: TaskTimeBarProps) => {
  const { todoData, taskTimeData } = useTodoContext();
  const { sectionData } = useProjectContext();
  const chartsLegendHeight = useGetElementHeight('.MuiChartsLegend-root.MuiChartsLegend-row');

  const createChartData = () => {
    const calculateCumulativeTime = (id: number): number => {
      if (!taskTimeData) return 0;

      // Map を配列に変換して reduce を適用
      return Array.from(taskTimeData.values()).reduce((cumulativeTime, task) => {
        if (id === task.todoId && task.start && task.end) {
          const startTime = parseISO(task.start);
          const endTime = parseISO(task.end);
          const durationInSeconds = differenceInSeconds(endTime, startTime);
          // 差分が正の場合のみ累積
          return durationInSeconds > 0 ? cumulativeTime + durationInSeconds : cumulativeTime;
        }
        return cumulativeTime;
      }, 0);
    };

    const data = useMemo(() => {
      if (!todoIdsBySection || !sectionIds || !sectionData || !todoData) return [];

      return sectionIds.map((sectionId, index) => {
        // セクション名取得
        const label =
          sectionId === 'sec_0' ? '未分類' : sectionData.get(sectionId)?.name || '不明のセクション';

        // 各セクションの todoId を取得
        const todoIds = todoIdsBySection[sectionId] || [];

        // totalEstimatedTime の計算
        const totalEstimatedTime = todoIds.reduce((total, todoId) => {
          const estimated = todoData.get(todoId)?.estimated;
          if (estimated && estimated !== '00:00:00' && estimated !== '00:00') {
            const [hours, minutes, seconds = 0] = estimated.split(':').map(Number);
            const totalSeconds = hours * 3600 + minutes * 60 + seconds;
            const totalMinutes = Math.round(totalSeconds / 60); // 分に変換
            return total + totalMinutes / 60; // 時間単位の小数に変換
          }
          return total;
        }, 0);

        // totalTaskTime の計算
        const totalTaskTime = todoIds.reduce(
          (total, todoId) => total + calculateCumulativeTime(todoId) / 3600, // 秒を時間単位の小数に変換
          0
        );

        return {
          data: [totalEstimatedTime, totalTaskTime],
          label,
          id: sectionId,
          color: sectionId === 'sec_0' ? '#D6D6D6' : chartColor[index % chartColor.length],
          stack: 'total',
          valueFormatter,
        };
      });
    }, [todoIdsBySection, todoData, taskTimeData, sectionData, sectionIds]);

    return data;
  };

  const data = createChartData();

  return (
    <BarChart
      series={data}
      layout="horizontal"
      yAxis={[
        {
          data: yLabels,
          scaleType: 'band',
        },
      ]}
      barLabel={item => {
        if (item.value === 0) return null;
        return item.value ? formatMinutes(Math.round(item.value * 60)) : null;
      }}
      slotProps={{
        legend: {
          itemMarkWidth: 15,
          itemMarkHeight: 15,
          labelStyle: {
            fontSize: 12,
            fontWeight: 500,
            lineHeight: 1.35,
          },
        },
      }}
      margin={{ left: 40, right: 16, bottom: 30, top: chartsLegendHeight || 50 }}
      sx={{
        width: '100%',
        height: 'auto',
        '& .MuiChartsAxis-tickLabel': {
          fontSize: '12px !important',
          fontWeight: 500,
        },
        '& .MuiBarLabel-root': {
          fontSize: '12px !important',
          fill: '#fff !important',
        },
      }}
    />
  );
};

export default TaskTimeBar;
