import { unstable_batchedUpdates } from 'react-dom';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../contexts/AuthContext';

// セッション切れ処理
const useSessionExpiration = () => {
  const navigate = useNavigate();
  const { setUserId, setCsrfToken, setEntryURL, setLoginAuth } = useAuthContext();

  return () => {
    alert('セッションが切れました。再度ログインしてください。');
    unstable_batchedUpdates(() => {
      setUserId('');
      setCsrfToken('');
      setEntryURL('/');
      setLoginAuth(false);
    });
    navigate('/login');
  };
};

export default useSessionExpiration;
