import {
  // Box,
  Button,
  // Card,
  // CardActionArea,
  // CardContent,
  // Checkbox,
  Drawer,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material';
import AddCircleSharpIcon from '@mui/icons-material/AddCircleSharp';
import { format } from 'date-fns';
import { theme } from '../../theme';
import { MenuItemMap, Todo } from '../../types';
// import { formatSeconds } from '../../utils/dateFormatters';
// import { todoDarkColor, todoMainColor } from '../../utils/getColors';
import { fontSerif, scrollbarTransparent } from '../../styles/commonStyles';
// import Icon from '../ui/Icon';
// import useSaveRequest from '../../hooks/useSaveRequest';
// import Todo from '../../pages/Todo';
import TodoCard from '../common/TodoCard';

interface DailyInfoProps {
  todoData: Map<number, Todo> | null;
  setTodoData: React.Dispatch<React.SetStateAction<Map<number, Todo> | null>>;
  projectData: MenuItemMap;
  totalTaskTimes: Map<number, number> | undefined;
  currentDay: string;
  currentDayTodoIds: number[];
  setCurrentTodoId: React.Dispatch<React.SetStateAction<number>>;
  isDrawerOpen: boolean;
  setIsDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setButtonElement: (target: HTMLButtonElement | null) => void;
}

const DrawerRight = ({
  todoData,
  // setTodoData,
  projectData,
  // totalTaskTimes,
  currentDay,
  currentDayTodoIds,
  setCurrentTodoId,
  isDrawerOpen,
  setIsDrawerOpen,
  setIsModalOpen,
  setButtonElement,
}: DailyInfoProps) => {
  const menuDrawerWidth = 320;
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  // const saveRequest = useSaveRequest();

  // const toggleCompleted = useCallback((checked: number, id: number) => {
  //   const newCompleted = checked === 0 ? 1 : 0;
  //   saveRequest({
  //     apiUrl: process.env.REACT_APP_TODO_API,
  //     id: String(id),
  //     target: 'completed',
  //     data: newCompleted,
  //   })
  //     .then(() => {
  //       setTodoData(prev => {
  //         const newMap = new Map(prev);
  //         newMap.set(id, {
  //           ...(newMap.get(id) as Todo),
  //           completed: newCompleted,
  //         });
  //         return newMap;
  //       });
  //     })
  //     .catch(error => {
  //       console.error('完了の更新に失敗しました:', error);
  //     });
  // }, []);

  return (
    <Drawer
      variant={isMobile ? 'temporary' : 'permanent'}
      anchor={isMobile ? 'bottom' : 'right'}
      open={isDrawerOpen}
      onClose={() => {
        setIsDrawerOpen(false);
      }}
      ModalProps={{
        keepMounted: true,
      }}
      sx={{
        width: isMobile ? 'auto' : menuDrawerWidth,
        backgroundColor: 'transparent',
        '& .MuiDrawer-paper': {
          width: isMobile ? 'auto' : menuDrawerWidth,
          boxSizing: 'border-box',
          border: 'none',
          ...scrollbarTransparent,
          ...(isMobile && {
            height: '80vh',
            borderRadius: '8 8 0 0',
            backgroundColor: '#fff',
          }),
          ...(!isMobile && {
            top: 0,
            height: '100vh',
            borderRadius: 0,
            backgroundColor: 'transparent',
          }),
        },
      }}
    >
      <Stack component="section" padding={2.5} spacing={2}>
        <Typography
          component={'h2'}
          fontWeight={'bold'}
          variant="h6"
          sx={{ ...fontSerif, letterSpacing: '0.025em', fontWeight: 700 }}
        >
          {format(currentDay, 'yyyy年M月d日')}
        </Typography>
        {currentDayTodoIds.map((id, index) => {
          if (!todoData || !projectData) return null;
          return (
            <TodoCard
              key={index}
              data={todoData.get(id) as Todo}
              changeColor={true}
              setCurrentTodoId={setCurrentTodoId}
              setIsModalOpen={setIsModalOpen}
              pageType="home"
              setButtonElement={setButtonElement}
            />
          );
        })}
        <Button
          fullWidth
          startIcon={<AddCircleSharpIcon color="primary" />}
          onClick={e => {
            setButtonElement(e.currentTarget);
            setCurrentTodoId(0);
            setIsModalOpen(true);
          }}
          sx={{ textTransform: 'none', letterSpacing: 0.5 }}
        >
          ToDoを追加
        </Button>
      </Stack>
    </Drawer>
  );
};
export default DrawerRight;
