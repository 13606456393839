import { createContext, useContext, useState } from 'react';
import { TaskTime, Todo } from '../types';

interface TodoContextType {
  todoData: Map<number, Todo> | null;
  setTodoData: React.Dispatch<React.SetStateAction<Map<number, Todo> | null>>;
  taskTimeData: Map<number, TaskTime> | null;
  setTaskTimeData: React.Dispatch<React.SetStateAction<Map<number, TaskTime> | null>>;
}

export const TodoContext = createContext<TodoContextType | undefined>(undefined);

export const TodoContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [todoData, setTodoData] = useState<Map<number, Todo> | null>(null);
  const [taskTimeData, setTaskTimeData] = useState<Map<number, TaskTime> | null>(null);

  return (
    <TodoContext.Provider
      value={{
        todoData,
        setTodoData,
        taskTimeData,
        setTaskTimeData,
      }}
    >
      {children}
    </TodoContext.Provider>
  );
};

export const useTodoContext = () => {
  const context = useContext(TodoContext);
  if (!context) {
    throw new Error('TodoContextをプロバイダーの中で取得してください');
  }
  return context;
};
