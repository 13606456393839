import axios, { AxiosError, AxiosResponse } from 'axios';
import useSessionExpiration from './useSessionExpiration';
import { useAuthContext } from '../contexts/AuthContext';

interface DeleteRequestResponse {
  success: boolean;
  message?: string;
  error?: string;
  deletedIds?: number[];
}

const useDeleteRequest = () => {
  const { userId, csrfToken } = useAuthContext();
  const sessionExpireHandler = useSessionExpiration();

  const deleteRequest = async ({
    apiUrl,
    id,
    target,
    data,
  }: {
    apiUrl: string | undefined;
    id?: string;
    target?: string;
    data?: object;
  }): Promise<DeleteRequestResponse> => {
    if (!apiUrl) {
      alert('APIが設定されていないため、削除できません');
      return Promise.reject(new Error('API URL is not defined'));
    }
    try {
      const response: AxiosResponse<DeleteRequestResponse> = await axios.post(
        apiUrl,
        {
          userId,
          csrfToken,
          ...{ ...(id ? { id } : {}) },
          action: 'delete',
          ...{ ...(target ? { target } : {}) },
          ...{ ...(data ? { data } : {}) },
        },
        {
          withCredentials: true,
        }
      );
      if (response.data.success) {
        console.log(response.data.message); // 確認用
        return response.data; // 全データを返す
      }
      throw new Error(response.data.error || 'delete failed');
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const axiosError = error as AxiosError<DeleteRequestResponse>;
        if (axiosError.response) {
          const { status, data } = axiosError.response;
          if (status === 403 || status === 401) {
            console.error(`${status}:`, data.error);
            sessionExpireHandler();
          }
        }
      } else {
        alert('データの削除に失敗しました');
        console.error(error);
      }
      throw error;
    }
  };
  return deleteRequest;
};

export default useDeleteRequest;
