import { memo, useCallback, useMemo, useState } from 'react';
import {
  Box,
  CircularProgress,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { PieChart } from '@mui/x-charts/PieChart';
import { convertHexToRgb, convertRgbToHex } from '../../utils/convertColor';
import { ExpenseCategory, IncomeCategory, Money, MoneyType } from '../../types';
import { theme } from '../../theme';
import { center } from '../../styles/commonStyles';

interface IncomeExpensePieChartProps {
  moneyData: Money[] | null;
  incomeCategoryMap: Map<number, IncomeCategory> | null;
  expenseCategoryMap: Map<number, ExpenseCategory> | null;
}

const IncomeExpensePieChart = ({
  moneyData,
  incomeCategoryMap,
  expenseCategoryMap,
}: IncomeExpensePieChartProps) => {
  const [selectedType, setSelectedType] = useState<MoneyType>('支出');
  const handleTypeChange = useCallback((e: SelectChangeEvent<'収入' | '支出'>) => {
    setSelectedType(e.target.value as MoneyType);
  }, []);

  // グラフに渡すデータを作成
  const createTotalAmountByCategory = (
    data: Money[],
    targetType: string,
    category: Map<number, IncomeCategory | ExpenseCategory>
  ) => {
    const totalAmountByCategory = data
      .filter(item => {
        // targetTypeでフィルタリング
        if (item.type !== targetType) return false;
        return true;
      })
      .reduce(
        (acc, item) => {
          // カテゴリごとにamountを合計
          const categoryName = category.get(item.category)?.name || '不明なカテゴリー';
          if (!acc[categoryName]) {
            acc[categoryName] = 0;
          }
          acc[categoryName] += item.amount;
          return acc;
        },
        {} as Record<string, number>
      );

    // オブジェクトを配列に変換し、ソート
    const sortedTotalAmountByCategory = Object.entries(totalAmountByCategory).sort((a, b) => {
      if (a[0] === 'その他') return 1; // "その他" は常に最後
      if (b[0] === 'その他') return -1;
      return b[1] - a[1]; // 数値で降順ソート
    });

    // グラフに渡すデータの形式に変換
    const chartData = sortedTotalAmountByCategory.map(([category, amount], index) => ({
      id: index + 1,
      value: amount,
      label: category,
      ...{ ...(category === 'その他' ? { color: '#D6D6D6' } : {}) },
    }));
    return chartData;
  };

  // 収入のデータ
  const incomeData = useMemo(() => {
    if (!moneyData || !incomeCategoryMap) return null;
    return createTotalAmountByCategory(moneyData, '収入', incomeCategoryMap);
  }, [moneyData, incomeCategoryMap]);

  // 支出のデータ
  const expenseData = useMemo(() => {
    if (!moneyData || !expenseCategoryMap) return null;
    return createTotalAmountByCategory(moneyData, '支出', expenseCategoryMap);
  }, [moneyData, expenseCategoryMap]);

  // 色Aから色Bまでを分割した配列を生成する関数
  const generateGradientColors = (colorA: string, colorB: string, steps: number) => {
    if (steps <= 1) {
      return [colorA]; // ステップが1以下の場合は、開始色のみを返す
    }
    const rgbA = convertHexToRgb(colorA);
    const rgbB = convertHexToRgb(colorB);
    const colors = Array.from({ length: steps }, (_, i) => {
      const ratio = i / (steps - 1); // 0から1までの割合
      const r = Math.round(rgbA.r + ratio * (rgbB.r - rgbA.r));
      const g = Math.round(rgbA.g + ratio * (rgbB.g - rgbA.g));
      const b = Math.round(rgbA.b + ratio * (rgbB.b - rgbA.b));
      return convertRgbToHex(r, g, b);
    });
    return colors;
  };

  const incomeColors = useMemo(() => {
    if (!incomeData) return null;
    const hasOther = incomeData.some(item => item.label === 'その他');
    const step = hasOther ? incomeData.length - 1 : incomeData.length;
    return generateGradientColors(
      theme.palette.incomeColor.dark,
      theme.palette.incomeColor.light,
      step
    );
  }, [incomeData]);

  const expenseColors = useMemo(() => {
    if (!expenseData) return null;
    const hasOther = expenseData.some(item => item.label === 'その他');
    const step = hasOther ? expenseData.length - 1 : expenseData.length;
    return generateGradientColors(
      theme.palette.expenseColor.dark,
      theme.palette.expenseColor.light,
      step
    );
  }, [expenseData]);

  return (
    <>
      <FormControl fullWidth>
        <Select
          size="small"
          labelId="type-select-label"
          id="type-select"
          value={selectedType}
          onChange={handleTypeChange}
          inputProps={{ 'aria-label': '表示する収支の種類' }}
          sx={{
            marginBottom: '1rem',
          }}
        >
          <MenuItem
            value="収入"
            sx={{
              borderRadius: 0,
            }}
          >
            収入
          </MenuItem>
          <MenuItem
            value="支出"
            sx={{
              borderRadius: 0,
            }}
          >
            支出
          </MenuItem>
        </Select>
      </FormControl>
      {incomeColors && expenseColors ? (
        <PieChart
          colors={selectedType === '収入' ? incomeColors : expenseColors}
          series={[
            {
              data: selectedType === '収入' ? incomeData || [] : expenseData || [],
            },
          ]}
          slotProps={{
            legend: {
              itemMarkWidth: 15,
              itemMarkHeight: 15,
              labelStyle: {
                fontSize: 12,
                fontWeight: 500,
                lineHeight: 1.35,
              },
            },
          }}
          sx={{
            width: '100%',
            height: 'auto',
          }}
        />
      ) : (
        <Box
          sx={{
            width: '100%',
            height: '100%',
            ...center,
          }}
        >
          <CircularProgress color="secondary" size={20} />
        </Box>
      )}
    </>
  );
};

export default memo(IncomeExpensePieChart);
