import { useEffect, useRef, useState } from 'react';
import {
  Button,
  ClickAwayListener,
  Grow,
  IconButton,
  ListItemIcon,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Tooltip,
} from '@mui/material';
import Icon from './Icon';

export interface MenuItemProps {
  label: string;
  icon: string;
  disabled?: boolean;
  selected?: boolean;
  onClick: () => void;
}

interface DropDownMenuProps {
  menuName: string;
  ariaLabel: string;
  style?: React.CSSProperties;
  tooltip?: string;
  arrow?: boolean;
  icon: string;
  iconSize?: string;
  iconColor?: 'primary' | 'secondary';
  menuItems: MenuItemProps[];
  onOpenChange?: (open: boolean) => void; // 呼び出し元に開閉状況を通知するために使用
}

export const DropDownMenu = ({
  menuName,
  ariaLabel,
  style,
  tooltip,
  arrow = false,
  icon,
  iconSize,
  iconColor,
  menuItems,
  onOpenChange,
}: DropDownMenuProps) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);

  const handleToggle = () => {
    setOpen(prevOpen => {
      const newOpen = !prevOpen;
      onOpenChange?.(newOpen); // 呼び出し元に開閉状況を通知するために使用
      return newOpen;
    });
  };

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setOpen(false);
    onOpenChange?.(false); // 呼び出し元に開閉状況を通知するために使用
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    }
  }

  // openがfalseからtrueに変わったときにボタンにフォーカスを戻す
  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }
    prevOpen.current = open;
  }, [open]);

  return (
    <>
      {tooltip ? (
        <>
          <Tooltip title={tooltip} placement="top">
            {arrow ? (
              <Button
                variant="text"
                ref={anchorRef}
                id={`${menuName}-button`}
                aria-controls={open ? `${menuName}-menu` : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                aria-label={ariaLabel}
                onClick={handleToggle}
                endIcon={
                  <Icon icon="arrow-down-s-fill" size="0.75rem" color={iconColor && iconColor} />
                }
                sx={{
                  minWidth: '52px',
                  ...style,
                  ...(open && { backgroundColor: 'rgba(0, 0, 0, 0.075)' }),
                }}
              >
                <Icon icon={icon} size={iconSize && iconSize} color={iconColor && iconColor} />
              </Button>
            ) : (
              <IconButton
                ref={anchorRef}
                id={`${menuName}-button`}
                aria-controls={open ? `${menuName}-menu` : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-label={ariaLabel}
                aria-haspopup="true"
                onClick={handleToggle}
                size="small"
                sx={{
                  ...style,
                  ...(open && { backgroundColor: 'var(--IconButton-hoverBg)' }),
                }}
              >
                <Icon icon={icon} size={iconSize && iconSize} color={iconColor && iconColor} />
              </IconButton>
            )}
          </Tooltip>
          <Popper
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            placement="bottom-start"
            transition
            disablePortal
            sx={{
              zIndex: 1,
            }}
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin: placement === 'bottom-start' ? 'left top' : 'left bottom',
                }}
              >
                <Paper
                  variant="outlined"
                  sx={{
                    p: '0.25rem',
                    width: 'max-content',
                  }}
                >
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList
                      autoFocusItem={open}
                      id={`${menuName}-menu`}
                      aria-labelledby={`${menuName}-button`}
                      onKeyDown={handleListKeyDown}
                      sx={{
                        padding: 0,
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '0.25rem',
                        flexWrap: 'wrap',
                      }}
                    >
                      {menuItems.map((item, index) => (
                        <MenuItem
                          key={index}
                          onClick={e => {
                            handleClose(e);
                            item.onClick();
                          }}
                          disabled={item.disabled || false}
                          selected={item.selected || false}
                        >
                          <ListItemIcon>
                            <Icon icon={item.icon} size="1rem" />
                          </ListItemIcon>
                          {item.label}
                        </MenuItem>
                      ))}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </>
      ) : (
        <>
          {arrow ? (
            <Button
              variant="text"
              ref={anchorRef}
              id={`${menuName}-button`}
              aria-controls={open ? `${menuName}-menu` : undefined}
              aria-expanded={open ? 'true' : undefined}
              aria-haspopup="true"
              aria-label={ariaLabel}
              onClick={handleToggle}
              endIcon={<Icon icon="arrow-down-s-fill" size="0.75rem" />}
              sx={{
                minWidth: '52px',
                ...style,
                ...(open && { backgroundColor: 'rgba(0, 0, 0, 0.075)' }),
              }}
            >
              <Icon icon={icon} size={iconSize && iconSize} color={iconColor && iconColor} />
            </Button>
          ) : (
            <IconButton
              ref={anchorRef}
              id={`${menuName}-button`}
              aria-controls={open ? `${menuName}-menu` : undefined}
              aria-expanded={open ? 'true' : undefined}
              aria-label={ariaLabel}
              aria-haspopup="true"
              onClick={handleToggle}
              size="small"
              sx={{
                ...style,
                ...(open && { backgroundColor: 'var(--IconButton-hoverBg)' }),
              }}
            >
              <Icon icon={icon} size={iconSize && iconSize} color={iconColor && iconColor} />
            </IconButton>
          )}
          <Popper
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            placement="bottom-start"
            transition
            disablePortal
            sx={{
              zIndex: 1,
            }}
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin: placement === 'bottom-start' ? 'left top' : 'left bottom',
                }}
              >
                <Paper
                  variant="outlined"
                  sx={{
                    p: '0.25rem',
                    width: 'max-content',
                  }}
                >
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList
                      autoFocusItem={open}
                      id={`${menuName}-menu`}
                      aria-labelledby={`${menuName}-button`}
                      onKeyDown={handleListKeyDown}
                      sx={{
                        padding: 0,
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '0.25rem',
                        flexWrap: 'wrap',
                      }}
                    >
                      {menuItems.map((item, index) => (
                        <MenuItem
                          key={index}
                          onClick={e => {
                            handleClose(e);
                            item.onClick();
                          }}
                          disabled={item.disabled || false}
                          selected={item.selected || false}
                        >
                          <ListItemIcon>
                            <Icon icon={item.icon} size="1rem" />
                          </ListItemIcon>
                          {item.label}
                        </MenuItem>
                      ))}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </>
      )}
    </>
  );
};
