import { Editor } from '@tiptap/react';
import {
  Figcaption,
  HorizontalRule,
  ImageBlock,
  ImageUpload,
  Link,
  CodeBlock,
} from '../extensions';

// HTMLの表（テーブル）内で「行」または「列」のグリップが選択されているかを判定
export const isTableGripSelected = (node: HTMLElement) => {
  let container = node;

  while (container && !['TD', 'TH'].includes(container.tagName)) {
    container = container.parentElement!;
  }

  const gripColumn =
    container && container.querySelector && container.querySelector('a.grip-column.selected');
  const gripRow =
    container && container.querySelector && container.querySelector('a.grip-row.selected');

  if (gripColumn || gripRow) {
    return true;
  }

  return false;
};

// エディター内で特定のカスタムノード（拡張機能に関連するノード）が選択されているか、
// またはisTableGripSelectedで判定したテーブルグリップが選択されているかを判定
export const isCustomNodeSelected = (editor: Editor, node: HTMLElement) => {
  const customNodes = [
    HorizontalRule.name,
    ImageBlock.name,
    ImageUpload.name,
    CodeBlock.name,
    ImageBlock.name,
    Link.name,
    Figcaption.name,
  ];

  return customNodes.some(type => editor.isActive(type)) || isTableGripSelected(node);
};

export default isCustomNodeSelected;
