import React, { useCallback, useEffect, useRef, useState } from 'react';
import { createPortal, unstable_batchedUpdates } from 'react-dom';
import { useLocation, useNavigate } from 'react-router-dom';
import { debounce } from 'lodash';
import { Box, IconButton } from '@mui/material';
import {
  CancelDrop,
  closestCenter,
  pointerWithin,
  rectIntersection,
  CollisionDetection,
  DndContext,
  DragOverlay,
  DropAnimation,
  getFirstCollision,
  MouseSensor,
  TouchSensor,
  Modifiers,
  UniqueIdentifier,
  useSensors,
  useSensor,
  MeasuringStrategy,
  defaultDropAnimationSideEffects,
} from '@dnd-kit/core';
import {
  AnimateLayoutChanges,
  SortableContext,
  useSortable,
  arrayMove,
  defaultAnimateLayoutChanges,
  verticalListSortingStrategy,
  SortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { useTodoContext } from '../../../contexts/TodoContext';
import { useProjectContext } from '../../../contexts/ProjectContext';
import { Container, ContainerProps } from './components/Container';
import { Item } from './components/Item';
import {
  FolderMap,
  FolderOrderMap,
  MenuItemMap,
  SetFolderMap,
  SetFolderOrderMap,
  SetMenuItemMap,
  SetMenuItemOrderMap,
} from '../../../types';
import { drawerWidth, navHeight, scrollbarWhite } from '../../../styles/commonStyles';
import { theme } from '../../../theme';
import Icon from '../../ui/Icon';
import useSaveMenuRequest from '../../../hooks/useSaveMenuRequest';

const animateLayoutChanges: AnimateLayoutChanges = args =>
  defaultAnimateLayoutChanges({ ...args, wasDragging: true });

const DroppableContainer = ({
  children,
  disabled,
  id,
  pathname,
  items,
  style,
  label,
  onRemove,
  setFolders,
  ...props
}: ContainerProps & {
  disabled?: boolean;
  id: UniqueIdentifier;
  items: UniqueIdentifier[];
  style?: React.CSSProperties;
}) => {
  const { active, attributes, isDragging, listeners, over, setNodeRef, transition, transform } =
    useSortable({
      id,
      data: {
        type: 'container',
        children: items,
      },
      animateLayoutChanges,
    });
  const isOverContainer = over
    ? (id === over.id && active?.data.current?.type !== 'container') || items.includes(over.id)
    : false;

  return (
    <Container
      ref={disabled ? undefined : setNodeRef}
      id={id}
      style={{
        ...style,
        transition,
        transform: CSS.Translate.toString(transform),
        opacity: isDragging ? 0.5 : undefined,
      }}
      label={label}
      pathname={pathname}
      hover={isOverContainer}
      handleProps={{
        ...attributes,
        ...listeners,
      }}
      onRemove={onRemove}
      setFolders={setFolders}
      {...props}
    >
      {children}
    </Container>
  );
};

const useMountStatus = () => {
  const [isMounted, setIsMounted] = useState(false);
  useEffect(() => {
    const timeout = setTimeout(() => setIsMounted(true), 500);
    return () => clearTimeout(timeout);
  }, []);
  return isMounted;
};

interface StyleArgs {
  index: number;
  value: UniqueIdentifier;
  isDragging: boolean;
  isSorting: boolean;
  overIndex: number;
  containerId: UniqueIdentifier;
}
interface SortableItemProps {
  containerId: UniqueIdentifier;
  id: UniqueIdentifier;
  index: number;
  label: string;
  pathname: string;
  disabled?: boolean;
  style(args: StyleArgs): React.CSSProperties;
  getIndex(id: UniqueIdentifier): number;
  renderItem(): React.ReactElement;
  wrapperStyle({ index }: { index: number }): React.CSSProperties;
  onRemove(): void;
}

const SortableItem = ({
  disabled,
  id,
  index,
  label,
  renderItem,
  style,
  pathname,
  containerId,
  getIndex,
  wrapperStyle,
  onRemove,
}: SortableItemProps) => {
  const { setNodeRef, listeners, isDragging, isSorting, over, overIndex, transform, transition } =
    useSortable({
      id,
    });
  const mounted = useMountStatus();
  const mountedWhileDragging = isDragging && !mounted;

  return (
    <Item
      ref={disabled ? undefined : setNodeRef}
      dragging={isDragging}
      sorting={isSorting}
      index={index}
      id={id}
      label={label}
      pathname={pathname}
      wrapperStyle={wrapperStyle({ index })}
      onRemove={onRemove}
      style={style({
        index,
        value: id,
        isDragging,
        isSorting,
        overIndex: over ? getIndex(over.id) : overIndex,
        containerId,
      })}
      transition={transition}
      transform={transform}
      fadeIn={mountedWhileDragging}
      listeners={listeners}
      renderItem={renderItem}
    />
  );
};

const dropAnimation: DropAnimation = {
  sideEffects: defaultDropAnimationSideEffects({
    styles: {
      active: {
        opacity: '0.5',
      },
    },
  }),
};

type Items = Record<UniqueIdentifier, UniqueIdentifier[]>;

interface DraggableMenuProps {
  adjustScale?: boolean;
  cancelDrop?: CancelDrop;
  containerStyle?: React.CSSProperties;
  getItemStyles?(args: {
    value: UniqueIdentifier;
    index: number;
    overIndex: number;
    isDragging: boolean;
    containerId: UniqueIdentifier;
    isSorting: boolean;
    isDragOverlay: boolean;
  }): React.CSSProperties;
  wrapperStyle?(args: { index: number }): React.CSSProperties;
  renderItem?: () => React.ReactElement;
  strategy?: SortingStrategy;
  modifiers?: Modifiers;
  open: boolean;
  page: string;
  items: Items;
  folderIds: UniqueIdentifier[];
  menuItems: MenuItemMap;
  setMenuItems: SetMenuItemMap;
  setMenuItemOrder: SetMenuItemOrderMap;
  folders: FolderMap;
  setFolders: SetFolderMap;
  folderOrder: FolderOrderMap;
  setFolderOrder: SetFolderOrderMap;
  handleDrawerClose: () => void;
}

export const DraggableMenu = ({
  adjustScale = false,
  cancelDrop,
  containerStyle,
  getItemStyles = () => ({}),
  wrapperStyle = () => ({}),
  modifiers,
  renderItem,
  strategy = verticalListSortingStrategy,
  open,
  page,
  items: menuIdsByFolder,
  folderIds,
  menuItems,
  setMenuItems,
  setMenuItemOrder,
  folders,
  setFolders,
  folderOrder,
  setFolderOrder,
  handleDrawerClose,
}: DraggableMenuProps) => {
  const pathname = useLocation().pathname.split('/')[1];
  const pathId = useLocation().pathname.split('/')[2];
  const navigate = useNavigate();
  const { setTodoData } = useTodoContext();
  const { setSectionData, setSectionOrder, setTodoOrder } = useProjectContext();

  useEffect(() => {
    console.log('DraggableAccordionMenu：初回レンダリング');
  }, []);

  console.log('DraggableAccordionMenu：レンダリング');

  const [containers, setContainers] = useState(folderIds);
  const [items, setItems] = useState<Items>(menuIdsByFolder);

  const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null);
  const lastOverId = useRef<UniqueIdentifier | null>(null);
  const recentlyMovedToNewContainer = useRef(false);
  const isSortingContainer = activeId ? containers.includes(activeId) : false;
  const [clonedItems, setClonedItems] = useState<Items | null>(null);
  const saveRequest = useSaveMenuRequest();

  // menuIdsByFolder変更時にstateを初期化
  useEffect(() => {
    unstable_batchedUpdates(() => {
      setItems(menuIdsByFolder);
      setContainers(folderIds);
      setActiveId(null);
      setClonedItems(null);
    });
  }, [menuIdsByFolder]);

  // キーボード操作は利用しない
  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 10,
      },
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 250,
        tolerance: 5,
      },
    })
  );

  const iconButtonRef = useRef<HTMLButtonElement>(null);
  const isInitialRender = useRef(true);
  useEffect(() => {
    if (isInitialRender.current) {
      // 初回レンダリング時は何もしない
      isInitialRender.current = false;
      return;
    }
    // ドロワーが開いたときにIconButtonにフォーカスを移す
    if (open && iconButtonRef.current) {
      iconButtonRef.current.focus();
    }
  }, [open]);

  /**
   * Custom collision detection strategy optimized for multiple containers
   *
   * - First, find any droppable containers intersecting with the pointer.
   * - If there are none, find intersecting containers with the active draggable.
   * - If there are no intersecting containers, return the last matched intersection
   *
   */
  const collisionDetectionStrategy: CollisionDetection = useCallback(
    args => {
      if (activeId && activeId in items) {
        return closestCenter({
          ...args,
          droppableContainers: args.droppableContainers.filter(container => container.id in items),
        });
      }

      // Start by finding any intersecting droppable
      const pointerIntersections = pointerWithin(args);
      const intersections =
        pointerIntersections.length > 0
          ? // If there are droppables intersecting with the pointer, return those
            pointerIntersections
          : rectIntersection(args);
      let overId = getFirstCollision(intersections, 'id');

      if (overId != null) {
        if (overId in items) {
          const containerItems = items[overId];

          // If a container is matched and it contains items (columns 'A', 'B', 'C')
          if (containerItems.length > 0) {
            // Return the closest droppable within that container
            overId = closestCenter({
              ...args,
              droppableContainers: args.droppableContainers.filter(
                container => container.id !== overId && containerItems.includes(container.id)
              ),
            })[0]?.id;
          }
        }

        lastOverId.current = overId;

        return [{ id: overId }];
      }

      // When a draggable item moves to a new container, the layout may shift
      // and the `overId` may become `null`. We manually set the cached `lastOverId`
      // to the id of the draggable item that was moved to the new container, otherwise
      // the previous `overId` will be returned which can cause items to incorrectly shift positions
      if (recentlyMovedToNewContainer.current) {
        lastOverId.current = activeId;
      }

      // If no droppable is matched, return the last match
      return lastOverId.current ? [{ id: lastOverId.current }] : [];
    },
    [activeId, items]
  );

  const findContainer = (id: UniqueIdentifier) => {
    if (id in items) {
      return id;
    }
    return Object.keys(items).find(key => items[key].includes(id));
  };

  const getIndex = (id: UniqueIdentifier) => {
    const container = findContainer(id);
    if (!container) {
      return -1;
    }
    const index = items[container].indexOf(id);
    return index;
  };

  const onDragCancel = () => {
    if (clonedItems) {
      // Reset items to their original state in case items have been
      // Dragged across containers
      setItems(clonedItems);
    }
    setActiveId(null);
    setClonedItems(null);
  };

  useEffect(() => {
    requestAnimationFrame(() => {
      recentlyMovedToNewContainer.current = false;
    });
  }, [items]);

  // フォルダの移動
  const saveFolderOrder = useCallback(
    (ids: UniqueIdentifier[]) => {
      saveRequest({
        apiUrl: process.env.REACT_APP_SAVE_MENU_API,
        tableType: pathname,
        action: 'sort',
        orderType: 'folder',
        data: ids,
      }).catch(error => {
        console.error('フォルダの並びの更新に失敗しました:', error);
      });
    },
    [pathname]
  );

  // 同じフォルダ内のアイテムの移動
  const saveItemOrder = useCallback(
    (ids: UniqueIdentifier[]) => {
      saveRequest({
        apiUrl: process.env.REACT_APP_SAVE_MENU_API,
        tableType: pathname,
        action: 'sort',
        orderType: 'item',
        data: ids,
      }).catch(error => {
        console.error('アイテムの並びの更新に失敗しました:', error);
      });
    },
    [pathname]
  );

  // フォルダ間のアイテムの移動
  const saveItemOrderOverFolder = useCallback(
    debounce((overContainer: UniqueIdentifier, overIds: UniqueIdentifier[]) => {
      saveRequest({
        apiUrl: process.env.REACT_APP_SAVE_MENU_API,
        id: overContainer,
        tableType: pathname,
        action: 'sort',
        orderType: 'item',
        data: overIds,
      })
        .then(() => {
          setMenuItemOrder(prev => {
            const newMap = new Map(prev);
            overIds.forEach((id, index) => {
              const menuItem = newMap.get(Number(id));
              if (menuItem) {
                newMap.set(Number(id), {
                  itemId: menuItem.itemId,
                  folderId: overContainer,
                  sort: index,
                });
              }
            });
            return newMap;
          });
        })
        .catch(error => {
          console.error('アイテムの並びの更新に失敗しました:', error);
        });
    }, 1000),
    [pathname]
  );

  // フォルダの削除
  const removeFolder = useCallback(
    (containerID: UniqueIdentifier) => {
      const itemsArray = items[containerID];
      if (itemsArray.length > 0) {
        alert('フォルダにページが含まれているため削除できません');
        return;
      }
      saveRequest({
        apiUrl: process.env.REACT_APP_SAVE_MENU_API,
        tableType: pathname,
        id: containerID,
        action: 'remove',
        orderType: 'folder',
      })
        .then(() => {
          unstable_batchedUpdates(() => {
            setFolders(prev => {
              const newMap = new Map(prev);
              newMap.delete(containerID);
              return newMap;
            });
            setFolderOrder(prev => {
              const newMap = new Map(prev);
              newMap.delete(containerID);
              return newMap;
            });
            setContainers(prev => prev.filter(id => id !== containerID));
            setItems(prev => {
              const newItems = Object.fromEntries(
                Object.entries(prev).filter(([key]) => key !== containerID)
              );
              return newItems;
            });
          });
        })
        .catch(error => {
          console.error('フォルダの削除に失敗しました:', error);
        });
    },
    [items, pathname]
  );

  // アイテムの削除
  const removeItem = useCallback(
    (id: UniqueIdentifier, containerID: UniqueIdentifier) => {
      const isConfirmed = window.confirm('このページを削除しますか？');
      if (isConfirmed) {
        saveRequest({
          apiUrl: process.env.REACT_APP_SAVE_MENU_API,
          tableType: pathname,
          id,
          action: 'remove',
          orderType: 'item',
        })
          .then(response => {
            unstable_batchedUpdates(() => {
              if (pathname === 'project') {
                setTodoOrder(prev => {
                  const newMap = new Map(prev);
                  response.todoIds?.forEach(id => {
                    newMap.delete(id);
                  });
                  return newMap;
                });
                setTodoData(prev => {
                  const newMap = new Map(prev);
                  response.todoIds?.forEach(id => {
                    const todo = newMap.get(id);
                    if (todo) {
                      newMap.set(id, { ...todo, projectId: null, sectionId: null });
                    }
                  });
                  return newMap;
                });
                setSectionOrder(prev => {
                  const newMap = new Map(prev);
                  response.sectionIds?.forEach(id => {
                    newMap.delete(id);
                  });
                  return newMap;
                });
                setSectionData(prev => {
                  const newMap = new Map(prev);
                  response.sectionIds?.forEach(id => {
                    newMap.delete(id);
                  });
                  return newMap;
                });
              }
              setMenuItems(prev => {
                const newMap = new Map(prev);
                newMap.delete(id);
                return newMap;
              });
              setMenuItemOrder(prev => {
                const newMap = new Map(prev);
                newMap.delete(id);
                return newMap;
              });
              setItems(prev => {
                const newItems = { ...prev };
                newItems[containerID] = newItems[containerID].filter(itemId => itemId !== id);
                return newItems;
              });
            });
            if (pathId === String(id)) {
              navigate(`/${pathname}`);
            }
          })
          .catch(error => {
            console.error('アイテムの削除に失敗しました:', error);
          });
      }
    },
    [pathname, pathId]
  );

  // 新規のフォルダIDを作成する
  const generateNewFolderId = useCallback(() => {
    if (!folders) return null;
    const prefixMap = {
      memo: 'MF',
      project: 'PF',
      gallery: 'GF',
    };
    const prefix = prefixMap[pathname as 'memo' | 'project' | 'gallery'];
    // プレフィックスに続く数値部分の最大値を取得
    let maxNumber = 0;
    folders.forEach((_, key) => {
      if (typeof key !== 'string') return;
      const [, numberStr] = key.split('-');
      const num = parseInt(numberStr, 10);
      if (!Number.isNaN(num)) {
        maxNumber = Math.max(maxNumber, num);
      }
    });
    return `${prefix}-${maxNumber + 1}`;
  }, [folders, pathname]);

  // 新規フォルダを保存
  const addNewFolder = useCallback(() => {
    const newFolderId = generateNewFolderId();
    if (!newFolderId) {
      alert('フォルダIDの取得に失敗しました');
      return;
    }
    const noCategorySort = folderOrder?.get('noCategory') || containers.length;
    saveRequest({
      apiUrl: process.env.REACT_APP_SAVE_MENU_API,
      id: newFolderId,
      tableType: pathname,
      action: 'add',
      orderType: 'folder',
      data: {
        name: '新規フォルダ',
        sort: noCategorySort,
      },
    })
      .then(() => {
        unstable_batchedUpdates(() => {
          setFolders(prev => {
            const newMap = new Map(prev);
            newMap.set(newFolderId, '新規フォルダ');
            return newMap;
          });
          setFolderOrder(prev => {
            const newMap = new Map(prev);
            newMap.set(newFolderId, noCategorySort);
            newMap.set('noCategory', noCategorySort + 1);
            return newMap;
          });
          setContainers(prev => {
            const newContainers = prev.filter(folder => folder !== 'noCategory');
            return [...newContainers, newFolderId, 'noCategory'];
          });
          setItems(prev => ({
            ...prev,
            [newFolderId]: [],
          }));
        });
      })
      .catch(error => {
        console.error('Folderの追加に失敗しました:', error);
      });
  }, [pathname, containers, folderOrder, folders, generateNewFolderId]);

  // 新規のアイテムIDを作成する
  const generateNewItemId = useCallback(() => {
    if (!menuItems) return null;
    // Mapが空であれば1から始める
    if (menuItems.size === 0) return 1;
    const maxId = Math.max(...Array.from(menuItems.keys()).map(key => parseInt(key as string, 10)));
    return maxId + 1;
  }, [menuItems]);

  // 新規アイテムを保存
  const addNewItem = useCallback(() => {
    const newItemId = generateNewItemId();
    if (!newItemId) {
      alert('アイテムIDの取得に失敗しました');
      return;
    }
    const sort = items.noCategory.length;
    saveRequest({
      apiUrl: process.env.REACT_APP_SAVE_MENU_API,
      id: newItemId,
      tableType: pathname,
      action: 'add',
      orderType: 'item',
      data: {
        title: `新規${page}ページ`,
        sort,
      },
    })
      .then(() => {
        unstable_batchedUpdates(() => {
          const newMenuItem = {
            itemId: newItemId,
            folderId: 'noCategory',
            sort,
          };
          setMenuItems(prev => {
            const newMap = new Map(prev);
            newMap.set(newItemId, `新規${page}ページ`);
            return newMap;
          });
          setMenuItemOrder(prev => {
            const newMap = new Map(prev);
            newMap.set(newItemId, newMenuItem);
            return newMap;
          });
          setItems(prev => ({
            ...prev,
            noCategory: [...prev.noCategory, newItemId],
          }));
        });
      })
      .catch(error => {
        console.error('Itemの追加に失敗しました:', error);
      });
  }, [items, menuItems, page, pathname, generateNewItemId]);

  const renderContainerDragOverlay = (
    containerId: UniqueIdentifier,
    pathname: string,
    setFolders: SetFolderMap
  ) => (
    <Container
      id={containerId as string}
      label={folders?.get(containerId) ?? ''}
      style={{
        height: '100%',
      }}
      pathname={pathname}
      onRemove={() => removeFolder(containerId)}
      setFolders={setFolders}
      shadow
    >
      <></>
      {/* アコーディオンの開閉を状態を取得しないと、子要素が表示されない */}
      {/* {items[containerId].map((item, index) => (
          <Item
            key={item}
            label={menuItems?.get(item) ?? ''}
            id={item}
            pathname={pathname}
            onRemove={() => {}}
            style={getItemStyles({
              containerId,
              overIndex: -1,
              index: getIndex(item),
              value: item,
              isDragging: false,
              isSorting: false,
              isDragOverlay: false,
            })}
            wrapperStyle={wrapperStyle({ index })}
            renderItem={renderItem}
          />
        ))} */}
    </Container>
  );

  const renderSortableItemDragOverlay = (id: UniqueIdentifier, onRemove: () => void) => (
    <Item
      label={menuItems?.get(id) ?? ''}
      id={id}
      pathname={pathname}
      onRemove={onRemove}
      style={getItemStyles({
        containerId: findContainer(id) as UniqueIdentifier,
        overIndex: -1,
        index: getIndex(id),
        value: id,
        isSorting: true,
        isDragging: true,
        isDragOverlay: true,
      })}
      wrapperStyle={wrapperStyle({ index: 0 })}
      renderItem={renderItem}
      dragOverlay
    />
  );

  return (
    <>
      <Box
        role="toolbar"
        aria-label={`${page}一覧の操作`}
        sx={{
          position: 'absolute',
          top: 0,
          right: 0,
          zIndex: 2,
          p: 1,
          width: drawerWidth,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          backgroundColor: 'white',
          borderBottom: '1px solid',
          borderColor: theme.palette.divider,
          '& button + button': {
            marginLeft: 0.5,
          },
        }}
      >
        <IconButton ref={iconButtonRef} aria-label="新規フォルダの追加" onClick={addNewFolder}>
          <Icon icon="folder-add-line" />
        </IconButton>
        <IconButton aria-label={`新規${page}の追加`} onClick={addNewItem}>
          <Icon icon="file-add-line" />
        </IconButton>
        <IconButton
          aria-label={`${page}一覧を閉じる`}
          onClick={handleDrawerClose}
          sx={{
            marginLeft: 'auto !important',
          }}
        >
          {theme.direction === 'ltr' ? (
            <Icon icon="arrow-left-s-line" />
          ) : (
            <Icon icon="arrow-right-s-line" />
          )}
        </IconButton>
      </Box>
      <Box
        sx={{
          ...scrollbarWhite,
          overflowY: 'auto',
        }}
      >
        <Box
          sx={{
            padding: 1,
            marginTop: 6.5,
            marginBottom: { xs: navHeight, md: 0 },
            minHeight: {
              xs: `calc(100vh - ( ${navHeight} + 52px ))`,
              md: 'calc(100vh - 52px)',
            }, // 52pxはツールバーの高さ
          }}
        >
          <DndContext
            sensors={sensors}
            collisionDetection={collisionDetectionStrategy}
            measuring={{
              droppable: {
                strategy: MeasuringStrategy.Always,
              },
            }}
            onDragStart={({ active }) => {
              setActiveId(active.id);
              setClonedItems(items);
            }}
            onDragOver={({ active, over }) => {
              const overId = over?.id;
              if (overId == null || active.id in items) {
                return;
              }
              const overContainer = findContainer(overId);
              const activeContainer = findContainer(active.id);
              if (!overContainer || !activeContainer) {
                return;
              }
              // 別コンテナから別コンテナにアイテムを移動する場合
              if (activeContainer !== overContainer) {
                setItems(items => {
                  const activeItems = items[activeContainer];
                  const overItems = items[overContainer];
                  const overIndex = overItems.indexOf(overId);
                  const activeIndex = activeItems.indexOf(active.id);
                  let newIndex: number;
                  if (overId in items) {
                    newIndex = overItems.length + 1;
                  } else {
                    const isBelowOverItem =
                      over &&
                      active.rect.current.translated &&
                      active.rect.current.translated.top > over.rect.top + over.rect.height;
                    const modifier = isBelowOverItem ? 1 : 0;
                    newIndex = overIndex >= 0 ? overIndex + modifier : overItems.length + 1;
                  }
                  recentlyMovedToNewContainer.current = true;
                  const activeContainerItems = items[activeContainer].filter(
                    item => item !== active.id
                  );
                  const overContainerItems = [
                    ...items[overContainer].slice(0, newIndex),
                    items[activeContainer][activeIndex],
                    ...items[overContainer].slice(newIndex, items[overContainer].length),
                  ];
                  saveItemOrderOverFolder(overContainer, overContainerItems);
                  return {
                    ...items,
                    [activeContainer]: activeContainerItems,
                    [overContainer]: overContainerItems,
                  };
                });
              }
            }}
            onDragEnd={({ active, over }) => {
              if (active.id in items && over?.id) {
                if (over.id === 'noCategory' || active.id === over.id) {
                  return;
                }
                // フォルダーの並び替え
                setContainers(containers => {
                  const activeIndex = containers.indexOf(active.id);
                  const overIndex = containers.indexOf(over.id);
                  const movedContainers = arrayMove(containers, activeIndex, overIndex);
                  setFolderOrder(prev => {
                    const newMap = new Map(prev);
                    movedContainers.forEach((id, index) => {
                      newMap.set(String(id), index);
                    });
                    saveFolderOrder(movedContainers);
                    return newMap;
                  });
                  return movedContainers;
                });
              }

              const activeContainer = findContainer(active.id);
              if (!activeContainer) {
                setActiveId(null);
                return;
              }
              const overId = over?.id;
              if (overId == null) {
                setActiveId(null);
                return;
              }
              const overContainer = findContainer(overId);
              if (overContainer) {
                const activeIndex = items[activeContainer].indexOf(active.id);
                const overIndex = items[overContainer].indexOf(overId);
                if (activeIndex !== overIndex) {
                  // 同じフォルダ内でアイテムの並び替え
                  setItems(items => {
                    const movedItems = arrayMove(items[overContainer], activeIndex, overIndex);
                    setMenuItemOrder(prev => {
                      const newMap = new Map(prev);
                      movedItems.forEach((id, index) => {
                        newMap.set(Number(id), {
                          itemId: Number(id),
                          folderId: overContainer,
                          sort: index,
                        });
                      });
                      return newMap;
                    });
                    saveItemOrder(movedItems);
                    return {
                      ...items,
                      [overContainer]: movedItems,
                    };
                  });
                }
              }
              setActiveId(null);
            }}
            cancelDrop={cancelDrop}
            onDragCancel={onDragCancel}
            modifiers={modifiers}
          >
            <nav
              className="draggableMenu"
              aria-label={`${page}一覧`}
              style={{
                width: '100%',
                display: 'inline-grid',
                gridAutoFlow: 'row',
                rowGap: '8px',
              }}
            >
              <SortableContext items={containers} strategy={verticalListSortingStrategy}>
                {containers.map(containerId => (
                  <DroppableContainer
                    key={containerId}
                    id={containerId as string}
                    label={folders?.get(containerId) ?? ''}
                    pathname={pathname}
                    items={items[containerId]}
                    style={containerStyle}
                    onRemove={() => removeFolder(containerId)}
                    setFolders={setFolders}
                  >
                    <SortableContext items={items[containerId]} strategy={strategy}>
                      {items[containerId].map((value, index) => (
                        <SortableItem
                          key={value}
                          id={value}
                          index={index}
                          label={menuItems?.get(value) ?? ''}
                          pathname={pathname}
                          disabled={isSortingContainer}
                          style={getItemStyles}
                          wrapperStyle={wrapperStyle}
                          renderItem={renderItem as () => React.ReactElement}
                          containerId={containerId}
                          getIndex={getIndex}
                          onRemove={() => removeItem(value, containerId)}
                        />
                      ))}
                    </SortableContext>
                  </DroppableContainer>
                ))}
              </SortableContext>
            </nav>
            {createPortal(
              <DragOverlay adjustScale={adjustScale} dropAnimation={dropAnimation}>
                {activeId
                  ? containers.includes(activeId)
                    ? renderContainerDragOverlay(activeId, pathname, setFolders)
                    : renderSortableItemDragOverlay(activeId, () => {})
                  : null}
              </DragOverlay>,
              document.body
            )}
          </DndContext>
        </Box>
      </Box>
    </>
  );
};
