import { useEffect } from 'react';
import { Routes, Route, Navigate, useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { Box, CircularProgress } from '@mui/material';
import File from './pages/File';
import Gallery from './pages/Gallery';
import GalleryPost from './pages/GalleryPost';
import Health from './pages/Health';
import Home from './pages/Home';
import Login from './pages/Login';
import Memo from './pages/Memo';
import MemoIndex from './pages/MemoIndex';
import MemoPost from './pages/MemoPost';
import Money from './pages/Money';
import NotFound from './pages/NotFound';
import Project from './pages/Project';
import ProjectIndex from './pages/ProjectIndex';
import ProjectPost from './pages/ProjectPost';
import Search from './pages/Search';
import Setting from './pages/Setting';
import Todo from './pages/Todo';
import Layout from './components/common/Layout';
import { LoginResponse } from './types/index';
import { useAuthContext } from './contexts/AuthContext';
import { TodoContextProvider } from './contexts/TodoContext';
import { MemoContextProvider } from './contexts/MemoContext';
import { GalleryContextProvider } from './contexts/GalleryContext';
import { ProjectContextProvider } from './contexts/ProjectContext';
import { theme } from './theme';
import { center } from './styles/commonStyles';

function App() {
  const location = useLocation();
  const navigate = useNavigate();
  const {
    userId,
    setUserId,
    csrfToken,
    setCsrfToken,
    loginAuth,
    setLoginAuth,
    isLoading,
    setIsLoading,
    entryURL,
    setEntryURL,
  } = useAuthContext();

  // あとで削除
  console.log('色の確認', theme.palette);

  useEffect(() => {
    // ログイン認証が完了しているかチェック
    if (loginAuth && userId !== '' && csrfToken !== '') {
      setIsLoading(false);
      return;
    }
    setEntryURL(location.pathname);

    const apiUrl = process.env.REACT_APP_AUTH_API;
    if (!apiUrl) {
      // APIがなければ、ログインページにリダイレクト
      setIsLoading(false);
      navigate('/login');
      return;
    }
    axios
      .get<LoginResponse>(apiUrl, {
        withCredentials: true, // クッキーを含めて送信する
      })
      .then(response => {
        if (response.data.success) {
          // 認証成功
          setUserId(response.data.userId);
          setCsrfToken(response.data.csrfToken);
          setLoginAuth(true);
          setIsLoading(false);
        } else {
          // 認証失敗
          if (response.status === 401) {
            setUserId('');
            setCsrfToken('');
            setLoginAuth(false);
            alert('セッションが切れました。再度ログインしてください。');
          }
          setIsLoading(false);
          navigate('/login');
        }
      })
      .catch(error => {
        console.error(error);
        setIsLoading(false);
        navigate('/login');
      });
  }, []);

  // 認証が必要なページを保護するためのラップコンポーネント
  const RequireAuth = ({ children }: { children: JSX.Element }) => {
    if (isLoading) {
      return (
        <Box
          sx={{
            width: '100%',
            height: '100vh',
            position: 'fixed',
            top: 0,
            left: 0,
            zIndex: 9999,
            ...center,
          }}
        >
          <CircularProgress color="secondary" size={30} />
        </Box>
      );
    }
    return loginAuth ? children : <Navigate replace to="/login" />;
  };

  return (
    <div className="App">
      <Routes>
        <Route
          path="/login"
          element={<Login loginAuth={loginAuth} setLoginAuth={setLoginAuth} entryURL={entryURL} />}
        />
        <Route
          path="/"
          element={
            <TodoContextProvider>
              <MemoContextProvider>
                <GalleryContextProvider>
                  <ProjectContextProvider>
                    <Layout
                      isLoading={isLoading}
                      setUserId={setUserId}
                      setCsrfToken={setCsrfToken}
                      setLoginAuth={setLoginAuth}
                      setEntryURL={setEntryURL}
                    />
                  </ProjectContextProvider>
                </GalleryContextProvider>
              </MemoContextProvider>
            </TodoContextProvider>
          }
        >
          <Route
            index
            element={
              <TodoContextProvider>
                <RequireAuth>
                  <Home />
                </RequireAuth>
              </TodoContextProvider>
            }
          />
          <Route
            path="/file"
            element={
              <RequireAuth>
                <File />
              </RequireAuth>
            }
          />
          <Route
            path="/gallery"
            element={
              <RequireAuth>
                <Gallery />
              </RequireAuth>
            }
          >
            <Route
              path=":postId"
              element={
                <RequireAuth>
                  <GalleryPost />
                </RequireAuth>
              }
            />
          </Route>
          <Route
            path="/health"
            element={
              <RequireAuth>
                <Health />
              </RequireAuth>
            }
          />
          <Route
            path="/memo"
            element={
              <RequireAuth>
                <Memo />
              </RequireAuth>
            }
          >
            <Route
              index
              element={
                <RequireAuth>
                  <MemoIndex />
                </RequireAuth>
              }
            />
            <Route
              path=":postId"
              element={
                <RequireAuth>
                  <MemoPost />
                </RequireAuth>
              }
            />
          </Route>
          <Route
            path="/money"
            element={
              <RequireAuth>
                <Money />
              </RequireAuth>
            }
          />
          <Route
            path="/project"
            element={
              <TodoContextProvider>
                <RequireAuth>
                  <Project />
                </RequireAuth>
              </TodoContextProvider>
            }
          >
            <Route
              index
              element={
                <RequireAuth>
                  <ProjectIndex />
                </RequireAuth>
              }
            />
            <Route
              path=":postId"
              element={
                <RequireAuth>
                  <ProjectPost />
                </RequireAuth>
              }
            />
          </Route>
          <Route
            path="/search"
            element={
              <RequireAuth>
                <Search />
              </RequireAuth>
            }
          />
          <Route
            path="/setting"
            element={
              <RequireAuth>
                <Setting />
              </RequireAuth>
            }
          />
          <Route
            path="/todo"
            element={
              <RequireAuth>
                <Todo />
              </RequireAuth>
            }
          />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
