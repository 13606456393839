import { memo, useEffect } from 'react';
import { Container } from '@mui/material';
import { navHeight } from '../../styles/commonStyles';

interface MainContainerProps {
  children: React.ReactNode;
}

const MainContainer = ({ children }: MainContainerProps) => {
  useEffect(() => {
    console.log('MainContainer:初回レンダリング');
  }, []);
  console.log('MainContainer:レンダリング');
  return (
    <Container
      component="main"
      maxWidth={false}
      disableGutters
      sx={{
        minHeight: '100vh',
        p: { xs: `2rem 1.5rem calc(${navHeight} + 2rem)`, md: '2rem 1.5rem' },
      }}
    >
      {children}
    </Container>
  );
};

export default memo(MainContainer);
