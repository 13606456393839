import { alpha } from '@mui/system';
import { theme } from '../theme';

export const navHeight = '50px';
export const navWidth = '80px';
export const drawerWidth = '220px';

export const fontSerif = {
  fontFamily: `"Zen Old Mincho", "Times New Roman", "ヒラギノ明朝 ProN", "Hiragino Mincho ProN", "Yu Mincho", serif !important`,
};

export const center = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

export const bgBlack = {
  color: 'white',
  backgroundColor: 'transparent',
  backgroundImage:
    'linear-gradient(45deg,rgba(40, 40, 40, 0.93),rgba(40, 40, 40, 0.93)),url(/img/noise.webp)',
  backgroundSize: 'auto, 125px',
};

export const bgWhite = {
  backgroundColor: 'transparent',
  backgroundImage:
    'linear-gradient(180deg,rgba(247, 244, 240, 0.93),rgba(247, 244, 240, 0.93)),url(/img/noise.webp)',
  backgroundSize: 'auto, 125px',
};

export const scrollbarBlack = {
  '&::-webkit-scrollbar': {
    ...bgBlack,
    width: 6,
    height: 6,
  },
  '&::-webkit-scrollbar-thumb': {
    background: '#ffffff54',
    borderRadius: '999px',
  },
  '&::-webkit-scrollbar-thumb:hover': {
    background: '#ffffff82',
  },
};

export const scrollbarWhite = {
  '&::-webkit-scrollbar': {
    backgroundColor: '#e4e4e4',
    width: 6,
    height: 6,
  },
  '&::-webkit-scrollbar-thumb': {
    background: '#c7c7c7',
    borderRadius: '999px',
  },
  '&::-webkit-scrollbar-thumb:hover': {
    background: '#afafaf',
  },
};

export const scrollbarTransparent = {
  '&::-webkit-scrollbar': {
    backgroundColor: '#ffffff7d',
    width: 6,
    height: 6,
  },
  '&::-webkit-scrollbar-thumb': {
    background: '#c7c7c7',
    borderRadius: '999px',
  },
  '&::-webkit-scrollbar-thumb:hover': {
    background: '#afafaf',
  },
};

export const chartColor = [
  '#5F000F',
  '#923535',
  '#D4827E',
  '#2B3955',
  '#406C93',
  '#8CA8BE',
  '#235658',
  '#518385',
  '#8CC4BF',
];

export const incomeChartColor = ['#001b3a', '#044165', '#406c93', '#8ca8be', '#D6D6D6'];

export const selectMenuItem = {
  borderRadius: '0px',
  padding: '0.4rem 1rem',
};

// zindexの全体管理
export const zNavigation = {
  zIndex: 1000, // 最大値
};

export const zdrawerLeft = {
  zIndex: 50,
};

export const zEditorMenu = {
  zIndex: 100,
};

/*
【設定】
ナビゲーション: 1000;
ドロワー（左）: 50;
Editorのメニュー：100～


【default】
モバイルステッパー: 1000;
ファブ: 1050;
短縮ダイヤル: 1050;
アプリバー: 1100;
引き出し: 1200;
モーダル: 1300;
スナックバー: 1400;
ツールチップ: 1500;
*/

// -----------------------------------------------
// フォーム関係
// -----------------------------------------------
export const textFieldStandardStyle = {
  '& .MuiInputBase-root': {
    backgroundColor: '#fff',
  },
  '.MuiInputBase-root:not(.Mui-disabled, .Mui-error)::before': {
    borderColor: 'transparent',
  },
  '.MuiInputBase-root:hover:not(.Mui-disabled, .Mui-error):before': {
    borderColor: 'transparent',
  },
  '.MuiInputBase-root::after': {
    borderWidth: '1px',
  },
};

export const textFieldStandardStyleWithLabel = {
  width: '100%',
  flexDirection: 'row !important',
  alignItems: 'center',
  flexWrap: 'wrap',
  '.MuiFormLabel-root': {
    position: 'static !important',
    transform: 'none !important',
    padding: '0.65rem 0.5rem',
    fontSize: '0.825rem',
    letterSpacing: '0.035em',
    lineHeight: 1,
    borderRadius: '3px',
    marginRight: '0.5rem',
    flexShrink: 0,
    ...bgBlack,
  },
  ".MuiFormLabel-root[data-shrink='false']": {
    position: 'static !important',
    transform: 'none !important',
  },
  ".MuiFormLabel-root[data-shrink='true']": {
    color: '#fff',
  },
  '.MuiFormLabel-root.Mui-error': {
    color: '#fff',
  },
  '.MuiInputBase-root': {
    backgroundColor: 'transparent',
    marginTop: '0 !important',
    flexGrow: 1,
  },
  '& input': {
    border: 'none !important',
  },
  '.MuiInputBase-root:not(.Mui-disabled, .Mui-error)::before': {
    borderColor: 'transparent',
  },
  '.MuiInputBase-root:hover:not(.Mui-disabled, .Mui-error):before': {
    borderColor: 'transparent',
  },
  '.MuiInputBase-root.Mui-disabled:before': {
    borderColor: theme.palette.divider,
  },
  '.MuiInputBase-root::after': {
    borderWidth: '1px',
  },
  '.MuiFormHelperText-root': {
    width: '100%',
  },
  '.MuiFormLabel-asterisk': {
    display: 'none',
  },
  legend: {
    display: 'none',
  },
  fieldset: {
    top: '0 !important',
  },
};

export const textFieldStandardStyleWithLabel2 = {
  width: '100%',
  flexDirection: 'row !important',
  alignItems: 'center',
  flexWrap: 'wrap',
  '.MuiFormLabel-root': {
    position: 'static !important',
    transform: 'none !important',
    padding: '0.5rem',
    fontSize: '0.825rem',
    letterSpacing: '0.035em',
    lineHeight: 1,
    borderRadius: '2px',
    marginRight: '0.5rem',
    flexShrink: 0,
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.text.secondary,
  },
  ".MuiFormLabel-root[data-shrink='false']": {
    position: 'static !important',
    transform: 'none !important',
  },
  ".MuiFormLabel-root[data-shrink='true']": {
    color: theme.palette.text.secondary,
  },
  '.MuiFormLabel-root.Mui-error': {
    color: theme.palette.text.secondary,
  },
  '.MuiInputBase-root': {
    backgroundColor: '#fff',
    marginTop: '0 !important',
    flexGrow: 1,
  },
  '.MuiInputBase-root:not(.Mui-disabled, .Mui-error)::before': {
    borderColor: 'transparent',
  },
  '.MuiInputBase-root:hover:not(.Mui-disabled, .Mui-error):before': {
    borderColor: 'transparent',
  },
  '.MuiInputBase-root::after': {
    borderWidth: '1px',
  },
  '.MuiFormHelperText-root': {
    width: '100%',
  },
  '.MuiFormLabel-asterisk': {
    display: 'none',
  },
  legend: {
    display: 'none',
  },
  fieldset: {
    top: '0 !important',
  },
};

export const textFieldOutlinedStyleWithLabel = {
  width: '100%',
  flexDirection: 'row !important',
  alignItems: 'center',
  flexWrap: 'wrap',
  '.MuiFormLabel-root': {
    position: 'static !important',
    transform: 'none !important',
    padding: '0.65rem 0.5rem',
    fontSize: '0.825rem',
    letterSpacing: '0.035em',
    lineHeight: 1,
    borderRadius: '3px',
    marginRight: '0.5rem',
    flexShrink: 0,
    ...bgBlack,
  },
  ".MuiFormLabel-root[data-shrink='false']": {
    position: 'static !important',
    transform: 'none !important',
  },
  ".MuiFormLabel-root[data-shrink='true']": {
    color: '#fff',
  },
  '.MuiFormLabel-root.Mui-error': {
    color: '#fff',
  },
  '.MuiInputBase-root': {
    backgroundColor: 'transparent',
    marginTop: '0 !important',
    flexGrow: 1,
  },
  '& input': {
    border: 'none !important',
  },
  '.MuiInputBase-root:not(.Mui-disabled, .Mui-error)::before': {
    borderColor: 'transparent',
  },
  '.MuiInputBase-root:hover:not(.Mui-disabled, .Mui-error):before': {
    borderColor: 'transparent',
  },
  '.MuiInputBase-root.Mui-disabled:before': {
    borderColor: theme.palette.divider,
  },
  '.MuiInputBase-root::after': {
    borderWidth: '1px',
  },
  '.MuiFormHelperText-root': {
    marginLeft: 0,
    width: '100%',
  },
  '.MuiFormLabel-asterisk': {
    display: 'none',
  },
  legend: {
    display: 'none',
  },
  fieldset: {
    top: '0 !important',
  },
};

export const textFieldOutlinedStyleWithLabel2 = {
  width: '100%',
  flexDirection: 'row !important',
  alignItems: 'center',
  flexWrap: 'wrap',
  '.MuiFormLabel-root': {
    position: 'static !important',
    transform: 'none !important',
    padding: '0.65rem 0.5rem',
    fontSize: '0.825rem',
    letterSpacing: '0.035em',
    lineHeight: 1,
    borderRadius: '3px',
    marginRight: '0.5rem',
    flexShrink: 0,
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.text.secondary,
    pointerEvents: 'all',
  },
  ".MuiFormLabel-root[data-shrink='false']": {
    position: 'static !important',
    transform: 'none !important',
  },
  ".MuiFormLabel-root[data-shrink='true']": {
    color: theme.palette.text.secondary,
  },
  '.MuiFormLabel-root.Mui-error': {
    color: theme.palette.text.secondary,
  },
  '.MuiInputBase-root': {
    backgroundColor: 'transparent',
    marginTop: '0 !important',
    flexGrow: 1,
  },
  '& input': {
    border: 'none !important',
  },
  '.MuiInputBase-root:not(.Mui-disabled, .Mui-error)::before': {
    borderColor: 'transparent',
  },
  '.MuiInputBase-root:hover:not(.Mui-disabled, .Mui-error):before': {
    borderColor: 'transparent',
  },
  '.MuiInputBase-root.Mui-disabled:before': {
    borderColor: theme.palette.divider,
  },
  '.MuiInputBase-root::after': {
    borderWidth: '1px',
  },
  '.MuiFormHelperText-root': {
    marginLeft: 0,
    width: '100%',
  },
  '.MuiFormLabel-asterisk': {
    display: 'none',
  },
  legend: {
    display: 'none',
  },
  fieldset: {
    top: '0 !important',
  },
};

export const autocompleteStyle = {
  display: 'flex',
  alignItems: 'center',
};

export const autocompleteTextFieldStyle = {
  '.MuiInputBase-input.MuiFilledInput-input:focus-visible': {
    outline: 'solid 2px',
    outlineOffset: '2px',
    borderRadius: '1px',
  },
  '.MuiFormLabel-root[data-shrink="false"]': {
    left: '-4px',
    top: '-6px',
    color: alpha(theme.palette.secondary.main, 0.5),
    transition: 'none',
  },
  '.MuiFormLabel-root[data-shrink="true"]': {
    color: 'transparent',
    opacity: 0,
    transition: 'none',
  },
  '.MuiFormHelperText-root': {
    marginLeft: '0',
  },
};
