import { memo } from 'react';
import { alpha, Box, CircularProgress, Paper, Stack, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { bgBlack, center, fontSerif } from '../../styles/commonStyles';
import Icon from '../ui/Icon';
import { theme } from '../../theme';
import { BalanceByMonthly, BalanceByYear } from '../../types';

interface BalanceSummaryProps {
  balanceByMonthly: BalanceByMonthly[] | null;
  balanceByYear: BalanceByYear[] | null;
  currentYear: number;
}

const BalanceSummary = ({ balanceByMonthly, balanceByYear, currentYear }: BalanceSummaryProps) => (
  <Grid container spacing={2}>
    {(
      balanceByYear || [
        { type: 'income', label: '収入', amount: null, icon: 'arrow-right-up-long-line' },
        {
          type: 'expense',
          label: '支出',
          amount: null,
          icon: 'arrow-right-down-long-line',
        },
        {
          type: 'balance',
          label: '収支',
          amount: null,
          icon: 'line-chart-line',
        },
      ]
    ).map(data => (
      <Grid key={data.type} size={{ xs: 12, sm: 4 }}>
        <Stack
          spacing={2}
          sx={{
            p: 2,
            borderRadius: '6px',
            position: 'relative',
            ...bgBlack,
            '&::before': {
              content: "''",
              position: 'absolute',
              top: '50%',
              left: '50%',
              width: 'calc(100% - 8px)',
              height: 'calc(100% - 8px)',
              border: '1px solid #fff',
              borderRadius: '4px',
              transform: 'translate(-50%, -50%)',
              zIndex: 2,
            },
          }}
        >
          <span
            style={{
              color: alpha(theme.palette.secondary.light, 0.8),
              letterSpacing: '0.075em',
              fontSize: '0.875rem',
            }}
          >
            <Icon icon={data.icon} color="#fff" style={{ marginRight: '0.5rem' }} />
            年間{data.label}
          </span>
          {data.amount !== null ? (
            <Typography
              variant="h6"
              sx={{
                textAlign: 'right',
                color: '#fff',
                fontWeight: 700,
                ...fontSerif,
              }}
            >
              ¥ {data.amount.toLocaleString('ja-JP')}
            </Typography>
          ) : (
            <Box
              sx={{
                width: '100%',
                height: '32px',
                ...center,
              }}
            >
              <CircularProgress color="secondary" size={20} />
            </Box>
          )}
        </Stack>
      </Grid>
    ))}
    {(
      balanceByMonthly || [
        { x: '1月', income: null, expense: null },
        { x: '2月', income: null, expense: null },
        { x: '3月', income: null, expense: null },
        { x: '4月', income: null, expense: null },
        { x: '5月', income: null, expense: null },
        { x: '6月', income: null, expense: null },
        { x: '7月', income: null, expense: null },
        { x: '8月', income: null, expense: null },
        { x: '9月', income: null, expense: null },
        { x: '10月', income: null, expense: null },
        { x: '11月', income: null, expense: null },
        { x: '12月', income: null, expense: null },
      ]
    ).map((data, index) => (
      <Grid key={index} size={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
        <Paper
          variant="outlined"
          sx={{
            p: 2,
            borderRadius: '6px',
            width: '100%',
          }}
        >
          <Typography
            variant="body1"
            component="h4"
            sx={{
              ...fontSerif,
              fontWeight: 700,
            }}
          >
            {currentYear}年{index + 1}月
          </Typography>
          {data.income !== null && data.expense !== null ? (
            <>
              <Stack
                direction="row"
                justifyContent="space-between"
                spacing={1.5}
                sx={{
                  marginTop: '0.75rem',
                }}
              >
                <span
                  style={{
                    backgroundColor: `${alpha(theme.palette.incomeColor.dark, 0.15)}`,
                    padding: '6px',
                    borderRadius: '2px',
                    lineHeight: 1,
                  }}
                >
                  <Icon
                    icon="arrow-right-up-long-line"
                    size="1rem"
                    color={theme.palette.incomeColor.dark}
                  />
                </span>

                <span>¥ {data.income.toLocaleString('ja-JP')}</span>
              </Stack>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
                spacing={1.5}
                sx={{
                  marginTop: '0.35rem',
                }}
              >
                <span
                  style={{
                    backgroundColor: `${alpha(theme.palette.expenseColor.dark, 0.15)}`,
                    padding: '6px',
                    borderRadius: '2px',
                    lineHeight: 1,
                  }}
                >
                  <Icon
                    icon="arrow-right-down-long-line"
                    size="1rem"
                    color={theme.palette.expenseColor.dark}
                  />
                </span>
                <span>¥ {data.expense.toLocaleString('ja-JP')}</span>
              </Stack>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
                spacing={1}
                sx={{
                  borderTop: `solid 1px ${theme.palette.divider}`,
                  paddingTop: '0.25rem',
                  marginTop: '0.6rem',
                }}
              >
                <span
                  style={{
                    fontSize: '0.875rem',
                    fontWeight: 500,
                    color: theme.palette.secondary.dark,
                    letterSpacing: '0.075em',
                    marginTop: '3px',
                    paddingRight: '0.5rem',
                    borderRight: `solid 1px ${theme.palette.divider}`,
                  }}
                >
                  収支
                </span>
                <span
                  style={{
                    fontWeight: 700,
                  }}
                >
                  ¥ {(data.income - data.expense).toLocaleString('ja-JP')}
                </span>
              </Stack>
            </>
          ) : (
            <Box
              sx={{
                width: '100%',
                height: '110px',
                ...center,
              }}
            >
              <CircularProgress color="secondary" size={20} />
            </Box>
          )}
        </Paper>
      </Grid>
    ))}
  </Grid>
);

export default memo(BalanceSummary);
