import { memo } from 'react';
import { Divider } from '@mui/material';
import { BubbleMenu, Editor } from '@tiptap/react';
import ColorPicker from './Colorpicker';
import { EditLinkPopover } from './EditLinkPopover';
import { ContentTypePicker } from './ContentTypePicker';
import { useTextmenuCommands } from '../hooks/useTextmenuCommands';
import { useTextmenuStates } from '../hooks/useTextmenuStates';
import { useTextmenuContentTypes } from '../hooks/useTextmenuContentTypes';
import { Toolbar } from '../../../ui/Toolbar';
import DropDown from '../../../ui/DropDown';
import { zEditorMenu } from '../../../../styles/commonStyles';

interface TextMenuProps {
  editor: Editor;
}

const TextMenu = ({ editor }: TextMenuProps) => {
  const commands = useTextmenuCommands(editor);
  const states = useTextmenuStates(editor);
  const blockOptions = useTextmenuContentTypes(editor);
  return (
    <BubbleMenu
      tippyOptions={{
        zIndex: zEditorMenu.zIndex + 1,
        popperOptions: {
          placement: 'top-start',
          modifiers: [
            {
              name: 'preventOverflow',
              options: {
                boundary: 'viewport',
                padding: 8,
                zIndex: zEditorMenu.zIndex + 2,
              },
            },
            {
              name: 'flip',
              options: {
                fallbackPlacements: ['bottom-start', 'top-end', 'bottom-end'],
              },
            },
          ],
        },
        maxWidth: 'calc(100vw - 16px)',
      }}
      editor={editor}
      pluginKey="textMenu"
      shouldShow={states.shouldShow}
      updateDelay={100}
    >
      <Toolbar.Wrapper>
        <ContentTypePicker options={blockOptions} />
        <Divider orientation="vertical" flexItem />
        <Toolbar.Button
          icon="bold"
          ariaLabel="太字"
          tooltip="太字"
          onClick={commands.onBold}
          isActive={states.isBold}
        />
        <Toolbar.Button
          icon="italic"
          ariaLabel="斜体"
          tooltip="斜体"
          onClick={commands.onItalic}
          isActive={states.isItalic}
        />
        <Toolbar.Button
          icon="underline"
          ariaLabel="下線"
          tooltip="下線"
          onClick={commands.onUnderline}
          isActive={states.isUnderline}
        />
        <Toolbar.Button
          icon="strikethrough"
          ariaLabel="取り消し線"
          tooltip="取り消し線"
          onClick={commands.onStrike}
          isActive={states.isStrike}
        />
        <Toolbar.Button
          icon="code-line"
          ariaLabel="コード"
          tooltip="コード"
          onClick={commands.onCode}
          isActive={states.isCode}
        />
        <Toolbar.Button
          icon="code-block"
          ariaLabel="コードブロック"
          tooltip="コードブロック"
          onClick={commands.onCodeBlock}
          isActive={false}
        />
        <EditLinkPopover onSetLink={commands.onLink} />
        <DropDown
          id="highlightColor"
          icon="mark-pen-line"
          label="ハイライトカラー"
          padding="0.5rem"
        >
          <ColorPicker
            color={states.currentHighlight as string}
            onChange={commands.onChangeHighlight}
            onClear={commands.onClearHighlight}
          />
        </DropDown>
        <DropDown id="textColor" icon="palette-line" label="テキストカラー" padding="0.5rem">
          <ColorPicker
            color={states.currentColor as string}
            onChange={commands.onChangeColor}
            onClear={commands.onClearColor}
          />
        </DropDown>
        <DropDown id="options" icon="more-line" label="他のオプション">
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: '0.25rem',
              flexWrap: 'wrap',
            }}
          >
            <Toolbar.Button
              icon="subscript"
              ariaLabel="下付き"
              tooltip="下付き"
              onClick={commands.onSubscript}
              isActive={states.isSubscript}
            />
            <Toolbar.Button
              icon="superscript"
              ariaLabel="上付き"
              tooltip="上付き"
              onClick={commands.onSuperscript}
              isActive={states.isSuperscript}
            />
            <Toolbar.Button
              icon="align-left"
              ariaLabel="左揃え"
              tooltip="左揃え"
              onClick={commands.onAlignLeft}
              isActive={states.isAlignLeft}
            />
            <Toolbar.Button
              icon="align-center"
              ariaLabel="中央揃え"
              tooltip="中央揃え"
              onClick={commands.onAlignCenter}
              isActive={states.isAlignCenter}
            />
            <Toolbar.Button
              icon="align-right"
              ariaLabel="右揃え"
              tooltip="右揃え"
              onClick={commands.onAlignRight}
              isActive={states.isAlignRight}
            />
            <Toolbar.Button
              icon="align-justify"
              ariaLabel="両端揃え"
              tooltip="両端揃え"
              onClick={commands.onAlignJustify}
              isActive={states.isAlignJustify}
            />
          </div>
        </DropDown>
      </Toolbar.Wrapper>
    </BubbleMenu>
  );
};

export default memo(TextMenu);
